import styled from "styled-components";

export const DetailsContainer = styled.div`
  margin-bottom: 40px;
`;

type RowWrapperProps = {
  view?: string;
};

export const RowWrapper = styled.div<RowWrapperProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.view === "desktop"
      ? `
    @media (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 30px;
    }
  `
      : `
    @media (min-width: 550px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 30px;
    }
  `}
`;
