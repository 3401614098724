type employeeAction =
  | {
      type: "UPDATE_EMPLOYEE_INFORMATION";
      payload: any;
    }
  | { type: "CLEAR_EMPLOYEE_INFORMATION" }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_IMAGE_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: any }
  | {
      type: "GET_EMPLOYEE_IMAGE";
      payload: string;
    }
  | {
      type: "UPDATE_EMPLOYEE_IMAGE";
      payload: string;
    };

export type EmployeeActions = employeeAction;

export type EmployeeState = {
  employee: any | null; //Change when we know how employees will look
  employeeImage: string | null;
  isLoading: boolean;
  imageIsLoading: boolean;
  error: { message: string; err: Error } | null;
};

export const employeeInitState: EmployeeState = {
  employee: null,
  employeeImage: null,
  isLoading: false,
  imageIsLoading: false,
  error: null,
};

export const employeeStateReducer = (
  state: EmployeeState,
  action: EmployeeActions
): EmployeeState => {
  switch (action.type) {
    case "UPDATE_EMPLOYEE_INFORMATION": {
      const newState = {
        ...state,
        employee: { ...state.employee, ...action.payload },
      };
      return newState;
    }
    case "CLEAR_EMPLOYEE_INFORMATION": {
      return {
        ...state,
        ...employeeInitState,
      };
    }
    case "GET_EMPLOYEE_IMAGE": {
      return {
        ...state,
        employeeImage: action.payload,
      };
    }
    case "UPDATE_EMPLOYEE_IMAGE": {
      return {
        ...state,
        employeeImage: action.payload,
      };
    }
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_IMAGE_IS_LOADING": {
      return { ...state, imageIsLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
