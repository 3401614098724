import styled from "styled-components";
import { Color } from "../../../../../config/colors";

export const FavoriteImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 10px;
`;

export const FavoriteWorkerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  grid-row: 2/3;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  padding: 8px;
  border-radius: 8px;
  h4 {
    margin: 0 8px;
  }

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const NoFavoritesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopLinksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
