import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { Text, CheckInOutModal, GigInfoContainer } from "./clockStamp.styled";
import { FontFamily, FontSize } from "config/font";
import { Modal } from "@material-ui/core";
import moment from "moment-timezone";
import { TimeReportForStamping } from "model/Company";

type Props = {
  timeReport?: TimeReportForStamping;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setKeyShouldBeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
};

export const ClockStampCheckInOutModal: React.FC<Props> = ({
  timeReport,
  setShowModal,
  setKeyShouldBeDisabled,
  showModal
}) => {
  const { t } = useTranslation();
  
  const activeShift = timeReport?.active;
  const isCheckOut = activeShift?.checkInTime && !activeShift?.checkOutTime;
  const userName = activeShift?.firstName;

  const handleCloseModal = () => {
    setShowModal(false);
    setKeyShouldBeDisabled(false);
  };

  const formatTime = (time: string | undefined) =>
    moment.tz(time, "UTC").tz("Europe/Stockholm").format("YYYY-MM-DD HH:mm");

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <CheckInOutModal>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H3}
          color={Color.MidnightBlue}
        >
          {isCheckOut
            ? `${t("StampClock.HaveANiceDay")} ${userName}!`
            : `${t("StampClock.Welcome")} ${userName}!`}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H4}
          color={Color.MidnightBlue}
        >
          {isCheckOut
            ? t("StampClock.YouAreCheckedOut")
            : t("StampClock.YouAreCheckedIn")}
        </Text>
        {!isCheckOut && activeShift && (
          <GigInfoContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {activeShift.gigRole}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.StartTime")}: ${formatTime(activeShift.startTime)}`}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
            >
              {`${t("StampClock.EndTime")}: ${formatTime(activeShift.endTime)}`}
            </Text>
          </GigInfoContainer>
        )}
      </CheckInOutModal>
    </Modal>
  );
};
