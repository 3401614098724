import { useEffect, useState, useRef } from "react";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Dropdown, ListViewModel } from "components/dropdown/Dropdown";
import { Color } from "config/colors";
import {
  Wrapper,
  DropdownWrapper,
  SaveButton,
  StyledProgress,
  PreviewBody,
  PreviewContent,
  SaveContent,
  LoadingWrapper,
  SaveButtonModal,
  ColumnCenterDiv,
  TextInput,
} from "./calendarTemplatePicker.styled";
import { useTranslation } from "react-i18next";
import { CalendarTemplateDto } from "model/GigTemplate";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { adjustShiftsToWeek } from "web-apps/company/utils/utils";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { CompanyCalendarGigDto } from "model/Calendar";
import {
  CompanyGigRequestDto,
  GigResolution,
  GigStatus,
  PaymentMethod,
} from "model/Gig";
import { CircularProgress, Modal } from "@material-ui/core";
import { PreviewCalendar } from "./previewCalendar.component";
import { useSaveManyGigs } from "hooks/useSaveManyGigs";
import { useGetManyGigs } from "hooks/useGetManyGigs";
import moment, { Moment } from "moment";
import { Api } from "services/api/api.service";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { BannerComponent } from "components/banners/banner.component";

type Props = {
  startDate: Date;
  refetchCalendarGigs: () => void;
  currentDates: { date: Moment; weekDay: string }[];
};

export const CalendarTemplatePicker = ({
  startDate,
  refetchCalendarGigs,
  currentDates,
}: Props) => {
  const [companyState] = useCompanyStore();
  const [calendarState] = useCalendarStore();
  const [templates, setTemplates] = useState<CalendarTemplateDto[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<CalendarTemplateDto>();
  const [calendarData, setCalendarData] = useState<CompanyCalendarGigDto[]>([]);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [gigs, isLoadingCreateGigs, , saveManyGigs] = useSaveManyGigs();
  const [, , , getManyGigs] = useGetManyGigs();
  const { t } = useTranslation();
  const isLoading = isLoadingApi || isLoadingCreateGigs;
  const [templateToDelete, setTemplateToDelete] =
    useState<ListViewModel | null>(null);
  const [passedShifts, setPassedShifts] = useState(false);

  const apiState = useRef({ loading: false, completed: false, page: 0 });

  const fetchCalendarTemplates = () => {
    if (
      !companyState.company ||
      apiState.current.loading ||
      apiState.current.completed
    )
      return;

    apiState.current.loading = true;
    setIsLoadingApi(true);

    Api()
      .company.template.getWeekTemplates(companyState.company.id)
      .then((res) => {
        setTemplates(res.data);
        setIsLoadingApi(false);
        apiState.current.loading = false;
      })
      .catch((err) => {
        setIsLoadingApi(false);
        apiState.current.loading = false;
      });
  };

  useEffect(() => {
    fetchCalendarTemplates();
  }, []);

  // Run when all gigs are created after hitting Apply
  // Will close Modal and update the calendar state.
  useEffect(() => {
    if (gigs) {
      // Reftech Calendar Gigs when gigs are saved to API
      refetchCalendarGigs();
      setIsPreviewOpen(false);
    }
  }, [gigs]);

  const onSaveCalendarTemplate = () => {
    if (!calendarState.currentWeek.length || apiState.current.loading) {
      return;
    }

    apiState.current.loading = true;
    setIsLoadingApi(true);
    setIsSaveOpen(false);

    getManyGigs(calendarState.currentWeek.map((c) => c.id))
      .then((fetchedGigs) => {
        if (companyState.company?.id) {
          const newGigs = fetchedGigs.map((gig) => {
            let shifts = gig.shifts;
            let weeklyCalendarShifts = calendarState.currentWeek.find(
              (c) => c.id === gig.id
            )?.shifts;
            let filteredShifts = shifts.filter((shift) =>
              weeklyCalendarShifts?.find((matching) => matching.id === shift.id)
            );

            if (filteredShifts.length > 0) {
              return {
                ...gig,
                shifts: filteredShifts,
                id: -1,
                description: gig.description ?? "",
                paymentType: gig.paymentType ?? PaymentMethod.NONE,
              };
            }
          });

          const data: any = {
            companyId: companyState.company?.id,
            name: templateName,
            gigs: newGigs,
          };
          Api()
            .company.template.saveWeekTemplate(companyState.company.id, data)
            .then((res) => {
              setTemplates([...templates, res.data]);
            });
        }
      })
      .finally(() => {
        apiState.current.loading = false;
        setIsLoadingApi(false);
      });
  };

  const onTemplateSelected = (vm: ListViewModel) => {
    const { value } = vm;
    const weekTemplate = templates.find((t) => t.id === value);
    if (weekTemplate) {
      const calendarDtos: CompanyCalendarGigDto[] = [];
      let hasPassedShifts = false;

      weekTemplate.gigs.forEach((gigTemplate, id) => {
        let adjustedShifts = adjustShiftsToWeek(gigTemplate, startDate);
        const { amountOfWorkers, role } = gigTemplate;

        let filteredShifts = adjustedShifts.filter(
          (shift) => new Date(shift.startTime) > new Date()
        );
        if (filteredShifts.length < adjustedShifts.length) {
          hasPassedShifts = true;
        }
        if (filteredShifts?.length > 0) {
          calendarDtos.push({
            id,
            resolution: GigResolution.NOT_FILLED,
            status: GigStatus.CREATED,
            amountOfWorkers,
            role,
            applications: [],
            shifts: filteredShifts.map(({ startTime, endTime }, shiftId) => ({
              id: shiftId,
              timeReports: [],
              startTime,
              endTime,
            })),
          });
        }
      });
      setPassedShifts(hasPassedShifts);

      // Sort the adjusted time after
      const sorted = [...calendarDtos].sort(
        (e1, e2) =>
          new Date(e1.shifts[0].startTime).getTime() -
          new Date(e2.shifts[0].startTime).getTime()
      );
      setCalendarData(sorted);
      setSelectedTemplate(weekTemplate);
      setIsPreviewOpen(true);
    }
  };

  const removeTemplate = (vm: ListViewModel) => {
    // TODO: Remove calendar template API
    setShowDeleteModal(true);

    if (companyState.company?.id && typeof vm.value === "number") {
      apiState.current.loading = true;
      setIsLoadingApi(true);
      Api()
        .company.template.deleteWeeklyTemplate(
          companyState.company?.id,
          vm.value
        )
        .then(() => {
          setTemplates(templates.filter((t) => t.id !== vm.value));
          apiState.current.loading = false;
          setIsLoadingApi(false);
          setShowDeleteModal(false);
        })
        .catch(() => {
          apiState.current.loading = false;
          setIsLoadingApi(false);
        });
    }
    setSelectedTemplate(undefined);
  };

  const onRemoveTemplate = (vm: ListViewModel) => {
    setShowDeleteModal(true);
    setTemplateToDelete(vm);
  };

  const onApplyTemplate = () => {
    if (selectedTemplate) {
      const request = selectedTemplate.gigs.map<CompanyGigRequestDto>((t) => ({
        ...t,
        location: companyState.company?.location || "59.3293, 18.0686",
        shifts: adjustShiftsToWeek(t, startDate),
      }));
      saveManyGigs(request);
    } else {
      setIsPreviewOpen(false);
    }
  };

  useEffect(() => {
    if (!isPreviewOpen) {
      setSelectedTemplate(undefined);
    }
  }, [isPreviewOpen]);

  return (
    <Wrapper>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DropdownWrapper>
          {templates?.length > 0 ? (
            <Dropdown
              textColor={Color.MidnightBlue}
              smallerFont
              smallerHeight
              placeholder={t("GigsCompany.SelectTemplate")}
              activeValue={selectedTemplate?.id}
              items={templates.map((m: any) => ({
                value: m.id,
                label: m.name,
              }))}
              disabled={isLoading}
              showSpinner={isLoading}
              maxHeight={200}
              onChange={onTemplateSelected}
              onRemove={onRemoveTemplate}
            />
          ) : isLoading ? (
            <StyledProgress size={18} color="inherit" />
          ) : (
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
              style={{ margin: 0 }}
            >
              {t("GigsCompany.CalendarTemplateEmpty")}
            </Text>
          )}
        </DropdownWrapper>

        <SaveButton
          backgroundColor={Color.MidnightBlue}
          icon={isLoadingApi}
          disabled={!calendarState.currentWeek.length || isLoading}
          onClick={() => {
            setTemplateName(
              `${t("CalendarCompany.Week")} ${moment(startDate).week()}`
            );
            setIsSaveOpen(true);
          }}
        >
          {isLoadingApi ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            t("GigsCompany.CreateCalendarTemplate")
          )}
        </SaveButton>
      </div>

      <Modal
        open={isPreviewOpen}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
      >
        <PreviewBody>
          <PreviewContent>
            <H3>
              {t("GigsCompany.CalendarPreview") +
                ` (${selectedTemplate?.name || ""})`}
            </H3>

            {passedShifts && (
              <BannerComponent
                backgroundColor={Color.SeaBlue200}
                fill={Color.Destructive}
              >
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.Destructive}
                >
                  {t("CalendarCompany.TemplateHasBeenAdjusted")}
                </Text>
                <Text color={Color.MidnightBlue}>
                  {t("CalendarCompany.PastTemplateInfo")}
                </Text>
              </BannerComponent>
            )}
            <PreviewCalendar
              calendarData={calendarData}
              currentWeek={moment(startDate).week()}
              loading={isLoadingCreateGigs}
              onApply={onApplyTemplate}
              onCancel={() => {
                setIsPreviewOpen(false);
              }}
              currentDates={currentDates}
            />
          </PreviewContent>
          {isLoadingCreateGigs && (
            <LoadingWrapper>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.H4}
                color={Color.SeaBlue200}
              >
                {t("GigsCompany.CalendarCreating")}
              </Text>
              <StyledProgress />
            </LoadingWrapper>
          )}
        </PreviewBody>
      </Modal>

      <Modal open={isSaveOpen} onClose={() => setIsSaveOpen(false)}>
        <SaveContent>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
            color={Color.SeaBlue600}
          >
            {t("GigsCompany.CreateCalendarName")}
          </Text>
          <TextInput
            value={templateName}
            onChange={(e) => {
              setTemplateName(e.currentTarget.value);
            }}
          />
          <ColumnCenterDiv>
            <SaveButtonModal
              disabled={!templateName || templateName.trim().length < 3}
              onClick={onSaveCalendarTemplate}
            >
              {t("GigsCompany.CreateCalendarTemplate")}
            </SaveButtonModal>
            <CustomLink
              onClick={() => setIsSaveOpen(false)}
              color={Color.Destructive}
            >
              {t("General.Cancel")}
            </CustomLink>
          </ColumnCenterDiv>
        </SaveContent>
      </Modal>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <SaveContent>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
            color={Color.SeaBlue600}
          >
            {t("CalendarCompany.DeleteWeekTemplate")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.Standard}
            color={Color.SeaBlue600}
          >
            {t("CalendarCompany.DoYouWantToDeleteWeekTemplate", {
              template: templateToDelete?.label || "",
            })}
          </Text>
          <ButtonStyled
            style={{ marginBottom: "10px" }}
            backgroundColor={Color.Destructive}
            onClick={() => {
              templateToDelete && removeTemplate(templateToDelete);
            }}
          >
            {t("General.Remove")}
          </ButtonStyled>

          <ButtonStyled
            backgroundColor={Color.SeaBlue400}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            {t("General.Cancel")}
          </ButtonStyled>
        </SaveContent>
      </Modal>
    </Wrapper>
  );
};
