import React from "react";
import { useAuthStore } from "stores/authStore/authStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { ApplicationList } from "web-apps/company/components/applications/applicationList.component";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { LoaderProgress } from "components/icon/loaderProgress.component";

export const ApplicationPage = () => {
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  const [applicationState] = useApplicationStore();

  return (
    <LoggedInPageContainer>
      {authState.isLoading ||
      companyState.isLoading ||
      applicationState.isLoading ? (
        <LoaderProgress />
      ) : (
        <ApplicationList />
      )}
    </LoggedInPageContainer>
  );
};
