import React from "react";
import { useAuthStore } from "stores/authStore/authStore";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3 } from "components/Typography/text.styled";
import { LoaderProgress } from "components/icon/loaderProgress.component";

export const ProfilePage = () => {
  const [authState] = useAuthStore();

  return (
    <LoggedInPageContainer>
      {authState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          <H3>Profile</H3>
        </>
      )}
    </LoggedInPageContainer>
  );
};
