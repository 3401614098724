import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { ReactComponent as ImageIcon } from "images/icons/svg/image.svg";
import { H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  InputContainer,
  LabelStyled,
  TextInput,
  TextArea,
  LabelFileInput,
  IconInput,
} from "components/form/inputs/inputs.styled";
import {
  CompanyImage,
  DividerLine,
  ProfileDiv,
  IconWrapper,
} from "../../profile/profileInfo.styled";
import {
  ImageContainer,
  FormStyled,
  SettingsProfileGrid,
} from "./profileSettings.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { clamp } from "web-apps/company/utils/utils";

type Props = {
  view?: string;
};

export const ProfileSettings: React.FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();
  const inputEl = useRef<HTMLInputElement>(null);
  const [companyName, setCompanyName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [description, setDescription] = useState("");
  const [imageError, setImageError] = useState(false);
  const history = useHistory();
  const [websiteError, setWebsiteError] = useState("");
  const textAreaEl = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (companyState.company) {
      setCompanyName(companyState.company.companyName || "");
      setDescription(companyState.company.description || "");
      setWebsiteUrl(companyState.company.websiteUrl || "");
    }
  }, [companyState.company]);

  useEffect(() => {
    const el = textAreaEl.current;
    if (!el) return;
    el.style.height = clamp(el.scrollHeight, 29, 1500) + "px";
  }, [description]);

  const validate = () => {
    if (companyName) {
      return true;
    } else {
      return false;
    }
  };

  const validateWebsite = () => {
    if (websiteUrl) {
      const reg =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
      if (reg.test(websiteUrl)) {
        setWebsiteError("");
        return true;
      } else {
        setWebsiteError(t("ProfileCompany.PleaseEnterValidWebsiteUrl"));
        return false;
      }
    } else {
      setWebsiteError("");
      return true;
    }
  };

  const madeChanges = () => {
    if (
      companyName !== companyState.company?.companyName ||
      websiteUrl !== companyState.company.websiteUrl ||
      description !== companyState.company.description
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpdate = () => {
    if (validate() && validateWebsite()) {
      if (companyState.company) {
        const data = {
          ...companyState.company,
          companyName,
          websiteUrl,
          description,
        };
        companyActions
          .saveCompany(data, companyState.companies, companyState.company.id)
          .then(() => {
            history.push("./profile");

            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.Updated"),
                message: t("ProfileCompany.CompanyHasBeenUpdated"),
              },
            });
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.OhNo"),
                message: t("ProfileCompany.CompanyCouldNotBeUpdated"),
              },
            });
          });
      }
    }
  };

  const updateImage = async () => {
    if (inputEl.current && companyState.company) {
      const file = inputEl.current.files![0];
      if (file && file.size < 20971520) {
        setImageError(false);
        companyActions
          .updateCompanyImage(companyState.company.id, file)
          .then(() => {
            if (companyState.company?.id) {
              companyActions.getCompanyImage(companyState.company.id);
            }
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.OhNo"),
                message: t("General.ErrorOccurred"),
              },
            });
          });
      } else {
        setImageError(true);
      }
    }
  };

  return (
    <>
      <Prompt
        when={madeChanges()}
        message={t("ProfileCompany.YouHaveUnSavedChanges")}
      />
      <H3>{t("ProfileCompany.EditProfile")}</H3>
      <SettingsProfileGrid>
        <ProfileDiv>
          <ImageContainer>
            {companyState.imageIsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {companyState.companyImage ? (
                  <CompanyImage src={companyState.companyImage} />
                ) : (
                  <CustomIcon
                    className="icon"
                    name="image"
                    square
                    width="250px"
                    size="190px"
                    color={Color.SeaBlue500}
                    backgroundColor={Color.SeaBlue300}
                    padding="10px"
                  />
                )}
                <IconWrapper button className="icon">
                  <ImageIcon />
                  <LabelFileInput htmlFor="upload-button" />
                  <IconInput
                    hidden
                    type="file"
                    id="upload-button"
                    ref={inputEl}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      updateImage();
                    }}
                  />
                </IconWrapper>
              </>
            )}
          </ImageContainer>
          {imageError && (
            <Text fontSize={FontSize.Small} color={Color.Destructive}>
              {t("ProfileCompany.FileTooLarge")}
            </Text>
          )}
        </ProfileDiv>
        <DividerLine style={{ gridRow: "1/2" }} />
        <FormStyled>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.DisplayName")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              type="text"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.currentTarget.value);
              }}
            />
          </InputContainer>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.Website")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              type="text"
              placeholder="http://"
              value={websiteUrl}
              onChange={(e) => {
                setWebsiteUrl(e.currentTarget.value);
              }}
            />
            {websiteError && (
              <Text fontSize={FontSize.Small} color={Color.Destructive}>
                {websiteError}
              </Text>
            )}
          </InputContainer>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.Bio")}</LabelStyled>
            <TextArea
              style={{ height: 20 }}
              ref={textAreaEl}
              name="bio"
              fontSize={FontSize.Large}
              placeholder={t("RegisterCompany.TellUsAboutCompany")}
              value={description}
              rows={5}
              onChange={(e) => {
                setDescription(e.currentTarget.value);
              }}
            />
          </InputContainer>
        </FormStyled>
      </SettingsProfileGrid>
      <ButtonStyled
        disabled={!validate() || !madeChanges()}
        onClick={handleUpdate}
      >
        {t("General.Save")}
      </ButtonStyled>
    </>
  );
};
