import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  CalendarContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { Text } from "components/Typography/text.styled";
import { BannerComponent } from "components/banners/banner.component";
import { Dashboard } from "web-apps/company/components/dashboard/dashboard.component";

export const DashboardPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();

  return (
    <>
      {authState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <CalendarContainer>
          {companyState.company?.verified ? (
            <Dashboard />
          ) : (
            <BannerComponent
              icon="clock"
              backgroundColor={Color.ChampagnePink}
              fill={Color.Destructive}
            >
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {t("GigsCompany.UnverifiedAccount")}
              </Text>
              <Text>{t("GigsCompany.UnverifiedAccountDetails")}</Text>
            </BannerComponent>
          )}
        </CalendarContainer>
      )}
    </>
  );
};
