import React from "react";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utils/utils";
import { WorkerDocument } from "model/WorkerDocument";
import {
  WorkerDocumentDownload,
  WorkerDocumentDownloadWrapper,
  WorkerDocumentList,
  WorkerDocumentListItem,
  WorkerDocumentListItemTitle,
  WorkerDocumentListTitle,
  WorkerDocumentNoFiles,
} from "./workerDocument.styled";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  documents: WorkerDocument[];
};

export const WorkerDocuments: React.FC<Props> = ({ documents }) => {
  const { t } = useTranslation();

  const downloadFile = (url: string) => {
    window.location.href = url;
  };

  return (
    <>
      <WorkerDocumentListTitle>
        {t("General.Documents")}
      </WorkerDocumentListTitle>
      <WorkerDocumentList>
        {documents &&
          documents.length > 0 &&
          documents.map((document, index) => (
            <WorkerDocumentListItem key={index}>
              <WorkerDocumentListItemTitle>
                <span>{document.fileName}</span>
              </WorkerDocumentListItemTitle>
              <WorkerDocumentDownloadWrapper>
                <WorkerDocumentDownload
                  onClick={() => downloadFile(document.downloadUrl)}
                  target="_blank"
                >
                  <span style={{ marginRight: 15 }}>
                    {t("General.Download")} ( {formatBytes(document.fileSize)} )
                  </span>
                  <span>
                    <CustomIcon
                      size="14px"
                      color={Color.White}
                      name="chevron-down"
                    />
                  </span>
                </WorkerDocumentDownload>
              </WorkerDocumentDownloadWrapper>
            </WorkerDocumentListItem>
          ))}
        {(!documents || documents.length === 0) && (
          <WorkerDocumentNoFiles>
            {t("General.NoDocumentsUploaded")}
          </WorkerDocumentNoFiles>
        )}
      </WorkerDocumentList>
    </>
  );
};
