import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import se from "services/translation/lang/swedishCountries.json";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  Text,
  H3,
  characterCounter,
  CustomLink,
} from "components/Typography/text.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import {
  EmployeeListStyled,
  ButtonContainer,
  NoApplicantsContainer,
  HeaderRow,
  PhoneInputWrapper,
  FavoritesWrapper,
  LabelsContainer,
  SettingsModalBody,
} from "./workpool.styled";
import { TabsStyled, TabStyled } from "../tab/tab.styled";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { WorkpoolListItem } from "./workpoolListItem.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { ModalBody } from "../applicantDetails/applicantDetails.styled";
import { WorkpoolWorker } from "model/Workpool";
import { Cell } from "./workpoolWorkerDetails.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  NumberInput,
  TextArea,
  TextInput,
} from "components/form/inputs/inputs.styled";
import { SkillTag } from "../gigDetail/gigDetail.styled";
import {
  HourlyRateContainer,
  NumberInputContainer,
} from "../saveGig/hourlyRate/hourlyRate.styled";
type EmployeesByStatus = {
  [key: string]: WorkpoolWorker[];
};
export const Workpool = () => {
  const [workpoolState, workpoolActions] = useWorkpoolStore();
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [currentEmployeeId, setCurrentEmployeeId] = useState<
    number | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [employeesByStatus, setEmployeesByStatus] = useState<EmployeesByStatus>(
    {
      Verified: [],
      Unverified: [],
      Invited: [],
    }
  );
  const [checkedEmployees, setCheckedEmployees] = useState<WorkpoolWorker[]>(
    []
  );
  const [showReferenceCodeInfo, setShowReferenceCodeInfo] = useState(false);
  const [showCopiedText, setSshowCopiedText] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [comment, setComment] = useState("");
  const [labels, setLabels] = useState<any[]>([]);
  const [rate, setRate] = useState(0);
  const [newLabel, setNewLabel] = useState("");
  let status = new URLSearchParams(window.location.search).get("status");
  if (status === null) {
    status = "Unverified";
  }
  const history = useHistory();

  const removeLabel = (selectedLabel: any) => {
    setLabels(labels.filter((label) => label.label !== selectedLabel.label));
  };
  useEffect(() => {
    setEmployeesByStatus({
      Verified: workpoolState.verifiedEmployees,
      Unverified: workpoolState.unverifiedEmployees.filter(
        (employee) => employee.requestType !== "CompanyRequest"
      ),
      Invited: workpoolState.unverifiedEmployees.filter(
        (employee) => employee.requestType !== "WorkerRequest"
      ),
    });
  }, [workpoolState.verifiedEmployees, workpoolState.unverifiedEmployees]);

  const updateEmployeeStatus = (updateStatus: "Verify" | "Reject") => {
    checkedEmployees.forEach((employee) => {
      if (companyState.company) {
        if (updateStatus === "Verify") {
          workpoolActions
            .verifyEmployee(companyState.company.id, employee)
            .then(() => {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark",
                  title: t("CompanyGroup.Verified"),
                  message: t("CompanyGroup.ApplicantHasBeenVerified"),
                },
              });
              setCheckedEmployees([]);
            })
            .catch(() => {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert",
                  title: t("Alerts.OhNo"),
                  message: t("CompanyGroup.ApplicationCouldNotBeUpdated"),
                },
              });
            });
        } else if (updateStatus === "Reject") {
          workpoolActions
            .deleteEmployee(companyState.company.id, employee.workerId)
            .then(() => {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark",
                  title: t("CompanyGroup.Rejected"),
                  message: t("CompanyGroup.ApplicationHasBeenRejected"),
                },
              });
              setCheckedEmployees([]);
            })
            .catch(() => {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert",
                  title: t("Alerts.OhNo"),
                  message: t(
                    "ApplicationsCompany.ApplicationCouldNotBeUpdated"
                  ),
                },
              });
            });
        }
      }
    });
  };

  const removeEmployee = () => {
    if (companyState.company && currentEmployeeId) {
      workpoolActions
        .deleteEmployee(companyState.company.id, currentEmployeeId)
        .then(() => {
          setCurrentEmployeeId(undefined);
          setShowModal(false);
        });
    }
  };

  const requestEmployee = () => {
    if (companyState.company) {
      const data = {
        companyId: companyState.company?.id,
        defaultHourlyRate: rate,
        labels: labels,
        comment: comment.trim(),
        phoneNumber: phoneNumber.replace("+", "").trim(),
      };
      workpoolActions
        .addNewEmployee(companyState.company?.id, data)
        .then((res) => {
          setPhoneNumber("");
          setComment("");
          setRate(0);
          setLabels([]);
          setNewLabel("");
          setShowEmployeeModal(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.Sent"),
              message: t("CompanyGroup.InviteToHasBeenSent", {
                worker: `${res.data.worker.firstName} ${res.data.worker.lastName}`,
              }),
            },
          });
        })
        .catch((err) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message:
                t("Alerts.SomethingWentWrong") +
                " " +
                t("CompanyGroup.PleaseEnsure"),
            },
          });
        });
    }
  };

  return (
    <>
      <HeaderRow>
        <H3> {t("CompanyGroup.Workpool")}</H3>
        {companyState.company?.referenceCode && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HeaderRow>
              <ButtonStyled
                fullWidth
                style={{ marginRight: "10px" }}
                onClick={() => setShowEmployeeModal(true)}
              >
                {t("CompanyGroup.Invite")}
              </ButtonStyled>
              <ButtonStyled
                icon
                backgroundColor={Color.SeaBlue400}
                onMouseEnter={() => setShowReferenceCodeInfo(true)}
                onMouseLeave={() => setShowReferenceCodeInfo(false)}
                color={Color.White}
                onClick={() => {
                  if (companyState.company?.referenceCode) {
                    navigator.clipboard.writeText(
                      companyState.company.referenceCode
                    );
                    setSshowCopiedText(true);
                    setTimeout(() => {
                      setSshowCopiedText(false);
                    }, 2000);
                  }
                }}
              >
                {companyState.company.referenceCode}
                <CustomIcon
                  name="clone"
                  size="22px"
                  color={Color.White}
                  padding="2px"
                />
              </ButtonStyled>
              <FavoritesWrapper
                onClick={() => {
                  history.push("profile/favorites");
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomIcon
                    name="favorites-active"
                    size="38px"
                    color={Color.BurntSienna}
                    padding="8px"
                  />
                  <Text
                    fontSize={FontSize.Large}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t("CompanyFavorite.Favorites")}
                  </Text>
                </div>
                <div>
                  <CustomIcon
                    name="chevron-right"
                    size="38px"
                    color={Color.BurntSienna}
                    padding="8px"
                  />
                </div>
              </FavoritesWrapper>
            </HeaderRow>
            {showReferenceCodeInfo && (
              <Cell
                style={{
                  margin: 20,
                  maxWidth: "200px",
                  position: "absolute",
                  top: "160px",
                  marginLeft: 70,
                }}
              >
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue600}
                  style={{
                    margin: 0,
                  }}
                >
                  {t("CompanyGroup.CompanyCodeInfo")}
                </Text>
              </Cell>
            )}
            {showCopiedText && (
              <Cell
                style={{
                  margin: 20,
                  maxWidth: "160px",
                  position: "absolute",
                  top: "65px",
                  marginLeft: 90,
                  textAlign: "center",
                }}
              >
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue600}
                  style={{
                    margin: 0,
                  }}
                >
                  {t("General.CopiedToClipboard")}
                </Text>
              </Cell>
            )}
          </div>
        )}
      </HeaderRow>
      <TabsStyled>
        <TabStyled
          active={status === "Verified"}
          onClick={() => history.push(`./work-pool?status=Verified`)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyGroup.Members")} (
            {workpoolState.verifiedEmployees.length})
          </Text>
        </TabStyled>
        <TabStyled
          active={status === "Unverified"}
          onClick={() => history.push(`./work-pool?status=Unverified`)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyGroup.Unverified")} (
            {employeesByStatus["Unverified"].length})
          </Text>
        </TabStyled>
        <TabStyled
          active={status === "Invited"}
          onClick={() => history.push(`./work-pool?status=Invited`)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyGroup.Invited")} ({employeesByStatus["Invited"].length})
          </Text>
        </TabStyled>
      </TabsStyled>
      {employeesByStatus[status || "Verified"]?.length !== 0 ? (
        <>
          <EmployeeListStyled>
            {employeesByStatus[status || "Verified" || "Unverified"].map(
              (employee: WorkpoolWorker) => {
                return (
                  <WorkpoolListItem
                    key={employee.id}
                    employeeData={employee}
                    filterEmployeeStatus={status || "Pending"}
                    checkedEmployees={checkedEmployees}
                    setCheckedEmployees={setCheckedEmployees}
                    setCurrentEmployeeId={setCurrentEmployeeId}
                    setShowModal={setShowModal}
                  />
                );
              }
            )}
          </EmployeeListStyled>
          {status === "Unverified" && (
            <ButtonContainer>
              <ButtonStyled
                disabled={checkedEmployees.length < 1}
                backgroundColor={Color.Destructive}
                onClick={() => {
                  updateEmployeeStatus("Reject");
                }}
              >
                {t("GigsCompany.Reject")}
              </ButtonStyled>
              <ButtonStyled
                disabled={checkedEmployees.length < 1}
                backgroundColor={Color.BurntSienna}
                onClick={() => {
                  updateEmployeeStatus("Verify");
                }}
              >
                {t("CompanyGroup.Verify")}
              </ButtonStyled>
            </ButtonContainer>
          )}
        </>
      ) : (
        <>
          <NoApplicantsContainer>
            <Text color={Color.SeaBlue600}>
              {status === "Verified"
                ? t("CompanyGroup.NoVerifiedGiggers")
                : status === "Unverified"
                ? t("CompanyGroup.NoUnverifiedGiggers")
                : t("CompanyGroup.NoInvites")}
            </Text>
          </NoApplicantsContainer>
        </>
      )}
      {status === "Invited" && (
        <>
          <Text
            color={Color.SeaBlue500}
            fontSize={FontSize.Small}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyGroup.Declined")}
          </Text>
          {workpoolState.declinedEmployees
            .filter((emp) => emp.response === "Declined")
            .map((employee) => (
              <WorkpoolListItem
                key={employee.id}
                employeeData={employee}
                filterEmployeeStatus={status || "Pending"}
                checkedEmployees={checkedEmployees}
                setCheckedEmployees={setCheckedEmployees}
                setCurrentEmployeeId={setCurrentEmployeeId}
                setShowModal={setShowModal}
              />
            ))}
          {workpoolState.declinedEmployees.filter(
            (emp) => emp.response === "Declined"
          ).length < 1 && (
            <Text
              color={Color.SeaBlue500}
              fontSize={FontSize.Small}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("CompanyGroup.NoDeclinedInvitations")}
            </Text>
          )}
        </>
      )}

      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyGroup.RemoveFromWorkpool")}
          </Text>
          <ButtonStyled
            onClick={() => removeEmployee()}
            style={{ marginBottom: "10px" }}
            backgroundColor={Color.Destructive}
          >
            {t("CompanyGroup.Remove")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.SeaBlue400}
            onClick={() => {
              setCurrentEmployeeId(undefined);
              setShowModal(false);
            }}
          >
            {t("CompanyGroup.Close")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
      <Modal
        open={showEmployeeModal}
        onClose={() => {
          setShowEmployeeModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SettingsModalBody>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyGroup.Invite")}
          </Text>
          <Text
            align="center"
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("CompanyGroup.AddGiggerToWorkpool")}
          </Text>
          <PhoneInputWrapper>
            <PhoneInput
              withCountryCallingCode
              placeholder="Ex. 076-123 45 67"
              defaultCountry="SE"
              value={phoneNumber}
              onChange={setPhoneNumber}
              labels={se}
              style={{ border: "none" }}
            />
          </PhoneInputWrapper>
          <>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              color={Color.SeaBlue600}
              fontSize={FontSize.Small}
              style={{ marginTop: 0, textAlign: "center" }}
            >
              {`(${t("CompanyGroup.OnlyYourCompanyCanSeeThisInfo")})`}
            </Text>
            <HourlyRateContainer>
              <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                {t("GigsCompany.HourlyRate")}
              </Text>
              <NumberInputContainer>
                <NumberInput
                  type="number"
                  name="minRate"
                  color={rate === 0 ? Color.SeaBlue200 : Color.MidnightBlue}
                  value={rate || ""}
                  onChange={(e) => {
                    setRate(parseInt(e.currentTarget.value) || 0);
                  }}
                />
              </NumberInputContainer>
            </HourlyRateContainer>
            {rate !== 0 ? (
              <div style={{ marginLeft: "15px" }}>
                <CustomLink
                  fontSize={FontSize.Small}
                  color={Color.Destructive}
                  onClick={() => setRate(0)}
                >
                  {t("CompanyGroup.RemoveDefaultRate")}
                </CustomLink>
              </div>
            ) : (
              <Text
                fontSize={FontSize.Small}
                color={Color.SeaBlue500}
                style={{ marginLeft: "15px" }}
              >
                {t("CompanyGroup.NoDefaultRate")}
              </Text>
            )}
            <HourlyRateContainer>
              <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                {t("CompanyGroup.Labels")}
              </Text>
              <NumberInputContainer>
                <TextInput
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                  placeholder={t("CompanyGroup.AddNewLabel")}
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (newLabel.trim() !== "") {
                        setLabels([...labels, { label: newLabel.trim() }]);
                        setNewLabel("");
                      }
                    }
                  }}
                />
              </NumberInputContainer>
              <CustomIcon
                disabled={newLabel.trim() === ""}
                name="plus_old"
                hover={true}
                backgroundColor={Color.BurntSienna}
                size="38px"
                color={Color.White}
                padding="5px"
                onClick={() => {
                  setLabels([...labels, { label: newLabel.trim() }]);
                  setNewLabel("");
                }}
              />
            </HourlyRateContainer>
            <LabelsContainer>
              {labels &&
                labels.map((label: any, index) => {
                  return (
                    <SkillTag key={index} style={{ margin: 4 }}>
                      <Text
                        color={Color.White}
                        fontFamily={FontFamily.MontserratSemiBold}
                        fontSize={FontSize.Standard}
                        style={{ margin: 0 }}
                      >
                        {label.label}
                      </Text>
                      <CustomIcon
                        name="cross"
                        size="16px"
                        color={Color.White}
                        onClick={() => {
                          removeLabel(label);
                        }}
                      />
                    </SkillTag>
                  );
                })}
            </LabelsContainer>
            <TextArea
              placeholder={t("CompanyGroup.AddComment")}
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {characterCounter(1024, comment.trim())}
          </>
          <ButtonStyled
            onClick={() => requestEmployee()}
            style={{ marginBottom: "10px" }}
            disabled={workpoolState.isLoading || !phoneNumber}
            backgroundColor={Color.BurntSienna}
          >
            {workpoolState.isLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              t("CompanyGroup.SendInvite")
            )}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.SeaBlue400}
            onClick={() => {
              setShowEmployeeModal(false);
            }}
          >
            {t("CompanyGroup.Close")}
          </ButtonStyled>
        </SettingsModalBody>
      </Modal>
    </>
  );
};
