import styled from "styled-components";
import { Color } from "config/colors";

export const SuggestionContainer = styled.div`
  padding: 5px;

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
  p {
    margin: 5px 0px;
  }
`;
