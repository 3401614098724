import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuthStore } from "stores/authStore/authStore";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { WorkpoolWorker } from "model/Workpool";
import { H3, Text } from "components/Typography/text.styled";
import { useTranslation } from "react-i18next";
import { WorkpoolWorkerDetails } from "web-apps/company/components/workpool/workpoolWorkerDetails.component";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { HeaderContainer } from "web-apps/company/components/workpool/workpoolWorkerDetails.styled";
import { Color } from "config/colors";

type MatchParams = {
  id?: string;
};

export const WorkpoolWorkerDetailsPage: React.FC<
  RouteComponentProps<MatchParams>
> = (props) => {
  const id = props.match.params.id;
  const { t } = useTranslation();
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  const [workpoolState] = useWorkpoolStore();
  const [workerData, setWorkerData] = useState<WorkpoolWorker>();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      let matchingWorker = workpoolState.unverifiedEmployees.find(
        (worker) => worker.id === parseInt(id)
      );

      if (!matchingWorker) {
        matchingWorker = workpoolState.verifiedEmployees.find(
          (worker) => worker.id === parseInt(id)
        );
      }
      if (!matchingWorker) {
        matchingWorker = workpoolState.declinedEmployees.find(
          (worker) => worker.id === parseInt(id)
        );
      }
      setWorkerData(matchingWorker);
    }
  }, [workpoolState.unverifiedEmployees, workpoolState.verifiedEmployees, id]);

  useEffect(() => {
    if (
      companyState.company?.id &&
      workerData &&
      workerData.companyId !== companyState.company?.id
    ) {
      history.goBack();
    }
  }, [companyState.company?.id, workerData]);

  return (
    <LoggedInPageContainer>
      {authState.isLoading ||
      companyState.isLoading ||
      workpoolState.isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {workerData && (
            <>
              <HeaderContainer>
                <H3>{`${workerData.worker.firstName} ${workerData.worker.lastName}`}</H3>
                <>
                  {workerData.isValidated && (
                    <Text
                      padding={true}
                      backgroundColor={Color.Positive}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyGroup.Member")}
                    </Text>
                  )}
                  {!workerData.isValidated &&
                    workerData.response !== "Declined" &&
                    workerData.requestType === "CompanyRequest" && (
                      <Text
                        padding={true}
                        backgroundColor={Color.ChampagnePink}
                        color={Color.Destructive}
                      >
                        {t("CompanyGroup.AwaitingResponse")}
                      </Text>
                    )}
                  {!workerData.isValidated &&
                    workerData.requestType === "WorkerRequest" && (
                      <Text
                        padding={true}
                        backgroundColor={Color.ChampagnePink}
                        color={Color.Destructive}
                      >
                        {t("CompanyGroup.Unverified")}
                      </Text>
                    )}
                </>
              </HeaderContainer>
              <WorkpoolWorkerDetails workerData={workerData} />
            </>
          )}
        </>
      )}
    </LoggedInPageContainer>
  );
};
