import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  SelectCompanyWrapper,
  OptionsWrapper,
  Option,
  CompanyImage,
  CompanyOption,
} from "./navBar.styled";

import { Select, SelectWrapper } from "components/form/select/select.styled";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProfileSelect: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [, authActions] = useAuthStore();
  const [, chatDispatch] = useChatStore();
  const [companyState, companyActions, companyDispatch] = useCompanyStore();
  const history = useHistory();

  const [companySelectOpen, setCompanySelectOpen] = useState(false);
  const [profileSelectOpen, setProfileSelectOpen] = useState(false);

  const handleClick = async () => {
    authActions.signOut().then(() => {
      companyDispatch({ type: "CLEAR_COMPANY_INFORMATION" });
      chatDispatch({ type: "CLEAR_CHAT_DATA" });
      history.push("/company/login");
      setOpen(!open);
    });
  };

  return (
    <>
      <SelectCompanyWrapper
        onMouseEnter={() => setProfileSelectOpen(!profileSelectOpen)}
        onMouseLeave={() => {
          setProfileSelectOpen(!profileSelectOpen);
          setCompanySelectOpen(false);
        }}
      >
        <Link to={"/company/profile"}>
          <SelectWrapper>
            <Select>
              <>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  style={{ whiteSpace: "normal" }}
                >
                  {companyState.company?.companyName}
                </Text>
              </>
              {companyState.companyImage ? (
                <CompanyImage src={companyState.companyImage} />
              ) : (
                <CustomIcon
                  key={1}
                  name="company"
                  size="30px"
                  color={Color.SeaBlue500}
                  backgroundColor={Color.SeaBlue300}
                  padding="4px"
                  style={{ marginLeft: "8px" }}
                />
              )}
            </Select>
          </SelectWrapper>
        </Link>

        {profileSelectOpen && (
          <OptionsWrapper>
            <Option
              onClick={() => {
                history.push("/company/profile");
                setCompanySelectOpen(!companySelectOpen);
                setProfileSelectOpen(false);
              }}
            >
              <Text fontFamily={FontFamily.MontserratBold}>
                {t("ProfileCompany.Profile")}
              </Text>
              <CustomIcon
                name="person"
                size="48px"
                color={Color.SeaBlue400}
                padding="10px"
              />
            </Option>
            <Option
              onClick={() => {
                history.push("/company/settings");
                setCompanySelectOpen(!companySelectOpen);
              }}
            >
              <Text fontFamily={FontFamily.MontserratBold}>
                {t("General.Settings")}
              </Text>
              <CustomIcon
                name="settings"
                size="48px"
                color={Color.SeaBlue400}
                padding="10px"
              />
            </Option>
            <Option
              onClick={() => {
                setCompanySelectOpen(!companySelectOpen);
              }}
            >
              <Text fontFamily={FontFamily.MontserratBold}>
                {t("CompanySelect.YourCompanies")}
              </Text>
              <CustomIcon
                name={companySelectOpen ? "chevron-up" : "chevron-down"}
                size="48px"
                color={Color.SeaBlue400}
                padding="10px"
              />
            </Option>
            {companySelectOpen && (
              <>
                {companyState.companies.map((company) => (
                  <CompanyOption
                    onClick={() => {
                      companyActions.switchCompany(company);
                      setCompanySelectOpen(false);
                      setProfileSelectOpen(false);
                    }}
                  >
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.SeaBlue600}
                    >
                      {company.companyName}
                    </Text>
                    {companyState.company?.id === company.id ? (
                      <CustomIcon
                        name="checkmark"
                        size="35px"
                        color={Color.BurntSienna}
                        padding="4px"
                        style={{ margin: "0 8px" }}
                      />
                    ) : company.companyImage ? (
                      <CompanyImage src={company.companyImage} />
                    ) : (
                      <CustomIcon
                        name="company"
                        size="30px"
                        color={Color.SeaBlue500}
                        padding="4px"
                        backgroundColor={Color.SeaBlue300}
                        style={{ margin: "0 8px" }}
                      />
                    )}
                  </CompanyOption>
                ))}
              </>
            )}
            <Option
              onClick={() => {
                history.push("/company/registration");
                setCompanySelectOpen(false);
                setProfileSelectOpen(false);
              }}
            >
              <Text fontFamily={FontFamily.MontserratSemiBold}>
                {t("CompanySelect.AddCompany")}
              </Text>
              <CustomIcon
                name={"plus"}
                size="48px"
                color={Color.SeaBlue500}
                padding="10px"
              />
            </Option>
            {/* <Option  //ADD WHEN REST OF EMPLOYEE FUNCTIONALITY IS FINNISHIED 
              onClick={() => {
                history.push("/company/employees");
                setCompanySelectOpen(false);
                setProfileSelectOpen(false);
              }}
            >
              <Text fontFamily={FontFamily.MontserratSemiBold}>
                {t("CompanyEmployees.CompanyEmployees")}
              </Text>
              <CustomIcon
                name={"person"}
                size="48px"
                color={Color.SeaBlue500}
                padding="10px"
              />
            </Option> */}
            <Option
              onClick={() => {
                handleClick();
                setCompanySelectOpen(false);
                setProfileSelectOpen(false);
              }}
            >
              <Text fontFamily={FontFamily.MontserratSemiBold}>
                {t("ProfileCompany.SignOut")}
              </Text>
              <CustomIcon
                name={"logout"}
                size="48px"
                color={Color.SeaBlue400}
                padding="10px"
              />
            </Option>
          </OptionsWrapper>
        )}
      </SelectCompanyWrapper>
    </>
  );
};
