import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { MenuItems } from "../menuList/menuItems.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { ChatContainerList } from "../chat/chatContainerList.component";
import { CustomLink, Text } from "components/Typography/text.styled";
import {
  Burger,
  NavBarStyled,
  LogInWrapper,
  ChatIconWrapper,
  ChatButton,
  Flex1,
} from "./navBar.styled";
import { NavBarMenuListStyled } from "../menuList/navBarMenuList.styled";
import LogoSVG from "images/logo/svg/simflexity-logo.svg";
import { Logo } from "../logo/logo.styled";
import { Badge } from "components/buttons/buttons.styled";
import { ProfileSelect } from "./profileSelect.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyRole } from "model/User";

type MenuItem = {
  text: string;
  path: string;
  image: string;
};

type Props = {
  menuList?: MenuItem[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavBar: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();
  const [chatState, chatDispatch] = useChatStore();

  const [companyState, , companyDispatch] = useCompanyStore();
  const [missedMessagesQty, setMissedMessagesQty] = useState(0);
  const [showChatContainer, setShowChatContainer] = useState(false);
  const [menuList, setMenuList] = useState<MenuItem[]>([]);

  const handleClick = async () => {
    authActions.signOut().then(() => {
      companyDispatch({ type: "CLEAR_COMPANY_INFORMATION" });
      chatDispatch({ type: "CLEAR_CHAT_DATA" });
      history.push("/company/login");
      setOpen(!open);
    });
  };

  useEffect(() => {
    authState.accountType == "Company" &&
    authState.user?.role == CompanyRole.COMPANY_TIMESTAMP
      ? setMenuList(getMenuList(CompanyRole.COMPANY_TIMESTAMP, t))
      : setMenuList(getMenuList(authState.accountType, t));
  }, [authState]);

  useEffect(() => {
    const unreadMessagesCount = chatState.state.summary.chats.reduce(
      (previousValue, { unread }) => previousValue + unread,
      0
    );
    setMissedMessagesQty(unreadMessagesCount);
  }, [chatState.state.summary]);

  return (
    <NavBarStyled>
      {authState.user?.role !== CompanyRole.COMPANY_TIMESTAMP && (
        <Link to="/company/dashboard">
          <Logo src={LogoSVG} alt="logo" style={{ height: 75 }} />
        </Link>
      )}
      {authState.user?.role == CompanyRole.COMPANY_TIMESTAMP && (
        <Link to="/company/stampclock">
          <Logo src={LogoSVG} alt="logo" style={{ height: 75 }} />
        </Link>
      )}
      <Flex1>
        <NavBarMenuListStyled>
          {companyState.company && (
            <MenuItems menuList={menuList} open={open} setOpen={setOpen} />
          )}
        </NavBarMenuListStyled>
        {authState.signedIn &&
          companyState.company &&
          authState.user?.role !== CompanyRole.COMPANY_TIMESTAMP && (
            <ChatButton
              onMouseEnter={() => {
                setShowChatContainer(true);
              }}
              onMouseLeave={() => {
                setShowChatContainer(false);
              }}
            >
              <ChatIconWrapper>
                <CustomIcon
                  name="chat"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                {missedMessagesQty > 0 && (
                  <Badge>
                    <Text fontSize={FontSize.Small} color={Color.White}>
                      {missedMessagesQty}
                    </Text>
                  </Badge>
                )}
              </ChatIconWrapper>
              {showChatContainer && (
                <ChatContainerList
                  setShowChatContainer={setShowChatContainer}
                />
              )}
            </ChatButton>
          )}
      </Flex1>
      <LogInWrapper>
        {authState.signedIn &&
        companyState.company &&
        authState.user?.role !== CompanyRole.COMPANY_TIMESTAMP ? (
          <ProfileSelect open={open} setOpen={setOpen} />
        ) : authState.signedIn ? (
          <CustomLink onClick={handleClick} color={Color.Destructive}>
            {t("ProfileCompany.SignOut")}
          </CustomLink>
        ) : (
          <>
            {/* Comment out when ready for empployee login */}
            {/*             <CustomLink
              to={`${path.replace("company", "employee")}/login`}
              color={Color.Destructive}
            >
              {t("LoginCompany.EmployeeLogin")} 
            </CustomLink> */}
            <CustomLink to={`${path}/login`} color={Color.MidnightBlue}>
              {t("LoginCompany.CompanyLogin")}
            </CustomLink>
          </>
        )}
      </LogInWrapper>
      <Burger>
        <CustomIcon
          name="hamburger"
          size="40px"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </Burger>
    </NavBarStyled>
  );
};
