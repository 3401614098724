import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { WorkpoolWorker } from "model/Workpool";

const getWorkpoolUnverifiedEmployees = async (
  companyId: number
): Promise<AxiosResponse<WorkpoolWorker[]>> => {
  return await axiosRequest.auth.get<WorkpoolWorker[]>(
    `${API_ROOT}/companies/${companyId}/employee/GetUnverifiedEmployees`
  );
};

const getWorkpoolVerifiedEmployees = async (
  companyId: number
): Promise<AxiosResponse<WorkpoolWorker[]>> => {
  return await axiosRequest.auth.get<WorkpoolWorker[]>(
    `${API_ROOT}/companies/${companyId}/employee/GetVerifiedEmployees`
  );
};

const getDeclinedEmployees = async (
  companyId: number
): Promise<AxiosResponse<WorkpoolWorker[]>> => {
  return await axiosRequest.auth.get<WorkpoolWorker[]>(
    `${API_ROOT}/companies/${companyId}/employee/GetEmployees?status=Declined`
  );
};

const verifyWorkpoolEmployee = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/employee/Verify`,
    { workerId }
  );
};

const deleteWorkpoolEmployee = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<any[]>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/companies/${companyId}/employee/RemoveEmployee/${workerId}`
  );
};

const addEmployee = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<any>(
    `${API_ROOT}/companies/${companyId}/employee`,
    data
  );
};

const updateWorkpoolEmployee = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.put<any>(
    `${API_ROOT}/companies/${companyId}/employee/${data.workerId}`,
    data
  );
};

export const workpool = {
  getWorkpoolUnverifiedEmployees,
  getWorkpoolVerifiedEmployees,
  verifyWorkpoolEmployee,
  deleteWorkpoolEmployee,
  addEmployee,
  getDeclinedEmployees,
  updateWorkpoolEmployee,
};
