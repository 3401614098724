import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAuthStore } from "stores/authStore/authStore";
import { CompanySettings } from "web-apps/company/components/companySettings/companySettings.component";
import { LoggedOutPageContainer } from "components/pageLayouts/pageLayouts.styled";

export const CompanySettingsPage = () => {
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();

  return (
    <>
      {companyState.isLoading || authState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <CompanySettings />
      )}
    </>
  );
};
