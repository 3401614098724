import { CompanyCalendarGigDto } from "model/Calendar";
import {
  CalendarGrid,
  CalendarHeaderGrid,
  GigHeader,
} from "../weeklyCalendar.styled";
import { WeekDay, ButtonWrapper } from "./previewCalendar.styled";
import { PreviewCalendarGig } from "./previewCalendarGig.component";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { useTranslation } from "react-i18next";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Moment } from "moment";

type Props = {
  calendarData: CompanyCalendarGigDto[];
  currentWeek: number;
  loading?: boolean;
  onApply: () => void;
  onCancel: () => void;
  currentDates: { date: Moment; weekDay: string }[];
};

export const PreviewCalendar = ({
  calendarData,
  currentWeek,
  loading,
  onApply,
  onCancel,
  currentDates,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Text
        fontSize={FontSize.Large}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {t("CalendarCompany.Week")} {currentWeek}
      </Text>
      <CalendarHeaderGrid>
        <GigHeader>
          <Text
            fontSize={FontSize.Standard}
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("General.Gig")}
          </Text>
        </GigHeader>
        {currentDates.map((day: { date: Moment; weekDay: string }, index) => (
          <WeekDay key={index}>
            <Text
              fontSize={FontSize.Standard}
              color={Color.White}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {day.weekDay}
            </Text>
            <Text
              fontSize={FontSize.Small}
              color={Color.White}
              fontFamily={FontFamily.MontserratRegular}
            >
              {day.date.format("DD/MM")}
            </Text>
          </WeekDay>
        ))}
      </CalendarHeaderGrid>
      <CalendarGrid>
        {calendarData.map((gigData, gigDataIndex: number) => (
          <PreviewCalendarGig
            key={gigData.id}
            gigData={gigData}
            gigDataIndex={gigDataIndex}
          />
        ))}
      </CalendarGrid>
      <ButtonWrapper isDisabled={loading}>
        <ButtonStyled onClick={onApply} disabled={calendarData.length === 0}>
          {t("GigsCompany.CreateGigs")}
        </ButtonStyled>
        <ButtonStyled backgroundColor={Color.SeaBlue500} onClick={onCancel}>
          {t("General.Cancel")}
        </ButtonStyled>
      </ButtonWrapper>
      {calendarData.length === 0 && (
        <Text
          color={Color.Destructive}
          fontSize={FontSize.Small}
          style={{ textAlign: "center" }}
        >
          {t("CalendarCompany.NoGigsAreBeingAdded")}
        </Text>
      )}
    </>
  );
};
