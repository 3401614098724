import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { DetailsContainer, RowWrapper } from "./detailsSettings.styled";
import {
  CheckBox,
  InputContainer,
  LabelStyled,
  TextInput,
} from "components/form/inputs/inputs.styled";
import { H3, H4, Text } from "components/Typography/text.styled";
import { LocationMap } from "web-apps/company/components/location/locationMap.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { Prompt } from "react-router-dom";

type Props = {
  view?: string;
};

export const DetailsSettings: React.FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [legalName, setLegalName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  /*   const [collectiveAgreement, setCollectiveAgreement] = useState("");
  const [hasCollectiveAgreement, setHasCollectiveAgreement] =
    useState<boolean>(false); */

  const [companyState, companyActions] = useCompanyStore();

  useEffect(() => {
    if (companyState.company) {
      setLegalName(companyState.company.legalName || "");
      setRegistrationNumber(companyState.company.registrationNumber || "");
      setPhoneNumber(companyState.company.phoneNumber || "");
      setAddress(companyState.company.address || "");
      setPostalCode(companyState.company.postalCode || "");
      setCity(companyState.company.city || "");
      setLocation(companyState.company.location || "");
      /*  setCollectiveAgreement(companyState.company.collectiveAgreement || "");
      if (companyState.company.collectiveAgreement) {
        setHasCollectiveAgreement(true);
      } else {
        setHasCollectiveAgreement(false);
      } */
    }
  }, [companyState.company]);

  const validate = () => {
    if (
      legalName &&
      address &&
      location &&
      city &&
      phoneNumber &&
      postalCode /* &&
      ((!hasCollectiveAgreement && collectiveAgreement.length === 0) ||
        (hasCollectiveAgreement && collectiveAgreement)) */
    ) {
      return true;
    }
  };

  const madeChanges = () => {
    if (
      legalName !== companyState.company?.legalName ||
      address !== companyState.company.address ||
      location !== companyState.company.location ||
      city !== companyState.company.city ||
      phoneNumber !== companyState.company.phoneNumber ||
      postalCode !== companyState.company.postalCode /* ||
      collectiveAgreement !== companyState.company.collectiveAgreement */
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      if (companyState.company) {
        const data = {
          ...companyState.company,
          legalName,
          phoneNumber,
          address,
          postalCode,
          city,
          location,
          /*           collectiveAgreement,
           */
        };
        companyActions
          .saveCompany(data, companyState.companies, companyState.company.id)
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.Updated"),
                message: t("ProfileCompany.CompanyHasBeenUpdated"),
              },
            });
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.OhNo"),
                message: t("ProfileCompany.CompanyCouldNotBeUpdated"),
              },
            });
          });
      }
    }
  };

  return (
    <>
      <Prompt
        when={madeChanges()}
        message={t("ProfileCompany.YouHaveUnSavedChanges")}
      />
      <H3>{t("ProfileCompany.EditDetails")}</H3>
      <DetailsContainer>
        <H4 fontFamily={FontFamily.LeagueSpartanBold}>
          {t("ProfileCompany.CompanyDetails")}
        </H4>
        <RowWrapper>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.LegalName")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              value={legalName}
              onChange={(e) => setLegalName(e.currentTarget.value)}
            />
          </InputContainer>
        </RowWrapper>
        <RowWrapper>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.RegistrationNumber")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              disabled
              value={registrationNumber}
            />
          </InputContainer>
          {companyState.company?.referenceCode && (
            <InputContainer>
              <LabelStyled>{t("ProfileCompany.CompanyCode")}</LabelStyled>
              <TextInput
                fontSize={FontSize.Large}
                disabled
                value={companyState.company?.referenceCode}
              />
            </InputContainer>
          )}
        </RowWrapper>
        <RowWrapper>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.PhoneNumber")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.currentTarget.value)}
            />
          </InputContainer>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.Email")}</LabelStyled>
            <TextInput
              fontSize={FontSize.Large}
              value={companyState.company?.email || ""}
              disabled
            />
          </InputContainer>
        </RowWrapper>
      </DetailsContainer>
      <DetailsContainer>
        <H4 fontFamily={FontFamily.LeagueSpartanBold}>
          {t("ProfileCompany.CompanyAddress")}
        </H4>
        <RowWrapper>
          <InputContainer>
            <LabelStyled>{t("ProfileCompany.Address")}</LabelStyled>
            <LocationMap
              setAddress={setAddress}
              setCity={setCity}
              setPostalCode={setPostalCode}
              setLocation={setLocation}
            />
            {!address ||
              !city ||
              !postalCode ||
              (!location && (
                <Text color={Color.Destructive}>
                  {t("ProfileCompany.InvalidAddress")}
                </Text>
              ))}
          </InputContainer>
        </RowWrapper>
        {/*  <RowWrapper style={{ justifyContent: "left", alignItems: "center" }}>
          <Text
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("RegisterCompany.DoYouHaveCollectiveAgreement")}
          </Text>

          <CheckBox
            onClick={() => {
              setHasCollectiveAgreement(!hasCollectiveAgreement);
              setCollectiveAgreement("");
            }}
          >
            {hasCollectiveAgreement && (
              <CustomIcon color={Color.BurntSienna} name="checkmark" />
            )}
          </CheckBox>
        </RowWrapper>

        {hasCollectiveAgreement && (
          <InputContainer>
            <LabelStyled>
              {t("RegisterCompany.CollectiveAgreement")}
            </LabelStyled>
            <TextInput
              onChange={(e) => setCollectiveAgreement(e.target.value)}
              value={collectiveAgreement}
            />
          </InputContainer>
        )} */}
        <RowWrapper view={view}></RowWrapper>
      </DetailsContainer>
      <ButtonStyled
        disabled={
          !validate() ||
          !madeChanges() ||
          !city ||
          !address ||
          !postalCode ||
          !location
        }
        onClick={handleSubmit}
      >
        {t("General.Save")}
      </ButtonStyled>
    </>
  );
};
