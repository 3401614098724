import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize } from "config/font";

type Props = {
  filterApplicationStatus: boolean;
};

export const ContractModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 640px;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  background-color: ${Color.White};
  outline: none;
  padding: 20px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
`;

export const GigApplicantsListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin: 10px 0 50px;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  width: fit-content;
  margin: auto;

  button {
    margin: 0 10px;
  }
`;

export const ApplicantRow = styled.li`
  border-bottom: 1px solid ${Color.SeaBlue200};
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px;

  p {
    margin: 10px;
  }

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const ApplicantInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  padding: 0 20px;

  p {
    margin: 0;
  }
`;

export const ApplicantImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const NoApplicantsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: ${Color.SeaBlue200};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type RowProps = {
  justify?: string;
  paddingTop?: number;
};

export const RowWrapper = styled.div<RowProps>`
  display: flex;
  align-items: center;
  padding-top: ${(props) => props.paddingTop && props.paddingTop}px;
  justify-content: ${(props) => props.justify && props.justify};
`;

export const WithdrawOfferModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;

  .MuiInputBase-root {
    margin-bottom: 15px;
  }
  button {
    font-size: ${FontSize.Small};
  }

  p {
    margin: 0 0 30px 0;
  }
`;
