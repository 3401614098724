import styled from "styled-components";
import { Color } from "config/colors";

type TabProps = {
  active: boolean;
};

export const TabsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TabStyled = styled.div<TabProps>`
  width: 50%;
  flex-basis: 50%;
  text-align: center;
  border-bottom: ${(props) =>
    props.active
      ? `3px solid ${Color.BurntSienna}`
      : `1px solid ${Color.SeaBlue200}`};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;