import React from "react";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { Text } from "components/Typography/text.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyOption } from "./drawerMenuList.styled";
import { CompanyImage } from "../navBar/navBar.styled";
import { CustomIcon } from "components/icon/customIcon.component";

type Props = {
  setCompanySelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DrawerCompanyList: React.FC<Props> = ({
  setCompanySelectOpen,
  setOpen,
}) => {
  const [companyState, companyActions] = useCompanyStore();
  return (
    <>
      {companyState.companies.map((company, index) => {
        return (
          <CompanyOption
            onClick={() => {
              companyActions.switchCompany(company, company.id);
              setCompanySelectOpen(false);
              setOpen(false);
            }}
          >
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.SeaBlue500}
            >
              {company.companyName}
            </Text>
            {company.companyImage ? (
              <CompanyImage src={company.companyImage} />
            ) : (
              <CustomIcon
                name="company"
                size="30px"
                color={Color.SeaBlue500}
                backgroundColor={Color.SeaBlue300}
                style={{ marginLeft: "8px" }}
              />
            )}
          </CompanyOption>
        );
      })}
    </>
  );
};
