import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Instant, LocalDateTime, LocalTime } from "@js-joda/core";
import { ShiftRequestDto } from "model/Shift";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { ShiftForm } from "./shiftForm.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { ShiftStyled, ShiftInfo } from "./shiftForm.styled";
import { addLeadingZero, calculateBreakTime } from "utils/utils";
import moment from "moment";

type Props = {
  shiftIndex: number;
  shift: ShiftRequestDto;
  shifts: ShiftRequestDto[];
  onChange: (shifts: ShiftRequestDto[]) => void;
  isClone: boolean | undefined;
  createGig?: boolean;
};

export const Shift: React.FC<Props> = ({
  shiftIndex,
  shift,
  shifts,
  onChange,
  isClone,
  createGig,
}) => {
  const { t } = useTranslation();
  const startDate = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
  const endDate = LocalDateTime.ofInstant(Instant.parse(shift.endTime));
  const dayMonth = `${startDate.dayOfMonth()}/${startDate.monthValue()}`;
  const time = `${`${addLeadingZero(startDate.hour())}:${addLeadingZero(
    startDate.minute()
  )} - ${addLeadingZero(endDate.hour())}:${addLeadingZero(endDate.minute())}`}`;
  const weekDay = t(
    `WeekDays.${LocalDateTime.ofInstant(Instant.parse(shift.startTime))
      .dayOfWeek()
      .name()}SHORT`
  );

  const [editFormOpen, setEditFormOpen] = useState(false);

  const deleteShift = () => {
    if (shifts[shiftIndex].id && !isClone && !createGig) {
      const newData = shifts.map((shift, index) => {
        return index === shiftIndex ? { ...shift, deleteEntry: true } : shift;
      });
      onChange(newData);
    } else {
      const newData = shifts.filter((_, index) => index !== shiftIndex);
      onChange(newData);
    }
  };

  return (
    <>
      <ShiftStyled>
        <ShiftInfo
          onClick={() => {
            setEditFormOpen(!editFormOpen);
          }}
        >
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {weekDay}
          </Text>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {dayMonth}
          </Text>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {time}
          </Text>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {shift.breakStartTime &&
              shift.breakEndTime &&
              calculateBreakTime(
                LocalTime.ofInstant(
                  Instant.parse(shift.breakStartTime.slice(0, 19) + "Z")
                ).toString(),
                LocalTime.ofInstant(
                  Instant.parse(shift.breakEndTime.slice(0, 19) + "Z")
                ).toString(),
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(shift.startTime)
                  ).toString()
                ),
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(shift.endTime)
                  ).toString()
                )
              ) + t("General.MinutesShortened")}
            {!shift.breakStartTime &&
              !shift.breakEndTime &&
              (shift.breakTime ? shift.breakTime : "0") +
                t("General.MinutesShortened")}
          </Text>
        </ShiftInfo>
        <CustomIcon
          name="trashcan"
          color={Color.White}
          backgroundColor={Color.Destructive}
          padding="4px"
          onClick={() => {
            deleteShift();
            setEditFormOpen(false);
          }}
        />
      </ShiftStyled>
      <ShiftForm
        shiftIndex={shiftIndex}
        shift={shift}
        shifts={shifts}
        onChange={onChange}
        shiftFormOpen={editFormOpen}
        setShiftFormOpen={setEditFormOpen}
        editGig={isClone || !createGig ? true : false}
      />
    </>
  );
};
