import { NumberInput } from "components/form/inputs/inputs.styled";
import { Color } from "config/colors";
import styled from "styled-components";

export const DetailsContainer = styled.div`
  margin-bottom: 40px;
`;

type RowWrapperProps = {
  view?: string;
};

type RadioProps = {
  checked: boolean;
};

type DayButtonProps = {
  selected: boolean;
};
export const RowWrapper = styled.div<RowWrapperProps>`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: end;
  margin-bottom: 20px;
  ${(props) =>
    props.view === "desktop"
      ? `
    @media (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 30px;
    }
  `
      : `
    @media (min-width: 550px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 30px;
    }
  `};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 90px;
  z-index: 10;
  background-color: ${Color.White};
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
`;
export const Select = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 0 20px;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 8px;
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;

export const RadioButtonWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${Color.White};
  border: 2px solid ${Color.BurntSienna};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const RadioButtonCenter = styled.div<RadioProps>`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  ${(props) =>
    props.checked === true
      ? `background-color: ${Color.BurntSienna};`
      : `background-color: ${Color.White};`};
`;

export const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  justify-content: space-around;
  margin-top: 16px;
`;

export const ObValueInput = styled(NumberInput)`
  width: 80px;
  background-color: ${Color.SeaBlue200};
  &:focus {
    border: 1px solid ${Color.BurntSienna};
    background-color: rgba(45, 138, 91, 0.2);
    color: ${Color.BurntSienna};
  }
`;

export const DayButton = styled.div<DayButtonProps>`
  ${(props) =>
    props.selected === true
      ? `background-color: ${Color.Positive};`
      : `background-color: ${Color.SeaBlue300};`};
  padding: 5px 5px;
  border-radius: 8px;
  margin: 2px;
  ${(props) =>
    props.selected === true
      ? `border: 1px solid ${Color.BurntSienna};`
      : `border: 1px solid ${Color.White}`};

  p {
    ${(props) =>
      props.selected === true
        ? `color: ${Color.BurntSienna};`
        : `color: ${Color.SeaBlue600}`};
    margin: 0px;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const DayRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 340px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 16px;
`;

export const ResultRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    margin: 4px;
    word-break: break-word;
    max-width: 340px;
  }
`;
