import React, { useEffect, useState } from "react";
import { LocalDateTime, ChronoUnit, Instant } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  HeadCell,
  TimeReportsGrid,
  TimeReportsGridBody,
  Cell,
  RoleText,
  RoleWrapper,
} from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import {
  addLeadingZero,
  calculateBreakTime,
  isWithin15Minutes,
} from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { GigCosts, PaymentMethod } from "model/Gig";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { Logo } from "components/nav/logo/logo.styled";
import moment from "moment";
import { getCostsTimeReports, shiftWithAbsence } from "web-apps/company/utils/utils";
import { WorkerTimeReportsFooter } from "./workerTimeReportsFooter.component";

type Props = {
  monthlyWorkerWithTimeReports: WorkerWithTimeReports;
  setChosenTimeReportWithWorker: React.Dispatch<
    React.SetStateAction<TimeReportDto | null>
  >;

  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalCostMonthly: React.Dispatch<React.SetStateAction<number>>;
  totalCostMonthly: number;
  setTotalCostMonthlyApproved?: React.Dispatch<React.SetStateAction<number>>;
  select?: boolean;
  selectedTimeReports?: any[];
  setSelectedTimeReports?: React.Dispatch<React.SetStateAction<any>>;
  showCheckInCheckOutColumns: boolean;
  month?: number;
  year?: number;
  unhandledView?:boolean;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};


export const WorkerTimeReports: React.FC<Props> = ({
  monthlyWorkerWithTimeReports,
  setChosenTimeReportWithWorker,
  setModalOpen,
  setTotalCostMonthly,
  setTotalCostMonthlyApproved,
  select,
  selectedTimeReports,
  setSelectedTimeReports,
  showCheckInCheckOutColumns,
  month,
  year,
  unhandledView
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  let totalTimeMin = 0;
  let totalTimeMinApproved = 0;
  const history = useHistory();
  const [totalCost, setTotalCost] = useState<GigCosts>(emptyCosts);
  const [totalCostApproved, setTotalCostApproved] =
    useState<GigCosts>(emptyCosts);

  useEffect(() => {
    if (setTotalCostMonthlyApproved) {
      setTotalCostMonthlyApproved(0);
    }
    setTotalCostMonthly(0);
    if (companyState.company?.id) {
      const approved = monthlyWorkerWithTimeReports.timeReports.filter(
        (timeReport) => {
          return (
            timeReport.status === TimeReportStatus.APPROVED ||
            timeReport.status === TimeReportStatus.INVOICE_NOT_PAID ||
            timeReport.status === TimeReportStatus.PROCESSING_PAYMENT ||
            timeReport.status === TimeReportStatus.PAYMENT_STARTED ||
            timeReport.status === TimeReportStatus.PAID ||
            (timeReport.status === TimeReportStatus.CLOSED &&
              (timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
                timeReport.resolution === TimeReportResolution.PAID ||
                timeReport.resolution === TimeReportResolution.PAYONDEMAND))
          );
        }
      );
      getCostsTimeReports(
        monthlyWorkerWithTimeReports.timeReports,
        companyState.company.id
      ).then((costs) => setTotalCost(costs));
      getCostsTimeReports(approved, companyState.company.id).then((costs) =>
        setTotalCostApproved(costs)
      );
    }
  }, [monthlyWorkerWithTimeReports, companyState.company?.id]);

  useEffect(() => {
    if (totalCost.totalCost > 0) {
      setTotalCostMonthly((prev) => prev + totalCost.totalCost);
    }
  }, [totalCost]);

  useEffect(() => {
    if (totalCost.totalCost > 0 && setTotalCostMonthlyApproved !== undefined) {
      setTotalCostMonthlyApproved((prev) => prev + totalCostApproved.totalCost);
    }
  }, [totalCostApproved]);

  const getStatus = (status: TimeReportStatus) => {
    switch (status) {
      case TimeReportStatus.CLOSED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Closed")}</Text>
            <CustomIcon
              name="checkmark"
              color={Color.BurntSienna}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.REJECTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Rejected")}</Text>
            <CustomIcon name="cross" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.PROCESSING_PAYMENT: {
        return (
          <>
            <Text>{t("CompanyTimeReport.ProcessingPayment")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.INVOICE_NOT_PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.InvoiceNotPaid")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.NEW: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Unsubmitted")}</Text>
            <CustomIcon name="clock" color={Color.SeaBlue500} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.SUBMITTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Submitted")}</Text>
            <CustomIcon
              name="alert"
              color={Color.ChampagnePink}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Paid")}</Text>
            <CustomIcon name="wallet" color={Color.BurntSienna} padding="4px" />
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <TimeReportsGrid showCheckInCheckOutColumns={showCheckInCheckOutColumns} unhandledView={unhandledView}>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Date")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Shift")}
          </Text>
        </HeadCell>
        {showCheckInCheckOutColumns && (
          <HeadCell>
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyTimeReport.CheckInCheckOut")}
            </Text>
          </HeadCell>
        )}
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ marginLeft: "8px" }}
          >
            {t("CompanyTimeReport.Role")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Break")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Time")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.HourlyRate")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Status")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Updated")}
          </Text>
        </HeadCell>
        {!unhandledView && (<HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Resolution")}
          </Text>
        </HeadCell>)}
      </TimeReportsGrid>

      {monthlyWorkerWithTimeReports.timeReports.map(
        (timeReport, timeReportIndex) => {
          const startTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.startTime)
          );
          const endTime = LocalDateTime.ofInstant(
            Instant.parse(timeReport.endTime)
          );
          let shiftTotalMinutes = startTime.until(endTime, ChronoUnit.MINUTES);

          if (timeReport.breakEndTime && timeReport.breakStartTime) {
            shiftTotalMinutes -= calculateBreakTime(
              timeReport.breakStartTime,
              timeReport.breakEndTime,
              moment(
                LocalDateTime.ofInstant(
                  Instant.parse(timeReport.startTime)
                ).toString()
              ),
              moment(
                LocalDateTime.ofInstant(
                  Instant.parse(timeReport.endTime)
                ).toString()
              )
            );
          }

          const shiftHours = Math.floor(shiftTotalMinutes / 60);
          const shiftMinutes = Math.round(shiftTotalMinutes % 60);


          const shiftTimeString = `${shiftWithAbsence(timeReport) ? "-" : ""} ${
            shiftHours && shiftHours !== 0 ? shiftHours + "h " : ""
          }${shiftMinutes && shiftMinutes !== 0 ? shiftMinutes + "min" : ""}`;
          totalTimeMin += shiftTotalMinutes;
          if (
            timeReport.status === TimeReportStatus.APPROVED ||
            timeReport.status === TimeReportStatus.INVOICE_NOT_PAID ||
            timeReport.status === TimeReportStatus.PROCESSING_PAYMENT ||
            timeReport.status === TimeReportStatus.PAYMENT_STARTED ||
            timeReport.status === TimeReportStatus.PAID ||
            (timeReport.status === TimeReportStatus.CLOSED &&
              (timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
                timeReport.resolution === TimeReportResolution.PAID ||
                timeReport.resolution === TimeReportResolution.PAYONDEMAND))
          ) {
            totalTimeMinApproved += shiftTotalMinutes;
          }
          const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;

          return (
            <TimeReportsGridBody
              showCheckInCheckOutColumns={showCheckInCheckOutColumns} unhandledView={unhandledView}
              key={timeReportIndex}
              onClick={() => {
                if (select && selectedTimeReports && setSelectedTimeReports) {
                  if (
                    selectedTimeReports?.find(
                      (selectedTimeReport) =>
                        timeReport.id === selectedTimeReport.id
                    )
                  ) {
                    const newSelected = selectedTimeReports.filter(
                      (selectedTimeReport) =>
                        selectedTimeReport.id !== timeReport.id
                    );
                    setSelectedTimeReports(newSelected);
                  } else {
                    setSelectedTimeReports([
                      ...selectedTimeReports,
                      timeReport,
                    ]);
                  }
                } else {
                  setChosenTimeReportWithWorker(timeReport);
                  setModalOpen(true);
                }
              }}
            >
              <Cell>
                <Text fontSize={FontSize.Large}>{`${t(
                  weekDayString
                )} ${startTime.dayOfMonth()}/${startTime.monthValue()}`}</Text>
              </Cell>
              <Cell>
                <Text fontSize={FontSize.Large}>
                  {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
                    startTime.minute()
                  )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
                    endTime.minute()
                  )}`}
                </Text>
              </Cell>
              {showCheckInCheckOutColumns && (
                <Cell>
                  <Text
                    fontSize={FontSize.Large}
                    color={
                      timeReport.checkInTime == null ||
                      isWithin15Minutes(
                        new Date(timeReport.startTime),
                        new Date(timeReport.checkInTime)
                      )
                        ? undefined
                        : Color.Destructive
                    }
                  >
                    {timeReport.checkInTime
                      ? new Date(timeReport.checkInTime).toLocaleTimeString(
                          "sv-SE",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : ""}
                  </Text>
                  <Text>{"-"}</Text>
                  <Text
                    fontSize={FontSize.Large}
                    color={
                      timeReport.checkOutTime == null ||
                      isWithin15Minutes(
                        new Date(timeReport.endTime),
                        new Date(timeReport.checkOutTime)
                      )
                        ? undefined
                        : Color.Destructive
                    }
                  >
                    {timeReport.checkOutTime
                      ? new Date(timeReport.checkOutTime).toLocaleTimeString(
                          "sv-SE",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : ""}
                  </Text>
                </Cell>
              )}
              <Cell>
                <RoleWrapper
                  onClick={(e) => {
                    e.stopPropagation();

                    if (
                      window.location.href.indexOf(
                        timeReport.gigId.toString()
                      ) !== -1
                    ) {
                      history.push(`../gig/${timeReport.gigId}`);
                    } else {
                      history.push(`./gig/${timeReport.gigId}`);
                    }
                  }}
                >
                  <RoleText fontSize={FontSize.Standard}>
                    {timeReport.gigRole}
                  </RoleText>
                  <CustomIcon name="document" color={Color.White} size="20px" />
                </RoleWrapper>
              </Cell>
              <Cell>
                <Text fontSize={FontSize.Large}>
                  {timeReport.breakStartTime && timeReport.breakEndTime
                    ? calculateBreakTime(
                        timeReport.breakStartTime,
                        timeReport.breakEndTime,
                        moment(
                          LocalDateTime.ofInstant(
                            Instant.parse(timeReport.startTime)
                          ).toString()
                        ),
                        moment(
                          LocalDateTime.ofInstant(
                            Instant.parse(timeReport.endTime)
                          ).toString()
                        )
                      )
                    : 0}
                  {t("General.MinutesShortened")}
                </Text>
              </Cell>
              <Cell>
                <Text fontSize={FontSize.Large}>{shiftTimeString}</Text>
              </Cell>
              <Cell>
                <Text fontSize={FontSize.Large}>{timeReport.hourlyRate}kr</Text>
              </Cell>
              <Cell>{getStatus(timeReport.status)} </Cell>
              <Cell>
                <Text fontSize={FontSize.Large}>
                  {`${new Date(timeReport.updatedAt).toLocaleDateString(
                    "sv-SE",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )} ${new Date(timeReport.updatedAt).toLocaleTimeString(
                    "sv-SE",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}`}
                </Text>
              </Cell>
              {!unhandledView && (<Cell>
                {timeReport.resolution !== TimeReportResolution.UNDEFINED && (
                  <Text fontSize={FontSize.Large}>
                    {t(`CompanyTimeReport.${timeReport.resolution}`)}
                  </Text>
                )}
                 {timeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY && (
                  <Logo
                    src={SimflexityPayLogo}
                    style={{
                      height: "18px",
                      width: "auto",
                      marginLeft: "10px",
                    }}
                    alt="SimflexityPay logo"
                  />
                )}
                {select && selectedTimeReports && setSelectedTimeReports && (
                  <CustomIcon
                    name="checkmark"
                    size="27px"
                    color={Color.White}
                    backgroundColor={
                      selectedTimeReports?.find(
                        (selectedTimeReport) =>
                          timeReport.id === selectedTimeReport.id
                      )
                        ? Color.MidnightBlue
                        : Color.White
                    }
                    padding="2px"
                    onClick={() => {
                      if (
                        selectedTimeReports?.find(
                          (selectedTimeReport) =>
                            timeReport.id === selectedTimeReport.id
                        )
                      ) {
                        const newSelected = selectedTimeReports.filter(
                          (selectedTimeReport) =>
                            selectedTimeReport.id !== timeReport.id
                        );
                        setSelectedTimeReports(newSelected);
                      } else {
                        setSelectedTimeReports([
                          ...selectedTimeReports,
                          timeReport,
                        ]);
                      }
                    }}
                    style={{
                      border: `2px solid ${Color.MidnightBlue}`,
                      flexShrink: 0,
                      marginLeft: "15px",
                    }}
                  />
                )}
              </Cell>)}
            </TimeReportsGridBody>
          );
        }
      )}
      {month && year && (
        <WorkerTimeReportsFooter
          monthlyWorkerWithTimeReports={monthlyWorkerWithTimeReports}
          month={month}
          year={year}
        />
      )}
    </>
  );
};
