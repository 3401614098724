import { AxiosResponse } from "axios";
import { ApplicationState } from "./applicationStore.reducer";
import { ApplicationDispatch } from "./applicationStore";
import { Api } from "services/api/api.service";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationWorkerEndorsementDto,
} from "model/Application";

const getApplicationWorkerEndorsements =
  (dispatch: ApplicationDispatch) =>
  async (
    companyId: number,
    workerId: number
  ): Promise<AxiosResponse<ApplicationWorkerEndorsementDto[]>> => {
    const res = await Api()
      .company.worker.getWorkerEndorsements(companyId, workerId)
      .then((res) => {
        dispatch({
          type: "GET_APPLICATION_WORKER_ENDORSEMENTS",
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when fetching worker endorsements", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
    return res;
  };
const createApplicationWorkerEndorsement =
  (dispatch: ApplicationDispatch) =>
  async (
    companyId: number,
    workerId: number,
    skill: string
  ): Promise<AxiosResponse<ApplicationWorkerEndorsementDto>> => {
    const res = await Api()
      .company.worker.createWorkerEndorsement(companyId, workerId, skill)
      .then((res) => {
        dispatch({
          type: "CREATE_APPLICATION_WORKER_ENDORSEMENT",
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when fetching worker endorsements", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
    return res;
  };
const deleteApplicationWorkerEndorsement =
  (dispatch: ApplicationDispatch) =>
  async (
    companyId: number,
    workerId: number,
    endorsementId: number
  ): Promise<AxiosResponse<any>> => {
    const res = await Api()
      .company.worker.deleteWorkerEndorsement(
        companyId,
        workerId,
        endorsementId
      )
      .then((res) => {
        dispatch({
          type: "DELETE_APPLICATION_WORKER_ENDORSEMENT",
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when fetching worker endorsements", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
    return res;
  };
const updateApplicationStatus =
  (dispatch: ApplicationDispatch) =>
  async (
    companyId: number,
    applicationId: number,
    applicationData: ApplicationRequestDto
  ): Promise<AxiosResponse<ApplicationDto> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.application.updateApplicationStatus(
        companyId,
        applicationId,
        applicationData
      )
      .then((res) => {
        dispatch({
          type: "UPDATE_APPLICATION",
          payload: { ...res.data },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating application status", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

export const applicationActions = (
  dispatch: ApplicationDispatch,
  state: ApplicationState
) => ({
  updateApplicationStatus: updateApplicationStatus(dispatch),
  getApplicationWorkerEndorsements: getApplicationWorkerEndorsements(dispatch),
  createApplicationWorkerEndorsement:
    createApplicationWorkerEndorsement(dispatch),
  deleteApplicationWorkerEndorsement:
    deleteApplicationWorkerEndorsement(dispatch),
});
