import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { CompanyGigDto, OfferGigRequest } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { NumberInput } from "components/form/inputs/inputs.styled";
import {
  RowWrapper,
  OfferModalBody,
  WorkersContainer,
} from "./gigDetail.styled";
import { CircularProgress } from "@material-ui/core";
import { NumberInputContainer } from "../saveGig/hourlyRate/hourlyRate.styled";
import { gig } from "services/api/company/gig.service";
import { useAlertStore } from "stores/alertStore/alertStore";
import { TabsStyled, TabStyled } from "../tab/tab.styled";
import { OfferWorkerListItem } from "./offerWorkerListItem";
import { Api } from "services/api/api.service";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { SelectRateWrapper } from "../workpool/workpool.styled";

type Props = {
  gigData?: CompanyGigDto;
  createGig: boolean;
  setOfferList: React.Dispatch<
    React.SetStateAction<
      {
        workerId: number;
        rate: number;
        usingOfferRate: boolean;
        type: "favorite" | "workpool";
      }[]
    >
  >;
  offerList: {
    workerId: number;
    rate: number;
    usingOfferRate: boolean;
    type: "favorite" | "workpool";
  }[];
  setOfferModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offerRate: number;
  setOfferRate: React.Dispatch<React.SetStateAction<number>>;
  useOfferRateForAll: boolean;
  setUseOfferRateForAll: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OfferModal: React.FC<Props> = ({
  gigData,
  createGig,
  setOfferList,
  offerList,
  setOfferModalOpen,
  offerRate,
  setOfferRate,
  useOfferRateForAll,
  setUseOfferRateForAll,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [applicationState, , applicationDispatch] = useApplicationStore();
  const [workpoolState] = useWorkpoolStore();
  const [, , alertDispatch] = useAlertStore();
  const [isLoading, setIsLoading] = useState(false);
  const [offerType, setOfferType] = useState<"favorites" | "workpool">(
    "favorites"
  );
  const [offersError, setOffersError] = useState<number[]>([]);
  const [calendarState, calendarActions] = useCalendarStore();
  const [alreadyOffered, setAllreadyOffered] = useState<ApplicationDto[]>([]);

  const sendOffer = async (data: OfferGigRequest, workerId: number) => {
    const respons = await gig
      .offerGigToWorker(data)
      .then((res) => {
        return res.data.applicationId;
      })
      .then((appid) => {
        if (companyState.company) {
          return Api()
            .company.application.getApplicationById(
              companyState.company?.id,
              appid
            )
            .then((applicationRes) => {
              return applicationRes;
            });
        }
      });

    return respons;
  };

  useEffect(() => {
    if (offerList.length === 0) {
      setUseOfferRateForAll(false);
    }
  }, [offerList]);

  useEffect(() => {
    if (companyState.company?.id && gigData) {
      setIsLoading(true);
      Api()
        .company.application.getGigApplications(
          companyState.company.id,
          gigData?.id
        )
        .then((res) => {
          setAllreadyOffered(
            res.data.filter(
              (app) =>
                app.status === ApplicationStatus.PENDING ||
                app.status === ApplicationStatus.OFFERED ||
                (app.status === ApplicationStatus.CLOSED &&
                  app.resolution === ApplicationResolution.ACCEPTED)
            )
          );
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log(err);
        });
    }
  }, [gigData, companyState.company?.id]);

  const renderOfferModal = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("GigsCompany.OfferGig")}
        </Text>
        <TabsStyled>
          <TabStyled
            active={offerType === "favorites"}
            onClick={() => setOfferType("favorites")}
          >
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
            >
              {t("CompanyFavorite.Favorites")} ({companyState.favorites.length})
            </Text>
          </TabStyled>
          <TabStyled
            active={offerType === "workpool"}
            onClick={() => setOfferType("workpool")}
          >
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
            >
              {t("GigsCompany.Workpool")} (
              {workpoolState.verifiedEmployees.length})
            </Text>
          </TabStyled>
        </TabsStyled>
        <WorkersContainer>
          {offerType === "workpool" &&
            workpoolState.verifiedEmployees.map((employee) => (
              <OfferWorkerListItem
                key={employee.workerId}
                offerList={offerList}
                setOfferList={setOfferList}
                firstName={employee.worker.firstName}
                lastName={employee.worker.lastName}
                workerId={employee.workerId}
                firebaseId={employee.worker.firebaseId}
                hourlyRate={employee.defaultHourlyRate || 0}
                offerRate={offerRate}
                useOfferRate={useOfferRateForAll}
                type={"workpool"}
                disabled={
                  alreadyOffered.find(
                    (app) => app.worker.id === employee.workerId
                  )
                    ? true
                    : false
                }
              />
            ))}
          {offerType === "workpool" &&
            workpoolState.verifiedEmployees.length < 1 && (
              <Text
                color={Color.SeaBlue500}
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                style={{ marginTop: "16px" }}
              >
                {t("CompanyGroup.NoVerifiedGiggers")}
              </Text>
            )}
          {offerType === "favorites" &&
            companyState.favorites.map((favorite) => (
              <OfferWorkerListItem
                key={favorite.workerId}
                offerList={offerList}
                setOfferList={setOfferList}
                firstName={favorite.firstName}
                lastName={favorite.lastName}
                workerId={favorite.workerId}
                firebaseId={favorite.workerFirebaseId}
                hourlyRate={favorite.hourlyRate || 0}
                offerRate={offerRate}
                useOfferRate={useOfferRateForAll}
                type={"favorite"}
                disabled={
                  alreadyOffered.find(
                    (app) => app.worker.id === favorite.workerId
                  )
                    ? true
                    : false
                }
              />
            ))}

          {offerType === "favorites" && companyState.favorites.length < 1 && (
            <Text
              color={Color.SeaBlue500}
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              style={{ marginTop: "16px" }}
            >
              {t("CompanyFavorite.NoFavorites")}
            </Text>
          )}
        </WorkersContainer>
        <RowWrapper>
          <Text
            fontSize={FontSize.Large}
            color={Color.SeaBlue600}
            style={{ paddingTop: 25 }}
          >
            {t("GigsCompany.HourlyRate")}
          </Text>
          <NumberInputContainer>
            <NumberInput
              type="number"
              min={0}
              value={offerRate.toString()}
              onChange={(e) => {
                setOfferRate(parseInt(e.currentTarget.value) || 0);
              }}
              onBlur={(e) => {
                !e.currentTarget.value && setOfferRate(0);
              }}
              color={
                offerList.find(
                  (offer) =>
                    !offer.usingOfferRate === false || useOfferRateForAll
                )
                  ? Color.BurntSienna
                  : Color.SeaBlue500
              }
            />
            {offerList.find((offer) => offer.type === "workpool") && (
              <SelectRateWrapper>
                <CustomIcon
                  name="checkmark"
                  size="27px"
                  color={Color.White}
                  backgroundColor={
                    useOfferRateForAll ? Color.BurntSienna : Color.White
                  }
                  padding="2px"
                  style={{
                    border: `2px solid ${Color.BurntSienna}`,
                    flexShrink: 0,
                    marginLeft: "15px",
                  }}
                  onClick={() => setUseOfferRateForAll(!useOfferRateForAll)}
                />
                <Text>{t("CompanyGroup.UseThisRateForAllWorkers")}</Text>
              </SelectRateWrapper>
            )}
          </NumberInputContainer>
        </RowWrapper>
        {!createGig && gigData && (
          <RowWrapper>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setOfferModalOpen(false);
              }}
            >
              {t("General.Cancel")}
            </ButtonStyled>
            <ButtonStyled
              disabled={offerRate <= 0 || offerList.length < 1}
              onClick={async () => {
                let newApplications: ApplicationDto[] = [];
                let allApplications = applicationState.applications;
                setIsLoading(true);
                await Promise.all(
                  offerList.map(async (offer, index) => {
                    let offerGigRequest: OfferGigRequest = {
                      companyId: gigData.companyId,
                      workerId: offer.workerId,
                      gigId: gigData.id,
                      rate:
                        offer.usingOfferRate || useOfferRateForAll
                          ? offerRate
                          : offer.rate,
                    };
                    const applicationRes = await sendOffer(
                      offerGigRequest,
                      offer.workerId
                    );

                    let removeIndex = null;
                    applicationState.applications.map(
                      (application, applicationIndex) => {
                        if (
                          application.status !== "Closed" &&
                          applicationRes?.data.gigId === application.gigId &&
                          applicationRes.data.worker.id ===
                            application.worker.id
                        ) {
                          removeIndex = applicationIndex;
                        }
                      }
                    );
                    if (removeIndex !== null) {
                      allApplications.splice(removeIndex, 1);
                    }
                    if (applicationRes) {
                      newApplications.push(applicationRes.data);
                    }

                    if (index + 1 === offerList.length) {
                      setIsLoading(false);
                    }
                    if (
                      index + 1 === offerList.length &&
                      offersError.length === 0
                    ) {
                      setOfferModalOpen(false);
                      alertDispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                          title: t("GigsCompany.OfferSent"),
                          message: t("GigsCompany.OfferSentToApplicant"),
                        },
                      });
                      if (calendarState.currentWeek.length > 0) {
                        calendarActions.updateApplicationsCalendarContext(
                          newApplications
                        );
                      }
                      setOfferList([]);
                    } else if (
                      index + 1 === offerList.length &&
                      offersError.length > 0
                    ) {
                      alertDispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          icon: "cross", // TODO: ADD SAME ICON AS IN APP
                          title: t("Alerts.OhNo"),
                          message: t("Alerts.SomethingWentWrong"),
                        },
                      });
                      setOfferList([]);
                      setOffersError([]);
                      setOfferModalOpen(false);
                    }
                  })
                );
                applicationDispatch({
                  type: "GET_ALL_APPLICATIONS",
                  payload: allApplications.concat(newApplications),
                });
              }}
            >
              {isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                t("GigsCompany.SendOfferToApplicant")
              )}
            </ButtonStyled>
          </RowWrapper>
        )}
        {createGig && (
          <RowWrapper>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setOfferModalOpen(false);
                setOfferList([]);
              }}
            >
              {t("General.Cancel")}
            </ButtonStyled>
            <ButtonStyled
              disabled={offerRate <= 0 || offerList.length < 1}
              onClick={() => {
                setOfferModalOpen(false);
              }}
            >
              {t("General.Done")}
            </ButtonStyled>
          </RowWrapper>
        )}
      </>
    );
  };

  return (
    <OfferModalBody>
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        renderOfferModal()
      )}
    </OfferModalBody>
  );
};
