import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter"
import firebase from "services/firebase/firebase.config";
import { CHAT_ROOT } from "services/baseURLs.config";
import {
  ChatState,
  ChatActions,
  chatStateReducer,
  chatInitState,
  Message,
  Chat,
  Summary,
} from "./chatStore.reducer";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

const ChatStateContext = createContext<
  | {
      state: ChatState;
      socket: React.MutableRefObject<
        | (Socket<DefaultEventsMap, DefaultEventsMap> & {
            removeAllListeners?: () => void;
          })
        | null
      >;
    }
  | undefined
>(undefined);

const ChatDispatchContext = createContext<
  React.Dispatch<ChatActions> | undefined
>(undefined);

export const ChatStoreProvider: React.FC = ({ children }) => {
  const socketRef = useRef<
    (Socket & { removeAllListeners?: () => void }) | null
  >(null);
  const [state, dispatch] = useReducer(chatStateReducer, chatInitState);
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async function (user) {
        if (user) {
          const token =
            (await firebase.auth().currentUser?.getIdToken()) || null;
          setFirebaseToken(token);
        } else {
          setFirebaseToken(null);
          dispatch({ type: "CLEAR_CHAT_DATA" });
        }
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (firebaseToken && authState.accountType && companyState.company) {
      socketRef.current = io(CHAT_ROOT, {
        query: {
          userId: companyState.company.id.toString(),
          firebaseToken: firebaseToken,
          type: authState.accountType,
        },
      });

      if (socketRef.current) {
        /* Good handy log for debugging! */
        // socketRef.current.onAny((event, ...args) => {
        //   console.log(event, args);
        // });

        socketRef.current.on("connect_error", () => {
          console.log("CONNECT_ERROR");
        });

        socketRef.current.on("connect", () => {
          console.log("Connected to socket server...");
        });

        socketRef.current.on("summary", (data: Summary) => {
          dispatch({ type: "GET_SUMMARY", payload: data });
        });

        socketRef.current.on("recent_messages", (data: Chat) => {
          dispatch({
            type: "RECENT_MESSAGES",
            payload: data,
          });
        });

        socketRef.current.on(
          "message_sent",
          (data: { gigId: string; message: Message }) => {
            const userId = companyState.company?.id.toString();
            const otherUser = data.message.users.filter(
              (user) =>
                user.userId !== userId || user.type !== authState.accountType
            )[0];

            dispatch({
              type: "MESSAGE_SENT",
              payload: { ...data, otherUser },
            });
          }
        );

        socketRef.current.on("auth_failed", () => {
          console.log("Unauthorized");
        });
      }
    }

    return () => {
      socketRef.current?.removeAllListeners!();
      socketRef.current?.disconnect();
    };
  }, [authState.accountType, companyState.company?.id, firebaseToken]);

  return (
    <ChatStateContext.Provider value={{ state: state, socket: socketRef }}>
      <ChatDispatchContext.Provider value={dispatch}>
        {children}
      </ChatDispatchContext.Provider>
    </ChatStateContext.Provider>
  );
};

export const useChatStore = () => {
  const state = useContext(ChatStateContext);
  const dispatch = useContext(ChatDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error("useChatStore must be used within a ChatStoreProvider");
  }

  return [state, dispatch] as const;
};
