import React from "react";
import { useTranslation } from "react-i18next";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import { ChatContainerListItem } from "./chatContainerListItem.component";
import {
  ChatContainer,
  ChatContainerListStyled,
  NoChatsContainer,
} from "components/nav/chat/chatContainerList.styled";
import { Text } from "components/Typography/text.styled";

type Props = {
  setShowChatContainer: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChatContainerList: React.FC<Props> = ({
  setShowChatContainer,
}) => {
  const { t } = useTranslation();
  const [chatState] = useChatStore();

  return (
    <ChatContainer style={{ alignItems: "flex-start" }}>
      <ChatContainerListStyled>
        {chatState.state.summary.chats?.length !== 0 ? (
          chatState.state.summary.chats.map((chatSummary, index) => (
            <ChatContainerListItem
              key={index}
              chatSummary={chatSummary}
              setShowChatContainer={setShowChatContainer}
            />
          ))
        ) : (
          <NoChatsContainer>
            <Text>{t("Chat.NoChatHistory")}</Text>
          </NoChatsContainer>
        )}
      </ChatContainerListStyled>
    </ChatContainer>
  );
};
