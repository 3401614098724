import React from "react";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { CompanySelect } from "../../components/companySelect/companySelect.component";

export const CompanySelectPage = () => {
  return (
    <LoggedInPageContainer>
      <CompanySelect />
    </LoggedInPageContainer>
  );
};
