import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { PaymentMethod } from "model/Gig";
import { WorkerTimeReports } from "./workerTimeReports.component";
import { TimeReportModal } from "./timeReportModal.component";
import {
  WorkerTimeReportContainer,
  NoTimeReportsWrapper,
  CircularProgressWrapper,
} from "./monthlyOverview.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { RatingModal } from "./ratingModal.component";
import { EndorseModal } from "./endorsements/endorseModal.component";
import { SelectRow, SelectText } from "./timeReports.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  ButtonContainer,
  ModalBody,
} from "../applicantDetails/applicantDetails.styled";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { CircularProgress, Modal } from "@material-ui/core";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { isWithin15Minutes, updateTimeReportInContext } from "utils/utils";
import { Banner, InfoCircleBanner } from "components/banners/banner.styled";

export const UnhandledOverview: React.FC = () => {
  const { t } = useTranslation();
  const [chosenTimeReportWithWorker, setChosenTimeReportWithWorker] =
    useState<TimeReportDto | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [totalCostMonthly, setTotalCostMonthly] = useState(0);
  const [select, setSelect] = useState(false);
  const [selectedTimeReports, setSelectedTimeReports] = useState([]);
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [unhandledTimeReports, setUnhandledTimeReports] = useState<
    WorkerWithTimeReports[]
  >([]);
  const [showCheckInCheckOutColumns, setShowCheckInCheckOutColumns] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const getWorkersWithTimeReports = async () => {
    setIsLoading(true);
    if (companyState.company?.id) {
      let unhandled: any[] = [];

      try {
        const res =
          await Api().company.timeReport.getUnhandledTimeReportWorkers(
            companyState.company.id
          );

        const promises = res.data.map(async (worker) => {
          if (companyState.company?.id) {
            const timeReportRes =
              await Api().company.timeReport.getWorkerUnhandledTimeReports(
                companyState.company.id,
                worker.workerId
              );
            return {
              worker,
              timeReports: timeReportRes.data,
            };
          }
        });

        const result = await Promise.all(promises);
        unhandled = result.filter(Boolean);
        setIsLoading(false);
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: unhandled,
        });
        timeReportActions.getUnhandledTimeReportCount(companyState.company.id);
      } catch (err) {
        console.log(err, "ERROR FETCHING UNHANDLED TIME REPORTS", err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getWorkersWithTimeReports();
  }, [companyState.company?.id]);

  useEffect(() => {
    setUnhandledTimeReports(timeReportState.unhandledTimeReports);
  }, [timeReportState.unhandledTimeReports]);

  const thereAreNoTimeReportsWithDifferenceInTime = () => {
    return unhandledTimeReports.map((timeReportData) => {
      return timeReportData.timeReports.some((timeReport) => {
        return (
          (timeReport.checkInTime !== null &&
            isWithin15Minutes(
              new Date(timeReport.startTime),
              new Date(timeReport.checkInTime)
            )) ||
          (timeReport.checkOutTime !== null &&
            isWithin15Minutes(
              new Date(timeReport.endTime),
              new Date(timeReport.checkOutTime)
            ))
        );
      });
    });
  };

  const handleSelectedTimeReports = async (
    response: TimeReportStatus.APPROVED | TimeReportStatus.REJECTED
  ) => {
    setIsLoading(true);
    let errors = 0;

    await Promise.all(
      selectedTimeReports.map(async (selectedTimeReport: TimeReportDto) => {
        let newStatus: TimeReportStatus;
        if (response === TimeReportStatus.APPROVED) {
          newStatus =
            selectedTimeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY
              ? TimeReportStatus.APPROVED
              : TimeReportStatus.CLOSED;
        } else {
          newStatus = TimeReportStatus.REJECTED;
        }
        const newTimeReport = {
          ...selectedTimeReport,
          status: newStatus,
          ...(newStatus === TimeReportStatus.CLOSED && {
            resolution: TimeReportResolution.TIME_REPORTED,
          }),
        };
        await Api()
          .company.timeReport.updateGigTimeReport(
            selectedTimeReport.companyId,
            selectedTimeReport.gigId,
            newTimeReport
          )
          .then(async (res) => {
            if (
              newStatus === TimeReportStatus.APPROVED &&
              selectedTimeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY
            ) {
              await timeReportActions
                .createPayoutForTimeReport(selectedTimeReport)
                .then((res) => {
                  timeReportDispatch({
                    type: "UPDATE_UNHANDLED_TIME_REPORTS",
                    payload: updateTimeReportInContext(
                      timeReportState.unhandledTimeReports,
                      "UPDATE_UNHANDLED_TIME_REPORTS",
                      newTimeReport
                    ),
                  });
                })
                .catch((err) => {
                  console.log(
                    "ERROR CREATING PAYOUT ID",
                    selectedTimeReport.id,
                    err
                  );
                  errors++;
                });
            } else {
              timeReportDispatch({
                type: "UPDATE_UNHANDLED_TIME_REPORTS",
                payload: updateTimeReportInContext(
                  timeReportState.unhandledTimeReports,
                  "UPDATE_UNHANDLED_TIME_REPORTS",
                  newTimeReport
                ),
              });
            }
          })
          .catch((err) => {
            console.log(
              "ERROR UPDATING TIMEREPORT STATUS",
              selectedTimeReport.id,
              err
            );
            errors++;
          });
      })
    );
    if (errors === 0) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "checkmark",
          title: t("Alerts.Updated"),
          message: t("CompanyTimeReport.TimeReportsHaveBeenUpdated"),
        },
      });
    } else {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert",
          title: t("Alerts.OhNo"),
          message: t("CompanyTimeReport.AllTimeReportsWereNotUpdated"),
        },
      });
    }

    if (companyState.company?.id) {
      timeReportActions.getUnhandledTimeReportCount(companyState.company.id);
    }
    setSelectedTimeReports([]);
    setSubmitModalOpen(false);
    setRejectModalOpen(false);
    setIsLoading(false);
  };

  const renderModalBody = (status: TimeReportStatus) => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {status === TimeReportStatus.APPROVED
            ? t("CompanyTimeReport.ApproveAndPayTimeReports", {
                timeReports: selectedTimeReports.length.toString(),
              })
            : t("CompanyTimeReport.RejectTimeReports", {
                timeReports: selectedTimeReports.length.toString(),
              })}
        </Text>
        {status === TimeReportStatus.APPROVED && (
          <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
            {t("CompanyTimeReport.TimeReportsForSimflexityPayGigs")}
          </Text>
        )}

        <ButtonContainer>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setSubmitModalOpen(false);
              setRejectModalOpen(false);
            }}
          >
            {t("General.Cancel")}
          </ButtonStyled>
          <ButtonStyled
            onClick={() => {
              if (status == TimeReportStatus.APPROVED) {
                handleSelectedTimeReports(TimeReportStatus.APPROVED);
              } else {
                handleSelectedTimeReports(TimeReportStatus.REJECTED);
              }
            }}
            backgroundColor={
              status === TimeReportStatus.REJECTED
                ? Color.Destructive
                : Color.BurntSienna
            }
          >
            {status === TimeReportStatus.REJECTED
              ? t("CompanyTimeReport.Reject")
              : t("CompanyTimeReport.Approve") +
                " " +
                t("CompanyTimeReport.AndPay")}
          </ButtonStyled>
        </ButtonContainer>
      </>
    );
  };

  return (
    <>
      {unhandledTimeReports.length !== 0 && (
        <>
          {!thereAreNoTimeReportsWithDifferenceInTime() && (
            <Banner backgroundColor={Color.Warning}>
              <InfoCircleBanner>
                <Text
                  fontFamily={FontFamily.LeagueSpartanBold}
                  color={Color.White}
                  padding
                  fontSize={FontSize.Standard}
                >
                  i
                </Text>
              </InfoCircleBanner>
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {t(
                  "CompanyTimeReport.ThereIsUnhandledTimeReportsWhereTimeDiffers"
                )}
              </Text>
            </Banner>
          )}
          <SelectRow>
            <SelectText
              fontFamily={FontFamily.MontserratSemiBold}
              onClick={() => {
                setShowCheckInCheckOutColumns(!showCheckInCheckOutColumns);
              }}
            >
              {t("CompanyTimeReport.ShowCheckInCheckOutColumns")}
            </SelectText>
            <CustomIcon
              name="checkmark"
              size="27px"
              color={Color.White}
              backgroundColor={
                showCheckInCheckOutColumns ? Color.MidnightBlue : Color.White
              }
              padding="2px"
              onClick={() => {
                setShowCheckInCheckOutColumns(!showCheckInCheckOutColumns);
              }}
              style={{
                border: `2px solid ${Color.MidnightBlue}`,
                flexShrink: 0,
                marginLeft: "8px",
                marginRight: "20px",
              }}
            />
            <SelectText
              fontFamily={FontFamily.MontserratSemiBold}
              onClick={() => {
                setSelect(!select);
              }}
            >
              {t("CompanyTimeReport.SelectMultiple")}
            </SelectText>
            <CustomIcon
              name="checkmark"
              size="27px"
              color={Color.White}
              backgroundColor={select ? Color.MidnightBlue : Color.White}
              padding="2px"
              onClick={() => {
                setSelectedTimeReports([]);
                setSelect(!select);
              }}
              style={{
                border: `2px solid ${Color.MidnightBlue}`,
                flexShrink: 0,
                marginLeft: "8px",
              }}
            />
          </SelectRow>
        </>
      )}

      {!isLoading && unhandledTimeReports.length !== 0 ? (
        unhandledTimeReports.map((timeReportData, index) => {
          return (
            <WorkerTimeReportContainer key={index}>
              <H4>{`${timeReportData.worker.firstName} ${timeReportData.worker.lastName}`}</H4>
              <WorkerTimeReports
                select={select}
                selectedTimeReports={selectedTimeReports}
                setSelectedTimeReports={setSelectedTimeReports}
                monthlyWorkerWithTimeReports={timeReportData}
                setChosenTimeReportWithWorker={setChosenTimeReportWithWorker}
                setModalOpen={setModalOpen}
                setTotalCostMonthly={setTotalCostMonthly}
                totalCostMonthly={totalCostMonthly}
                showCheckInCheckOutColumns={showCheckInCheckOutColumns}
                unhandledView
              />
            </WorkerTimeReportContainer>
          );
        })
      ) : isLoading ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        <NoTimeReportsWrapper>
          <H4>{t("CompanyTimeReport.NoUnhandledTimeReports")}</H4>
          <Text>{t("CompanyTimeReport.NoUnhandledTimeReportsInfo")}</Text>
        </NoTimeReportsWrapper>
      )}
      {select && (
        <ButtonContainer>
          <ButtonStyled
            disabled={selectedTimeReports.length < 1}
            backgroundColor={Color.Destructive}
            onClick={() => setRejectModalOpen(true)}
          >
            {t("CompanyTimeReport.RejectSelected")}
          </ButtonStyled>
          <ButtonStyled
            disabled={selectedTimeReports.length < 1}
            onClick={() => setSubmitModalOpen(true)}
          >
            {t("CompanyTimeReport.ApproveSelected")}
          </ButtonStyled>
        </ButtonContainer>
      )}
      {chosenTimeReportWithWorker && (
        <TimeReportModal
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRatingModalOpen={setRatingModalOpen}
        />
      )}
      {ratingModalOpen && chosenTimeReportWithWorker && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}
      {endorseModalOpen && chosenTimeReportWithWorker && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
        />
      )}
      <Modal
        open={submitModalOpen}
        onClose={() => {
          setSubmitModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            renderModalBody(TimeReportStatus.APPROVED)
          )}
        </ModalBody>
      </Modal>
      <Modal
        open={rejectModalOpen}
        onClose={() => {
          setRejectModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          {isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            renderModalBody(TimeReportStatus.REJECTED)
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
