import firebase from "firebase";
import { CompanyUserDto } from "model/User";

export type authActions =
  | {
      type: "ACCOUNT_SIGN_IN";
      payload: {
        accountType?: "Company" | "User" | "Employee";
        activeAccountId?: number;
        user?: CompanyUserDto;
        firestoreUser: firebase.User;
      };
    }
  | { type: "CHANGE_ACTIVE_ACCOUNT"; payload: { activeAccountId: number } }
  | { type: "ACCOUNT_SIGN_OUT" }
  | { type: "UPDATE_STATE_FROM_CACHE"; payload: authState }
  | { type: "SET_IS_LOADING"; payload: boolean };

export type authState = {
  firestoreUser: firebase.User | null;
  accountType: "Company" | "User" | "Employee" | null | "CompanyTimeStamp";
  activeAccountId: number | null;
  user?: CompanyUserDto;
  signedIn: boolean;
  isLoading: boolean;
};

export const initialAuthState: authState = {
  firestoreUser: null,
  accountType: null,
  activeAccountId: null,
  signedIn: false,
  isLoading: false,
};

export const authStateReducer = (state: authState, action: authActions) => {
  switch (action.type) {
    case "ACCOUNT_SIGN_IN": {
      const signedIn = Boolean(action.payload.firestoreUser.uid);
      return {
        ...state,
        ...action.payload,
        signedIn,
      };
    }
    case "CHANGE_ACTIVE_ACCOUNT": {
      return { ...state, ...action.payload };
    }
    case "UPDATE_STATE_FROM_CACHE": {
      return { ...state, ...action.payload };
    }
    case "ACCOUNT_SIGN_OUT": {
      return {
        ...state,
        ...initialAuthState,
      };
    }
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    default: {
      return state;
    }
  }
};
