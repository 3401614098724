import React, { createContext, useReducer, useContext } from "react";
import { calendarActions } from "./calendarStore.actions";

import {
  CalendarActions,
  calendarInitState,
  CalendarState,
  calendarStateReducer,
} from "./calendarStore.reducer";

export type CalendarDispatch = (action: CalendarActions) => void;

const CalendarStateContext = createContext<CalendarState | undefined>(
  undefined
);
const CalendarDispatchContext = createContext<CalendarDispatch | undefined>(
  undefined
);

export const CalendarStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(calendarStateReducer, calendarInitState);

  return (
    <CalendarStateContext.Provider value={state}>
      <CalendarDispatchContext.Provider value={dispatch}>
        {children}
      </CalendarDispatchContext.Provider>
    </CalendarStateContext.Provider>
  );
};

export const useCalendarStore = () => {
  const state = useContext(CalendarStateContext);
  const dispatch = useContext(CalendarDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useCalendarStore must be used within a CalendarStoreProvider"
    );
  }

  const actions = calendarActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
