import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, Text } from "components/Typography/text.styled";
import { DrawerMenuListStyled } from "../menuList/drawerMenuList.styled";
import { DrawerStyled } from "./drawer.styled";
import { NavLi, NavIconLi } from "../menuList/navBarMenuList.styled";
import { useEmployeeStore } from "web-apps/employee/stores/employeeStore/employeeStore";
import { MenuItemsEmployee } from "../menuList/menuItemsEmployee.component";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Drawer: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();
  const [employeeState, , employeeDispatch] = useEmployeeStore();
  const menuList = getMenuList(authState.accountType, t);

  const handleClick = async () => {
    authActions.signOut().then(() => {
      employeeDispatch({ type: "CLEAR_EMPLOYEE_INFORMATION" });
      history.push("/employee/login");
      setOpen(!open);
    });
  };

  return (
    <DrawerStyled open={open} className="drawer">
      <CustomIcon
        name="cross"
        size="40px"
        onClick={() => {
          setOpen(!open);
        }}
        style={{ margin: "20px 20px 0 0", alignSelf: "flex-end" }}
      />

      <DrawerMenuListStyled>
        {authState.signedIn && employeeState.employee && (
          <>
            <MenuItemsEmployee
              menuList={menuList}
              open={open}
              setOpen={setOpen}
            />

            <NavIconLi
              active={window.location.pathname === "/employee/profile"}
            >
              <CustomLink
                color={Color.SeaBlue500}
                to={"/employee/profile"}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CustomIcon
                  name="person"
                  color={Color.SeaBlue500}
                  padding="5px"
                />
                <p style={{ margin: 0, textAlign: "center" }}>
                  {t("ProfileCompany.Profile")}
                </p>
              </CustomLink>
            </NavIconLi>

            <NavIconLi active={false}>
              <CustomLink color={Color.BurntSienna} onClick={handleClick}>
                <CustomIcon name="logout" padding="5px" />
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ margin: 0 }}
                >
                  {t("ProfileCompany.SignOut")}
                </Text>
              </CustomLink>
            </NavIconLi>
          </>
        )}
        {authState.signedIn && !employeeState.employee && (
          <NavLi active={false} color={Color.BurntSienna} onClick={handleClick}>
            <CustomLink to={`${path}/login`} color={Color.Destructive}>
              {t("ProfileCompany.SignOut")}
            </CustomLink>
          </NavLi>
        )}
        {!authState.signedIn && (
          <NavLi active={false}>
            <CustomLink to={`${path}/login`} color={Color.Destructive}>
              {t("LoginCompany.Login")}
            </CustomLink>
          </NavLi>
        )}
      </DrawerMenuListStyled>
    </DrawerStyled>
  );
};
