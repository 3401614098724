import styled from "styled-components";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { TabsStyled } from "../tab/tab.styled";

type Props = {
  active: boolean;
};

export const StyledTabGroup = styled(TabsStyled)`
  margin-bottom: 40px;
`;

export const TabStyled = styled.div<Props>`
  width: 50%;
  flex-basis: 50%;
  text-align: center;
  border-bottom: ${(props) =>
    props.active
      ? `3px solid ${Color.BurntSienna}`
      : `1px solid ${Color.SeaBlue200}`};

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const SelectRow = styled.div`
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: -50px;
`;

export const SelectText = styled(Text)`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
