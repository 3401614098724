import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TimeReportDto, WorkerWithTimeReports } from "model/TimeReport";
import { TimeReportModal } from "./timeReportModal.component";
import {
  WorkerTimeReportContainer,
  NoTimeReportsWrapper,
  CircularProgressWrapper,
} from "./monthlyOverview.styled";
import { H4 } from "components/Typography/text.styled";
import { RatingModal } from "./ratingModal.component";
import { EndorseModal } from "./endorsements/endorseModal.component";

import { WorkerGigTimeReports } from "./workerGigTimeReports.component";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { CircularProgress } from "@material-ui/core";

type Props = {
  gigId?: string;
};

export const GigTimeReportsOverView: React.FC<Props> = ({ gigId }) => {
  const { t } = useTranslation();
  const [chosenTimeReportWithWorker, setChosenTimeReportWithWorker] =
    useState<TimeReportDto | null>(null);
  const [workersWithTimeReports, setWorkersWithTimeReports] = useState<
    WorkerWithTimeReports[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [timeReportState, , timeReportDispatch] = useTimeReportStore();
  const [isLoading, setIsLoading] = useState(false);
  const [companyState] = useCompanyStore();

  useEffect(() => {
    fetchGigTimeReports();
  }, [gigId]);

  useEffect(() => {
    timeReportDispatch({
      type: "UPDATE_GIG_TIME_REPORTS",
      payload: workersWithTimeReports,
    });
  }, [workersWithTimeReports]);

  const fetchGigTimeReports = () => {
    setIsLoading(true);
    if (companyState.company?.id) {
      Api()
        .company.timeReport.getGigTimeReports(
          companyState.company.id,
          parseInt(gigId as string)
        )
        .then((res) => {
          setWorkersWithTimeReports(sortTimeReportsByWorker(res.data));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const sortTimeReportsByWorker = (timeReports: TimeReportDto[]) => {
    const map = new Map<
      number,
      {
        worker: any;
        timeReports: TimeReportDto[];
      }
    >();

    timeReports.forEach((timeReport) => {
      if (map.has(timeReport.workerId)) {
        map.get(timeReport.workerId)?.timeReports.push(timeReport);
      } else {
        map.set(timeReport.workerId, {
          worker: {
            firstName: timeReport.firstName,
            lastName: timeReport.lastName,
            workerId: timeReport.workerId,
          },
          timeReports: [timeReport],
        });
      }
    });
    return [...map.values()];
  };

  return (
    <>
      {!isLoading &&
        timeReportState.gigTimeReports.map((workerWithTimeReports, index) => {
          return (
            <WorkerTimeReportContainer key={index}>
              <H4>{`${workerWithTimeReports.worker.firstName} ${workerWithTimeReports.worker.lastName}`}</H4>

              <WorkerGigTimeReports
                workerWithTimeReports={workerWithTimeReports}
                setChosenTimeReportWithWorker={setChosenTimeReportWithWorker}
                setModalOpen={setModalOpen}
              />
            </WorkerTimeReportContainer>
          );
        })}
      {isLoading && (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}

      {timeReportState.gigTimeReports.length === 0 && !isLoading && (
        <NoTimeReportsWrapper>
          <H4>{t("CompanyTimeReport.NoTimeReports")}</H4>
        </NoTimeReportsWrapper>
      )}

      {chosenTimeReportWithWorker && (
        <TimeReportModal
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRatingModalOpen={setRatingModalOpen}
        />
      )}
      {ratingModalOpen && chosenTimeReportWithWorker && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}
      {endorseModalOpen && chosenTimeReportWithWorker && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
        />
      )}
    </>
  );
};
