import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily } from "config/font";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-family: ${FontFamily.MontserratRegular};

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: auto;
    right: 0;
  }
`;

export const DashBoardColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.MontserratRegular};
  width: 520px;
`;

export const DashBoardCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
`;

export const DashboardCellContent = styled.div`
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DashboardCellWeekContent = styled.div`
  height: 35vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: blue;
  grid-column: 3;
`;

export const TimeReportCell = styled.div`
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  #accept-btn: {
    z-index: 100;
  }
  #reject-btn: {
    z-index: 100;
  }
`;

export const CellContent = styled.div`
  display: contents;
  width: 100%;
  grid-column: 1/3;
`;
