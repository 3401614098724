import styled from "styled-components";
import { Color } from "config/colors";

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0;
  }
`;

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
  border-bottom: 1px solid ${Color.SeaBlue200};
`;
export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
