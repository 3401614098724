import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { SkillAndEndorsements, TimeReportDto } from "model/TimeReport";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text, CustomLink } from "components/Typography/text.styled";
import { ModalBody, ButtonContainer } from "../timeReportModal.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { EndorsementListItem } from "./endorseListItem.component";
import { ButtonStyled } from "components/buttons/buttons.styled";

type Props = {
  chosenTimeReportWithWorker: TimeReportDto;
  setEndorseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  endorseModalOpen: boolean;
};

export const EndorseModal: React.FC<Props> = ({
  chosenTimeReportWithWorker,
  setEndorseModalOpen,
  endorseModalOpen,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [endorsements, setEndorsements] = useState<string[]>([]);
  const [workerSkillsAndEndorsements, setWorkerSkillsAndEndorsements] =
    useState<SkillAndEndorsements>();
  const [isLoading, setIsLoading] = useState(false);
  const [endorsementErrors, setEndorsementErrors] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    if (
      chosenTimeReportWithWorker.workerId &&
      companyState.company?.id &&
      chosenTimeReportWithWorker.id
    ) {
      Api()
        .company.timeReport.getWorkerSkillsAndEndorsements(
          companyState.company.id,
          chosenTimeReportWithWorker.id,
          chosenTimeReportWithWorker.workerId
        )
        .then((res) => {
          setWorkerSkillsAndEndorsements(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [
    chosenTimeReportWithWorker.workerId,
    companyState.company?.id,
    chosenTimeReportWithWorker.id,
  ]);

  const submitEndorsements = () => {
    setIsLoading(true);
    endorsements.map((skill, index) => {
      if (companyState.company) {
        Api()
          .company.worker.createWorkerEndorsement(
            companyState.company.id,
            chosenTimeReportWithWorker.workerId,
            skill
          )
          .then((res) => {})
          .catch((err) => {
            setEndorsementErrors([...endorsementErrors, skill]);
          });
        if (index + 1 === endorsements.length) {
          setIsLoading(false);
        }
        if (index + 1 === endorsements.length && endorsementErrors.length < 1) {
          setEndorseModalOpen(false);
          setEndorsements([]);
          setEndorsementErrors([]);
        }
        if (index + 1 === endorsements.length && endorsementErrors.length > 0) {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
          setEndorsements([]);
          setEndorsementErrors([]);
        }
      }
    });
  };

  return (
    <Modal
      open={endorseModalOpen}
      onClose={() => {
        setEndorseModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.SeaBlue600}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.PraiseUser", {
              user: chosenTimeReportWithWorker.firstName,
            })}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.SeaBlue600}
          >
            {t("CompanyTimeReport.YouDoThisByEndorsingTheirSkills", {
              user: chosenTimeReportWithWorker.firstName,
            })}
          </Text>
          {workerSkillsAndEndorsements &&
            workerSkillsAndEndorsements.skills.map((skill) => (
              <EndorsementListItem
                workerSkillsAndEndorsements={workerSkillsAndEndorsements}
                skill={skill.skill}
                endorsements={endorsements}
                setEndorsements={setEndorsements}
              />
            ))}

          <ButtonContainer>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <ButtonStyled
                disabled={endorsements.length < 1}
                onClick={submitEndorsements}
              >
                {t("CompanyTimeReport.Endorse")}
              </ButtonStyled>
            )}
          </ButtonContainer>
          <CustomLink
            color={Color.BurntSienna}
            align="right"
            onClick={() => {
              setEndorseModalOpen(false);
            }}
          >
            {t("RegisterCompany.Skip")}
          </CustomLink>
        </div>
      </ModalBody>
    </Modal>
  );
};
