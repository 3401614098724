import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { getDateString } from "utils/utils";
import { ApplicationDto } from "model/Application";
import { GigApplicantsList } from "web-apps/company/components/gigApplicants/gigApplicantsList.component";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { useAuthStore } from "stores/authStore/authStore";
import { FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { HeaderContainer } from "web-apps/company/components/gigApplicants/gigApplicants.styled";
import { Color } from "config/colors";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { useGetGig } from "hooks/useGetGig";
import { Api } from "services/api/api.service";
import { GigDetailsModal } from "web-apps/company/components/calendar/gigDetailsModal.component";

type MatchProps = {
  id?: string;
};

export const GigApplicantsPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const id = props.match.params.id;
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [applicationState] = useApplicationStore();
  const [authState] = useAuthStore();
  const [applicants, setApplicants] = useState<ApplicationDto[]>([]);
  const history = useHistory();
  const [gigData, isLoading, error, fetch] = useGetGig(id);
  const [appIsLoading, setAppIsLoading] = useState(false);
  const [showGigModalOpen, setShowGigModalOpen] = useState(false);

  useEffect(() => {
    if (
      companyState.company?.id &&
      gigData &&
      gigData.companyId !== companyState.company?.id
    ) {
      history.goBack();
    } else if (companyState.company?.id && id) {
      fetch(id);
      setAppIsLoading(true);
      Api()
        .company.application.getGigApplications(
          companyState.company?.id,
          parseInt(id)
        )
        .then((res) => {
          setApplicants(res.data);
          setAppIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAppIsLoading(false);
        });
    }
  }, [id, companyState.company?.id]);

  return (
    <LoggedInPageContainer>
      {authState.isLoading ||
      companyState.isLoading ||
      isLoading ||
      appIsLoading ||
      applicationState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          {gigData && (
            <>
              <HeaderContainer>
                <H3>
                  {t(gigData.role, { ns: "roles" })}{" "}
                  {getDateString(gigData.shifts[0].startTime)}
                </H3>
                <Text fontSize={FontSize.Large}>
                  {gigData.workers.length} / {gigData.amountOfWorkers}{" "}
                  {t("General.Giggers")}
                </Text>
              </HeaderContainer>
              <HeaderContainer>
                <CustomLink
                  onClick={() => history.goBack()}
                  color={Color.Destructive}
                  text-align="right"
                >
                  {t("General.Back")}
                </CustomLink>
                <ButtonStyled
                  onClick={() => setShowGigModalOpen(true)}
                  style={{ margin: 0 }}
                >
                  {t("ApplicationsCompany.ViewGig")}
                </ButtonStyled>
              </HeaderContainer>
            </>
          )}
          <GigApplicantsList
            applicants={applicants}
            gigId={id}
            setApplicants={setApplicants}
          />
        </>
      )}
      {showGigModalOpen && gigData && (
        <GigDetailsModal
          gigId={gigData.id}
          setModalOpen={setShowGigModalOpen}
          modalOpen
        />
      )}
    </LoggedInPageContainer>
  );
};
