import React, { createContext, useReducer, useContext, useEffect } from "react";
import { AuthActions } from "./authStore.actions";
import {
  authState,
  authStateReducer,
  authActions,
  initialAuthState,
} from "./authStore.reducer";
import firebase from "firebase";

export type AuthStoreDispatch = (action: authActions) => void;

const authStateContext = createContext<authState | undefined>(undefined);
const authDispatchContext = createContext<AuthStoreDispatch | undefined>(
  undefined
);
const auth = firebase.auth;

export const AuthStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authStateReducer, initialAuthState);

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      if (!user) {
        localStorage.removeItem("PERSISTED::AuthState");
        localStorage.removeItem("CalendarDate");
        dispatch({ type: "ACCOUNT_SIGN_OUT" });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const presistedAuthState = localStorage.getItem("PERSISTED::AuthState");
    // If persisted state exist we will update the store with those values.
    if (presistedAuthState) {
      const cachedAuthState: authState = JSON.parse(presistedAuthState);
      dispatch({
        type: "UPDATE_STATE_FROM_CACHE",
        payload: cachedAuthState,
      });
    }
  }, []);

  // Sync state changes to LocalStorage
  useEffect(() => {
    localStorage.setItem(
      "PERSISTED::AuthState",
      JSON.stringify({
        firestoreUser: state.firestoreUser,
        accountType: state.accountType,
        activeAccountId: state.activeAccountId,
        signedIn: state.signedIn,
      })
    );
  }, [state]);

  return (
    <authStateContext.Provider value={state}>
      <authDispatchContext.Provider value={dispatch}>
        {children}
      </authDispatchContext.Provider>
    </authStateContext.Provider>
  );
};

export const useAuthStore = () => {
  const state = useContext(authStateContext);
  const dispatch = useContext(authDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error("useAuthStore must be used within a authStoreProvider");
  }

  const actions = AuthActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
