import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { getMenuList } from "utils/utils";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink } from "components/Typography/text.styled";
import { Burger, NavBarStyled, LogInWrapper, Flex1 } from "./navBar.styled";
import { NavBarMenuListStyled } from "../menuList/navBarMenuList.styled";
import LogoSVG from "images/logo/svg/simflexity-logo.svg";
import { Logo } from "../logo/logo.styled";
import { useEmployeeStore } from "web-apps/employee/stores/employeeStore/employeeStore";
import { MenuItemsEmployee } from "../menuList/menuItemsEmployee.component";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavBar: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [authState, authActions] = useAuthStore();

  const [employeeState, , employeeDispatch] = useEmployeeStore();
  const menuList = getMenuList(authState.accountType, t);

  const handleClick = async () => {
    authActions.signOut().then(() => {
      employeeDispatch({ type: "CLEAR_EMPLOYEE_INFORMATION" });
      history.push("/employee/login");
      setOpen(!open);
    });
  };

  return (
    <NavBarStyled>
      <Link to="/employee/schedule">
        <Logo src={LogoSVG} alt="logo" style={{ height: 75 }} />
      </Link>
      <Flex1>
        <NavBarMenuListStyled>
          {employeeState.employee && (
            <MenuItemsEmployee
              menuList={menuList}
              open={open}
              setOpen={setOpen}
            />
          )}
        </NavBarMenuListStyled>
      </Flex1>
      <LogInWrapper>
        {authState.signedIn && employeeState.employee ? (
          <CustomLink onClick={handleClick} color={Color.Destructive}>
            {t("ProfileCompany.SignOut")}
          </CustomLink>
        ) : (
          <>
            <CustomLink to={`${path}/login`} color={Color.MidnightBlue}>
              {t("LoginCompany.EmployeeLogin")}
            </CustomLink>
            <CustomLink
              to={`${path.replace("employee", "company")}/login`}
              color={Color.Destructive}
            >
              {t("LoginCompany.CompanyLogin")}
            </CustomLink>
          </>
        )}
      </LogInWrapper>
      <Burger>
        <CustomIcon
          name="hamburger"
          size="40px"
          onClick={() => {
            setOpen(!open);
          }}
        />
      </Burger>
    </NavBarStyled>
  );
};
