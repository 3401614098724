import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Key, NumberPadContainer, Text } from "./numberPad.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

type NumberPadProps = {
  checkedIn?: boolean;
  keyShouldBeDisabled: boolean;
  onNumberChange: (num: string | boolean) => void;
  onClickStampKey: () => void;
};

export const NumberPad: React.FC<NumberPadProps> = ({
  checkedIn,
  keyShouldBeDisabled,
  onNumberChange,
  onClickStampKey,
}) => {
  return (
    <>
      <NumberPadContainer>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("1")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"1"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("2")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"2"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("3")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"3"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("4")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"4"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("5")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"5"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("6")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"6"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("7")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"7"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("8")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"8"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("9")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"9"}
          </Text>
        </Key>
        <Key
          backgroundColor={Color.Destructive}
          onClick={() => onNumberChange(true)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"C"}
          </Text>
        </Key>
        <Key
          backgroundColor={keyShouldBeDisabled ? Color.Disabled : null}
          onClick={() => onNumberChange("0")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {"0"}
          </Text>
        </Key>
        <Key
          backgroundColor={
            !keyShouldBeDisabled ? Color.Disabled : Color.BurntSienna
          }
          onClick={() => onClickStampKey()}
        >
          
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H3}
            color={Color.White}
          >
            {!checkedIn ? "In" : "Ut"}
          </Text>
        </Key>
      </NumberPadContainer>
    </>
  );
};
