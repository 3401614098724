import styled from "styled-components";

export const RowDisabled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8;
  width: 300px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8;
  width: 300px;

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
