import { CalendarState } from "./calendarStore.reducer";
import { CalendarDispatch } from "./calendarStore";
import { CompanyCalendarGigDto } from "model/Calendar";
import { ApplicationDto } from "model/Application";
import { TimeReportDto } from "model/TimeReport";
import { addLeadingZero } from "utils/utils";
import moment from "moment";
import { CompanyState } from "../companyStore/companyStore.reducer";
import { Api } from "services/api/api.service";

const removeGigFromCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) => (gigId: number) => {
    let newContext: CompanyCalendarGigDto[] = JSON.parse(
      JSON.stringify(state.currentWeek)
    );
    dispatch({
      type: "UPDATE_WEEKLY_CALENDAR",
      payload: newContext.filter((gig) => gig.id !== gigId),
    });
  };

const updateApplicationsCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (applications: ApplicationDto[]) => {
    let newContext: CompanyCalendarGigDto[] = JSON.parse(
      JSON.stringify(state.currentWeek)
    );
    let newCalendarApplications = applications.map((app) => ({
      id: app.id,
      status: app.status,
      resolution: app.resolution,
      worker: {
        firstName: app.worker.firstName,
        lastName: app.worker.lastName,
        id: app.worker.id,
      },
    }));
    const gigId = applications[0].gigId;
    const gigIndex = newContext.findIndex((gig) => gig.id === gigId);
    newContext[gigIndex].applications = [
      ...newContext[gigIndex].applications,
      ...newCalendarApplications,
    ];

    dispatch({
      type: "UPDATE_WEEKLY_CALENDAR",
      payload: newContext,
    });
  };

const updateTimeReportCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (newTimeReport: TimeReportDto) => {
    const newCalendarWeek: CompanyCalendarGigDto[] = JSON.parse(
      JSON.stringify(state.currentWeek)
    );
    const gigIndex = state.currentWeek.findIndex(
      (gig) => gig.id === newTimeReport.gigId
    );

    if (gigIndex !== -1) {
      const shiftIndex = newCalendarWeek[gigIndex]?.shifts.findIndex(
        (shift) => shift.id === newTimeReport.gigShiftId
      );
      if (shiftIndex !== -1) {
        const newTimeReports = newCalendarWeek[gigIndex].shifts[
          shiftIndex
        ].timeReports.map((timeReport) =>
          timeReport.id === newTimeReport.id
            ? {
                id: newTimeReport.id,
                workerId: newTimeReport.workerId,
                startTime: newTimeReport.startTime,
                endTime: newTimeReport.endTime,
                status: newTimeReport.status,
                resolution: newTimeReport.resolution,
              }
            : timeReport
        );
        newCalendarWeek[gigIndex].shifts[shiftIndex].timeReports =
          newTimeReports;
        dispatch({
          type: "UPDATE_WEEKLY_CALENDAR",
          payload: newCalendarWeek,
        });
      }
    }
  };

const createDateNumbersCalendar = (
  currentDates: {
    date: moment.Moment;
    weekDay: string;
  }[]
) => {
  const firstDate = currentDates[0].date;
  const lastDate = currentDates[6].date;

  const start = `${firstDate.year()}${addLeadingZero(
    firstDate.month() + 1
  )}${addLeadingZero(firstDate.date())}`;
  const end = `${lastDate.year()}${addLeadingZero(
    lastDate.month() + 1
  )}${addLeadingZero(lastDate.date())}`;
  return { start: parseInt(start), end: parseInt(end) };
};

const updateWeeklyCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (
    t: any,
    companyState: CompanyState,
    type: "UPDATE_WEEKLY_CALENDAR" | "UPDATE_OVERVIEW_WEEK_CALENDAR"
  ) => {
    const today = moment();

    const currentDate =
      type === "UPDATE_WEEKLY_CALENDAR" && localStorage.getItem("CalendarDate")
        ? moment(localStorage.getItem("CalendarDate"))
        : today;

    const todayWeekday = currentDate.day();

    const firstDayOfWeek = moment(
      moment(currentDate).subtract(todayWeekday - 1, "days")
    );

    const weekdayList = [
      t("WeekDays.MONDAYSHORT"),
      t("WeekDays.TUESDAYSHORT"),
      t("WeekDays.WEDNESDAYSHORT"),
      t("WeekDays.THURSDAYSHORT"),
      t("WeekDays.FRIDAYSHORT"),
      t("WeekDays.SATURDAYSHORT"),
      t("WeekDays.SUNDAYSHORT"),
    ];
    let dates: {
      date: moment.Moment;
      weekDay: string;
    }[] = [];

    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(moment(firstDayOfWeek).add(x, "days")),
        weekDay: weekdayList[x],
      });
    }

    if (companyState.company?.id && dates.length > 0) {
      dispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });

      Api()
        .company.calendar.getCalendarGigs(
          companyState.company.id,
          createDateNumbersCalendar(dates).start,
          createDateNumbersCalendar(dates).end
        )
        .then((res) => {
          dispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
          dispatch({
            type: type,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
          console.log("ERROR FETCHING CALENDAR GIGS", err);
        });
    }
  };

const removeGigFromCalendarContextOverview =
  (dispatch: CalendarDispatch, state: CalendarState) => (gigId: number) => {
    let newContext: CompanyCalendarGigDto[] = JSON.parse(
      JSON.stringify(state.overviewWeek)
    );
    dispatch({
      type: "UPDATE_OVERVIEW_WEEK_CALENDAR",
      payload: newContext.filter((gig) => gig.id !== gigId),
    });
  };
export const calendarActions = (
  dispatch: CalendarDispatch,
  state: CalendarState
) => ({
  removeGigFromCalendarContext: removeGigFromCalendarContext(dispatch, state),
  updateApplicationsCalendarContext: updateApplicationsCalendarContext(
    dispatch,
    state
  ),
  updateTimeReportCalendarContext: updateTimeReportCalendarContext(
    dispatch,
    state
  ),
  updateWeeklyCalendarContext: updateWeeklyCalendarContext(dispatch, state),

  removeGigFromCalendarContextOverview: removeGigFromCalendarContextOverview(
    dispatch,
    state
  ),
});
