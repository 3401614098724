import React from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Text } from "components/Typography/text.styled";
import { FormStyled } from "../registrationContainer/registrationContainer.styled";

type Props = {
  handleLogin: () => void;
};

export const Confirmation: React.FC<Props> = ({ handleLogin }) => {
  const { t } = useTranslation();

  return (
    <FormStyled
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Text
        style={{ alignSelf: "center" }}
        fontSize={FontSize.H3}
        fontFamily={FontFamily.LeagueSpartanBold}
      >
        {t("RegisterCompany.CompanyAdded")}
      </Text>
      <ButtonStyled onClick={handleLogin}>
        {t("RegisterCompany.Continue")}
      </ButtonStyled>
    </FormStyled>
  );
};
