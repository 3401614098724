import { TimeReportDispatch } from "./timeReportStore";
import { TimeReportState } from "./timeReportStore.reducer";
import { Api } from "services/api/api.service";
import { TimeReportDto } from "model/TimeReport";

const createPayoutForTimeReport =
  (dispatch: TimeReportDispatch) => async (timeReport: TimeReportDto) => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    await Api()
      .company.createPayoutForTimeReport(timeReport.companyId, timeReport.id)
      .then((res) => {})
      .catch((e: Error) => {
        console.error("Error when creating payout for time report", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
      });
    dispatch({ type: "SET_IS_LOADING", payload: false });
  };

const getUnhandledTimeReportCount =
  (dispatch: TimeReportDispatch) => async (companyId: number) => {
    await Api()
      .company.timeReport.getUnhandledTimeReportCount(companyId)
      .then((res) => {
        dispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: res.data.count,
        });
      })
      .catch((e: Error) => {
        console.error("Error when updating unhandled time report count", e);
      });
  };

const setLoading =
  (dispatch: TimeReportDispatch) => (loadingState: boolean) => {
    dispatch({ type: "SET_IS_LOADING", payload: loadingState });
  };

export const timeReportActions = (
  dispatch: TimeReportDispatch,
  state: TimeReportState
) => ({
  createPayoutForTimeReport: createPayoutForTimeReport(dispatch),
  setLoading: setLoading(dispatch),
  getUnhandledTimeReportCount: getUnhandledTimeReportCount(dispatch),
});
