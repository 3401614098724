import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  ButtonContainer,
  Column,
  RowWrapper,
  ModalBody,
  IconWrapper,
  RadioButtonWrapper,
  RadioButtonCenter,
  RadioRow,
  ObValueInput,
  DayButton,
  DayRow,
  ResultRow,
} from "./obSettings.styled";

import { CustomLink, H4, Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import {
  DatePickerContainer,
  ErrorMessage,
  ShiftInputContainer,
  TimeInput,
} from "../../saveGig/shift/shiftForm.styled";
import { ChronoUnit, LocalDate, LocalTime } from "@js-joda/core";

import { CircularProgress, Modal } from "@material-ui/core";
import { NumberInputContainer } from "../../saveGig/hourlyRate/hourlyRate.styled";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import { Api } from "services/api/api.service";
import {
  CompanyOBSetting,
  OBSettingWeekDayEN,
  OBSettingWeekDaySE,
} from "model/Company";
import i18n from "services/translation/i18n";

type Props = {
  setShowObForm: React.Dispatch<React.SetStateAction<boolean>>;
  showObForm: boolean;
  currentWeekList: any[];
};

export const ObSettingsForm: React.FC<Props> = ({
  setShowObForm,
  showObForm,
  currentWeekList,
}) => {
  const { t } = useTranslation();

  const [companyState, , companyDispatch] = useCompanyStore();

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const nowTime = LocalTime.now().truncatedTo(ChronoUnit.MINUTES);
  const [obType, setObType] = useState<"Percent" | "Kr" | null>(
    t("ProfileCompany.Kr")
  );
  const [obValue, setObValue] = useState("10.00");
  const [obValueError, setObValueError] = useState(false);
  const [useDate, setUseDate] = useState(false);
  const [date, setDate] = useState<Moment | null>(moment());
  const [focused, setFocused] = useState(false);
  const today = LocalDate.now();

  const [isLoading, setIsLoading] = useState(false);
  const [weekList, setWeekList] = useState<string[]>([]);
  const [overlapping, setOverlapping] = useState(false);

  const options = [
    t("WeekDays.MONDAY"),
    t("WeekDays.TUESDAY"),
    t("WeekDays.WEDNESDAY"),
    t("WeekDays.THURSDAY"),
    t("WeekDays.FRIDAY"),
    t("WeekDays.SATURDAY"),
    t("WeekDays.SUNDAY"),
  ];

  const resetForm = () => {
    setWeekList([]);
    setDate(moment());
    setStartTime(nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString());
    setEndTime(nowTime.plusHours(2).truncatedTo(ChronoUnit.HOURS).toString());
    setObType(t("ProfileCompany.Kr"));
    setUseDate(false);
  };

  useEffect(() => {
    setStartTime(nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString());
    setEndTime(nowTime.plusHours(2).truncatedTo(ChronoUnit.HOURS).toString());
  }, []);

  const getTotalObTime = () => {
    if (startTime && endTime) {
      if (startTime === endTime) {
        return "24h 0min";
      } else {
        let minDur = moment
          .duration(
            moment(
              IsOverNight() || endTime === "00:00"
                ? "2022-03-04T" + endTime
                : "2022-03-03T" + endTime
            ).diff(moment("2022-03-03T" + startTime))
          )
          .asMinutes();
        const HDur = Math.floor(minDur / 60);
        return `${HDur}${t("General.HourShortened")} ${minDur % 60}min`;
      }
    }
  };

  const checkIfOverlappingSettings = () => {
    if (weekList.length > 0 && !useDate) {
      let overlapp = 0;
      weekList.map((day) => {
        const dayEnum = options.indexOf(day) + 1;
        const sameDaySettings = companyState.obSettings.filter(
          (setting) => setting.weekday === dayEnum
        );
        sameDaySettings.map((setting: CompanyOBSetting) => {
          const settingStartTime = moment(setting.startTime, "HH:mm:ss");
          const settingEndTime = moment(setting.endTime, "HH:mm:ss");
          const settingStartDate = moment("2022-12-10");

          settingStartDate.set({
            hour: settingStartTime.get("hour"),
            minute: settingStartTime.get("minute"),
            second: settingStartTime.get("second"),
          });
          const settingEndDate = moment("2022-12-10");

          settingEndDate.set({
            hour: settingEndTime.get("hour"),
            minute: settingEndTime.get("minute"),
            second: settingEndTime.get("second"),
          });

          if (settingEndDate < settingStartDate) {
            settingEndDate.add(1, "days");
          }

          const newStartTime = moment(startTime, "HH:mm:ss");
          const newEndTime = moment(endTime, "HH:mm:ss");
          const newStartDate = moment("2022-12-10");

          newStartDate.set({
            hour: newStartTime.get("hour"),
            minute: newStartTime.get("minute"),
            second: newStartTime.get("second"),
          });

          const newEndDate = moment("2022-12-10");

          newEndDate.set({
            hour: newEndTime.get("hour"),
            minute: newEndTime.get("minute"),
            second: newEndTime.get("second"),
          });
          if (newEndDate < newStartDate) {
            newEndDate.add(1, "days");
          }

          if (
            newStartDate <= settingEndDate &&
            settingStartDate <= newEndDate
          ) {
            overlapp++;
          }
        });
        if (overlapp > 0) {
          setOverlapping(true);
        } else {
          setOverlapping(false);
        }
      });
    } else if (date && useDate) {
      let overlapp = 0;
      const sameDaySettings = companyState.obSettings.filter(
        (setting) => setting.date === date.format("YYYY-MM-DD")
      );
      sameDaySettings.map((setting: CompanyOBSetting) => {
        const settingStartTime = moment(setting.startTime, "HH:mm:ss");
        const settingEndTime = moment(setting.endTime, "HH:mm:ss");
        const settingStartDate = moment("2022-12-10");

        settingStartDate.set({
          hour: settingStartTime.get("hour"),
          minute: settingStartTime.get("minute"),
          second: settingStartTime.get("second"),
        });
        const settingEndDate = moment("2022-12-10");

        settingEndDate.set({
          hour: settingEndTime.get("hour"),
          minute: settingEndTime.get("minute"),
          second: settingEndTime.get("second"),
        });

        if (settingEndDate < settingStartDate) {
          settingEndDate.add(1, "days");
        }

        const newStartTime = moment(startTime, "HH:mm:ss");
        const newEndTime = moment(endTime, "HH:mm:ss");
        const newStartDate = moment("2022-12-10");

        newStartDate.set({
          hour: newStartTime.get("hour"),
          minute: newStartTime.get("minute"),
          second: newStartTime.get("second"),
        });

        const newEndDate = moment("2022-12-10");

        newEndDate.set({
          hour: newEndTime.get("hour"),
          minute: newEndTime.get("minute"),
          second: newEndTime.get("second"),
        });
        if (newEndDate < newStartDate) {
          newEndDate.add(1, "days");
        }

        if (newStartDate <= settingEndDate && settingStartDate <= newEndDate) {
          overlapp++;
        }
      });
      if (overlapp > 0) {
        setOverlapping(true);
      } else {
        setOverlapping(false);
      }
    } else {
      setOverlapping(false);
    }
  };

  useEffect(() => {
    checkIfOverlappingSettings();
  }, [weekList, startTime, endTime, date, showObForm]);

  const validateDate = () => {
    return date && today.toString() <= date.format("YYYY-MM-DD");
  };

  const validateStartTime = () => {
    if (useDate && date && today.toString() === date.format("YYYY-MM-DD")) {
      return startTime >= nowTime.toString();
    } else {
      return true;
    }
  };

  const validateForm = () => {
    if (
      (startTime &&
        endTime &&
        validateStartTime() &&
        obType &&
        parseFloat(obValue) > 0 &&
        useDate &&
        !overlapping &&
        date &&
        validateDate) ||
      (startTime &&
        validateStartTime() &&
        endTime &&
        obType &&
        parseFloat(obValue) > 0 &&
        !useDate &&
        !overlapping &&
        weekList.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const IsOverNight = () => {
    const newStartTime = moment(startTime, "HH:mm:ss");
    const newEndTime = moment(endTime, "HH:mm:ss");
    const tempStartDate = moment("2022-12-10");

    tempStartDate.set({
      hour: newStartTime.get("hour"),
      minute: newStartTime.get("minute"),
      second: newStartTime.get("second"),
    });

    const tempEndDate = moment("2022-12-10");

    tempEndDate.set({
      hour: newEndTime.get("hour"),
      minute: newEndTime.get("minute"),
      second: newEndTime.get("second"),
    });
    if (endTime === "00:00") {
      return false;
    } else if (tempEndDate < tempStartDate || startTime === endTime) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const resList: CompanyOBSetting[] = [];
    if (weekList.length > 0 && !useDate) {
      await Promise.all(
        weekList.map(async (dayString: string) => {
          let dayIndex =
            i18n.language === "sv" ||
            i18n.language === "sv-se" ||
            i18n.language === "se"
              ? OBSettingWeekDaySE[dayString as keyof typeof OBSettingWeekDaySE]
              : OBSettingWeekDayEN[
                  dayString as keyof typeof OBSettingWeekDayEN
                ];
          if (companyState.company?.id) {
            const obSetting: CompanyOBSetting = {
              endTime: IsOverNight()
                ? "23:59:59"
                : moment(endTime, i18n.language === "sv" ? "HH:mm a" : "h:mm a")
                    .subtract(1, "seconds")
                    .format("HH:mm:ss"),
              startTime: moment(startTime, "HH:mm a").format("HH:mm:ss"),
              date: useDate && date ? date.format("YYYY-MM-DD") : null,
              weekday:
                !useDate && typeof dayIndex === "number" ? dayIndex : null,
              fixedValue:
                obType === t("ProfileCompany.Kr") ? parseFloat(obValue) : null,
              percentValue:
                obType === t("ProfileCompany.Percent")
                  ? parseFloat(obValue)
                  : null,
              companyId: companyState.company.id,
            };
            await Api()
              .company.addCompanyObSetting(companyState.company?.id, obSetting)
              .then(async (res) => {
                resetForm();
                resList.push(res.data);
                if (IsOverNight() && companyState.company && !useDate) {
                  const obSetting: CompanyOBSetting = {
                    endTime: moment(
                      endTime,
                      i18n.language === "sv" ? "HH:mm a" : "h:mm a"
                    )
                      .subtract(1, "seconds")
                      .format("HH:mm:ss"),
                    startTime: "00:00:01",
                    date: null,
                    weekday: !useDate && dayIndex + 1 < 7 ? dayIndex + 1 : 0,
                    fixedValue:
                      obType === t("ProfileCompany.Kr")
                        ? parseFloat(obValue)
                        : null,
                    percentValue:
                      obType === t("ProfileCompany.Percent")
                        ? parseFloat(obValue)
                        : null,
                    companyId: companyState.company.id,
                  };
                  await Api()
                    .company.addCompanyObSetting(
                      companyState.company?.id,
                      obSetting
                    )
                    .then((res) => {
                      resList.push(res.data);

                      setShowObForm(false);
                    })
                    .catch((err) => {
                      console.log(err, err.response);
                    });
                }
                setShowObForm(false);
              })
              .catch((err) => {
                console.log(err, err.response);
              });
          }
        })
      );
    } else if (useDate && date) {
      if (companyState.company?.id) {
        const obSetting: CompanyOBSetting = {
          endTime: IsOverNight()
            ? "23:59:59"
            : moment(endTime, i18n.language === "sv" ? "HH:mm a" : "h:mm a")
                .subtract(1, "seconds")
                .format("HH:mm:ss"),
          startTime: moment(startTime, "HH:mm a").format("HH:mm:ss"),
          date: useDate && date ? date.format("YYYY-MM-DD") : null,
          weekday: null,
          fixedValue:
            obType === t("ProfileCompany.Kr") ? parseFloat(obValue) : null,
          percentValue:
            obType === t("ProfileCompany.Percent") ? parseFloat(obValue) : null,
          companyId: companyState.company.id,
        };
        await Api()
          .company.addCompanyObSetting(companyState.company?.id, obSetting)
          .then(async (res) => {
            resetForm();
            resList.push(res.data);
            if (IsOverNight() && useDate && date) {
              if (companyState.company?.id) {
                const secondDate = moment(date, "YYYY-MM-DD");
                secondDate.add(1, "days");

                const obSetting: CompanyOBSetting = {
                  endTime: moment(
                    endTime,
                    i18n.language === "sv" ? "HH:mm a" : "h:mm a"
                  )
                    .subtract(1, "seconds")
                    .format("HH:mm:ss"),
                  startTime: "00:00:01",
                  date: secondDate.format("YYYY-MM-DD"),
                  weekday: null,
                  fixedValue:
                    obType === t("ProfileCompany.Kr")
                      ? parseFloat(obValue)
                      : null,
                  percentValue:
                    obType === t("ProfileCompany.Percent")
                      ? parseFloat(obValue)
                      : null,
                  companyId: companyState.company.id,
                };
                await Api()
                  .company.addCompanyObSetting(
                    companyState.company?.id,
                    obSetting
                  )
                  .then((res) => {
                    resList.push(res.data);

                    setShowObForm(false);
                  })
                  .catch((err) => {
                    console.log(err, err.response);
                  });
              }
            }
            setShowObForm(false);
          })
          .catch((err) => console.log(err, err.response));
      }
    }

    companyDispatch({
      type: "UPDATE_COMPANY_OBSETTINGS",
      payload: [...companyState.obSettings, ...resList],
    });
    setIsLoading(false);
  };

  return (
    <Modal
      open={showObForm}
      onClose={() => {
        setShowObForm(false);
      }}
    >
      <ModalBody>
        {isLoading ? (
          <CircularProgress style={{ color: Color.BurntSienna }} />
        ) : (
          <>
            <H4 style={{ marginTop: "0px" }}>
              {t("ProfileCompany.OBInconvenientWorkingHours")}
            </H4>
            {!useDate && (
              <DayRow>
                {options.map((day) => {
                  const selected = weekList.some((weekday) => weekday === day);
                  return (
                    <DayButton
                      selected={selected}
                      onClick={() => {
                        if (!selected) {
                          setWeekList([...weekList, day]);
                        } else {
                          setWeekList(weekList.filter((d) => d !== day));
                        }
                      }}
                    >
                      <Text
                        fontFamily={FontFamily.MontserratSemiBold}
                        fontSize={FontSize.Small}
                      >
                        {day}
                      </Text>
                    </DayButton>
                  );
                })}
              </DayRow>
            )}
            {useDate && (
              <ShiftInputContainer>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Large}
                >
                  {t("GigsCompany.Date")}
                </Text>
                <DatePickerContainer>
                  <SingleDatePicker
                    date={date}
                    onDateChange={(date) => date && setDate(date)}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    id="SingleDatePicker"
                    hideKeyboardShortcutsPanel={true}
                    anchorDirection="right"
                    numberOfMonths={1}
                    displayFormat="DD/MM/YYYY"
                  />
                </DatePickerContainer>
                {!validateDate() && (
                  <ErrorMessage>{t("GigsCompany.DateHasPassed")}</ErrorMessage>
                )}
              </ShiftInputContainer>
            )}
            <CustomLink
              fontSize={FontSize.Small}
              color={Color.SeaBlue400}
              fontFamily={FontFamily.MontserratRegular}
              onClick={() => setUseDate(!useDate)}
            >
              {useDate
                ? t("ProfileCompany.EnterWeekDay")
                : t("ProfileCompany.EnterDate")}
            </CustomLink>
            <RowWrapper>
              <Column>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Small}
                >
                  {t("ProfileCompany.StartTime")}*
                </Text>

                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="startTime"
                  type="time"
                  value={startTime}
                  onChange={(e) => {
                    setStartTime(e.currentTarget.value);
                  }}
                />
              </Column>

              <IconWrapper>
                <CustomIcon name="chevron-right" color={Color.BurntSienna} />
              </IconWrapper>
              <Column>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Small}
                >
                  {t("ProfileCompany.EndTime")}*
                </Text>
                <TimeInput
                  fontFamily={FontFamily.MontserratBold}
                  fontSize={FontSize.Large}
                  name="endTime"
                  type="time"
                  value={endTime}
                  onChange={(e) => {
                    setEndTime(e.currentTarget.value);
                  }}
                />
              </Column>
            </RowWrapper>
            <CustomLink
              fontSize={FontSize.Small}
              color={Color.SeaBlue400}
              fontFamily={FontFamily.MontserratRegular}
              onClick={() => {
                setStartTime("00:00");
                setEndTime("00:00");
              }}
            >
              {t("GigsCompany.ToSelectTheEntireDay")}
            </CustomLink>
            {!validateStartTime() && (
              <Text color={Color.Destructive} fontSize={FontSize.Small}>
                {t("GigsCompany.EnterAValidTime")}
              </Text>
            )}
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Small}
              style={{ marginTop: "16px", marginBottom: "0px" }}
            >
              {t("ProfileCompany.Compensation")}*
            </Text>
            <RowWrapper>
              <RadioRow>
                <RadioButtonWrapper
                  onClick={() => setObType(t("ProfileCompany.Kr"))}
                >
                  <RadioButtonCenter
                    checked={obType === t("ProfileCompany.Kr")}
                  />
                </RadioButtonWrapper>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ marginLeft: "8px" }}
                >
                  {t("ProfileCompany.Kr")}
                </Text>
              </RadioRow>
              <RadioRow>
                <RadioButtonWrapper
                  onClick={() => setObType(t("ProfileCompany.Percent"))}
                >
                  <RadioButtonCenter
                    checked={obType === t("ProfileCompany.Percent")}
                  />
                </RadioButtonWrapper>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  style={{ marginLeft: "8px" }}
                >
                  {t("ProfileCompany.Percent")}
                </Text>
              </RadioRow>
            </RowWrapper>
            <RowWrapper>
              <NumberInputContainer>
                <ObValueInput
                  style={
                    obValueError
                      ? {
                          borderColor: "red",
                          border: `1px solid ${Color.Destructive}`,
                          color: Color.Destructive,
                        }
                      : {}
                  }
                  inputMode="decimal"
                  type="number"
                  defaultValue={obValue}
                  min={0}
                  value={obValue.replace(",", ".")}
                  onChange={(e) => {
                    e.currentTarget.value.replace(",", ".");
                    setObValue(e.currentTarget.value.replace(",", "."));
                    let regex = /^[0-9]+([.,][0-9]{0,3})?$/;
                    if (!regex.test(e.currentTarget.value)) {
                      setObValueError(true);
                    } else {
                      setObValueError(false);
                    }
                  }}
                  max={999}
                  step="0.01"
                />
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.H4}
                >
                  {obType === t("ProfileCompany.Percent")
                    ? "%"
                    : t("ProfileCompany.Kr")}
                </Text>
              </NumberInputContainer>
            </RowWrapper>
            {overlapping && (
              <Text
                fontFamily={FontFamily.MontserratRegular}
                color={Color.Destructive}
              >
                {t("ProfileCompany.ThisObSettingIsOverlapping")}
              </Text>
            )}
            <ResultRow>
              <Text
                fontFamily={FontFamily.MontserratBold}
                fontSize={FontSize.Small}
              >
                {useDate
                  ? t("ProfileCompany.Date") + ":"
                  : t("ProfileCompany.Weekdays") + ":"}
              </Text>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Standard}
              >
                {` ${
                  useDate && date
                    ? date.format("YYYY-MM-DD")
                    : weekList.map((day) => " " + day)
                } `}
              </Text>
            </ResultRow>
            <ResultRow>
              <Text
                fontFamily={FontFamily.MontserratBold}
                fontSize={FontSize.Small}
              >
                {t("ProfileCompany.Time") + ":"}
              </Text>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Standard}
              >
                {` kl ${startTime} - ${endTime}`}{" "}
                {" (" + getTotalObTime() + ")"}
              </Text>
            </ResultRow>
            <ResultRow>
              <Text
                fontFamily={FontFamily.MontserratBold}
                fontSize={FontSize.Small}
              >
                {t("ProfileCompany.Compensation") + ":"}
              </Text>
              {parseFloat(obValue) > 0 && (
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Standard}
                >
                  {`${obValue.toString()}  ${
                    obType === t("ProfileCompany.Percent")
                      ? "%"
                      : t("ProfileCompany.Kr")
                  } / tim`}
                </Text>
              )}
            </ResultRow>

            <ButtonContainer>
              <ButtonStyled
                disabled={!validateForm()}
                onClick={handleSubmit}
                style={{ marginBottom: "10px" }}
              >
                {t("General.Save")}
              </ButtonStyled>
              <CustomLink onClick={() => setShowObForm(false)}>
                {t("General.Cancel")}
              </CustomLink>
            </ButtonContainer>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
