import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import {
  FormContainer,
  FormStyled,
  InputContainer,
  TextInput,
  Text,
  InfoContainer,
  DayInfoContainer,
  CompanyNameContainer,
} from "./clockStamp.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FontFamily, FontSize } from "config/font";
import { NumberPad } from "./numberPad.component";
import { TimeReportForStamping } from "model/Company";
import moment from "moment-timezone";
import { ClockStampChooseTimeModal } from "./clockStampChooseTimeModal.component";
import { ClockStampCheckInOutModal } from "./clockStampCheckInOutModal.component";

export const ClockStamp = () => {
  const { t } = useTranslation();
  const [telephoneNumber, setTelephoneNumber] = useState<string>("+46");
  const [companyState, companyActions] = useCompanyStore();
  const numberRegex = /^\+46\d{9}$/;
  const [userIsCheckedIn, setUserIsCheckedIn] = useState<boolean>(false);
  const [keyShouldBeDisabled, setKeyShouldBeDisabled] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [today, setToday] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [timeReport, setTimeReport] = useState<TimeReportForStamping>();
  const [errorText, setErrorText] = useState("");
  const [showChooseTimeModal, setShowChooseTimeModal] = useState(false);
  const [checkInWithCurrentTime, setCheckInWithCurrentTime] =
    useState<boolean>(true);

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const isValidNumber = numberRegex.test(telephoneNumber);

    if (telephoneNumber.length >= 2) {
      setErrorText("");
    }

    if (isValidNumber) {
      setErrorText("");
      getWorkerTimeReport();
    }
  }, [telephoneNumber]);

  useEffect(() => {
    const { active } = timeReport || {};
    const { checkInTime, checkOutTime } = active || {};

    if (checkInTime && checkOutTime) {
      setErrorText(t("StampClock.NoOpenTimeReports"));
      const intervalId = setInterval(clearInputs, 60000);
      setKeyShouldBeDisabled(false);
      setUserIsCheckedIn(false);
      return () => clearInterval(intervalId);
    }
    setUserIsCheckedIn(!!(checkInTime && !checkOutTime));
  }, [timeReport]);

  const getWorkerTimeReport = () => {
    const isValidNumber = numberRegex.test(telephoneNumber);
    if (isValidNumber && companyState.company?.id) {
      companyActions
        .getTimeReportForStamping(
          companyState.company?.id,
          telephoneNumber.substring(1)
        )
        .then((res) => {
          setTimeReport(res);
          setKeyShouldBeDisabled(isValidNumber);
        })
        .catch((err: any) => {
          err.response.data.errorMessage === "Worker not found"
            ? setErrorText("StampClock.NoNumber")
            : setErrorText(t("StampClock.NoOpenTimeReports"));
        });
    }
  };

  const clearInputs = () => {
    setTelephoneNumber("+46");
    setUserIsCheckedIn(false);
    setErrorText("");
  };

  const handleCheckInCheckOut = () => {
    const { active, companyId, workerPhoneNumber } = timeReport || {};
    if (
      active &&
      active.timeReportId &&
      (!active.checkInTime || !active.checkOutTime) &&
      companyId &&
      workerPhoneNumber &&
      timeReport?.workerPhoneNumber === telephoneNumber.substring(1)
    ) {
      if (
        active &&
        ((moment().utc().format('YYYY-MM-DDTHH:mm') < active.startTime && !active.checkInTime) ||
          (moment().utc().format('YYYY-MM-DDTHH:mm') > active.endTime && active.checkInTime))
      ) {
        setShowChooseTimeModal(true);
      } else {
        doTheCheckInAndOut(
          companyId,
          active.timeReportId,
          workerPhoneNumber,
          checkInWithCurrentTime
        );
      }
    }
  };

  const doTheCheckInAndOut = (
    companyId: number,
    timeReportId: number,
    workerPhoneNumber: string,
    withCurrentTime: boolean
  ) => {
    companyActions
      .handleCheckInCheckOut(
        companyId,
        timeReportId,
        workerPhoneNumber,
        withCurrentTime
      )
      .then(() => {
        setShowModal(true);
        clearInputs();
      })
      .catch((err: any) => {
        if (
          err.response.data.errorMessage ===
          "You can't check out before the shift have started"
        ) {
          setErrorText(t("StampClock.CantCheckOutBeforeShiftStarted"));
        }
      });
  };

  const onClickedC = () => {
    setTelephoneNumber("+46");
    setErrorText("");
  };

  const updateTime = () => {
    const currentDate = moment();
    setTime(currentDate.format("HH:mm"));
    setToday(
      currentDate.format("dddd D MMMM").replace(/^\w/, (c) => c.toUpperCase())
    );
  };

  return (
    <>
      <InfoContainer>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H1}
          color={Color.MidnightBlue}
          alignSelf={"flex-end"}
        >
          {t("StampClock.Welcome")}
        </Text>
        <DayInfoContainer>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H2}
            color={Color.MidnightBlue}
          >
            {time}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H2}
            color={Color.MidnightBlue}
          >
            {today}
          </Text>
        </DayInfoContainer>
      </InfoContainer>
      <FormContainer>
        <FormStyled>
          {errorText !== "" && (
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
              color={Color.Destructive}
              marginLeft={70}
            >
              {t(errorText)}
            </Text>
          )}
          <InputContainer>
            <TextInput
              value={telephoneNumber}
              onChange={(e) => setTelephoneNumber(e.target.value)}
              placeholder={t("StampClock.TelephoneNumber")}
            />
          </InputContainer>
          <NumberPad
            checkedIn={userIsCheckedIn}
            keyShouldBeDisabled={keyShouldBeDisabled}
            onNumberChange={(newNumber) =>
              typeof newNumber == "boolean"
                ? onClickedC()
                : setTelephoneNumber(telephoneNumber + newNumber)
            }
            onClickStampKey={() => handleCheckInCheckOut()}
          />
        </FormStyled>
        <CompanyNameContainer>
          <Text fontSize={FontSize.H3}>
            {companyState.company?.companyName}
          </Text>
        </CompanyNameContainer>
      </FormContainer>
      {showChooseTimeModal && (
        <ClockStampChooseTimeModal
          timeReport={timeReport}
          setShowChooseTimeModal={setShowChooseTimeModal}
          setCheckInWithCurrentTime={setCheckInWithCurrentTime}
          showChooseTimeModal={showChooseTimeModal}
          doTheCheckInAndOut={doTheCheckInAndOut}
          telephoneNumber={telephoneNumber}
        />
      )}
      {showModal && (
        <ClockStampCheckInOutModal
          timeReport={timeReport}
          setShowModal={setShowModal}
          setKeyShouldBeDisabled={setKeyShouldBeDisabled}
          showModal={showModal}
        />
      )}
    </>
  );
};
