let apiHost: string;
let chatHost: string;
let googleKey: string;

const hostname = window && window.location && window.location.hostname;
// Production
if (hostname === "app.simflexity.com") {
  apiHost = "https://api.simflexity.com";
  chatHost = "https://gigleerbackendchat-prod.azurewebsites.net";
  googleKey = "AIzaSyDTqnnV670j9pWZw9wEku5VKtCjVcUVdg0";
}
// Staging
else if (hostname === "app-stage.simflexity.com") {
  apiHost = "https://api-stage.simflexity.com";
  chatHost = "https://gigleerbackendchat-staging.azurewebsites.net";
  googleKey = "AIzaSyDMXv1Ni1Pk3sbFsa6W3bfraVY8rkmRrbY";
}
// Dev
else {
  apiHost = "https://api-dev.simflexity.com";
  chatHost = "https://gigleerbackendchat-dev.azurewebsites.net";
  googleKey = "AIzaSyD1DJ5GAYQrcNYlkPtGtAClz_-6PByXwAQ";
}

export const API_ROOT = `${apiHost}/api`;
export const CHAT_ROOT = chatHost;
export const GOOGLE_KEY = googleKey;
