import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "stores/authStore/authStore";
import { CalendarContainer } from "components/pageLayouts/pageLayouts.styled";
import { H3 } from "components/Typography/text.styled";
import { LoaderProgress } from "components/icon/loaderProgress.component";

export const SchedulePage = () => {
  const [authState] = useAuthStore();

  return (
    <CalendarContainer>
      {authState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          <H3>Schedule</H3>
        </>
      )}
    </CalendarContainer>
  );
};
