import React from "react";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuthStore } from "stores/authStore/authStore";
import { useGlobalStore } from "stores/globalStore/globalStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { SaveGigForm } from "web-apps/company/components/saveGig/saveGigForm/saveGigForm.component";
import { H3 } from "components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { useGetGig } from "hooks/useGetGig";

type MatchProps = {
  id?: string;
};

export const EditGigPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const { t } = useTranslation();
  const gigId = props.match.params.id;
  const [authState] = useAuthStore();
  const [globalState] = useGlobalStore();
  const [companyState] = useCompanyStore();
  const isClone = window.location.href.includes("clone") ? true : false;
  const [gigData, isLoading] = useGetGig(gigId, true);

  return (
    <>
      {companyState.isLoading ||
      globalState.isLoading ||
      authState.isLoading ||
      isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          <H3>
            {isClone ? t("GigsCompany.CloneGig") : t("GigsCompany.EditGig")}
          </H3>
          {gigData && <SaveGigForm gigData={gigData} isClone={isClone} />}
        </LoggedInPageContainer>
      )}
    </>
  );
};
