import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "../companyStore/companyStore";
import { applicationActions } from "./applicationStore.actions";
import {
  ApplicationActions,
  applicationInitState,
  ApplicationState,
  ApplicationStateReducer,
} from "./applicationStore.reducer";

export type ApplicationDispatch = (action: ApplicationActions) => void;

const ApplicationStateContext = createContext<ApplicationState | undefined>(
  undefined
);
const ApplicationDispatchContext = createContext<
  ApplicationDispatch | undefined
>(undefined);

export const ApplicationStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    ApplicationStateReducer,
    applicationInitState
  );
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (companyState.company?.id) {
      Api()
        .company.application.getPendingApplicationsCount(
          companyState.company?.id
        )
        .then((res: any) => {
          dispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: res.data.value,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [companyState.company]);

  return (
    <ApplicationStateContext.Provider value={state}>
      <ApplicationDispatchContext.Provider value={dispatch}>
        {children}
      </ApplicationDispatchContext.Provider>
    </ApplicationStateContext.Provider>
  );
};

export const useApplicationStore = () => {
  const state = useContext(ApplicationStateContext);
  const dispatch = useContext(ApplicationDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useApplicationStore must be used within a ApplicationStoreProvider"
    );
  }

  const actions = applicationActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
