import { useTranslation } from "react-i18next";
import { ClockStamp } from "web-apps/company/components/clockStamp/clockStamp.component";

export const ClockStampPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <ClockStamp />
    </>
  );
};
