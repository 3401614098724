import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getWorkerImg } from "services/firebase/firebase.service";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CompanyGigUserDto } from "model/Gig";
import { UserApplicationDto } from "model/Application";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import {
  Chat as ChatType,
  ChatUser,
  Message,
} from "web-apps/company/stores/chatStore/chatStore.reducer";
import { CustomIcon } from "components/icon/customIcon.component";
import { H3, Text } from "components/Typography/text.styled";
import {
  ChatContainer,
  MessageListStyled,
  MessageListItemStyled,
  SendMessageForm,
  TextInput,
  WorkerImageWrapper,
  RowWrapper,
  SubmitButton,
  SmallImage,
} from "./chat.styled";

type Props = {
  chatData: ChatType;
  user: CompanyGigUserDto | (UserApplicationDto & { firebaseId: string });
  gigId: string;
  userId: string;
};

export const Chat: React.FC<Props> = ({ chatData, user, gigId, userId }) => {
  const { t } = useTranslation();
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  const [chatState, chatDispatch] = useChatStore();
  const [currentMessage, setCurrentMessage] = useState("");
  const [workerImage, setWorkerImage] = useState("");
  const gigIdRef = useRef<string>();
  const otherUserRef = useRef<ChatUser>();
  const userRef = useRef<ChatUser>();

  useEffect(() => {
    if (chatState.socket.current) {
      chatState.socket.current.on("message_sent", onMessageSent);
    }

    return () => {
      chatState.socket.current?.off("message_sent", onMessageSent);
    };
  }, [chatState.socket.current]);

  useEffect(() => {
    if (!companyState.company || !userId || !gigId) return;

    gigIdRef.current = gigId;
    userRef.current = {
      userId: companyState.company?.id.toString(),
      type: "Company",
    };
    otherUserRef.current = {
      userId: userId,
      type: "User",
    };

    readMessages();
  }, [gigId, userId, companyState.company?.id]);

  useEffect(() => {
    if (user?.firebaseId) {
      getWorkerImg(user.firebaseId).then((res) => {
        setWorkerImage(res);
      });
    }
  }, [user]);

  const onMessageSent = (data: { gigId: string; message: Message }) => {
    if (
      gigIdRef.current !== undefined &&
      userRef.current !== undefined &&
      data.gigId === gigIdRef.current &&
      data.message.users.find((user) => {
        if (otherUserRef.current !== undefined) {
          return (
            user.userId === otherUserRef.current.userId &&
            user.type === otherUserRef.current.type
          );
        }
      })
    ) {
      readMessages();
    }
  };

  const readMessages = () => {
    if (
      gigIdRef.current !== undefined &&
      userRef.current !== undefined &&
      otherUserRef.current !== undefined
    ) {
      chatState.socket.current?.emit("read_messages", {
        gigId: gigIdRef.current,
        otherUser: otherUserRef.current,
      });

      chatDispatch({
        type: "MESSAGES_READ",
        payload: { otherUser: otherUserRef.current, gigId: gigIdRef.current },
      });
    }
  };

  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!chatState.socket.current || !authState.accountType || !userRef.current)
      return;

    chatState.socket.current.emit("send_message", {
      text: currentMessage,
      gigId: chatData.gigId,
      users: [chatData.otherUser, userRef.current],
      sender: userRef.current,
    });

    setCurrentMessage("");
  };

  return (
    <ChatContainer>
      <H3
        fontSize={FontSize.H4}
        fontFamily={FontFamily.MontserratSemiBold}
        style={{ margin: "0 0 10px" }}
      >{`${user.firstName} ${user.lastName}`}</H3>
      <MessageListStyled>
        {chatData.messages.map((message, index) => {
          const d = new Date(message.createdAt);
          const localeDateString = d.toLocaleDateString(
            navigator.languages[0],
            {
              weekday: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              month: "short",
            }
          );

          if (
            message.sender.userId === companyState.company?.id.toString() &&
            message.sender.type === "Company"
          ) {
            return (
              <MessageListItemStyled
                backgroundColor={Color.SeaBlue200}
                alignSelf="flex-end"
                key={index}
              >
                <Text
                  fontSize={FontSize.Large}
                  style={{
                    margin: "0 0 10px",
                  }}
                >
                  {message.text}
                </Text>
                <Text
                  style={{ margin: 0 }}
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue600}
                >
                  {localeDateString}
                </Text>
              </MessageListItemStyled>
            );
          } else {
            return (
              <RowWrapper key={index}>
                <WorkerImageWrapper>
                  {workerImage ? (
                    <SmallImage src={workerImage} />
                  ) : (
                    <CustomIcon
                      name="person"
                      size="100%"
                      color={Color.SeaBlue600}
                      padding="10px"
                      backgroundColor={Color.SeaBlue300}
                    />
                  )}
                </WorkerImageWrapper>
                <MessageListItemStyled
                  backgroundColor={Color.BurntSienna}
                  alignSelf="flex-start"
                >
                  <Text
                    fontSize={FontSize.Large}
                    style={{ margin: "0 0 10px" }}
                    color={Color.SeaBlue200}
                  >
                    {message.text}
                  </Text>
                  <Text
                    style={{ margin: 0 }}
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue400}
                  >
                    {localeDateString}
                  </Text>
                </MessageListItemStyled>
              </RowWrapper>
            );
          }
        })}
      </MessageListStyled>
      <SendMessageForm onSubmit={sendMessage}>
        <TextInput
          value={currentMessage}
          onChange={(e) => {
            setCurrentMessage(e.currentTarget.value);
          }}
          placeholder={t("Chat.TextInputPlaceholder")}
        />
        <SubmitButton type="submit">
          <CustomIcon
            name="arrow-right"
            color={Color.BurntSienna}
            hover
            style={{
              outline: "none",
              background: "none",
              border: "none",
              padding: 0,
            }}
          />
        </SubmitButton>
      </SendMessageForm>
    </ChatContainer>
  );
};
