import firebase from "firebase/app";
import "firebase/auth";

/*
Settings icon -> Project Settings -> Web App
*/

const hostname = window && window.location && window.location.hostname;
let firebaseConfig;

//Prod
if (hostname === "app.simflexity.com") {
  firebaseConfig = {
    apiKey: "AIzaSyDTqnnV670j9pWZw9wEku5VKtCjVcUVdg0",
    authDomain: "simflexity-fb93d.firebaseapp.com",
    projectId: "simflexity-fb93d",
    storageBucket: "simflexity-fb93d.appspot.com",
    messagingSenderId: "790938619014",
    appId: "1:790938619014:web:8e0c552a862e05dd949507",
    measurementId: "G-P5HNV6Z12P"
  };
}
//Staging
else if (hostname === "app-stage.simflexity.com") {
  firebaseConfig = {
    apiKey: "AIzaSyDMXv1Ni1Pk3sbFsa6W3bfraVY8rkmRrbY",
    authDomain: "simflexity-staging.firebaseapp.com",
    projectId: "simflexity-staging",
    storageBucket: "simflexity-staging.appspot.com",
    messagingSenderId: "171919423741",
    appId: "1:171919423741:web:afd27f7f078716ba086694",
    measurementId: "G-K8HW1R1QK6",
  };
}
//Dev
else {
  firebaseConfig = {
    apiKey: "AIzaSyD1DJ5GAYQrcNYlkPtGtAClz_-6PByXwAQ",
    authDomain: "simflexity-dev.firebaseapp.com",
    projectId: "simflexity-dev",
    storageBucket: "simflexity-dev.appspot.com",
    messagingSenderId: "407549295767",
    appId: "1:407549295767:web:446328b43147732fddbdd2",
    measurementId: "G-SZ34ZFX6HG"
  };
}
firebase.initializeApp(firebaseConfig);
/* Secondary app is needed to create new firebase accounts for employees while staying signed in to current company. */
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

export {secondaryApp};
export default firebase;
