import { Wrapper, ButtonWrapper } from "./promptModal.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

type ButtonInfo = {
  label: string,
  color?: Color,
  callback: () => void,
}

type Props = {
  header?: string,
  message: string,
  accept?: ButtonInfo,
  alternativeAccept?: ButtonInfo,
  cancel?: ButtonInfo,
}

export const PromptModal = ({
  header,
  message,
  accept,
  alternativeAccept,
  cancel,
}: Props) => {
  return (
    <Wrapper>
      <Text
        align="center"
        fontSize={FontSize.H4}
        fontFamily={FontFamily.MontserratSemiBold}
      >
        {header}
      </Text>
      <Text
        align="center"
        fontFamily={FontFamily.MontserratSemiBold}
        fontSize={FontSize.Standard}
      >
        {message}
      </Text>
      <ButtonWrapper>
        {
          accept && (
            <ButtonStyled backgroundColor={accept.color ?? Color.BurntSienna} onClick={accept.callback}>
              {accept.label}
            </ButtonStyled>
          )
        }
        {
          alternativeAccept && (
            <ButtonStyled backgroundColor={alternativeAccept.color ?? Color.BurntSienna} onClick={alternativeAccept.callback}>
              {alternativeAccept.label}
            </ButtonStyled>
          )
        }
        {
          cancel && (
            <ButtonStyled backgroundColor={cancel.color ?? Color.BurntSienna} onClick={cancel.callback}>
              {cancel.label}
            </ButtonStyled>
          )
        }  
      </ButtonWrapper>
    </Wrapper>
  )
};
