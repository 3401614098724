import React, { useState } from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import {
  PrivateRoute,
  PublicRoute,
} from "web-apps/company/components/customRoute/customRoute.component";
import { EmployeeStoreProvider } from "./stores/employeeStore/employeeStore";
import { SchedulePage } from "./pages/schedule.page";
import { LoginPage } from "./pages/login.page";
import { NavBar } from "components/nav/navBar/navBarEmployee.component";
import { Drawer } from "components/nav/drawer/drawerEmployee.component";
import { ProfilePage } from "./pages/profile.page";

export const EmployeeApp = () => {
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);

  return (
    <EmployeeStoreProvider>
      <NavBar open={open} setOpen={setOpen} />
      <Drawer open={open} setOpen={setOpen} />
      <Switch>
        <PublicRoute path={`${path}/login`}>
          <LoginPage />
        </PublicRoute>

        <PrivateRoute path={`${path}/schedule`}>
          <SchedulePage />
        </PrivateRoute>

        <PrivateRoute path={`${path}/profile`}>
          <ProfilePage />
        </PrivateRoute>
      </Switch>
    </EmployeeStoreProvider>
  );
};
