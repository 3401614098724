import styled from "styled-components";
import { Color } from "config/colors";

export const TitleAndDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;

  a > p:first-child {
    margin: 0 5px 10px 5px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  p {
    margin: 0 5px;
  }

  @media (min-width: 1100px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a > p:first-child {
      margin: 0 50px 0 0;
    }

    a {
      flex-grow: 1;
    }
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p:nth-child(2) {
    white-space: nowrap;
  }

  @media (min-width: 1100px) {
    flex-basis: 250px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content space-between;
  align-items: center;
  grid-column: 1/4;
  background-color: ${Color.SeaBlue200};
  margin: 10px 0;
  padding: 20px 20px 20px 10px;
  border-radius: 8px;

  a {
    text-decoration: none;
  }
`;
