import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { ReactComponent as ImageIcon } from "images/icons/svg/image.svg";
import {
  LabelStyled,
  InputContainer,
  TextArea,
  LabelFileInput,
  IconInput,
} from "components/form/inputs/inputs.styled";
import {
  FormStyled,
  RowWrapper,
} from "../registrationContainer/registrationContainer.styled";
import { ProgressIndicator } from "components/form/progressIndicator/progressIndicator.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { ImageContainer } from "../../companySettings/profileSettings/profileSettings.styled";
import { CircularProgress } from "@material-ui/core";
import { CompanyImage, IconWrapper } from "../../profile/profileInfo.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { useAlertStore } from "stores/alertStore/alertStore";
import { FontSize } from "config/font";

type Props = {
  companyBio: string;
  setCompanyBio: React.Dispatch<React.SetStateAction<string>>;
  updateCompanyDescription: () => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const CreateProfile: React.FC<Props> = ({
  companyBio,
  setCompanyBio,
  updateCompanyDescription,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  const [companyState, companyActions] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [imageError, setImageError] = useState(false);
  const inputEl = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("finnish");
    if (companyBio) {
      updateCompanyDescription();
    } else {
      setPage(page + 1);
    }
  };

  const updateImage = async () => {
    if (inputEl.current && companyState.company) {
      const file = inputEl.current.files![0];
      if (file && file.size < 20971520) {
        setImageError(false);
        companyActions
          .updateCompanyImage(companyState.company.id, file)
          .then(() => {
            if (companyState.company?.id) {
              companyActions.getCompanyImage(companyState.company.id);
            }
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.OhNo"),
                message: t("General.ErrorOccurred"),
              },
            });
          });
      } else {
        setImageError(true); ///ouasdooasd
      }
    }
  };

  const textAreaAdjust = (element: EventTarget & HTMLTextAreaElement) => {
    element.style.height = "0px";
    element.style.height = element.scrollHeight + "px";
  };

  return (
    <FormStyled onSubmit={handleSubmit}>
      <ProgressIndicator steps={3} currentStep={3} />
      <H3>{t("RegisterCompany.CreateProfile")}</H3>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <ImageContainer>
          {companyState.imageIsLoading ? (
            <CircularProgress />
          ) : (
            <>
              {companyState.companyImage ? (
                <CompanyImage src={companyState.companyImage} />
              ) : (
                <CustomIcon
                  square
                  width="250px"
                  className="icon"
                  name="image"
                  size="190px"
                  color={Color.SeaBlue500}
                  backgroundColor={Color.SeaBlue300}
                  padding="10px"
                />
              )}
              <IconWrapper className="icon" button>
                <ImageIcon />
                <LabelFileInput htmlFor="upload-button" />
                <IconInput
                  hidden
                  type="file"
                  id="upload-button"
                  ref={inputEl}
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    updateImage();
                  }}
                />
              </IconWrapper>
            </>
          )}
        </ImageContainer>
        {imageError && (
          <Text fontSize={FontSize.Small} color={Color.Destructive}>
            {t("ProfileCompany.FileTooLarge")}
          </Text>
        )}
      </div>

      <InputContainer>
        <LabelStyled>{t("RegisterCompany.Bio")}</LabelStyled>
        <TextArea
          value={companyBio}
          placeholder={t("RegisterCompany.TellUsAboutCompany")}
          onChange={(e) => {
            setCompanyBio(e.currentTarget.value);
            textAreaAdjust(e.currentTarget);
          }}
        />
      </InputContainer>

      <ButtonStyled>
        {companyState.companyImage || companyBio
          ? t("RegisterCompany.CreateProfile")
          : t("General.Skip")}
      </ButtonStyled>
      <RowWrapper></RowWrapper>
    </FormStyled>
  );
};
