import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { CustomLink, Text } from "components/Typography/text.styled";
import { DayShiftRow, ModalBody } from "../weeklyCalendar.styled";
import { Modal } from "@material-ui/core";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import moment from "moment";
import {
  CompanyCalendarApplication,
  CompanyCalendarShift,
  CompanyCalendarTimereport,
} from "model/Calendar";
import { ApplicationResolution } from "model/Application";
import { TimeReportModalContainer } from "../timeReportModalContainer.component";
import { TimeReportResolution, TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import { Cell } from "../../workpool/workpoolWorkerDetails.styled";

type GigDetailsModalProps = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  day: string;
};

type RowType = {
  application: CompanyCalendarApplication;
  shift: CompanyCalendarShift;
  timeReport?: CompanyCalendarTimereport;
  gigId: number;
};

export const DayShiftsModal: React.FC<GigDetailsModalProps> = (props) => {
  const { t } = useTranslation();
  const [calendarState] = useCalendarStore();
  const [, , alertDispatch] = useAlertStore();
  const [filteredShifts, setFilteredShifts] = useState<RowType[]>([]);
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const [clickedRow, setClickedRow] = useState<RowType>();
  const [timeReportModalOpen, setTimeReportModalOpen] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

  useEffect(() => {
    filterShifts();
  }, [calendarState.currentWeek]);

  const filterShifts = () => {
    const shifts: RowType[] = [];
    calendarState.currentWeek.map((calendarGig) => {
      calendarGig.applications.map((app) => {
        if (
          app.resolution !== ApplicationResolution.DECLINED &&
          app.resolution !== ApplicationResolution.REJECTED &&
          app.resolution !== ApplicationResolution.WITHDRAWN
        ) {
          calendarGig.shifts.map((shift) => {
            if (moment(shift.startTime).day() === moment(props.day).day()) {
              const matchingTimeReport = shift.timeReports.find(
                (timeReport) => timeReport.workerId === app.worker.id
              );
              if (matchingTimeReport) {
                shifts.push({
                  application: app,
                  shift: shift,
                  timeReport: matchingTimeReport,
                  gigId: calendarGig.id,
                });
              } else {
                shifts.push({
                  application: app,
                  shift: shift,
                  gigId: calendarGig.id,
                });
              }
            }
          });
        }
      });
    });
    const sortedShifts = shifts.sort(
      (a, b) =>
        Date.parse(a.timeReport ? a.timeReport.startTime : a.shift.startTime) -
        Date.parse(b.timeReport ? b.timeReport.startTime : b.shift.startTime)
    );
    setFilteredShifts(sortedShifts);
  };

  return (
    <Modal
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Text
            fontSize={FontSize.H3}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: "12px" }}
          >
            {t("CalendarCompany.AssignedShifts", {
              day: `${weekdayList[moment(props.day).day() - 1]} ${moment(
                props.day
              ).format("D/M")}`,
            })}
          </Text>
          {filteredShifts.map((item) => {
            return (
              <DayShiftRow
                onClick={() => {
                  if (!item.timeReport) {
                    alertDispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        icon: "checkmark",
                        title: t("ApplicationsCompany.Offered"),
                        message: t(
                          "CalendarCompany.GigHasBeenOfferedToWorker",
                          {
                            user: item.application.worker.firstName,
                          }
                        ),
                      },
                    });
                  } else {
                    setTimeReportModalOpen(true);
                    setClickedRow(item);
                  }
                }}
                backgroundColor={
                  item.application.resolution === ApplicationResolution.ACCEPTED
                    ? Color.Positive
                    : Color.ChampagnePink
                }
                leftBorderColor={
                  item.timeReport?.status === TimeReportStatus.SUBMITTED
                    ? Color.Destructive
                    : item.timeReport?.resolution ===
                        TimeReportResolution.PAID ||
                      item.timeReport?.resolution ===
                        TimeReportResolution.TIME_REPORTED ||
                      item.timeReport?.resolution ===
                        TimeReportResolution.PAYONDEMAND
                    ? Color.BurntSienna
                    : Color.SeaBlue500
                }
              >
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {`${item.application.worker.firstName} ${item.application.worker.lastName}`}
                </Text>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {`${moment(
                    item.timeReport
                      ? item.timeReport.startTime
                      : item.shift.startTime
                  ).format("HH:mm")} - ${moment(
                    item.timeReport
                      ? item.timeReport.endTime
                      : item.shift.endTime
                  ).format("HH:mm")}`}
                </Text>
              </DayShiftRow>
            );
          })}
          {filteredShifts.length === 0 && (
            <Text>{t("CalendarCompany.NoAssingedShiftsThisDay")}</Text>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {showCopyConfirmation && (
              <Cell
                style={{
                  maxWidth: "160px",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue600}
                  style={{
                    margin: 0,
                  }}
                >
                  {t("General.CopiedToClipboard")}
                </Text>
              </Cell>
            )}
            {filteredShifts.length > 0 && (
              <CustomLink
                onClick={() => {
                  let stringSchedule = `${
                    weekdayList[moment(props.day).day() - 1]
                  } ${moment(props.day).format("D/M")} \n`;
                  filteredShifts.map((item) => {
                    const nameString = `${item.application.worker.firstName} ${item.application.worker.lastName} `;
                    const shiftString = `${
                      item.timeReport
                        ? moment(item.timeReport.startTime).format("HH:mm") +
                          " - " +
                          moment(item.timeReport.endTime).format("HH:mm")
                        : moment(item.shift.startTime).format("HH:mm") +
                          " - " +
                          moment(item.shift.endTime).format("HH:mm")
                    }`;
                    stringSchedule += `${nameString} | ${shiftString} \n`;
                  });
                  navigator.clipboard.writeText(stringSchedule).then(() => {
                    setShowCopyConfirmation(true);
                    setTimeout(() => {
                      setShowCopyConfirmation(false);
                    }, 2000);
                  });
                }}
                color={Color.SeaBlue600}
              >
                {t("CalendarCompany.CopyDay")}
              </CustomLink>
            )}
          </div>

          <CustomLink
            onClick={() => props.setModalOpen(false)}
            color={Color.Destructive}
          >
            {t("General.Close")}
          </CustomLink>
        </div>

        {timeReportModalOpen && clickedRow?.timeReport && (
          <TimeReportModalContainer
            modalOpen={timeReportModalOpen}
            setModalOpen={setTimeReportModalOpen}
            gigId={clickedRow.gigId}
            timeReportId={clickedRow.timeReport.id}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
