import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  LabelStyled,
  SmallerInputContainer,
} from "components/form/inputs/inputs.styled";
import { Api } from "services/api/api.service";
import moment, { Moment } from "moment";
import {
  DatePickerContainer,
  ErrorMessage,
  SmallerShiftInputContainer,
  TimeInput,
} from "../saveGig/shift/shiftForm.styled";
import { ChronoUnit, LocalDate, LocalTime } from "@js-joda/core";
import { SingleDatePicker } from "react-dates";
import { RoleWrapper } from "../saveGig/saveGigForm/saveGigForm.styled";
import { CompanyEmployee, ScheduleShift } from "model/Employees";
import { AutoCompleteSelectInput } from "components/form/inputs/autoCompleteSelectInput.component";

export type Position = {
  id: number;
  positionName: string;
};

type Props = {
  chosenDate?: string;
  chosenStation?: { id: number; name: string } | null;
  setShowCreateShiftModal: React.Dispatch<React.SetStateAction<boolean>>;
  stations: { id: number; name: string }[];
  roles: { id: number; role: string }[];
  scheduleShifts: ScheduleShift[];
  setScheduleShifts: React.Dispatch<React.SetStateAction<ScheduleShift[]>>;
  currentDates: any[];
};

export const SaveShiftForm: React.FC<Props> = ({
  chosenDate,
  chosenStation,
  setShowCreateShiftModal,
  stations,
  roles,
  scheduleShifts,
  setScheduleShifts,
  currentDates,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const today = LocalDate.now();
  const [shiftStartDate, setShiftStartDate] = useState(
    chosenDate ? moment(chosenDate) : moment()
  );
  const [shiftStartTime, setShiftStartTime] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState("");
  const [assignedEmployee, setAssignedEmployee] =
    useState<CompanyEmployee | null>(null);
  const [focused, setFocused] = useState(false);
  const [shiftEndDate, setShiftEndDate] = useState<Moment>(moment());
  const nowTime = LocalTime.now().truncatedTo(ChronoUnit.MINUTES);
  const [companyEmployees, setCompanyEmployees] = useState<CompanyEmployee[]>(
    []
  );
  const [assignedEmployeeInputValue, setAssignedEmployeeInputValue] =
    useState<string>(t("CompanyEmployees.NotAssigned"));
  const [assignedStationInputValue, setAssignedStationInputValue] =
    useState<string>(chosenStation ? chosenStation.name : stations[0].name);
  const [assignedStation, setAssignedStation] = useState<{
    id: number;
    name: string;
  } | null>(chosenStation ? chosenStation : stations[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedRole, setAssignedRole] = useState<{
    id: number;
    role: string;
  } | null>(null);
  const [assignedRoleInputValue, setAssignedRoleInputValue] = useState<string>(
    t("CompanyEmployees.NoRoleSelected")
  );

  useEffect(() => {
    setShiftStartDate(chosenDate ? moment(chosenDate) : moment());
    setShiftStartTime(
      nowTime.plusHours(1).truncatedTo(ChronoUnit.HOURS).toString()
    );
    setShiftEndTime(
      nowTime.plusHours(2).truncatedTo(ChronoUnit.HOURS).toString()
    );
  }, []);

  useEffect(() => {
    if (companyState.company?.id) {
      Api()
        .company.companyEmployees.getCompanyEmployees(companyState.company?.id)
        .then((res) => {
          setCompanyEmployees(res.data);
        });
    }
  }, [companyState.company?.id]);

  const validateStartTime = () => {
    if (today.toString() === shiftStartDate.format("YYYY-MM-DD")) {
      return shiftStartTime >= nowTime.toString();
    } else {
      return true;
    }
  };

  const overnightShift = () => {
    if (
      shiftEndTime < shiftStartTime &&
      (shiftEndDate.format("YYYY-MM-DD") ===
        shiftStartDate.format("YYYY-MM-DD") ||
        shiftStartDate > shiftEndDate)
    ) {
      setShiftEndDate(moment(shiftStartDate).add(1, "days"));
    } else if (shiftEndTime < shiftStartTime) {
      setShiftEndDate(moment(shiftStartDate).add(1, "days"));
    } else if (shiftEndTime > shiftStartTime) {
      setShiftEndDate(shiftStartDate);
    }
  };

  useEffect(() => {
    overnightShift();
  }, [shiftStartDate, shiftEndTime, shiftStartTime]);
  const validateShift = () => {
    if (
      shiftStartDate &&
      shiftStartTime &&
      shiftEndTime &&
      validateStartTime() &&
      assignedStation
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickSaveShift = () => {
    let startTime = new Date(
      `${shiftStartDate.format("YYYY-MM-DD")}T${shiftStartTime}`
    ).toISOString();
    let endTime = new Date(
      `${shiftEndDate.format("YYYY-MM-DD")}T${shiftEndTime}`
    ).toISOString();
    if (companyState.company?.id) {
      setIsLoading(true);

      const data = {
        companyId: companyState.company?.id,
        startTime: startTime,
        endTime: endTime,
        roleId: assignedRole?.id || null,
        stationId: assignedStation?.id || stations[0].id,
      };
      Api()
        .company.companyEmployees.createCompanyScheduleShift(
          companyState.company?.id,
          data
        )
        .then((res) => {
          if (assignedEmployee && companyState.company?.id) {
            Api()
              .company.companyEmployees.assignEmployeeScheduleShift(
                companyState.company?.id,
                {
                  companyId: companyState.company?.id,
                  shiftId: res.data.id,
                  employeeId: assignedEmployee?.id,
                }
              )
              .then((employeeRes) => {
                setIsLoading(false);
                if (
                  new Date(res.data.startTime) >= currentDates[0].date &&
                  new Date(res.data.startTime) <= currentDates[6].date
                ) {
                  setScheduleShifts([...scheduleShifts, employeeRes.data]);
                }
                setShowCreateShiftModal(false);
              });
          } else {
            setIsLoading(false);
            if (
              new Date(res.data.startTime) >= currentDates[0].date &&
              new Date(res.data.startTime) <= currentDates[6].date
            ) {
              setScheduleShifts([...scheduleShifts, res.data]);
            }
            setShowCreateShiftModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {!isLoading ? (
        <>
          <SmallerInputContainer>
            <LabelStyled>
              {t("CompanyEmployees.ChooseStation") + "*"}
            </LabelStyled>
            <RoleWrapper>
              <AutoCompleteSelectInput
                maxHeight="50px"
                inputValue={assignedStationInputValue}
                onChange={(val: string) => {
                  setAssignedStationInputValue(val);
                  setAssignedStation(
                    stations.find((item) => `${item.name}` === val) || null
                  );
                }}
                selections={stations.map((item) => `${item.name}`)}
              />
              {assignedStation && (
                <CustomIcon
                  name="cross"
                  color={Color.Destructive}
                  padding="4px"
                  onClick={() => {
                    setAssignedStation(null);
                    setAssignedStationInputValue(
                      t("CompanyEmployees.ChooseStation")
                    );
                  }}
                />
              )}
            </RoleWrapper>
          </SmallerInputContainer>

          <SmallerShiftInputContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
            >
              {t("GigsCompany.Date") + "*"}
            </Text>
            <DatePickerContainer>
              <SingleDatePicker
                date={shiftStartDate}
                onDateChange={(date: any) =>
                  shiftStartDate && setShiftStartDate(date)
                }
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="SingleDatePicker"
                hideKeyboardShortcutsPanel={true}
                anchorDirection="right"
                numberOfMonths={1}
                displayFormat="DD-MM-YYYY"
                firstDayOfWeek={1}
                isOutsideRange={() => false}
              />
            </DatePickerContainer>
          </SmallerShiftInputContainer>
          <SmallerShiftInputContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
            >
              {t("GigsCompany.ShiftStart") + "*"}
            </Text>
            <TimeInput
              fontFamily={FontFamily.MontserratBold}
              fontSize={FontSize.Large}
              name="shiftStartTime"
              type="time"
              value={shiftStartTime}
              onChange={(e) => {
                setShiftStartTime(e.currentTarget.value);
              }}
            />
            {!validateStartTime() && (
              <ErrorMessage>{t("GigsCompany.EnterAValidTime")}</ErrorMessage>
            )}
          </SmallerShiftInputContainer>
          <SmallerShiftInputContainer>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}
            >
              {t("GigsCompany.ShiftEnd") + "*"}
            </Text>
            <TimeInput
              fontFamily={FontFamily.MontserratBold}
              fontSize={FontSize.Large}
              name="shiftEndTime"
              type="time"
              required
              value={shiftEndTime}
              onChange={(e) => {
                setShiftEndTime(e.currentTarget.value);
              }}
            />
          </SmallerShiftInputContainer>
          <SmallerInputContainer>
            <LabelStyled>{t("CompanyEmployees.CompanyEmployees")}</LabelStyled>
            <RoleWrapper>
              <AutoCompleteSelectInput
                maxHeight="200px"
                inputValue={assignedEmployeeInputValue}
                onChange={(val: string) => {
                  setAssignedEmployeeInputValue(val);
                  setAssignedEmployee(
                    companyEmployees.find(
                      (emp) => `${emp.firstName} ${emp.lastName}` === val
                    ) || null
                  );
                }}
                selections={companyEmployees.map(
                  (emp) => `${emp.firstName} ${emp.lastName}`
                )}
              />
              {assignedEmployee && (
                <CustomIcon
                  name="cross"
                  color={Color.Destructive}
                  padding="4px"
                  onClick={() => {
                    setAssignedEmployee(null);
                    setAssignedEmployeeInputValue(
                      t("CompanyEmployees.NotAssigned")
                    );
                  }}
                />
              )}
            </RoleWrapper>
          </SmallerInputContainer>
          <SmallerInputContainer>
            <LabelStyled>{t("CompanyEmployees.ChooseRole")}</LabelStyled>
            <RoleWrapper>
              <AutoCompleteSelectInput
                maxHeight="150px"
                inputValue={assignedRoleInputValue}
                onChange={(val: string) => {
                  setAssignedRoleInputValue(val);
                  setAssignedRole(
                    roles.find((item) => `${item.role}` === val) || null
                  );
                }}
                selections={roles.map((item) => `${item.role}`)}
              />
              {assignedRole && (
                <CustomIcon
                  name="cross"
                  color={Color.Destructive}
                  padding="4px"
                  onClick={() => {
                    setAssignedStation(null);
                    setAssignedStationInputValue(
                      t("CompanyEmployees.ChooseRole")
                    );
                  }}
                />
              )}
            </RoleWrapper>
          </SmallerInputContainer>
          <ButtonStyled
            disabled={!validateShift()}
            onClick={() => handleClickSaveShift()}
          >
            {t("General.Save")}
          </ButtonStyled>
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
