import React, { useState } from "react";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { Banner } from "./banner.styled";

export const BannerComponent: React.FC<{
  icon?: any;
  fill?: Color;
  backgroundColor?: Color;
}> = (props) => {
  const [showBanner, setShowBanner] = useState(true);
  const { icon, children, fill, backgroundColor, ...rest } = props;
  return (
    <>
      {showBanner && (
        <Banner backgroundColor={backgroundColor} {...rest}>
          <CustomIcon
            name={icon || "cross"}
            color={fill}
            size="53px"
            padding="12px"
            onClick={() => !icon && setShowBanner(false)}
            style={{ flexShrink: 0 }}
          />
          <div>{children}</div>
        </Banner>
      )}
    </>
  );
};
