import { Workers } from "model/StaffingPool";

export type StaffingWorkersActions =
  | {
      type: "GET_WORKERS";
      payload: Workers;
    }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: StaffingWorkersState["error"] };

export type StaffingWorkersState = {
  loading: boolean;
  workers: Workers;
  error: string | null;
};

export const staffingWorkersInitState: StaffingWorkersState = {
  loading: false,
  workers: [],
  error: null,
};

export const StaffingWorkersStateReducer = (
  state: StaffingWorkersState,
  action: StaffingWorkersActions
): StaffingWorkersState => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return { ...state, loading: action.payload };
    case "GET_WORKERS":
      return { ...state, workers: action.payload, loading: false, error: null };
    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
