import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { MonthlyOverview } from "./monthlyOverview.component";
import { UnhandledOverview } from "./unhandledOverview.component";

import { StyledTabGroup } from "./timeReports.styled";
import { TabStyled } from "../tab/tab.styled";
import { Text } from "components/Typography/text.styled";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { PersonnelCarrierOverview } from "./personnelCarrierOverview.component";

export const TimeReports: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<"Filtered" | "All" | "PersonnelCarrier">("Filtered");
  const [timeReportState] = useTimeReportStore();
  const { t } = useTranslation();

  return (
    <>
      <StyledTabGroup>
        <TabStyled
          active={currentTab === "Filtered"}
          onClick={() => setCurrentTab("Filtered")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyTimeReport.UnhandledTimeReports")} (
            {timeReportState.unhandledTimeReportCount})
          </Text>
        </TabStyled>
        <TabStyled
          active={currentTab === "All"}
          onClick={() => setCurrentTab("All")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyTimeReport.AllTimeReports")}
          </Text>
        </TabStyled>
        <TabStyled
          active={currentTab === "PersonnelCarrier"}
          onClick={() => setCurrentTab("PersonnelCarrier")}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("CompanyTimeReport.PersonnelCarrier")}
          </Text>
        </TabStyled>
      </StyledTabGroup>

      {currentTab === "Filtered" && <UnhandledOverview />}
      {currentTab === "All" && <MonthlyOverview />}
      {currentTab === "PersonnelCarrier" && <PersonnelCarrierOverview />}
    </>
  );
};
