import { Redirect, Route, Switch } from "react-router-dom";
import { AlertStoreProvider } from "stores/alertStore/alertStore";
import { AuthStoreProvider } from "./stores/authStore/authStore";
import { GlobalStoreProvider } from "./stores/globalStore/globalStore";
import { CompanyApp } from "./web-apps/company/company.app";
import "./services/translation/i18n";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { EmployeeApp } from "web-apps/employee/employee.app";

function App() {
  return (
    <Switch>
      <AlertStoreProvider>
        <AuthStoreProvider>
          <GlobalStoreProvider>
            <Route
              exact
              path="/"
              render={() => <Redirect to="company/login" />}
            />
            <Route path="/company">
              <CompanyApp />
            </Route>
            <Route
              exact
              path="/"
              render={() => <Redirect to="employee/login" />}
            />
            <Route path="/employee">
              <EmployeeApp />
            </Route>
          </GlobalStoreProvider>
        </AuthStoreProvider>
      </AlertStoreProvider>
    </Switch>
  );
}

export default App;
