import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";
import { H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  ButtonContainer,
  IconWrapper,
  LoadingContainer,
  ModalBody,
  RoleIconsRow,
  TitleRow,
  UserInfoColumn,
  UserRow,
} from "./companyUsers.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { CompanyUser, UserRole } from "model/Company";
import { CompanyUserForm } from "./companyUserForm.component";
import { Api } from "services/api/api.service";
import { BannerComponent } from "components/banners/banner.component";

type Props = {
  view?: string;
};

export const CompanyUsers: React.FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions, companyDispatch] = useCompanyStore();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [currentUser, setCurrentUser] = useState<CompanyUser | null>(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (companyState.company?.id) {
      Api()
        .company.getCompanyUsers(companyState.company?.id)
        .then((res) => {
          companyDispatch({ type: "GET_COMPANY_USERS", payload: res.data });
        });
    }
  }, [companyState.company?.id]);

  const handleRemoveUser = (userId: number) => {
    if (companyState.company) {
      setIsLoading(true);
      companyActions
        .removeCompanyUser(companyState.company?.id, userId)
        .then(() => {
          setCurrentUser(null);
          setShowDeleteUserModal(false);
          setIsLoading(false);
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("CompanyUsers.CouldNotRemoveUser"),
            },
          });
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {!companyState.company?.verified ? (
        <BannerComponent
          icon="clock"
          backgroundColor={Color.ChampagnePink}
          fill={Color.Destructive}
        >
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.Destructive}
          >
            {t("GigsCompany.UnverifiedAccount")}
          </Text>
          <Text>{t("GigsCompany.UnverifiedAccountDetails")}</Text>
        </BannerComponent>
      ) : (
        <>
          <TitleRow>
            <H3>{t("CompanyUsers.CompanyUsers")}</H3>
            <ButtonContainer>
              <ButtonStyled onClick={() => setShowAddUserModal(true)}>
                {t("CompanyUsers.AddUser")}
              </ButtonStyled>
            </ButtonContainer>
          </TitleRow>
          <div>
            {companyState.users.map((user, index) => (
              <UserRow key={index}>
                <UserInfoColumn>
                  <Text>{`${user.firstName} ${user.lastName}`}</Text>
                  {user.phoneNumber && <Text>{user.phoneNumber}</Text>}
                  <Text>{user.email}</Text>
                </UserInfoColumn>
                <RoleIconsRow>
                  <Text
                    fontFamily={FontFamily.MontserratSemiBold}
                    fontSize={FontSize.Large}
                  >
                    {t(`CompanyUsers.${user.role}`)}
                  </Text>
                  {user.email !== companyState.companyUser?.email && (
                    <>
                      {companyState.companyUser?.role ===
                        UserRole.COMPANY_OWNER && (
                        <>
                          <IconWrapper>
                            <CustomIcon
                              name="pen"
                              size="40px"
                              backgroundColor={Color.BurntSienna}
                              color={Color.White}
                              padding="10px"
                              onClick={() => {
                                setCurrentUser(user);
                                setShowAddUserModal(true);
                              }}
                            />
                          </IconWrapper>

                          <IconWrapper>
                            <CustomIcon
                              name="trashcan"
                              size="40px"
                              backgroundColor={Color.Destructive}
                              color={Color.White}
                              padding="10px"
                              onClick={() => {
                                setCurrentUser(user);
                                setShowDeleteUserModal(true);
                              }}
                            />
                          </IconWrapper>
                        </>
                      )}
                    </>
                  )}
                </RoleIconsRow>
              </UserRow>
            ))}
          </div>
          <Modal
            open={showAddUserModal}
            onClose={() => {
              setShowAddUserModal(false);
              setCurrentUser(null);
            }}
          >
            <ModalBody>
              <H3>
                {currentUser
                  ? t("CompanyUsers.UpdateUser")
                  : t("CompanyUsers.AddUser")}
              </H3>
              <CompanyUserForm
                setShowAddUserModal={setShowAddUserModal}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
              />
            </ModalBody>
          </Modal>
          <Modal
            open={showDeleteUserModal}
            onClose={() => {
              setShowDeleteUserModal(false);
              setCurrentUser(null);
            }}
          >
            <ModalBody>
              {isLoading ? (
                <LoadingContainer>
                  <CircularProgress style={{ color: Color.BurntSienna }} />
                </LoadingContainer>
              ) : (
                <>
                  <H3>{t("CompanyUsers.RemoveCompanyUser")}</H3>
                  <RoleIconsRow>
                    <ButtonStyled
                      backgroundColor={Color.Destructive}
                      onClick={() =>
                        currentUser && handleRemoveUser(currentUser?.id)
                      }
                    >
                      {t("General.Remove")}
                    </ButtonStyled>

                    <ButtonStyled
                      backgroundColor={Color.SeaBlue400}
                      onClick={() => {
                        setShowDeleteUserModal(false);
                        setCurrentUser(null);
                      }}
                    >
                      {t("General.Cancel")}
                    </ButtonStyled>
                  </RoleIconsRow>
                </>
              )}
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};
