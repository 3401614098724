import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { LabelStyled, TextInput } from "components/form/inputs/inputs.styled";
import { InputContainer } from "components/form/inputs/inputs.styled";
import { Api } from "services/api/api.service";
import { RoleWrapper } from "../saveGig/saveGigForm/saveGigForm.styled";
import { ListItemWrapper } from "./companyEmployees.styled";

type Props = {
  type: "station" | "role";
  list: Array<any>;
  setList: React.Dispatch<React.SetStateAction<any[]>>;
};

export const StationModal: React.FC<Props> = ({ type, list, setList }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  useState<string>(t("CompanyEmployees.NotAssigned"));
  const [newItem, setNewItem] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const postEndpoint =
    type === "station"
      ? Api().company.companyEmployees.createCompanyScheduleStation
      : Api().company.companyEmployees.createCompanyScheduleRole;

  const deleteEndpoint =
    type === "station"
      ? Api().company.companyEmployees.deleteCompanyScheduleStation
      : Api().company.companyEmployees.deleteCompanyScheduleRole;

  const saveStation = () => {
    if (companyState.company?.id) {
      setIsLoading(true);
      postEndpoint(companyState.company?.id, newItem.trim())
        .then((res) => {
          setIsLoading(false);
          setList([...list, res.data]);
          setNewItem("");
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  const handleDelete = (id: number) => {
    if (companyState.company?.id) {
      setIsLoading(true);
      deleteEndpoint(companyState.company?.id, id)
        .then((res) => {
          setIsLoading(false);
          setList(list.filter((item) => item.id !== id));
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {list.map((item) => (
            <ListItemWrapper>
              <Text fontSize={FontSize.Large}>
                {item.name ? item.name : item.role}
              </Text>
              <CustomIcon
                name="trashcan"
                color={Color.Destructive}
                size={"24px"}
                onClick={() => {
                  handleDelete(item.id);
                }}
              />
            </ListItemWrapper>
          ))}
          <InputContainer>
            <LabelStyled>
              {type === "station"
                ? t("CompanyEmployees.NewStation")
                : t("CompanyEmployees.NewRole")}
            </LabelStyled>
            <RoleWrapper>
              <TextInput
                value={newItem}
                onChange={(e) => setNewItem(e.currentTarget.value)}
              />

              <CustomIcon
                name="plus_old"
                color={Color.White}
                backgroundColor={Color.MidnightBlue}
                size={"40px"}
                disabled={newItem.trim().length === 0}
                padding="6px"
                onClick={() => saveStation()}
              />
            </RoleWrapper>
          </InputContainer>
        </>
      )}
    </>
  );
};
