import styled from "styled-components";
import { Color } from "config/colors";

export const Wrapper = styled.div`
  position: absolute;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Color.White};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button:not(:first-child):not(:last-child) {
    margin: 0 20px;
  }
`;
