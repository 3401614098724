import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  ButtonContainer,
  CellContent,
  DashBoardCell,
  DashboardCellContent,
  DashboardCellWeekContent,
  DashBoardColumn,
  DashboardContainer,
  IconsContainer,
  TimeReportCell,
} from "./dashboard.styled";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import moment from "moment";
import { DashboardWeek } from "./week/dashboardWeek.component";
import { TimeReportModal } from "../timeReport/timeReportModal.component";
import { TimeReportDto, WorkerWithTimeReports } from "model/TimeReport";
import { CircularProgress, Modal } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { WorkpoolWorker } from "model/Workpool";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { getWorkHours } from "utils/utils";
import { RatingModal } from "../timeReport/ratingModal.component";
import { EndorseModal } from "../timeReport/endorsements/endorseModal.component";
import { ApplicantDetailsModal } from "../applicantDetails/applicantDetailsModal.component";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyState] = useCompanyStore();
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [workpoolState, workpoolActions] = useWorkpoolStore();
  const [timeReportModalOpen, setTimeReportModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [currentTimeReport, setCurrentTimeReport] = useState<TimeReportDto>();
  const [, , alertDispatch] = useAlertStore();
  const [unhandledTimeReports, setUnhandledTimeReports] = useState<
    TimeReportDto[] | null
  >(null);
  const [unhandledApplications, setUnhandledApplications] = useState<
    ApplicationDto[]
  >([]);
  const [createGigModal, setCreateGigModal] = useState(false);
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [currentApplication, setCurrentApplication] =
    useState<ApplicationDto>();

  const getWorkersWithTimeReports = async () => {
    if (companyState.company?.id) {
      let unhandled: any[] = [];
      timeReportDispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });

      try {
        const res =
          await Api().company.timeReport.getUnhandledTimeReportWorkers(
            companyState.company.id
          );

        const promises = res.data.map(async (worker) => {
          if (companyState.company?.id) {
            const timeReportRes =
              await Api().company.timeReport.getWorkerUnhandledTimeReports(
                companyState.company.id,
                worker.workerId
              );
            return {
              worker,
              timeReports: timeReportRes.data,
            };
          }
        });

        const result = await Promise.all(promises);
        unhandled = result.filter(Boolean);
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: unhandled,
        });
        timeReportActions.getUnhandledTimeReportCount(companyState.company.id);
      } catch (err) {
        timeReportDispatch({
          type: "SET_IS_LOADING",
          payload: false,
        });
        console.log(err, "ERROR FETCHING UNHANDLED TIME REPORTS", err);
      }
    }
  };

  useEffect(() => {
    createTimeReportList(timeReportState.unhandledTimeReports);
  }, [JSON.stringify(timeReportState.unhandledTimeReports)]);

  const createTimeReportList = (unhandled: WorkerWithTimeReports[]) => {
    const tr: TimeReportDto[] = [];

    unhandled.forEach((unhandledWorker: WorkerWithTimeReports, index) => {
      unhandledWorker.timeReports.forEach((workerTimeReport) => {
        tr.push(workerTimeReport);
      });
    });
    setUnhandledTimeReports(tr);
  };

  useEffect(() => {
    if (unhandledTimeReports) {
      timeReportDispatch({
        type: "SET_IS_LOADING",
        payload: false,
      });
    }
  }, [unhandledTimeReports]);

  const updateEmployeeStatus = (
    updateStatus: "Verify" | "Reject",
    employee: WorkpoolWorker
  ) => {
    if (companyState.company) {
      if (updateStatus === "Verify") {
        workpoolActions
          .verifyEmployee(companyState.company.id, employee)
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("CompanyGroup.Verified"),
                message: t("CompanyGroup.ApplicantHasBeenVerified"),
              },
            });
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("CompanyGroup.ApplicationCouldNotBeUpdated"),
              },
            });
          });
      } else if (updateStatus === "Reject") {
        workpoolActions
          .deleteEmployee(companyState.company.id, employee.workerId)
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("CompanyGroup.Rejected"),
                message: t("CompanyGroup.ApplicationHasBeenRejected"),
              },
            });
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
              },
            });
          });
      }
    }
  };

  useEffect(() => {
    getWorkersWithTimeReports();
  }, [companyState.company?.id]);

  useEffect(() => {
    if (companyState.company?.id) {
      applicationDispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.application.getPaginatedApplicationsPending(
          companyState.company.id,
          0,
          5
        )
        .then((res) => {
          setUnhandledApplications(res.data);
          applicationDispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch((err) => {
          console.log(err);
          applicationDispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company?.id]);

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED,
    applicant: ApplicationDto
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: applicationState.applicationsCount - 1,
          });
          if (status === ApplicationStatus.OFFERED) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Offered"),
                message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Rejected"),
                message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
              },
            });
          }
          setUnhandledApplications(
            unhandledApplications.filter(
              (application) => application.id !== data.id
            )
          );
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
            },
          });
        });
    }
  };

  const goToWeek = () => {
    localStorage.setItem("CalendarDate", moment().format("YYYY-MM-DD"));
    history.push("./calendar");
  };

  return (
    <>
      <ButtonContainer>
        <ButtonStyled onClick={() => setCreateGigModal(true)}>
          {t("GigsCompany.CreateGig")}
        </ButtonStyled>
      </ButtonContainer>

      <DashboardContainer>
        <DashBoardColumn>
          <DashBoardCell>
            <CustomLink
              color={Color.MidnightBlue}
              onClick={() => history.push("./time-reports")}
            >
              {t("CompanyTimeReport.TimeReports")}
            </CustomLink>
            <DashboardCellContent>
              {!timeReportState.isLoading &&
                unhandledTimeReports &&
                unhandledTimeReports.map((timeReport, index) => {
                  if (index < 4) {
                    return (
                      <TimeReportCell
                        onClick={() => {
                          setCurrentTimeReport(timeReport);
                          setTimeReportModalOpen(true);
                        }}
                      >
                        <Text
                          color={Color.MidnightBlue}
                          fontFamily={FontFamily.MontserratSemiBold}
                        >{`${timeReport.firstName} ${timeReport.lastName}`}</Text>

                        <Text color={Color.SeaBlue600}>
                          {`${moment(timeReport.startTime).format(
                            "DD/MM HH:mm"
                          )} - ${moment(timeReport.endTime).format(" HH:mm")}`}
                        </Text>
                        <Text>{getWorkHours(t, ...[timeReport])}</Text>
                      </TimeReportCell>
                    );
                  } else if (index === 5) {
                    return (
                      <CustomLink
                        onClick={() => history.push("./time-reports")}
                        color={Color.SeaBlue600}
                        fontFamily={FontFamily.MontserratRegular}
                        fontSize={FontSize.Standard}
                      >
                        +{unhandledTimeReports.length - 4}
                      </CustomLink>
                    );
                  }
                })}

              {!timeReportState.isLoading &&
                unhandledTimeReports !== null &&
                timeReportState.unhandledTimeReports.length === 0 && (
                  <Text color={Color.SeaBlue400}>
                    {t("CompanyTimeReport.NoUnhandledTimeReports")}
                  </Text>
                )}
              {(timeReportState.isLoading || unhandledTimeReports == null) && (
                <CircularProgress />
              )}
            </DashboardCellContent>
          </DashBoardCell>

          <DashBoardCell>
            <CustomLink
              color={Color.MidnightBlue}
              onClick={() => history.push("./applications")}
            >
              {t("ApplicationsCompany.Applications")}
            </CustomLink>
            <DashboardCellContent>
              {!applicationState.isLoading &&
                unhandledApplications.map((app, index) => {
                  if (index < 4) {
                    return (
                      <TimeReportCell>
                        <CellContent
                          onClick={(e) => {
                            setCurrentApplication(app);
                            setShowApplicationDetails(true);
                          }}
                        >
                          <Text
                            color={Color.MidnightBlue}
                            fontFamily={FontFamily.MontserratSemiBold}
                          >
                            {`${app.worker.firstName} ${app.worker.lastName}`}
                          </Text>
                          <Text color={Color.SeaBlue600}>
                            {`${app.gig.role}
                      ${moment(app.gig.startTime).format("DD/MM")}
                      ${
                        moment(app.gig.endTime).format("DD/MM/YY") !==
                        moment(app.gig.startTime).format("DD/MM/YY")
                          ? moment(app.gig.endTime).format("DD/MM")
                          : ""
                      }`}
                          </Text>
                        </CellContent>
                        <IconsContainer>
                          <CustomIcon
                            style={{ zIndex: 100 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.OFFERED,
                                app
                              )
                            }
                            name="checkmark"
                            size="48px"
                            padding="10px"
                            color={Color.BurntSienna}
                          />
                          <CustomIcon
                            style={{ zIndex: 100 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.CLOSED,
                                app
                              )
                            }
                            name="cross"
                            size="48px"
                            padding="10px"
                            color={Color.Destructive}
                          />
                        </IconsContainer>
                      </TimeReportCell>
                    );
                  } else if (index === 4) {
                    return (
                      <CustomLink
                        onClick={() => history.push("./applications")}
                        color={Color.SeaBlue600}
                        fontFamily={FontFamily.MontserratRegular}
                        fontSize={FontSize.Standard}
                      >
                        + {applicationState.applicationsCount - 4}
                      </CustomLink>
                    );
                  }
                })}
              {!applicationState.isLoading &&
                unhandledApplications.length === 0 && (
                  <Text color={Color.SeaBlue400}>
                    {t("ApplicationsCompany.NoNewApplications")}
                  </Text>
                )}
              {applicationState.isLoading && <CircularProgress />}
            </DashboardCellContent>
          </DashBoardCell>
        </DashBoardColumn>
        <DashBoardColumn>
          <DashBoardCell>
            <CustomLink color={Color.MidnightBlue} onClick={() => goToWeek()}>
              {t("CalendarCompany.Week")} {moment().week()}
            </CustomLink>
            <DashboardCellWeekContent>
              <DashboardWeek />
            </DashboardCellWeekContent>
          </DashBoardCell>

          <DashBoardCell>
            <CustomLink
              color={Color.MidnightBlue}
              onClick={() => history.push("./work-pool")}
            >
              {t("CompanyGroup.Workpool")}
            </CustomLink>
            <DashboardCellContent>
              {!workpoolState.isLoading &&
                workpoolState.unverifiedEmployees
                  .filter((emp) => emp.requestType === "WorkerRequest")

                  .map((request, index) => {
                    if (index < 4) {
                      return (
                        <TimeReportCell>
                          <CellContent
                            onClick={() =>
                              history.push(`./work-pool/${request.id}`)
                            }
                          >
                            <Text
                              color={Color.MidnightBlue}
                              fontFamily={FontFamily.MontserratSemiBold}
                            >
                              {`${request.worker.firstName} ${request.worker.lastName}`}
                            </Text>
                          </CellContent>
                          <IconsContainer>
                            <CustomIcon
                              onClick={() =>
                                updateEmployeeStatus("Verify", request)
                              }
                              name="checkmark"
                              size="48px"
                              padding="10px"
                              color={Color.BurntSienna}
                            />
                            <CustomIcon
                              onClick={() =>
                                updateEmployeeStatus("Reject", request)
                              }
                              name="cross"
                              size="48px"
                              padding="10px"
                              color={Color.Destructive}
                            />
                          </IconsContainer>
                        </TimeReportCell>
                      );
                    } else if (index === 4) {
                      return (
                        <CustomLink
                          onClick={() => history.push("./work-pool")}
                          color={Color.SeaBlue600}
                          fontFamily={FontFamily.MontserratRegular}
                          fontSize={FontSize.Standard}
                        >
                          + {workpoolState.unverifiedEmployees.length - 4}
                        </CustomLink>
                      );
                    }
                  })}
              {!workpoolState.isLoading &&
                workpoolState.unverifiedEmployees.filter(
                  (emp) => emp.requestType === "WorkerRequest"
                ).length === 0 && (
                  <Text color={Color.SeaBlue400}>
                    {t("CompanyGroup.NoNewWorkpoolRequests")}
                  </Text>
                )}
              {workpoolState.isLoading && <CircularProgress />}
            </DashboardCellContent>
          </DashBoardCell>
        </DashBoardColumn>
      </DashboardContainer>

      {currentTimeReport && (
        <TimeReportModal
          chosenTimeReportWithWorker={currentTimeReport}
          modalOpen={timeReportModalOpen}
          setModalOpen={setTimeReportModalOpen}
          setRatingModalOpen={setRatingModalOpen}
        />
      )}
      {ratingModalOpen && currentTimeReport && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={currentTimeReport}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}
      {endorseModalOpen && currentTimeReport && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={currentTimeReport}
        />
      )}
      <Modal
        open={createGigModal}
        onClose={() => {
          setCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("CalendarCompany.CreateGig")}</H3>
          <SaveGigForm setShowCreateGigModal={setCreateGigModal} />
        </ModalBody>
      </Modal>
      {showApplicationDetails && currentApplication && (
        <ApplicantDetailsModal
          applicant={currentApplication}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
          applications={unhandledApplications}
          setApplications={setUnhandledApplications}
          unhandled={true}
        />
      )}
    </>
  );
};
