import { AlertState } from "./alertStore.reducer";
import { AlertDispatch } from "./alertStore";

const showAlert =
  (dispatch: AlertDispatch) =>
  (title: string, message: string, icon?: string) => {
    dispatch({
      type: "SHOW_ALERT",
      payload: {
        title,
        message,
        icon,
      },
    });
  };

const hideAlert = (dispatch: AlertDispatch) => () => {
  dispatch({
    type: "HIDE_ALERT",
  });
};

export const alertActions = (dispatch: AlertDispatch, state: AlertState) => ({
  showAlert: showAlert(dispatch),
  hideAlert: hideAlert(dispatch),
});
