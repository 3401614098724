import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import firebase from "services/firebase/firebase.config";
import { Color } from "config/colors";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomLink, H4, Text } from "components/Typography/text.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import {
  FormContainer,
  ModalBody,
} from "web-apps/company/components/login/loginForm.styled";
import { LabelStyled, TextInput } from "components/form/inputs/inputs.styled";
import { useAuthStore } from "stores/authStore/authStore";
import { FontSize } from "config/font";

type passwordModalProps = {
  passwordModalOpen: boolean;
  setPasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const ResetPasswordModal: React.FC<passwordModalProps> = ({
  passwordModalOpen,
  setPasswordModalOpen,
  email,
  setEmail,
}) => {
  const [ableToSend, setAbleToSend] = useState(false);
  const [sent, setSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [authState, , authDispatch] = useAuthStore();

  const { t } = useTranslation();

  useEffect(() => {
    if (email) {
      setEmail(email.trim());
      setAbleToSend(true);
    } else {
      setAbleToSend(false);
    }
  }, [email]);

  useEffect(() => {
    if (!passwordModalOpen) {
      setAbleToSend(false);
      setSent(false);
      setEmailError(false);
    }
  }, [passwordModalOpen]);

  const sendPasswordResetEmail = () => {
    if (ableToSend) {
      authDispatch({ type: "SET_IS_LOADING", payload: true });
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setSent(true);
          authDispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          setEmailError(true);
          authDispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  };

  return (
    <Modal
      open={passwordModalOpen}
      onClose={() => {
        setPasswordModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        {!sent ? (
          <FormContainer>
            <H4>{t("LoginCompany.ResetYourPassword")}</H4>
            <Text>{t("LoginCompany.EnterYourAccountEmail")}</Text>
            <LabelStyled style={{ width: "100%" }}>
              {t("LoginCompany.Email")}
            </LabelStyled>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <Text fontSize={FontSize.Small} color={Color.Destructive}>
                {t("LoginCompany.CouldNotVerify")}
              </Text>
            )}
            <ButtonStyled
              fullWidth
              style={{ margin: "10px" }}
              disabled={!ableToSend}
              onClick={sendPasswordResetEmail}
            >
              {authState.isLoading ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                t("LoginCompany.Submit")
              )}
            </ButtonStyled>
            <CustomLink
              onClick={() => setPasswordModalOpen(false)}
              color={Color.Destructive}
              fontSize={FontSize.Small}
              align="center"
            >
              {t("General.Cancel")}
            </CustomLink>
          </FormContainer>
        ) : (
          <>
            <H4>{t("LoginCompany.Sent")}</H4>
            <Text>{t("LoginCompany.CheckYourEmail")}</Text>

            <ButtonStyled
              style={{ margin: "10px" }}
              onClick={() => setPasswordModalOpen(false)}
            >
              {t("General.Ok")}
            </ButtonStyled>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
