import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TimeReportResolution,
  TimeReportStatus,
  TimeTotal,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import {
  TimeReportsGridFooter,
  Cell,
  HeadCellFooter,
} from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { GigCosts } from "model/Gig";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import { Api } from "services/api/api.service";
import moment from "moment";

type Props = {
  monthlyWorkerWithTimeReports: WorkerWithTimeReports;
  month: number;
  year: number;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const WorkerTimeReportsFooter: React.FC<Props> = ({
  monthlyWorkerWithTimeReports,
  month,
  year,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [totalCost, setTotalCost] = useState<GigCosts>(emptyCosts);
  const [totalCostApproved, setTotalCostApproved] =
    useState<GigCosts>(emptyCosts);
  const [showAbsenceDetails, setShowAbsenceDetails] = useState(false);
  const [timeData, setTimeData] = useState({
    totalTime: "0h 0min",
    totalTimeApproved: "0h 0min",
    totalTimeSubmitted: "0h 0min",
    totalTimeLeft: "0h 0min",
    totalTimeAbsence: "0h 0min",
    totalTimeLeaveOfAbsence: "0h 0min",
    totalTimeVacation: "0h 0min",
    totalTimeSickLeave: "0h 0min",
    totalTimeSickLeaveChild: "0h 0min",
    totalTimeOther: "0h 0min",
    totalTimeParentalLeave: "0h 0min",
  });
  const [currentTimeTotal, setCurrentTimeTotal] = useState<TimeTotal>();

  const formatDuration = useMemo(
    () => (duration: string) => {
      const durationMoment = moment.duration(duration);
      const totalHours = Math.floor(durationMoment.asHours());
      const minutes = durationMoment.minutes();
      return `${totalHours}h ${minutes}min`;
    },
    []
  );

  useEffect(() => {
    if (companyState.company?.id) {
      const approved = monthlyWorkerWithTimeReports.timeReports.filter(
        (timeReport) => {
          return (
            timeReport.status === TimeReportStatus.APPROVED ||
            timeReport.status === TimeReportStatus.INVOICE_NOT_PAID ||
            timeReport.status === TimeReportStatus.PROCESSING_PAYMENT ||
            timeReport.status === TimeReportStatus.PAYMENT_STARTED ||
            timeReport.status === TimeReportStatus.PAID ||
            (timeReport.status === TimeReportStatus.CLOSED &&
              (timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
                timeReport.resolution === TimeReportResolution.PAID ||
                timeReport.resolution === TimeReportResolution.PAYONDEMAND))
          );
        }
      );
      getCostsTimeReports(
        monthlyWorkerWithTimeReports.timeReports,
        companyState.company.id
      ).then((costs) => setTotalCost(costs));
      getCostsTimeReports(approved, companyState.company.id).then((costs) =>
        setTotalCostApproved(costs)
      );
    }
  }, [monthlyWorkerWithTimeReports, companyState.company?.id]);

  useEffect(() => {
    if (currentTimeTotal) {
      setTimeData({
        totalTime: formatDuration(currentTimeTotal.plannedTime),
        totalTimeApproved: formatDuration(currentTimeTotal.approvedTime),
        totalTimeSubmitted: formatDuration(currentTimeTotal.submittedTime),
        totalTimeLeft: formatDuration(currentTimeTotal.remainingTime),
        totalTimeAbsence: formatDuration(currentTimeTotal.absenceTime),
        totalTimeLeaveOfAbsence: formatDuration(
          currentTimeTotal.absenceDetails.leaveOfAbsence
        ),
        totalTimeVacation: formatDuration(
          currentTimeTotal.absenceDetails.vacation
        ),
        totalTimeSickLeave: formatDuration(
          currentTimeTotal.absenceDetails.sickLeave
        ),
        totalTimeSickLeaveChild: formatDuration(
          currentTimeTotal.absenceDetails.sickLeaveChild
        ),
        totalTimeOther: formatDuration(currentTimeTotal.absenceDetails.other),
        totalTimeParentalLeave: formatDuration(
          currentTimeTotal.absenceDetails.parentalLeave
        ),
      });
    }
  }, [currentTimeTotal]);

  useEffect(() => {
    const companyId = companyState.company?.id;
    if (companyId) {
      Api()
        .company.getTimeTotalMonthlyWorker(
          companyId,
          monthlyWorkerWithTimeReports.worker.workerId,
          year,
          month
        )
        .then((res) => {
          setCurrentTimeTotal(res.data);
        })
        .catch((err) => {
          console.log("Error fetching time total", err);
        });
    }
  }, [companyState.company?.id]);

  return (
    <TimeReportsGridFooter style={{ backgroundColor: Color.MidnightBlue }}>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.Status")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.TotalHours")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.GrossSalaryExcludingOB")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.OB")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.SocialFees")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.SimflexityFee")}
        </Text>
      </HeadCellFooter>
      <HeadCellFooter>
        <Text
          color={Color.White}
          fontSize={FontSize.Standard}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyTimeReport.TotalCost")}
        </Text>
      </HeadCellFooter>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {t("CompanyTimeReport.Sceduled")} {t("CompanyTimeReport.Time")}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {timeData.totalTime}
        </Text>
      </Cell>

      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost.grossSalary.toFixed(2) + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost.obAmount + " kr"}
        </Text>
      </Cell>

      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost.socialFee + " kr"}
        </Text>
      </Cell>

      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost.simflexityFee + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCost.totalCost + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {t("CompanyTimeReport.Approved")} & {t("CompanyTimeReport.Closed")}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {timeData.totalTimeApproved}
        </Text>
      </Cell>

      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {(totalCostApproved.grossSalary + totalCostApproved.obAmount).toFixed(
            2
          ) + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCostApproved.obAmount + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCostApproved.socialFee + " kr"}
        </Text>
      </Cell>

      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCostApproved.simflexityFee + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {totalCostApproved.totalCost + " kr"}
        </Text>
      </Cell>
      <Cell>
        <Text
          color={Color.White}
          fontSize={FontSize.Small}
          fontFamily={FontFamily.MontserratBold}
          onClick={() => setShowAbsenceDetails(!showAbsenceDetails)}
          cursor={true}
        >
          {t("CompanyTimeReport.TotalAbsence")}
        </Text>
      </Cell>
      <Cell>
        <Text color={Color.White} fontSize={FontSize.Small}>
          {timeData.totalTimeAbsence}
        </Text>
      </Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      <Cell></Cell>
      {showAbsenceDetails && (
        <>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.SickLeave")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeSickLeave}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.SickLeaveChild")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeSickLeaveChild}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.Vacation")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeVacation}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.LeaveOfAbsence")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeLeaveOfAbsence}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.ParentalLeave")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeParentalLeave}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {t("CompanyTimeReport.Other")}
            </Text>
          </Cell>
          <Cell>
            <Text color={Color.White} fontSize={FontSize.Small}>
              {timeData.totalTimeOther}
            </Text>
          </Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
        </>
      )}
    </TimeReportsGridFooter>
  );
};
