import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

export const LoaderProgress: React.FC = () => {
  return <IconWrapper><CircularProgress/></IconWrapper>;
};

const IconWrapper = styled.div`
  position: fixed; 
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0; 
  display: grid; 
  place-content: center; 
  pointer-events: none; 
`;