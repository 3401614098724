import React from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CheckBox } from "components/form/inputs/inputs.styled";
import { Text} from "components/Typography/text.styled";
import {
  ButtonContainer,
} from "./timeReportModal.styled";
import { CheckBoxRow, FilterButton, FilterModal } from "./monthlyOverview.styled";
import { CustomIcon } from "components/icon/customIcon.component";
type Props = {
  showFilterModal: boolean;
  setShowFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOnlyDaysWithGigs: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTodaysGigs: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTodaysGigsMarked: React.Dispatch<React.SetStateAction<boolean>>;
  showTodaysGigsMarked: boolean;
  showOnlyDaysWithGigsMarked: boolean;
  setShowOnlyDaysWithGigsMarked: React.Dispatch<React.SetStateAction<boolean>>

};

export const PersonnelCarrierModal: React.FC<Props> = ({
  showFilterModal,
  setShowFilterModal,
  setShowOnlyDaysWithGigs,
  setShowTodaysGigs,
  setShowTodaysGigsMarked,
  showTodaysGigsMarked,
  showOnlyDaysWithGigsMarked,
  setShowOnlyDaysWithGigsMarked
}) => {
  const { t } = useTranslation();


  return (
    <Modal
    open={showFilterModal}
    onClose={() => {
      setShowFilterModal(false);
    }}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <FilterModal>
      <Text
        color={Color.MidnightBlue}
        fontFamily={FontFamily.MontserratSemiBold}
        fontSize={FontSize.H4}
      >
        {t("CompanyTimeReport.FilterContent")}
      </Text>
      <CheckBoxRow>
        <Text
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Standard}
        >
          {t("CompanyTimeReport.ShowGigsToday")}
        </Text>
        <CheckBox
          onClick={() => {
            setShowTodaysGigsMarked(!showTodaysGigsMarked);
          }}
        >
          {showTodaysGigsMarked && (
            <CustomIcon color={Color.BurntSienna} name="checkmark" />
          )}
        </CheckBox>
      </CheckBoxRow>
      <CheckBoxRow>
        <Text
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Standard}
        >
          {t("CompanyTimeReport.ShowOnlyDaysWithGigs")}
        </Text>
        <CheckBox
          onClick={() => {
            setShowOnlyDaysWithGigsMarked(!showOnlyDaysWithGigsMarked);
          }}
        >
          {showOnlyDaysWithGigsMarked && (
            <CustomIcon color={Color.BurntSienna} name="checkmark" />
          )}
        </CheckBox>
      </CheckBoxRow>
      <ButtonContainer>
        <FilterButton
          onClick={() => {
            setShowOnlyDaysWithGigs(false);
            setShowTodaysGigs(false);
            setShowTodaysGigsMarked(false);
            setShowTodaysGigsMarked(false);
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            color={Color.White}
          >
            {t("CompanyTimeReport.Clear")}
          </Text>
        </FilterButton>
        <FilterButton
          onClick={() => {
            setShowTodaysGigs(showTodaysGigsMarked);
            setShowOnlyDaysWithGigs(showOnlyDaysWithGigsMarked);
            setShowFilterModal(false);
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            color={Color.White}
          >
            {t("CompanyTimeReport.ApplyFilters")}
          </Text>
        </FilterButton>
      </ButtonContainer>
    </FilterModal>
  </Modal>
)
};
