import { WorkpoolWorker } from "model/Workpool";

export type WorkpoolActions =
  | {
      type: "GET_ALL_UNVERIFIED_EMPLOYEES";
      payload: WorkpoolWorker[];
    }
  | {
      type: "GET_ALL_VERIFIED_EMPLOYEES";
      payload: WorkpoolWorker[];
    }
  | {
      type: "GET_ALL_DECLINED_EMPLOYEES";
      payload: WorkpoolWorker[];
    }
  | {
      type: "VERIFY_EMPLOYEE";
      payload: WorkpoolWorker;
    }
  | {
      type: "DELETE_EMPLOYEE";
      payload: number;
    }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: WorkpoolState["error"] };

export type WorkpoolState = {
  unverifiedEmployees: WorkpoolWorker[];
  verifiedEmployees: WorkpoolWorker[];
  declinedEmployees: WorkpoolWorker[];

  isLoading: boolean;
  error: { message: string; err: Error } | null;
};

export const workpoolInitState: WorkpoolState = {
  unverifiedEmployees: [],
  verifiedEmployees: [],
  declinedEmployees: [],
  isLoading: false,
  error: null,
};

export const workpoolStateReducer = (
  state: WorkpoolState,
  action: WorkpoolActions
): WorkpoolState => {
  switch (action.type) {
    case "GET_ALL_UNVERIFIED_EMPLOYEES": {
      return {
        ...state,
        unverifiedEmployees: action.payload,
      };
    }
    case "GET_ALL_VERIFIED_EMPLOYEES": {
      return {
        ...state,
        verifiedEmployees: action.payload,
      };
    }
    case "GET_ALL_DECLINED_EMPLOYEES": {
      return {
        ...state,
        declinedEmployees: action.payload,
      };
    }
    case "VERIFY_EMPLOYEE": {
      const newUnverifiedEmployees = state.unverifiedEmployees.filter(
        (employee: WorkpoolWorker) =>
          action.payload.workerId !== employee.workerId
      );
      return {
        ...state,
        verifiedEmployees: [action.payload, ...state.verifiedEmployees],
        unverifiedEmployees: newUnverifiedEmployees,
      };
    }

    case "DELETE_EMPLOYEE": {
      const newUnverifiedEmployees = state.unverifiedEmployees.filter(
        (employee: WorkpoolWorker) => action.payload !== employee.workerId
      );
      const newVerifiedEmployees = state.verifiedEmployees.filter(
        (employee: WorkpoolWorker) => action.payload !== employee.workerId
      );
      return {
        ...state,
        verifiedEmployees: newVerifiedEmployees,
        unverifiedEmployees: newUnverifiedEmployees,
      };
    }

    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
