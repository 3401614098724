export enum CompanyRole {
  NONE = "None",
  SUPER_USER = "SuperUser",
  COMPANY_OWNER = "CompanyOwner",
  COMPANY_ADMIN = "CompanyAdmin",
  COMPANY_BASIC = "CompanyBasic",
  COMPANY_TIMESTAMP = "CompanyTimeStamp",
}

export type CompanyUserDto = {
  id: number;
  firebaseId: string | null;
  role: CompanyRole;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
};
export type UserSkillDto = {
  id: number;
  workerId: number;
  skill: string;
  experience: number;
  deleteEntry: boolean;
};
