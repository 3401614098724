import { Color } from "config/colors";
import styled from "styled-components";

export const UserRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Color.SeaBlue200};
  align-content: flex-end;
`;

export const RoleIconsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-left: 10px;
`;

export const UserInfoColumn = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 10px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  height: 50px;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;

  div {
  }
`;

export const OptionsWrapper = styled.div`
  width: 230px;
  right: 20px;
  background-color: ${Color.White};
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  border-radius: 8px;
`;

export const Option = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid ${Color.SeaBlue200};
  padding: 10px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
