import React, { useEffect, useState } from "react";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import {
  characterCounter,
  CustomLink,
  Text,
} from "components/Typography/text.styled";
import {
  LabelsContainer,
  SettingsButtonContainer,
  SettingsModalBody,
} from "./workpool.styled";
import { WorkpoolWorker } from "model/Workpool";
import { useTranslation } from "react-i18next";
import { CircularProgress, Modal } from "@material-ui/core";
import {
  HourlyRateContainer,
  NumberInputContainer,
} from "../saveGig/hourlyRate/hourlyRate.styled";
import { NumberInput, TextArea } from "components/form/inputs/inputs.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { SkillTag } from "../saveGig/saveGigForm/saveGigForm.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoadingContainer } from "../companySettings/companyUsers/companyUsers.styled";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { TextInput } from "../calendar/template/calendarTemplatePicker.styled";

type Props = {
  employeeData: WorkpoolWorker;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
};

export const WorkpoolWorkerSettings: React.FC<Props> = ({
  employeeData,
  setShowModal,
  showModal,
}) => {
  const [companyState] = useCompanyStore();
  const [workerRate, setWorkerRate] = useState(employeeData.defaultHourlyRate);
  const [comment, setComment] = useState(employeeData.comment || "");
  const [newLabel, setNewLabel] = useState("");
  const [labels, setLabels] = useState<any[]>(
    employeeData.labels ? employeeData.labels : []
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [workpoolState, , workpoolDispatch] = useWorkpoolStore();
  const [, , alertDispatch] = useAlertStore();

  const removeTag = (selectedLabel: any) => {
    setLabels(labels.filter((label) => label.label !== selectedLabel.label));
  };

  useEffect(() => {
    console.log(labels);
  }, [labels]);

  const saveSettings = () => {
    if (companyState.company?.id) {
      const data = {
        companyId: companyState.company?.id,
        workerId: employeeData.workerId,
        defaultHourlyRate: workerRate,
        labels: labels,
        comment: comment,
      };
      setLoading(true);
      console.log(data);
      Api()
        .company.workpool.updateWorkpoolEmployee(companyState.company.id, data)
        .then((res) => {
          setLoading(false);
          setShowModal(false);
          const workers = employeeData.isValidated
            ? workpoolState.verifiedEmployees
            : workpoolState.unverifiedEmployees;
          let newWorkers = workers.map((worker) => {
            if (worker.workerId === employeeData.workerId) {
              console.log("in if");
              return {
                ...worker,
                labels: res.data.labels,
                comment: res.data.comment,
                defaultHourlyRate: res.data.defaultHourlyRate,
              };
            } else return worker;
          });
          workpoolDispatch({
            type: employeeData.isValidated
              ? "GET_ALL_VERIFIED_EMPLOYEES"
              : "GET_ALL_UNVERIFIED_EMPLOYEES",
            payload: newWorkers,
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  useEffect(() => {
    if (showModal) {
      setWorkerRate(employeeData.defaultHourlyRate);
      setComment(employeeData.comment || "");
      setLabels(employeeData.labels ? employeeData.labels : []);
    } else {
      setComment("");
      setWorkerRate(0);
      setLabels([]);
      setNewLabel("");
    }
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <SettingsModalBody>
        {!loading && (
          <>
            <Text
              align="center"
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyGroup.SettingsForWorker", {
                worker: `${employeeData.worker.firstName} ${employeeData.worker.lastName}`,
              })}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              color={Color.SeaBlue600}
              fontSize={FontSize.Small}
              style={{ marginTop: 0, textAlign: "center" }}
            >
              {`(${t("CompanyGroup.OnlyYourCompanyCanSeeThisInfo")})`}
            </Text>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HourlyRateContainer>
                <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                  {t("GigsCompany.HourlyRate")}
                </Text>
                <NumberInputContainer>
                  <NumberInput
                    type="number"
                    name="minRate"
                    color={
                      workerRate === 0 ? Color.SeaBlue200 : Color.MidnightBlue
                    }
                    value={workerRate || ""}
                    onChange={(e) => {
                      setWorkerRate(parseInt(e.currentTarget.value) || 0);
                    }}
                  />
                  <Text
                    fontSize={FontSize.Large}
                    color={Color.SeaBlue600}
                    style={{ marginLeft: "5px" }}
                  >
                    Kr
                  </Text>
                </NumberInputContainer>
              </HourlyRateContainer>
              {workerRate !== 0 ? (
                <div style={{ marginLeft: "15px" }}>
                  <CustomLink
                    fontSize={FontSize.Small}
                    color={Color.Destructive}
                    onClick={() => setWorkerRate(0)}
                  >
                    {t("CompanyGroup.RemoveDefaultRate")}
                  </CustomLink>
                </div>
              ) : (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue500}
                  style={{ marginLeft: "15px" }}
                >
                  {t("CompanyGroup.NoDefaultRate")}
                </Text>
              )}
            </div>

            <HourlyRateContainer>
              <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                {t("CompanyGroup.Labels")}
              </Text>
              <NumberInputContainer>
                <TextInput
                  value={newLabel}
                  onChange={(e) => {
                    setNewLabel(e.target.value);
                  }}
                  placeholder={t("CompanyGroup.AddNewLabel")}
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (newLabel.trim() !== "") {
                        setLabels([...labels, { label: newLabel.trim() }]);
                        setNewLabel("");
                      }
                    }
                  }}
                />
              </NumberInputContainer>
              <CustomIcon
                disabled={newLabel.trim() === ""}
                name="plus_old"
                hover={true}
                backgroundColor={Color.BurntSienna}
                size="38px"
                color={Color.White}
                padding="5px"
                onClick={() => {
                  if (newLabel.trim() !== "") {
                    setLabels([...labels, { label: newLabel.trim() }]);
                    setNewLabel("");
                  }
                }}
              />
            </HourlyRateContainer>
            <LabelsContainer>
              {labels &&
                labels.map((label: any, index) => {
                  return (
                    <SkillTag key={index} style={{ margin: 4 }}>
                      <Text
                        color={Color.White}
                        fontFamily={FontFamily.MontserratSemiBold}
                        fontSize={FontSize.Standard}
                        style={{ margin: 0 }}
                      >
                        {label.label}
                      </Text>
                      <CustomIcon
                        name="cross"
                        size="16px"
                        color={Color.White}
                        onClick={() => {
                          removeTag(label);
                        }}
                      />
                    </SkillTag>
                  );
                })}
            </LabelsContainer>
            <TextArea
              placeholder={t("CompanyGroup.AddComment")}
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {characterCounter(1024, comment)}
            <SettingsButtonContainer>
              <ButtonStyled
                backgroundColor={Color.BurntSienna}
                onClick={() => saveSettings()}
              >
                {t("General.Save")}
              </ButtonStyled>
              <CustomLink
                onClick={() => setShowModal(false)}
                color={Color.Destructive}
              >
                {t("General.Cancel")}
              </CustomLink>
            </SettingsButtonContainer>
          </>
        )}
        {loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
      </SettingsModalBody>
    </Modal>
  );
};
