import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  ButtonsRow,
  CheckmarkContainer,
  Row,
  RowLink,
  TagRow,
} from "./applicationList.styled";
import {
  ApplicantImageWrapper,
  RowWrapper,
  WithdrawOfferModalBody,
} from "../gigApplicants/gigApplicants.styled";
import { SmallImage } from "../gig/gigs.styled";
import { Modal } from "@material-ui/core";
import { getWorkerImg } from "services/firebase/firebase.service";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import moment from "moment";
import { ApplicantDetailsModal } from "../applicantDetails/applicantDetailsModal.component";

type Props = {
  application: ApplicationDto;
  handleShowContract: (application: ApplicationDto) => void;
  selectApplications: boolean;
  setSelectedAppliations: React.Dispatch<
    React.SetStateAction<ApplicationDto[]>
  >;
  selectedApplications: ApplicationDto[];
};

export const ApplicationListItem: React.FC<Props> = ({
  application,
  handleShowContract,
  selectApplications,
  selectedApplications,
  setSelectedAppliations,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [applicantImg, setApplicantImg] = useState<string | null>(null);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [companyState] = useCompanyStore();
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [, , alertDispatch] = useAlertStore();
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (application.worker.firebaseId) {
      getWorkerImg(application.worker.firebaseId).then((res) => {
        setApplicantImg(res);
      });
    }
  }, []);

  useEffect(() => {
    if (selected) {
      setSelectedAppliations([...selectedApplications, application]);
    } else {
      setSelectedAppliations(
        selectedApplications.filter((app) => app.id !== application.id)
      );
    }
  }, [selected]);

  const withdrawOffer = () => {
    const req: ApplicationRequestDto = {
      ...application,
      status: ApplicationStatus.CLOSED,
      resolution: ApplicationResolution.WITHDRAWN,
    };

    if (companyState.company) {
      applicationActions
        .updateApplicationStatus(companyState.company.id, application.id, req)
        .then((res) => {
          setWithdrawModalOpen(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("GigsCompany.Withdrawn"),
              message: t("GigsCompany.OfferWithdrawn"),
            },
          });
        })
        .catch((err) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
        });
    }
  };

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED,
    applicant: ApplicationDto
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: applicationState.applicationsCount - 1,
          });
          if (status === ApplicationStatus.OFFERED) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Offered"),
                message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("ApplicationsCompany.Rejected"),
                message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
              },
            });
          }
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
            },
          });
        });
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("GigsCompany.WithdrawOffer")}
        </Text>

        <RowWrapper>
          <Text
            fontSize={FontSize.Large}
            color={Color.SeaBlue600}
            style={{ paddingTop: 25 }}
          >
            {t("GigsCompany.AreYouSureWithdrawOffer")}
          </Text>
        </RowWrapper>

        <RowWrapper>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setWithdrawModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            onClick={() => {
              withdrawOffer();
            }}
          >
            {t("GigsCompany.WithdrawOffer")}
          </ButtonStyled>
        </RowWrapper>
      </>
    );
  };

  return (
    <>
      <Row>
        <RowLink onClick={() => setShowApplicationDetails(true)}>
          <ApplicantImageWrapper>
            {applicantImg ? (
              <SmallImage src={applicantImg} />
            ) : (
              <CustomIcon
                name="person"
                size="100%"
                color={Color.SeaBlue600}
                padding="10px"
              />
            )}
          </ApplicantImageWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
            style={{ marginLeft: "4px" }}
          >
            {application.worker.firstName} {application.worker.lastName}
          </Text>
          <TagRow>
            <Text
              color={Color.White}
              backgroundColor={Color.SeaBlue600}
              padding
              style={{ marginLeft: "4px", marginRight: "4px" }}
            >
              {`${application.requestedRate} ${t("General.Currency")}`}
            </Text>
            {application.worker.stats &&
              application.worker.stats.some(
                (stat: any) => stat.stat === "Rating"
              ) && (
                <Text
                  color={Color.White}
                  backgroundColor={Color.SeaBlue600}
                  padding
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {application.worker.stats
                    .find((stat: any) => stat.stat === "Rating")
                    .value.toFixed(1) + " / 5"}{" "}
                  <CustomIcon
                    name="star-filled"
                    color={Color.White}
                    size="16px"
                    style={{ marginLeft: "4px" }}
                  />
                </Text>
              )}
          </TagRow>
        </RowLink>

        <RowLink
          onClick={() =>
            history.push(`./applications/${application.gigId}?status=Accepted`)
          }
        >
          <Text
            style={{ textOverflow: "ellipsis" }}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
            color={Color.MidnightBlue}
          >
            {`${moment(application.gig.startTime).format("D/M")} ${
              application.gig.role
            } `}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.Standard}
            color={Color.White}
            backgroundColor={Color.SeaBlue600}
            padding
          >
            {`${application.gig.hiredWorkerCount} / ${
              application.gig.amountOfWorkers
            } ${t("ApplicationsCompany.Hired")}`}
          </Text>
        </RowLink>

        <ButtonsRow>
          <TagRow>
            {handleShowContract && (
              <CustomIcon
                name="document"
                size="40px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={() => {
                  handleShowContract(application);
                }}
                style={{ marginRight: 10 }}
              />
            )}

            <CustomIcon
              name="chat"
              size="40px"
              color={Color.White}
              backgroundColor={Color.BurntSienna}
              padding="10px"
              onClick={() => {
                history.push(
                  `/company/chat?userId=${application.worker.id}&gigId=${application.gigId}&applicationId=${application.id}`
                );
              }}
            />
          </TagRow>
          {application.status === ApplicationStatus.PENDING &&
            !selectApplications && (
              <>
                <CustomIcon
                  name="checkmark"
                  size="34px"
                  color={Color.BurntSienna}
                  onClick={() => {
                    updateApplicationStatus(
                      ApplicationStatus.OFFERED,
                      application
                    );
                  }}
                  style={{
                    flexShrink: 0,
                  }}
                />
                <CustomIcon
                  name="cross"
                  size="34px"
                  color={Color.Destructive}
                  onClick={() => {
                    updateApplicationStatus(
                      ApplicationStatus.CLOSED,
                      application
                    );
                  }}
                  style={{
                    flexShrink: 0,
                  }}
                />
              </>
            )}
          {application.status === ApplicationStatus.PENDING &&
            selectApplications && (
              <CheckmarkContainer onClick={() => setSelected(!selected)}>
                {selected && (
                  <CustomIcon
                    name="checkmark"
                    size="34px"
                    color={Color.BurntSienna}
                    style={{
                      flexShrink: 0,
                    }}
                  />
                )}
              </CheckmarkContainer>
            )}

          {application.status === ApplicationStatus.OFFERED && (
            <Text
              backgroundColor={Color.SeaBlue600}
              color={Color.White}
              fontSize={FontSize.Small}
              padding
            >
              {t("GigsCompany.Offered")}
            </Text>
          )}
          {application.status === ApplicationStatus.CLOSED &&
            application.resolution === ApplicationResolution.ACCEPTED && (
              <Text
                backgroundColor={Color.SeaBlue600}
                color={Color.White}
                fontSize={FontSize.Small}
                padding
              >
                {t("ApplicationsCompany.Hired")}
              </Text>
            )}
          {application.status === ApplicationStatus.CLOSED &&
            (application.resolution === ApplicationResolution.REJECTED ||
              application.resolution === ApplicationResolution.WITHDRAWN ||
              application.resolution === ApplicationResolution.DECLINED) && (
              <>
                <Text
                  color={Color.White}
                  backgroundColor={Color.SeaBlue600}
                  padding
                  fontSize={FontSize.Small}
                >
                  {t(`GigsCompany.${application.resolution}`)}
                </Text>
              </>
            )}

          {application.status === ApplicationStatus.OFFERED && (
            <CustomIcon
              onClick={() => setWithdrawModalOpen(true)}
              name="trashcan"
              size="48px"
              color={Color.Destructive}
              padding="10px"
            />
          )}
        </ButtonsRow>
      </Row>
      <Modal
        open={withdrawModalOpen}
        onClose={() => {
          setWithdrawModalOpen(false);
        }}
      >
        <WithdrawOfferModalBody>{renderModalBody()}</WithdrawOfferModalBody>
      </Modal>
      {showApplicationDetails && (
        <ApplicantDetailsModal
          applicant={application}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
        />
      )}
    </>
  );
};
