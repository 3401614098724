import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

type KeyProps = {
  backgroundColor?: Color | null;
};

export const Key = styled.div<KeyProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:150px;
  height:100px;
  background-color: ${(props) =>
    props.backgroundColor ? (props.backgroundColor) : Color.MidnightBlue};
  margin:5px;
  border-radius: 20px;
  margin-left: 0px;
  pointer: pointer;
`;

export const NumberPadContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 600px;
`;




type FontProps = {
  color?: Color;
  fontSize?: FontSize;
  backgroundColor?: Color;
  fontFamily?: FontFamily;
  padding?: boolean;
  align?: "center" | "left" | "right";
};


export const Text = styled.p<FontProps>`
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratRegular},
    Helvetica;
  text-decoration: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "none"};
  ${(props) => props.padding && "padding: 5px; border-radius: 4px;"}
  ${(props) => props.align && `text-align: ${props.align};`}
`;
