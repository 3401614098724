import styled from "styled-components";
import { Color } from "config/colors";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: -24px;
`;

export const Container = styled.div`
  margin-left: 4px;
  margin-left: 4px;
  width: 32%;
`;

export const ActiveLine = styled.div`
  height: 5px;
  background-color: ${Color.BurntSienna};
  border-radius: 3px;
`;

export const InactiveLine = styled.div`
  height: 5px;
  background-color: ${Color.SeaBlue300};
  border-radius: 3px;
`;
