import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";
import { RegistrationContainer } from "web-apps/company/components/registration/registrationContainer/registrationContainer.component";

export const RegistrationPage = () => {
  const [authState] = useAuthStore();

  return (
    <>
      {authState.accountType !== "Company" && authState.accountType !== null ? (
        <Redirect to="./dashboard" />
      ) : (
        <RegistrationContainer />
      )}
    </>
  );
};
