import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";

import { Text } from "components/Typography/text.styled";
import {
  CompaniesContainer,
  CompanyImage,
  CompanyWrapper,
  SelectWrapper,
} from "./companySelect.styled";
import { useHistory } from "react-router-dom";
import { CustomIcon } from "components/icon/customIcon.component";

export const CompanySelect: React.FC = () => {
  const history = useHistory();
  const [companyState, companyActions] = useCompanyStore();
  const { t } = useTranslation();

  return (
    <>
      {companyState.companies.length > 0 && (
        <SelectWrapper>
          <Text
            fontFamily={FontFamily.LeagueSpartanBold}
            fontSize={FontSize.H2}
          >
            <Text fontFamily={FontFamily.MontserratSemiBold}></Text>
            {t("CompanySelect.YourCompanies")}
          </Text>
          <CompaniesContainer>
            {companyState.companies.map((company, index) => (
              <CompanyWrapper
                key={index}
                onClick={() => {
                  companyActions.switchCompany(company, company.id);
                  history.push("./dashboard");
                }}
              >
                {company.companyImage ? (
                  <CompanyImage src={company.companyImage} />
                ) : (
                  <CustomIcon
                    name="company"
                    size="200px"
                    color={Color.SeaBlue500}
                    backgroundColor={Color.SeaBlue300}
                    padding="4px"
                  />
                )}
                <Text fontFamily={FontFamily.MontserratSemiBold}>
                  {company.companyName}
                </Text>
              </CompanyWrapper>
            ))}
          </CompaniesContainer>
        </SelectWrapper>
      )}
    </>
  );
};
