import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Text } from "components/Typography/text.styled";
import {
  InputContainer,
  TextInput,
} from "components/form/inputs/inputs.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { SuggestionContainer } from "./locationMap.styled";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { GOOGLE_KEY } from "services/baseURLs.config";

type Props = {
  address?: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  inRegistration?: boolean;
  noMap?: boolean;
};

export const LocationMap: React.FC<Props> = ({
  address,
  setAddress,
  setCity,
  setPostalCode,
  setLocation,
  inRegistration,
  noMap,
}) => {
  const [libraries] = useState<["places"]>(["places"]);
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_KEY,
    libraries: libraries,
  });
  const [companyState] = useCompanyStore();
  const [addressString, setAddressString] = useState<string | null>("");
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [validAddress, setValidAddress] = useState(true);

  useEffect(() => {
    if (companyState.company?.location && !inRegistration) {
      const locationArr = companyState.company.location.split(",");
      setLat(Number(locationArr[0]));
      setLng(Number(locationArr[1]));
    }
    if (companyState.company?.address && !inRegistration) {
      setAddressString(
        `${companyState.company.address}, ${companyState.company.city}`
      );
    }
    if (address && inRegistration) {
      setAddressString(address);
    }
    return () => {
      setAddressString(null);
      setLat(null);
      setLng(null);
    };
  }, [companyState.company, address]);

  const handleSelect = (address: any) => {
    geocodeByAddress(address).then((results) => {
      getLatLng(results[0]).then((latlng) => {
        setLat(latlng.lat);
        setLng(latlng.lng);
        setLocation(`${latlng.lat},${latlng.lng}`);

        const streetNumber = results[0].address_components.find(
          (component) => component.types[0] === "street_number"
        );

        let street = results[0].address_components.find(
          (component) => component.types[0] === "route"
        );

        if (
          !streetNumber ||
          streetNumber === undefined ||
          !street ||
          street === undefined
        ) {
          street = results[0].address_components[0];
        }
        let postalTown = results[0].address_components.find(
          (component) => component.types[0] === "postal_town"
        );
        if (!postalTown || postalTown === undefined) {
          postalTown = results[0].address_components.find((element) =>
            element.types.find((type) => type === "locality")
          );
        }
        const code = results[0].address_components.find(
          (component) => component.types[0] === "postal_code"
        );
        if (
          postalTown?.long_name &&
          code?.long_name &&
          latlng.lat &&
          latlng.lng
        ) {
          setValidAddress(true);
          setAddressString(`${results[0].formatted_address}`);
          setAddress(`${street?.long_name} ${streetNumber?.long_name || ""}`);
          setCity(postalTown?.long_name);
          setPostalCode(code?.long_name.replace(/\s+/g, ""));
        } else {
          setValidAddress(false);
        }
      });
    });
  };

  return isLoaded ? (
    <>
      <PlacesAutocomplete
        value={addressString || ""}
        onChange={(e) => setAddressString(e)}
        onSelect={handleSelect}
        highlightFirstSuggestion
      >
        {({ getInputProps, suggestions, loading, getSuggestionItemProps }) => (
          <InputContainer>
            <TextInput
              {...getInputProps({
                placeholder: t("ProfileCompany.LocationPlaceholder"),
              })}
            />
            {!validAddress && (
              <Text color={Color.Destructive}>
                {t("ProfileCompany.InvalidAddress")}
              </Text>
            )}
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div style={{ marginTop: "10px" }}>
                  <CircularProgress />
                </div>
              )}
              {suggestions.map((suggestion, index) => {
                return (
                  <SuggestionContainer
                    {...getSuggestionItemProps(suggestion)}
                    key={index}
                  >
                    <Text
                      color={Color.SeaBlue600}
                      fontFamily={FontFamily.MontserratRegular}
                    >
                      {suggestion.description}
                    </Text>
                  </SuggestionContainer>
                );
              })}
            </div>
          </InputContainer>
        )}
      </PlacesAutocomplete>
      {!noMap && (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "300px",
          }}
          center={{ lat: lat || 59.334591, lng: lng || 18.06324 }}
          zoom={15}
        >
          {lat && lng && (
            <Marker
              title={companyState.company?.companyName || ""}
              position={{ lat: lat, lng: lng }}
            />
          )}
        </GoogleMap>
      )}
    </>
  ) : (
    <>
      <CircularProgress />
    </>
  );
};
