import styled from "styled-components";
import { TabsStyled } from "../../components/tab/tab.styled";

export const Wrapper = styled.div`
  top: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledTabGroup = styled(TabsStyled)`
  margin-bottom: 40px;
`;

