import React from "react";
import { Text } from "components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress } from "@material-ui/core";
import { BannerComponent } from "components/banners/banner.component";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useTranslation } from "react-i18next";
import { Workpool } from "web-apps/company/components/workpool/workpool.component";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";

export const WorkpoolPage = () => {
  const [companyState] = useCompanyStore();
  const [authState] = useCompanyStore();
  const [workpoolState] = useWorkpoolStore();

  const { t } = useTranslation();
  return (
    <div>
      <LoggedInPageContainer>
        {authState.isLoading ||
        workpoolState.isLoading ||
        companyState.isLoading ? (
          <LoggedOutPageContainer>
            <CircularProgress />
          </LoggedOutPageContainer>
        ) : (
          <LoggedInPageContainer>
            {companyState.company?.verified ? (
              <>
                <Workpool />
              </>
            ) : (
              <BannerComponent
                icon="clock"
                backgroundColor={Color.ChampagnePink}
                fill={Color.Destructive}
              >
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.Destructive}
                >
                  {t("GigsCompany.UnverifiedAccount")}
                </Text>
                <Text>{t("GigsCompany.UnverifiedAccountDetails")}</Text>
              </BannerComponent>
            )}
          </LoggedInPageContainer>
        )}
      </LoggedInPageContainer>
    </div>
  );
};
