import React from "react";

import {
  ActiveLine,
  Container,
  InactiveLine,
  Row,
} from "./progressIndicator.styled";

type Props = {
  steps: number;
  currentStep: number;
};

export const ProgressIndicator: React.FC<Props> = (props) => (
  <Row>
    {Array.from(Array(props.steps)).map((step, index) => (
      <Container>
        {props.currentStep >= index + 1 ? <ActiveLine /> : <InactiveLine />}
      </Container>
    ))}
  </Row>
);
