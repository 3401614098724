export enum FontSize {
  H1 = "62px",
  H2 = "44px",
  H3 = "30px",
  H4 = "18px",
  Large = "16px",
  Standard = "14px",
  Small = "12px",
}

export enum FontFamily {
  LeagueSpartanBold = "League Spartan",
  MontserratSemiBold = "Montserrat-SemiBold",
  MontserratBold = "Montserrat-Bold",
  MontserratRegular = "Montserrat-Regular",
}
