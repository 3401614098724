import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { H3 } from "../../../../../components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "../../../../../components/pageLayouts/pageLayouts.styled";
import { useCompanyStore } from "../../../stores/companyStore/companyStore";
import { useAuthStore } from "../../../../../stores/authStore/authStore";
import { FavoriteList } from "../../../components/profile/favorite/favoriteList.component";

export const FavoritePage = () => {
  const [companyState] = useCompanyStore();
  const [authState] = useAuthStore();
  const { t } = useTranslation();

  return (
    <>
      {authState.isLoading || companyState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          <H3>{t("CompanyFavorite.Favorites")}</H3>
          <FavoriteList />
        </LoggedInPageContainer>
      )}
    </>
  );
};
