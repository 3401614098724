import { UserEndorsement } from "./Endorsement";
import { GigType, PaymentMethod } from "./Gig";
import { UserSkillDto } from "./User";

export enum TimeReportStatus {
  UNDEFINED = "Undefined",
  NEW = "New",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  PROCESSING_PAYMENT = "ProcessingPayment",
  INVOICE_NOT_PAID = "InvoiceNotPaid",
  PAYMENT_STARTED = "PaymentStarted",
  PAID = "Paid",
  CLOSED = "Closed",
  CLOSED_BY_COMPANY = "ClosedByCompany",
}

export type SkillAndEndorsements = {
  endorsements: UserEndorsement[];
  skills: UserSkillDto[];
};

export type TimeReportDto = {
  id: number;
  workerId: number;
  gigId: number;
  gigShiftId: number;
  startTime: string;
  endTime: string;
  status: TimeReportStatus;
  userNotes: string | null;
  companyNotes: string | null;
  companyId: number;
  hourlyRate: number;
  resolution: TimeReportResolution;
  gigRole: string;
  gigType: GigType;
  paymentType?: PaymentMethod;
  firstName: string;
  lastName: string;
  isLastTimeReport?: boolean;
  breakStartTime?: string;
  breakEndTime?: string;
  breakTime?: string;
  updatedAt: string;
  checkInTime: string | null;
  checkOutTime: string | null;
};

export type TimeTotal = {
  plannedTime: string;
	approvedTime: string;
	submittedTime: string;
	remainingTime: string;
	absenceTime: string;
	absenceDetails: 
	{
		sickLeave: string;
		sickLeaveChild: string;
		vacation: string;
		leaveOfAbsence: string;
		parentalLeave: string;
		other: string;
	}
}

export type PersonnelCarrierTimeStamps = {
  timeReportId: number;
  gigId: number;
  gigRole: string;
  startTime: string;
  endTime: string;
  checkInTime: string | number | Date;
  checkOutTime: string | number | Date;
  breakTimeMinutes: number;
  status: string;
  resolution: string;
};

export type TimeReportPricingDto = {
  amountWithoutFee?: number;
  currency?: string;
  vat?: number;
  cost?: number;
  fee?: number;
  payroll?: number;
  amount?: number;
  invoiced_amount?: number;
  tax?: number;
  pension?: any;
  healthInsurance?: any;
};

export type TimeReportPaymentDto = {
  timeReportId: number;
  companyId: number;
};
export enum TimeReportResolution {
  UNDEFINED = "Undefined",
  PAID = "Paid",
  TIME_REPORTED = "TimeReported",
  CANCELLED = "Cancelled",
  PAYONDEMAND = "PayOnDemand",
  WITHDRAWN = "Withdrawn",
  SICKLEAVE = "SickLeave",
  SICKLEAVECHILD = "SickLeaveChild",
  VACATION = "Vacation",
  PARENTALLEAVE = "ParentalLeave",
  LEAVEOFABSENCE = "LeaveOfAbsence",
  OVERTIMECOMPENSATION = "OvertimeCompensation",
  FUNERALLEAVE = "FuneralLeave",
  STUDYLEAVE = "StudyLeave",
  LEAVEUNIONASSIGNEMENTS = "LeaveUnionAssignments",
  MILITARYSERVICE = "MilitaryService",
  OTHER = "Other",
}

export type WorkerTimeReportInfo = {
  amount: number;
  firstName: string;
  lastName: string;
  totalHours: number;
  totalMinutes: number;
  totalBreakTimeMinutes: number;
  workerId: number;
};

export type WorkerWithTimeReports = {
  worker: WorkerTimeReportInfo;
  timeReports: TimeReportDto[];
};

export type WorkerWithTimeReportsForPersonnelCarrier = {
  workerId: number;
  firstName: string;
  lastName: string;
  ssn: string;
  stamps: PersonnelCarrierTimeStamps[];
};
