import React, { useState, useEffect } from "react";
import { Color } from "config/colors";
import { CustomLink } from "components/Typography/text.styled";
import { NavIconLi, Badge } from "./navBarMenuList.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { useTranslation } from "react-i18next";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { ApplicationStatus } from "model/Application";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";

type MenuItem = {
  text: string;
  path: string;
  image: string;
};

type Props = {
  menuList: MenuItem[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuItems: React.FC<Props> = ({ menuList, open, setOpen }) => {
  const { t } = useTranslation();
  const [timeReportState] = useTimeReportStore();
  const [applicationState] = useApplicationStore();
  const [workpoolState] = useWorkpoolStore();
  const [unhandledTimereportsLength, setUnhandledTimereportsLength] =
    useState(0);
  const [workpoolApplicaitonsLength, setWorkpoolApplicationsLength] =
    useState(0);

  useEffect(() => {
    setUnhandledTimereportsLength(timeReportState.unhandledTimeReportCount);
    setWorkpoolApplicationsLength(
      workpoolState.unverifiedEmployees.filter(
        (emp) => emp.requestType === "WorkerRequest"
      ).length
    );
  }, [
    timeReportState.unhandledTimeReportCount,
    applicationState.applications,
    workpoolState.unverifiedEmployees,
  ]);

  return (
    <>
      {menuList.map((item, index) => {
        return (
          <NavIconLi
            active={window.location.pathname === item.path}
            key={index}
          >
            <CustomLink
              color={Color.SeaBlue500}
              to={item.path}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CustomIcon
                name={item.image}
                color={Color.SeaBlue500}
                padding="5px"
              />
              {item.text === t("CompanyTimeReport.TimeReports") &&
                unhandledTimereportsLength > 0 && (
                  <Badge>{unhandledTimereportsLength}</Badge>
                )}
              {item.text === t("ApplicationsCompany.Applications") &&
                applicationState.applicationsCount > 0 && (
                  <Badge>{applicationState.applicationsCount}</Badge>
                )}
              {item.text === t("CompanyGroup.Workpool") &&
                workpoolApplicaitonsLength > 0 && (
                  <Badge>{workpoolApplicaitonsLength}</Badge>
                )}
              <p style={{ margin: 0, textAlign: "center" }}>{item.text}</p>
            </CustomLink>
          </NavIconLi>
        );
      })}
    </>
  );
};
