import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

type FontProps = {
  color?: Color;
  fontSize?: FontSize;
  backgroundColor?: Color;
  fontFamily?: FontFamily;
  padding?: boolean;
  align?: "center" | "left" | "right";
  ontSize?: FontSize;
  marginLeft?: number;
  alignSelf?:
    | "auto"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";
};

export const LabelStyled = styled.span<{
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
}>`
  color: ${(props) => (props.color ? props.color : Color.SeaBlue600)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
  width: 100%;
`;


export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextInput = styled.input<FontProps>`
  width: 460px;
  padding: 5px;
  font-size: ${FontSize.H3};
  color: ${Color.White};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  background-color: ${Color.MidnightBlue};
  border-radius: 20px;
  height: 100px;
  padding-left: 20px;
  margin-left: 70px;

  &::placeholder {
    color: ${Color.White};
  }
`;

export const CompanyNameContainer = styled.div`
  text-align: right;
  margin-top: 100px;
  width:100%;
  p {
    margin-right: 100px;
  }
  @media (max-width: 912px) {
    p {
      text-align: center;
      font-size: 18px;
    }
  }
`;

export const InputContainer = styled.div`
  margin-right: 0px;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 70px;
  margin-right: 100px;
  @media (max-width: 912px) {
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 0px;
    p {
      margin-bottom: 0px;
    }
  }
`;

export const DayInfoContainer = styled.div`
  margin-top: 100px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0px;
  }
  @media (max-width: 912px) {
    margin-top: 0px;
    flex-direction: row;
    width: 100%;

    p {
      font-size: 18px;
      margin-bottom: 40px;
      margin-right: 20px;
    }
  }
`;

export const Text = styled.p<FontProps>`
  color: ${(props) => (props.color ? props.color : Color.MidnightBlue)};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratRegular},
    Helvetica;
  text-decoration: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "none"};
  ${(props) => props.padding && "padding: 5px; border-radius: 4px;"}
  ${(props) => props.align && `text-align: ${props.align};`}
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "none")};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : 0)};
`;

export const CheckInOutModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const CheckInChooseTimeModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 400px;
  background-color: ${Color.White};
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  p {
    margin: 0 0 30px 0;
  }
  button {
  height:100px;
  font-size: ${FontSize.H4};
  }
`;


export const GigInfoContainer = styled.div`
  background-color: ${Color.Positive};
  border-radius: 8px;
  padding: 20px;
`;
