import styled from "styled-components";
import { Color } from "config/colors";

export const ChatContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px;
  border: 1px solid ${Color.SeaBlue200};
  background-color: ${Color.White};
  max-height: 300px;
  overflow-y: scroll;
`;

export const ChatContainerListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  list-style-type: none;
  padding: 0;
`;

export const Cell = styled.div`
  position: relative;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  padding: 8px;
  min-width: 250px;

  p {
    margin: 0;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const LatestMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
`;

export const NoChatsContainer = styled.div`
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
