import styled from "styled-components";
import { Color } from "config/colors";

type DrawerProps = {
  open: boolean;
};

export const DrawerStyled = styled.div<DrawerProps>`
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Color.White};
  border-right: 1px solid ${Color.SeaBlue200};
  ${(props) => !props.open && "display: none"};
  z-index: 100;
  scroll-y: scroll;

  @media (min-width: 1000px) {
    display: none;
  }
`;
