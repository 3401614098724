import { Color } from "config/colors";
import styled from "styled-components";

export const SlideShowWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

type SlideShowProps = {
  page: number;
};

export const SlideShow = styled.div<SlideShowProps>`
  display: flex;
  position: absolute;
  top: 0;
  left: ${(props) => props.page * -100}%;
  width: 100%;
  transition: left 0.5s;
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 20px;

  h4 {
    margin-top: 0;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  p {
    &:hover {
      cursor: pointer;
      color: ${Color.SeaBlue400};
    }
  }
`;
