import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

type SelectBoxProps = {
  open: boolean;
  maxHeight?: string;
};

export const Form = styled.form`
  position: relative;
  flex-grow: 1;
`;

export const SelectWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const Select = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FontFamily.LeagueSpartanBold};
  border-radius: 10px;
  color: ${Color.BurntSienna};
  margin-top: 10px;

  p {
    flex-grow: 1;
  }

  &:hover {
    cursor: pointer;
    background-color: ${Color.SeaBlue200};
  }
`;

export const FilledSelect = styled(Select)`
  background-color: ${Color.SeaBlue200};
  padding-left: 20px;
`;

export const SelectBox = styled.div<SelectBoxProps>`
  {${(props) => !props.open && "display: none;"}
  position: absolute;
  top: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: ${Color.White};
  z-index: 1;
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  max-height ${(props) => (props.maxHeight ? props.maxHeight : "400px")};

`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:hover {
    cursor: pointer;
    background-color: ${Color.SeaBlue200};
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.BurntSienna};
  font-family: ${FontFamily.LeagueSpartanBold};
  font-size: ${FontSize.H4};
  flex-grow: 1;
`;

export const SettingsSelect = styled(Select)`
  margin: 0px;
  border-bottom: 1px solid ${Color.SeaBlue200};
  border-radius: 0px;
`;
