export type AlertActions =
  | {
      type: "SHOW_ALERT";
      payload: Omit<AlertState, "show">;
    }
  | { type: "HIDE_ALERT" };

export type AlertState = {
  show: boolean;
  title: string;
  message: string;
  icon?: string;
};

export const initialState = { show: false, title: "", message: "", icon: "" };

export const alertStateReducer = (state: AlertState, action: AlertActions) => {
  switch (action.type) {
    case "SHOW_ALERT": {
      return { ...state, ...action.payload, show: true };
    }
    case "HIDE_ALERT": {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
