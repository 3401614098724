import React, { useEffect, useState } from "react";
import { LocalDateTime, ChronoUnit, Instant, LocalTime } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  HeadCell,
  TimeReportsGrid,
  TimeReportsGridBody,
  TimeReportsGridFooter,
  Cell,
  HeadCellFooter,
  RoleText,
  RoleWrapper,
} from "./monthlyOverview.styled";
import { Text } from "components/Typography/text.styled";
import { addLeadingZero, calculateBreakTime } from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import moment from "moment";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import { GigCosts } from "model/Gig";

type Props = {
  workerWithTimeReports: WorkerWithTimeReports;
  setChosenTimeReportWithWorker: React.Dispatch<
    React.SetStateAction<TimeReportDto | null>
  >;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const WorkerGigTimeReports: React.FC<Props> = ({
  workerWithTimeReports,
  setChosenTimeReportWithWorker,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  let totalTimeMin = 0;
  let totalTimeMinApproved = 0;
  const [companyState] = useCompanyStore();
  const history = useHistory();
  const [totalCost, setTotalCost] = useState<GigCosts>(emptyCosts);
  const [totalCostApproved, setTotalCostApproved] =
    useState<GigCosts>(emptyCosts);

  useEffect(() => {
    if (companyState.company?.id) {
      const approved = workerWithTimeReports.timeReports.filter(
        (timeReport) =>
          timeReport.resolution === TimeReportResolution.PAID ||
          timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
          timeReport.resolution === TimeReportResolution.PAYONDEMAND
      );

      getCostsTimeReports(
        workerWithTimeReports.timeReports,
        companyState.company.id
      ).then((costs) => setTotalCost(costs));
      getCostsTimeReports(approved, companyState.company.id).then((costs) =>
        setTotalCostApproved(costs)
      );
    }
  }, [workerWithTimeReports, companyState.company?.id]);

  const getStatus = (status: TimeReportStatus) => {
    switch (status) {
      case TimeReportStatus.CLOSED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Closed")}</Text>
            <CustomIcon
              name="checkmark"
              color={Color.BurntSienna}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.REJECTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Rejected")}</Text>
            <CustomIcon name="cross" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.PROCESSING_PAYMENT: {
        return (
          <>
            <Text>{t("CompanyTimeReport.ProcessingPayment")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.INVOICE_NOT_PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.InvoiceNotPaid")}</Text>
            <CustomIcon name="bank" color={Color.Destructive} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.NEW: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Unsubmitted")}</Text>
            <CustomIcon name="clock" color={Color.SeaBlue500} padding="4px" />
          </>
        );
      }
      case TimeReportStatus.SUBMITTED: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Submitted")}</Text>
            <CustomIcon
              name="alert"
              color={Color.ChampagnePink}
              padding="4px"
            />
          </>
        );
      }
      case TimeReportStatus.PAID: {
        return (
          <>
            <Text>{t("CompanyTimeReport.Paid")}</Text>
            <CustomIcon name="wallet" color={Color.BurntSienna} padding="4px" />
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <TimeReportsGrid>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Date")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Shift")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ marginLeft: "8px" }}
          >
            {t("CompanyTimeReport.Role")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Break")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Time")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Status")}
          </Text>
        </HeadCell>
        <HeadCell>
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Resolution")}
          </Text>
        </HeadCell>
      </TimeReportsGrid>

      {workerWithTimeReports.timeReports.map((timeReport, timeReportIndex) => {
        const startTime = LocalDateTime.ofInstant(
          Instant.parse(timeReport.startTime)
        );
        const endTime = LocalDateTime.ofInstant(
          Instant.parse(timeReport.endTime)
        );
        let shiftTotalMinutes = startTime.until(endTime, ChronoUnit.MINUTES);

        shiftTotalMinutes -=
          timeReport.breakStartTime && timeReport.breakEndTime
            ? calculateBreakTime(
                LocalTime.ofInstant(
                  Instant.parse(timeReport.breakStartTime.slice(0, 19) + "Z")
                )
                  .toString()
                  .slice(0, 5),
                LocalTime.ofInstant(
                  Instant.parse(timeReport.breakEndTime.slice(0, 19) + "Z")
                )
                  .toString()
                  .slice(0, 5),
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(timeReport.startTime.slice(0, 19) + "Z")
                  ).toString()
                ),
                moment(
                  LocalDateTime.ofInstant(
                    Instant.parse(timeReport.endTime.slice(0, 19) + "Z")
                  ).toString()
                )
              )
            : 0;

        const shiftHours = Math.floor(shiftTotalMinutes / 60);
        const shiftMinutes = shiftTotalMinutes % 60;

        const shiftTimeString = `${
          shiftHours && shiftHours !== 0 ? shiftHours + "h " : ""
        }${shiftMinutes && shiftMinutes !== 0 ? shiftMinutes + "min" : ""}`;
        totalTimeMin += shiftTotalMinutes;
        if (
          timeReport.resolution === TimeReportResolution.PAID ||
          timeReport.resolution === TimeReportResolution.TIME_REPORTED ||
          timeReport.resolution === TimeReportResolution.PAYONDEMAND
        ) {
          totalTimeMinApproved += shiftTotalMinutes;
        }
        const weekDayString = `WeekDays.${startTime.dayOfWeek().name()}SHORT`;

        return (
          <TimeReportsGridBody
            key={timeReportIndex}
            onClick={() => {
              setChosenTimeReportWithWorker(timeReport);
              setModalOpen(true);
            }}
          >
            <Cell>
              <Text fontSize={FontSize.Large}>{`${t(
                weekDayString
              )} ${startTime.dayOfMonth()}/${startTime.monthValue()}`}</Text>
            </Cell>
            <Cell>
              <Text fontSize={FontSize.Large}>
                {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
                  startTime.minute()
                )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
                  endTime.minute()
                )}`}
              </Text>
            </Cell>
            <Cell>
              <RoleWrapper
                onClick={(e) => {
                  e.stopPropagation();

                  if (
                    window.location.href.indexOf(timeReport.gigId.toString()) !=
                    -1
                  ) {
                    history.push(`../gig/${timeReport.gigId}`);
                  } else {
                    history.push(`./gig/${timeReport.gigId}`);
                  }
                }}
              >
                <RoleText fontSize={FontSize.Standard}>
                  {timeReport?.gigRole}
                </RoleText>
                <CustomIcon name="document" color={Color.White} size="20px" />
              </RoleWrapper>
            </Cell>
            <Cell>
              <Text fontSize={FontSize.Large}>
                {timeReport.breakStartTime && timeReport.breakEndTime
                  ? calculateBreakTime(
                      LocalTime.ofInstant(
                        Instant.parse(timeReport.breakStartTime)
                      )
                        .toString()
                        .slice(0, 5),
                      LocalTime.ofInstant(
                        Instant.parse(timeReport.breakEndTime)
                      )
                        .toString()
                        .slice(0, 5),
                      moment(
                        LocalDateTime.ofInstant(
                          Instant.parse(timeReport.startTime)
                        ).toString()
                      ),
                      moment(
                        LocalDateTime.ofInstant(
                          Instant.parse(timeReport.endTime)
                        ).toString()
                      )
                    )
                  : 0}
                {t("General.MinutesShortened")}
              </Text>
            </Cell>
            <Cell>
              <Text fontSize={FontSize.Large}>{shiftTimeString}</Text>
            </Cell>
            <Cell>{getStatus(timeReport.status)} </Cell>
            <Cell>
              {timeReport.resolution !== TimeReportResolution.UNDEFINED && (
                <Text fontSize={FontSize.Large}>
                  {t(`CompanyTimeReport.${timeReport.resolution}`)}
                </Text>
              )}
            </Cell>
          </TimeReportsGridBody>
        );
      })}

      <TimeReportsGridFooter style={{ backgroundColor: Color.MidnightBlue }}>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.Status")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.TotalHours")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.GrossSalaryExcludingOB")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.OB")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.SocialFees")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.SimflexityFee")}
          </Text>
        </HeadCellFooter>
        <HeadCellFooter>
          <Text
            color={Color.White}
            fontSize={FontSize.Standard}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("CompanyTimeReport.TotalCost")}
          </Text>
        </HeadCellFooter>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.All")}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {Math.floor(totalTimeMin / 60) + "h " + (totalTimeMin % 60) + "min"}{" "}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCost.grossSalary + " kr"}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCost.obAmount + " kr"}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCost.socialFee + " kr"}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCost.simflexityFee + " kr"}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCost.totalCost + " kr"}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.Approved")} & {t("CompanyTimeReport.Closed")}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {Math.floor(totalTimeMinApproved / 60) +
              "h " +
              (totalTimeMinApproved % 60) +
              "min"}{" "}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCostApproved.grossSalary + totalCostApproved.obAmount + " kr"}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCostApproved.socialFee + " kr"}
          </Text>
        </Cell>

        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCostApproved.simflexityFee + " kr"}
          </Text>
        </Cell>
        <Cell>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {totalCostApproved.totalCost + " kr"}
          </Text>
        </Cell>
      </TimeReportsGridFooter>
    </>
  );
};
