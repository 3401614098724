import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { FontFamily } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { DetailsContainer, Cell } from "./obSettings.styled";
import { H3, Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { Api } from "services/api/api.service";
import i18n from "services/translation/i18n";
import { ObSettingsForm } from "./obSettingsForm";
import moment from "moment";
import { OBSettingShortWeekDay } from "model/Company";

type Props = {
  view?: string;
};
type displaySettingList = {
  id: number[];
  startTime: string;
  endTime: string;
  weekday: number | null;
  date: string | null;
  companyId: number;
  percentValue: number | null;
  fixedValue: number | null;
};

export const ObSettings: React.FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  const [showObForm, setShowObForm] = useState(false);

  const [companyState, , companyDispatch] = useCompanyStore();
  const [settingList, setSettingList] = useState<displaySettingList[]>([]);
  const [dateList, setDateList] = useState<any[]>([]);
  const [weekList, setWeekList] = useState<any[]>([]);

  const shortWeekDays = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];

  useEffect(() => {
    displayOvernightSetting();
  }, [companyState.obSettings]);

  useEffect(() => {
    sortSettingsList(settingList);
  }, [settingList]);

  const sortSettingsList = (list: displaySettingList[]) => {
    let sorted: Array<displaySettingList[]> = [];
    list.map((setting: displaySettingList) => {
      const sameValueIndex = sorted.findIndex(
        (sortedSetting: displaySettingList[]) => {
          return (
            sortedSetting[0].fixedValue === setting.fixedValue &&
            setting.startTime === sortedSetting[0].startTime &&
            setting.endTime === sortedSetting[0].endTime &&
            setting.weekday !== null &&
            sortedSetting[0].weekday !== null
          );
        }
      );

      if (sameValueIndex !== -1) {
        sorted[sameValueIndex] = [...sorted[sameValueIndex], setting];
      } else {
        sorted.push([setting]);
      }
    });
    const dates = sorted.filter((setting) => setting[0].date !== null);
    setDateList(dates);
    const weekDays = sorted.filter((setting) => setting[0].weekday !== null);
    setWeekList(dates);
    const sortByWeekday = weekDays.map((sameSettingList) => {
      if (sameSettingList[0].weekday !== null) {
        // @ts-ignore: Unreachable code error
        sameSettingList.sort((a, b) => {
          if (a.weekday === 0) return 1;
          if (b.weekday === 0) return -1;
          if (a.weekday !== null && b.weekday !== null) {
            return a.weekday - b.weekday;
          }
        });
        return sameSettingList;
      }
    });

    setWeekList(sortByWeekday);
  };

  const displayOvernightSetting = () => {
    const nightTimeOb: any = [];
    const dayTimeOb: any = [];
    companyState.obSettings.map((currentSetting) => {
      const foundNightTime = companyState.obSettings.find((setting) => {
        const m = moment(currentSetting.endTime, "HH:mm:ss");
        const m2 = moment(setting.startTime, "HH:mm:ss").subtract(2, "seconds");
        const dateBefore = moment(setting.date, "YYYY-MM-DD");
        dateBefore.subtract(1, "days");
        const usingDateOverNight =
          setting.date &&
          dateBefore.format("YYYY-MM-DD") === currentSetting.date;
        return (
          ((setting.weekday !== null &&
            setting.weekday - 1 === currentSetting.weekday) ||
            (setting.weekday !== null &&
              setting.weekday === 0 &&
              currentSetting.weekday === 6) ||
            usingDateOverNight) &&
          (setting.percentValue === currentSetting.percentValue ||
            currentSetting.fixedValue === setting.fixedValue) &&
          m.format("HH:mm:ss") === m2.format("HH:mm:ss")
        );
      });
      if (foundNightTime && foundNightTime !== undefined) {
        nightTimeOb.push({
          id: [foundNightTime.id, currentSetting.id],
          weekday: currentSetting.weekday,
          date: currentSetting.date,
          startTime: currentSetting.startTime,
          endTime: foundNightTime.endTime,
          companyId: currentSetting.companyId,
          fixedValue: currentSetting.fixedValue,
          percentValue: currentSetting.percentValue,
        });
      }
    });

    companyState.obSettings.map((obSetting) => {
      const found = nightTimeOb.find((nightTimeOb: any) =>
        nightTimeOb.id.find((id: number) => id === obSetting.id)
      );
      if (!found || found === undefined) {
        dayTimeOb.push({ ...obSetting, id: [obSetting.id] });
      }
    });

    const newList = dayTimeOb.concat(nightTimeOb);
    setSettingList(newList);
  };

  const deleteObSetting = async (sameSettingsList: displaySettingList[]) => {
    let removedIds: number[] = [];

    await Promise.all(
      sameSettingsList.map(async (setting) => {
        await Promise.all(
          setting.id.map(async (id) => {
            if (companyState.company && setting?.id.length > 0) {
              await Api()
                .company.deleteCompanyObSettings(companyState.company?.id, id)
                .then(() => {
                  if (setting.id.length > 0) {
                    removedIds.push(id);
                  }
                })
                .catch((err) => {
                  console.log(err, err.response);
                });
            }
          })
        );
      })
    );

    const newSettings = companyState.obSettings.filter((currentSetting) => {
      if (currentSetting.id) {
        return !removedIds.includes(currentSetting.id);
      }
    });

    companyDispatch({
      type: "UPDATE_COMPANY_OBSETTINGS",
      payload: newSettings,
    });
  };

  return (
    <>
      <H3>{t("ProfileCompany.OBInconvenientWorkingHours")}</H3>

      <Text>{t("ProfileCompany.ObInfo")}</Text>
      {!showObForm && (
        <>
          <DetailsContainer></DetailsContainer>
          <ButtonStyled
            disabled={false}
            onClick={() => {
              setShowObForm(true);
            }}
          >
            {t("ProfileCompany.AddUnsocialHours")}
          </ButtonStyled>
        </>
      )}
      <ObSettingsForm
        setShowObForm={setShowObForm}
        showObForm={showObForm}
        currentWeekList={weekList}
      />
      {weekList.length > 0 && (
        <Text fontFamily={FontFamily.MontserratSemiBold}>
          {t("ProfileCompany.Weekdays")}
        </Text>
      )}

      {weekList.map((sameSettingsList: displaySettingList[], index) => (
        <Cell key={index}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ width: "300px" }}
          >
            {sameSettingsList.map(
              (setting) =>
                typeof setting.weekday === "number" &&
                t(`WeekDays.${OBSettingShortWeekDay[setting.weekday]}`) + ", "
            )}
          </Text>
          <Text>{`${moment(sameSettingsList[0].startTime, "HH:mm:ss").format(
            "HH:mm"
          )} - ${moment(sameSettingsList[0].endTime, "HH:mm:ss")
            .add(1, "second")
            .format("HH:mm")}`}</Text>
          <Text>
            {sameSettingsList[0].fixedValue
              ? sameSettingsList[0].fixedValue + "Kr"
              : sameSettingsList[0].percentValue + "%"}
          </Text>
          <CustomIcon
            style={{ marginLeft: "10px" }}
            className="icon"
            name="trashcan"
            size="20px"
            color={Color.Destructive}
            onClick={() => {
              deleteObSetting(sameSettingsList);
            }}
          />
        </Cell>
      ))}
      {dateList.length > 0 && (
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          style={{ marginTop: "32px" }}
        >
          {t("ProfileCompany.Dates")}
        </Text>
      )}
      {dateList.map((sameSettingsList: displaySettingList[], index) => (
        <Cell key={index}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ width: "300px" }}
          >
            {`${
              typeof sameSettingsList[0].weekday === "number"
                ? sameSettingsList.map(
                    (setting) =>
                      setting.weekday !== null &&
                      t(`WeekDays.${OBSettingShortWeekDay[setting.weekday]}`)
                  )
                : sameSettingsList[0].date
            } `}
          </Text>
          <Text>{`${moment(sameSettingsList[0].startTime, "HH:mm:ss").format(
            "HH:mm"
          )} - ${moment(sameSettingsList[0].endTime, "HH:mm:ss")
            .add(1, "second")
            .format("HH:mm")}`}</Text>
          <Text>
            {sameSettingsList[0].fixedValue
              ? sameSettingsList[0].fixedValue + "Kr"
              : sameSettingsList[0].percentValue + "%"}
          </Text>
          <CustomIcon
            style={{ marginLeft: "10px" }}
            className="icon"
            name="trashcan"
            size="20px"
            color={Color.Destructive}
            onClick={() => {
              deleteObSetting(sameSettingsList);
            }}
          />
        </Cell>
      ))}
    </>
  );
};
