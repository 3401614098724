import { useTranslation } from "react-i18next";
import { Wrapper } from "./payments.styled";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { SimflexityPayCompany } from "./simflexityPay/simflexityPay";

export const PaymentsCompany = () => {
  const [companyState] = useCompanyStore();

  const { t } = useTranslation();

  const renderTabs = () => {
    return (
      <>
        {companyState.company && (
          <SimflexityPayCompany
            companyId={companyState.company.id.toString()}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Wrapper>{renderTabs()}</Wrapper>
    </>
  );
};
