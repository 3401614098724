import styled from "styled-components";
import { FontSize, FontFamily } from "config/font";
import { Color } from "config/colors";
import { NumberInput, LabelStyled } from "components/form/inputs/inputs.styled";
import { Text } from "components/Typography/text.styled";

type ShiftFormProps = {
  open: boolean;
};

type BreakFormProps = {
  breakFormOpen: boolean;
};

export const LabelMargin = styled(LabelStyled)`
  margin: 0 5%;
`;

export const ShiftFormStyled = styled.div<ShiftFormProps>`
  max-width: 440px;
  border-radius: 10px;
  ${(props) => (!props.open ? "height: 0px;" : "height: 570px;")}
  transition: height 0.4s ease-in-out;
  overflow: hidden;
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  margin: 10px 0;
`;

export const ShiftStyled = styled.div`
  display: flex;
  align-items: center;
  max-width: 440px;
  background-color: ${Color.SeaBlue200};
  padding: 0px 20px;
  border-radius: 10px;
  margin: 10px 0 5px 0;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ShiftInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  flex: 1;
`;

export const TimeInput = styled.input<{
  fontSize?: FontSize;
  fontFamily?: FontFamily;
}>`
  background-color: ${Color.SeaBlue200};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
  border: none;
  padding: 10px;
  width: 100px;
  border-radius: 10px;
  font-family: ${(props) =>
      props.fontFamily
        ? `${props.fontFamily}, Helvetica`
        : FontFamily.MontserratBold},
    Helvetica;
  color: ${Color.MidnightBlue};
  outline: none;
  &:focus {
    border: 1px solid ${Color.BurntSienna};
    background-color: rgba(45, 138, 91, 0.2);
    color: ${Color.BurntSienna};
  }
`;

export const BreakInput = styled(NumberInput)`
  width: 100px;
  background-color: ${Color.SeaBlue200};
  &:focus {
    border: 1px solid ${Color.BurntSienna};
    background-color: rgba(45, 138, 91, 0.2);
    color: ${Color.BurntSienna};
  }
`;

export const DateInput = styled(TimeInput)`
  width: 180px;
  outline: none;
  &:focus {
    border: 1px solid ${Color.BurntSienna};
    background-color: rgba(45, 138, 91, 0.2);
    color: ${Color.BurntSienna};
  }
`;

export const ShiftInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 5%;
  width: 100%;
`;

export const SmallerShiftInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 5%;
  width: 100%;
`;

export const ErrorMessage = styled(Text)`
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  position: absolute;
  top: 35px;
  right: 20px;
  text-align: right;
  color: ${Color.Destructive};
`;

export const MaxShiftError = styled(Text)`
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  text-align: center;
  color: ${Color.Destructive};
`;

export const DatePickerContainer = styled.div`
  .SingleDatePickerInput__withBorder {
    border: none;
  }
  .DateInput_input {
    background-color: ${Color.SeaBlue200};
    border: none;
    font-family: ${FontFamily.MontserratBold};
    color: ${Color.MidnightBlue};
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
  }

  .DateInput_input__focused {
    background-color: ${Color.Positive};
    color: ${Color.BurntSienna};
    border: 1px solid ${Color.BurntSienna};
  }

  .DateInput_input::placeholder {
    color: ${Color.SeaBlue600};
    font-family: ${FontFamily.MontserratBold};
  }
  .DateRangePickerInput__withBorder {
    border: none;
  }

  #SingleDatePicker {
    border-radius: 10px;
  }

  .CalendarDay__selected {
    background: ${Color.BurntSienna};
    color: white;
    border: 1px solid ${Color.BurntSienna};
    font-family: ${FontFamily.MontserratRegular};
  }

  .CalendarDay__selected_span {
    background: ${Color.Positive};
    border: 1px solid ${Color.SeaBlue200};
    color: ${Color.BurntSienna};
    font-family: ${FontFamily.MontserratRegular};
  }

  DayPicker_weekHeader_li {
    font-family: ${FontFamily.MontserratRegular};
  }

  .CalendarDay__default {
    font-family: ${FontFamily.MontserratRegular};
    border-radius: 20px;
    border: none;
  }

  .CalendarDay__hovered_span {
    background: ${Color.Positive};
    color: ${Color.BurntSienna};
  }

  .CalendarDay__blocked_out_of_range {
    background-color: ${Color.SeaBlue200};
    color: ${Color.SeaBlue400};
  }

  .CalendarDay__blocked_calendar {
    background-color: ${Color.SeaBlue200};
    color: ${Color.SeaBlue400};
  }

  small {
    font-family: ${FontFamily.MontserratSemiBold};
    color: ${Color.MidnightBlue};
  }

  strong {
    font-family: ${FontFamily.MontserratSemiBold};
    color: ${Color.MidnightBlue};
  }

  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid ${Color.Positive};
  }

  .DayPickerKeyboardShortcuts_panel {
    font-family: ${FontFamily.MontserratRegular};
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${Color.SeaBlue600};
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${Color.SeaBlue200};
    border-radius: 10px;
  }

  .DayPicker__withBorder {
    border-radius: 10px;
    box-shadow: 0 4px 15px 0 rgb(185 185 185 / 50%);
  }

  .DateInput_fang {
    display: none;
  }

  .DateRangePickerInput_arrow {
    margin-left: 5px;
    margin-right: 5px;
    color: ${Color.SeaBlue600};
  }
`;
export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const BreakContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 5%;
  width: 100%;
  background-color: ${Color.SeaBlue300};
  border-radius: 8px;
`;
export const BreakFormStyled = styled.div<BreakFormProps>`
  max-width: 400px;
  border-radius: 10px;
  ${(props) => (!props.breakFormOpen ? "height: 0px;" : "height: 70px;")}
  transition: height 0.4s ease-in-out;
  overflow: hidden;
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  margin: 10px 0;
  background-color: ${Color.MidnightBlue};
`;

export const TextCenter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
