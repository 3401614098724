import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { Text, CheckInChooseTimeModal } from "./clockStamp.styled";
import { FontFamily, FontSize } from "config/font";
import { Modal } from "@material-ui/core";
import moment from "moment-timezone";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { ButtonContainer } from "../gigApplicants/gigApplicants.styled";
import { TimeReportForStamping } from "model/Company";

type Props = {
  timeReport?: TimeReportForStamping;
  setShowChooseTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckInWithCurrentTime: React.Dispatch<React.SetStateAction<boolean>>;
  showChooseTimeModal: boolean;
  doTheCheckInAndOut: (
    companyId: number,
    timeReportId: number,
    workerPhoneNumber: string,
    checkInWithCurrentTime: boolean
  ) => void;
  telephoneNumber: string;
};

export const ClockStampChooseTimeModal: React.FC<Props> = ({
  timeReport,
  setShowChooseTimeModal,
  setCheckInWithCurrentTime,
  showChooseTimeModal,
  doTheCheckInAndOut,
  telephoneNumber,
}) => {
  const { t } = useTranslation();

  const isShiftCheckOut =
    timeReport?.active?.checkInTime && !timeReport?.active?.checkOutTime;
  const phoneMatches =
    timeReport?.workerPhoneNumber === telephoneNumber.substring(1);

  const handleCheckInOut = (useCurrentTime: boolean) => {
    setCheckInWithCurrentTime(useCurrentTime);
    setShowChooseTimeModal(false);

    if (timeReport && timeReport.active && phoneMatches) {
      const {
        companyId,
        active: { timeReportId, checkInTime, checkOutTime },
      } = timeReport;

      if (companyId && timeReportId && (!checkInTime || !checkOutTime)) {
        doTheCheckInAndOut(
          companyId,
          timeReportId,
          timeReport.workerPhoneNumber,
          useCurrentTime
        );
      }
    }
  };

  const renderShiftTimeText = () => {
    const timeFormat = "HH:mm";
    if (isShiftCheckOut) {
      const endTime = moment.tz(timeReport?.active?.endTime, "UTC")
        .tz("Europe/Stockholm")
        .format(timeFormat);
      return t("StampClock.YourShiftEndsAt", { endTime: endTime });
    } else {
      const startTime = moment.tz(timeReport?.active?.startTime, "UTC")
        .tz("Europe/Stockholm")
        .format(timeFormat);
      return t("StampClock.YourShiftStartsAt", { startTime });
    }
  };

  const renderActionText = () =>
    isShiftCheckOut
      ? t("StampClock.HowDoYouWantToCheckOut")
      : t("StampClock.HowDoYouWantToCheckIn");

  return (
    <Modal
      open={showChooseTimeModal}
      onClose={() => setShowChooseTimeModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <CheckInChooseTimeModal>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H3}
          color={Color.MidnightBlue}
        >
          {isShiftCheckOut
            ? t("StampClock.CheckOutAfterEnd")
            : t("StampClock.CheckInBeforeStart")}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H4}
          color={Color.MidnightBlue}
        >
          {renderShiftTimeText()}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.H4}
          color={Color.MidnightBlue}
        >
          {renderActionText()}
        </Text>
        <ButtonContainer>
          <ButtonStyled
            onClick={() => handleCheckInOut(true)}
            backgroundColor={Color.BurntSienna}
            color={Color.White}
          >
            {t("StampClock.CurrentTime")}
          </ButtonStyled>
          <ButtonStyled
            onClick={() => handleCheckInOut(false)}
            backgroundColor={Color.BurntSienna}
            color={Color.White}
          >
            {isShiftCheckOut
              ? t("StampClock.ShiftEndTime")
              : t("StampClock.ShiftStartTime")}
          </ButtonStyled>
        </ButtonContainer>
      </CheckInChooseTimeModal>
    </Modal>
  );
};
