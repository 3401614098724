import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getWorkerImg } from "services/firebase/firebase.service";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { SmallImage } from "../gig/gigs.styled";
import {
  ApplicantRow,
  ApplicantImageWrapper,
  ApplicantInfoWrapper,
  RowWrapper,
  WithdrawOfferModalBody,
} from "./gigApplicants.styled";
import { Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { ApplicantDetailsModal } from "../applicantDetails/applicantDetailsModal.component";

type Props = {
  applicantData: ApplicationDto;
  checkedApplications?: ApplicationDto[];
  setCheckedApplications?: React.Dispatch<
    React.SetStateAction<ApplicationDto[]>
  >;
  handleShowContract?: (application: ApplicationDto) => void;
  applications?: ApplicationDto[];
  setApplications?: React.Dispatch<React.SetStateAction<ApplicationDto[]>>;
  onWithdraw?: (applicationId: number) => void;
};

export const GigApplicantsListItem: React.FC<Props> = ({
  applicantData,
  checkedApplications,
  setCheckedApplications,
  handleShowContract,
  setApplications,
  applications,
  onWithdraw
}) => {
  const { t } = useTranslation();
  const [applicantImg, setApplicantImg] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [, , alertDispatch] = useAlertStore();
  const [, applicationActions] = useApplicationStore();
  const [companyState] = useCompanyStore();
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (applicantData.worker.firebaseId) {
      getWorkerImg(applicantData.worker.firebaseId).then((res) => {
        setApplicantImg(res);
      });
    }
  }, []);

  useEffect(() => {
    if (isChecked && setCheckedApplications && checkedApplications) {
      setCheckedApplications([...checkedApplications, applicantData]);
    } else if (setCheckedApplications && checkedApplications) {
      const filtered = checkedApplications.filter(
        (application) => application.id !== applicantData.id
      );
      setCheckedApplications(filtered);
    }
  }, [isChecked]);

  const withdrawOffer = () => {
    const req: ApplicationRequestDto = {
      ...applicantData,
      status: ApplicationStatus.CLOSED,
      resolution: ApplicationResolution.WITHDRAWN,
    };

    if (companyState.company) {
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicantData.id, req)
        .then((res) => {
          setWithdrawModalOpen(false);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("GigsCompany.Withdrawn"),
              message: t("GigsCompany.OfferWithdrawn"),
            },
          });

          if (onWithdraw) {
            onWithdraw(applicantData.id);
          }
        })
        .catch((err) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
        });
    }
  };

  const renderModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("GigsCompany.WithdrawOffer")}
        </Text>

        <RowWrapper>
          <Text
            fontSize={FontSize.Large}
            color={Color.SeaBlue600}
            style={{ paddingTop: 25 }}
          >
            {t("GigsCompany.AreYouSureWithdrawOffer")}
          </Text>
        </RowWrapper>

        <RowWrapper>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setWithdrawModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            onClick={() => {
              withdrawOffer();
            }}
          >
            {t("GigsCompany.WithdrawOffer")}
          </ButtonStyled>
        </RowWrapper>
      </>
    );
  };

  return (
    <>
      <ApplicantRow>
        <ApplicantImageWrapper>
          {applicantImg ? (
            <SmallImage src={applicantImg} />
          ) : (
            <CustomIcon
              name="person"
              size="100%"
              color={Color.SeaBlue600}
              padding="10px"
            />
          )}
        </ApplicantImageWrapper>
        <ApplicantInfoWrapper
          onClick={() => {
            setShowApplicationDetails(true);
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {applicantData.worker.firstName} {applicantData.worker.lastName}
          </Text>
          <Text color={Color.White} backgroundColor={Color.SeaBlue600} padding>
            {`${applicantData.requestedRate} ${t("General.Currency")}`}
          </Text>
        </ApplicantInfoWrapper>
        {handleShowContract && (
          <CustomIcon
            name="document"
            size="40px"
            color={Color.White}
            backgroundColor={Color.BurntSienna}
            padding="10px"
            onClick={() => {
              handleShowContract(applicantData);
            }}
            style={{ marginRight: 10 }}
          />
        )}
        {applicantData.status === ApplicationStatus.CLOSED &&
          (applicantData.resolution === ApplicationResolution.REJECTED ||
            applicantData.resolution === ApplicationResolution.WITHDRAWN ||
            applicantData.resolution === ApplicationResolution.DECLINED) && (
            <>
              <Text
                color={Color.White}
                backgroundColor={Color.SeaBlue600}
                padding
              >
                {t(`GigsCompany.${applicantData.resolution}`)}
              </Text>
            </>
          )}
        <CustomIcon
          name="chat"
          size="40px"
          color={Color.White}
          backgroundColor={Color.BurntSienna}
          padding="10px"
          onClick={() => {
            history.push(
              `/company/chat?userId=${applicantData.worker.id}&gigId=${applicantData.gigId}&applicationId=${applicantData.id}`
            );
          }}
        />
        {applicantData.status === ApplicationStatus.PENDING && (
          <CustomIcon
            name="checkmark"
            size="27px"
            color={Color.White}
            backgroundColor={isChecked ? Color.BurntSienna : Color.White}
            padding="2px"
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            style={{
              border: `2px solid ${Color.BurntSienna}`,
              flexShrink: 0,
              marginLeft: "15px",
            }}
          />
        )}
        {applicantData.status === ApplicationStatus.OFFERED && (
          <Text
            backgroundColor={Color.SeaBlue600}
            color={Color.White}
            fontSize={FontSize.Small}
            padding
          >
            {t("GigsCompany.Offered")}
          </Text>
        )}
        {applicantData.status === ApplicationStatus.OFFERED && (
          <CustomIcon
            onClick={() => setWithdrawModalOpen(true)}
            name="trashcan"
            size="48px"
            color={Color.Destructive}
            padding="10px"
          />
        )}

        <Modal
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
          }}
        >
          <WithdrawOfferModalBody>{renderModalBody()}</WithdrawOfferModalBody>
        </Modal>
      </ApplicantRow>
      {showApplicationDetails && (
        <ApplicantDetailsModal
          applicant={applicantData}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
          setApplications={setApplications}
          applications={applications}
        />
      )}
    </>
  );
};
