export type ChatUser = {
  userId: string;
  type: "User" | "Company";
};

export type Message = {
  text: string;
  sender: ChatUser;
  createdAt: string;
  updatedAt: string;
  gigId: string;
  users: ChatUser[];
  read: boolean;
};

export type Summary = {
  chats: ChatSummary[];
};

export type ChatSummary = {
  gigId: string;
  otherUser: ChatUser;
  unread: number;
  lastMessage: Message;
};

export type Chat = {
  gigId: string;
  otherUser: ChatUser;
  messages: Message[];
};

export type ChatActions =
  | {
      type: "GET_SUMMARY";
      payload: Summary;
    }
  | {
      type: "RECENT_MESSAGES";
      payload: Chat;
    }
  | {
      type: "MESSAGE_SENT";
      payload: {
        gigId: string;
        otherUser: ChatUser;
        message: Message;
      };
    }
  | {
      type: "MESSAGES_READ";
      payload: {
        gigId: string;
        otherUser: ChatUser;
      };
    }
  | {
      type: "CLEAR_CHAT_DATA";
    };

export type ChatState = {
  summary: Summary;
  chats: Chat[];
};

export const chatInitState: ChatState = {
  summary: { chats: [] },
  chats: [],
};

export const chatStateReducer = (
  state: ChatState,
  action: ChatActions
): ChatState => {
  switch (action.type) {
    case "GET_SUMMARY": {
      return { ...state, summary: action.payload };
    }
    case "RECENT_MESSAGES": {
      const chatData = action.payload;

      const newChats = state.chats.filter((currentChatData) => {
        if (
          currentChatData.gigId === chatData.gigId &&
          currentChatData.otherUser.userId === chatData.otherUser.userId &&
          currentChatData.otherUser.type === chatData.otherUser.type
        ) {
          return false;
        } else {
          return true;
        }
      });

      newChats.push(chatData);

      return {
        ...state,
        chats: newChats,
      };
    }
    case "MESSAGE_SENT": {
      const { gigId, otherUser, message } = action.payload;
      let exists = false;

      const newChats = state.chats.map((currentChatData) => {
        if (
          currentChatData.gigId === gigId &&
          currentChatData.otherUser.userId === otherUser.userId &&
          currentChatData.otherUser.type === otherUser.type
        ) {
          exists = true;

          return {
            ...currentChatData,
            messages: [message, ...currentChatData.messages],
          };
        } else {
          return currentChatData;
        }
      });

      if (!exists) {
        newChats.push({
          gigId: gigId,
          otherUser: otherUser,
          messages: [message],
        });
      }

      let chatSummaryIndex: number | null = null;

      const newChatsSummary = state.summary.chats.map(
        (currentChatSummary, index) => {
          if (
            currentChatSummary.gigId === gigId &&
            currentChatSummary.otherUser.userId === otherUser.userId &&
            currentChatSummary.otherUser.type === otherUser.type
          ) {
            chatSummaryIndex = index;

            return {
              ...currentChatSummary,
              lastMessage: message,
              unread: currentChatSummary.unread + 1,
            };
          } else {
            return currentChatSummary;
          }
        }
      );

      if (chatSummaryIndex !== null) {
        const updatedChatSummary = newChatsSummary.splice(
          chatSummaryIndex,
          1
        )[0];

        newChatsSummary.unshift(updatedChatSummary);
      } else {
        newChatsSummary.unshift({
          gigId,
          otherUser,
          unread: 1,
          lastMessage: message,
        });
      }

      return {
        ...state,
        summary: {
          ...state.summary,
          chats: newChatsSummary,
        },
        chats: newChats,
      };
    }
    case "MESSAGES_READ": {
      const { gigId, otherUser } = action.payload;

      const newChats = state.chats.map((currentChatData) => {
        if (
          currentChatData.gigId === gigId &&
          currentChatData.otherUser.userId === otherUser.userId &&
          currentChatData.otherUser.type === otherUser.type
        ) {
          return {
            ...currentChatData,
            messages: currentChatData.messages.map((message) => ({
              ...message,
              read: true,
            })),
          };
        } else {
          return currentChatData;
        }
      });

      const newChatsSummary = state.summary.chats.map((currentChatSummary) => {
        if (
          currentChatSummary.gigId === gigId &&
          currentChatSummary.otherUser.userId === otherUser.userId &&
          currentChatSummary.otherUser.type === otherUser.type
        ) {
          return {
            ...currentChatSummary,
            unread: 0,
          };
        } else {
          return currentChatSummary;
        }
      });

      return {
        ...state,
        summary: {
          ...state.summary,
          chats: newChatsSummary,
        },
        chats: newChats,
      };
    }
    case "CLEAR_CHAT_DATA": {
      return chatInitState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
