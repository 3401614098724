import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { ProfileInfo } from "web-apps/company/components/profile/profileInfo.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAuthStore } from "stores/authStore/authStore";

export const ProfilePage = () => {
  const [companyState] = useCompanyStore();
  const [authState] = useAuthStore();

  return (
    <>
      {authState.isLoading || companyState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          <ProfileInfo />
        </LoggedInPageContainer>
      )}
    </>
  );
};
