import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CompanyCalendarGigDto } from "model/Calendar";

const getCalendarGigs = async (
  companyId: number,
  from?: number,
  end?: number,
): Promise<AxiosResponse<CompanyCalendarGigDto[]>> => {
  return await axiosRequest.auth
    .get<CompanyCalendarGigDto[]>(`${API_ROOT}/companies/${companyId}/Calendar/${from}-${end}`)
    .then((res) => res);
};

export const calendar = {
  getCalendarGigs,
};
