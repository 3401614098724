import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Modal } from "@material-ui/core";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Api } from "services/api/api.service";
import { CompanyEmployee } from "model/Employees";
import {
  CompanyEmployeeModalRow,
  EmployeeFormDividerLine,
  EmployeeListColumn,
  ListItemWrapper,
} from "./companyEmployees.styled";
import { NewEmployeeForm } from "./newEmployeeModal.component";
import { SaveContent } from "../calendar/template/calendarTemplatePicker.styled";

type Props = {
  setShowEmployeesModal: React.Dispatch<React.SetStateAction<boolean>>;
  list: CompanyEmployee[];
  setList: React.Dispatch<React.SetStateAction<CompanyEmployee[]>>;
};

export const EmployeeModal: React.FC<Props> = ({ list, setList }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const deleteEndpoint = Api().company.companyEmployees.removeCompanyEmployee;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<CompanyEmployee | null>(
    null
  );

  const handleDelete = (id: number) => {
    if (companyState.company?.id) {
      setIsLoading(true);
      deleteEndpoint(companyState.company?.id, id)
        .then((res) => {
          setIsLoading(false);
          setList(list.filter((item) => item.id !== id));
          setShowDeleteModal(false);
          setUserToDelete(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <CompanyEmployeeModalRow>
          <NewEmployeeForm employees={list} setEmployees={setList} />
          <EmployeeFormDividerLine />
          <EmployeeListColumn>
            {list.reverse().map((item) => (
              <ListItemWrapper>
                <Text fontSize={FontSize.Large}>
                  {`${item.firstName} ${item.lastName}`}
                </Text>
                <CustomIcon
                  name="trashcan"
                  color={Color.Destructive}
                  size={"24px"}
                  onClick={() => {
                    if (item.id) {
                      setUserToDelete(item);
                      setShowDeleteModal(true);
                    }
                  }}
                />
              </ListItemWrapper>
            ))}
          </EmployeeListColumn>
          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <SaveContent>
              <Text
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.H4}
                color={Color.SeaBlue600}
              >
                {t("CompanyEmployees.AreYouSureYouWantToRemoveEmployee", {
                  user: userToDelete?.firstName || "",
                })}
              </Text>
              <Text
                fontFamily={FontFamily.MontserratRegular}
                fontSize={FontSize.Standard}
                color={Color.SeaBlue600}
              >
                {t("CompanyEmployees.ThisEmployeeWillNoLongerHave", {
                  user: userToDelete?.firstName || "",
                })}
              </Text>
              <ButtonStyled
                style={{ marginBottom: "10px" }}
                backgroundColor={Color.Destructive}
                onClick={() => {
                  userToDelete?.id && handleDelete(userToDelete.id);
                }}
              >
                {t("General.Remove")}
              </ButtonStyled>

              <ButtonStyled
                backgroundColor={Color.SeaBlue400}
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                {t("General.Cancel")}
              </ButtonStyled>
            </SaveContent>
          </Modal>
        </CompanyEmployeeModalRow>
      )}
    </>
  );
};
