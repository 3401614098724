import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-right: 10px;
  width: 100%;
  border: none;
  background-color: white;
  color: ${Color.BurntSienna};
  font-family: ${FontFamily.LeagueSpartanBold};
  font-size: ${FontSize.H4};
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    background-color: ${Color.SeaBlue200};
  }

  p {
    white-space: nowrap;
  }
`;

export const IconHolder = styled.div`
  display: flex;
  margin-left: auto;
`;

export const StyledIcon = styled(CustomIcon)`
  margin: 0 4px;
`
