import axios from "axios";
import firebase from "firebase";
import promise from "promise";

let auth = axios.create();
let withoutAuth = axios.create();

auth.interceptors.request.use(
  async function (config) {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  function (error) {
    return promise.reject(error);
  }
);

export const axiosRequest = {
  auth,
  withoutAuth,
};
