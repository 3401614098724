import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import {
  SkillAndEndorsements,
  TimeReportDto,
  WorkerTimeReportInfo,
  WorkerWithTimeReportsForPersonnelCarrier,
} from "model/TimeReport";
import { RatingDto } from "model/Rating";

const getAllTimeReports = (
  companyId: number
): Promise<AxiosResponse<TimeReportDto[]>> => {
  return axiosRequest.auth
    .get<TimeReportDto[]>(`${API_ROOT}/companies/${companyId}/timereports`)
    .then((res) => {
      return res;
    });
};

const updateGigTimeReport = (
  companyId: number,
  gigId: number,
  timeReport: TimeReportDto
): Promise<AxiosResponse<TimeReportDto>> => {
  return axiosRequest.auth
    .put<TimeReportDto>(
      `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports/${timeReport.id}`,
      timeReport
    )
    .then((res) => {
      return res;
    });
};

const rateWorker = async (data: RatingDto): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<RatingDto>(
    `${API_ROOT}/companies/${data.companyId}/rating`,
    data
  );
};

const getWorkerSkillsAndEndorsements = (
  companyId: number,
  timeReportId: number,
  workerId: number
): Promise<AxiosResponse<any>> => {
  return axiosRequest.auth.get<SkillAndEndorsements[]>(
    `${API_ROOT}/companies/${companyId}/timereports/${timeReportId}/WorkerInfo/${workerId}`
  );
};

const getTimeReport = (
  companyId: number,
  gigId: number,
  timeReportId: number
): Promise<AxiosResponse<TimeReportDto>> => {
  return axiosRequest.auth.get<TimeReportDto>(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports/${timeReportId}`
  );
};

const getGigTimeReports = (
  companyId: number,
  gigId: number
): Promise<AxiosResponse<TimeReportDto[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/gigs/${gigId}/timereports`
  );
};

const getUnhandledTimeReportWorkers = (
  companyId: number
): Promise<AxiosResponse<WorkerTimeReportInfo[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/unhandled`
  );
};

const getWorkerUnhandledTimeReports = (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<TimeReportDto[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/unhandledWorker/${workerId}`
  );
};

const getAllTimeReportWorkersMonthly = (
  companyId: number,
  year: number,
  month: number
): Promise<AxiosResponse<WorkerTimeReportInfo[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/all/${year}-${month}`
  );
};

const getAllWorkerTimeReportsMonthly = (
  companyId: number,
  workerId: number,
  year: number,
  month: number
): Promise<AxiosResponse<TimeReportDto[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/all/${workerId}/${year}-${month}`
  );
};


const getAllWorkerPersonnelCarrier = (
  companyId: number,
  year: number,
  month: number,
  day: number,
): Promise<AxiosResponse<WorkerWithTimeReportsForPersonnelCarrier[]>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/personnelCarrier/${year}-${month}-${day}`
  );
};

const getUnhandledTimeReportCount = (
  companyId: number
): Promise<AxiosResponse<any>> => {
  return axiosRequest.auth.get(
    `${API_ROOT}/companies/${companyId}/timereports/unhandled/count`
  );
};

export const timeReport = {
  getAllTimeReports,
  updateGigTimeReport,
  rateWorker,
  getWorkerSkillsAndEndorsements,
  getTimeReport,
  getGigTimeReports,
  getUnhandledTimeReportWorkers,
  getWorkerUnhandledTimeReports,
  getAllTimeReportWorkersMonthly,
  getAllWorkerTimeReportsMonthly,
  getUnhandledTimeReportCount,
  getAllWorkerPersonnelCarrier
};
