import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { companyActions } from "./employeeStore.actions";
import {
  EmployeeActions,
  employeeInitState,
  EmployeeState,
  employeeStateReducer,
} from "./employeeStore.reducer";
import { useAuthStore } from "stores/authStore/authStore";
//import { getEmployeeImg } from "services/firebase/firebase.service";
import { Api } from "services/api/api.service";

export type EmployeeDispatch = (action: EmployeeActions) => void;

const EmployeeStateContext = createContext<EmployeeState | undefined>(
  undefined
);
const EmployeeDispatchContext = createContext<EmployeeDispatch | undefined>(
  undefined
);

export const EmployeeStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(employeeStateReducer, employeeInitState);
  const [, authActions, authDispatch] = useAuthStore();
  const [canActivate, setCanActivate] = useState(false);
  const history = useHistory();

  /*   const saveImagesOnCompanies = (companies: EmployeeDto[]) => {
    const newCompanies = companies.map(async (company: EmployeeDto) => {
      return { ...company, companyImage: await getEmployeeImg(company.id) };
    });
    return Promise.all(newCompanies).then((res) => {
      dispatch({
        type: "UPDATE_EMPLOYEE",
        payload: res,
      });
    });
  }; */

  useEffect(() => {
    console.log("in useEffect");
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      console.log("in useEffect auth changed function");

      setCanActivate(true);
      if (user) {
        console.log("in if user");
        authDispatch({ type: "SET_IS_LOADING", payload: true });
        authActions
          .loginEmailAndPasswordEmployee(user)
          .then((res: any) => {
            if (res.data) {
              console.log(res.data, "if userRes data");
              dispatch({
                type: "UPDATE_EMPLOYEE_INFORMATION",
                payload: res.data,
              });
              //saveImagesOnCompanies(res.data.companies);
            } else {
              console.log("else not res data");
              history.push("/employee/login");
            }
            authDispatch({ type: "SET_IS_LOADING", payload: false });
          })
          .catch(() => {
            authDispatch({ type: "SET_IS_LOADING", payload: false });
          });
      } else {
        console.log("else not user");
        localStorage.removeItem("EmployeeId");
        //localStorage.removeItem("CalendarDate");

        dispatch({ type: "CLEAR_EMPLOYEE_INFORMATION" });
      }
    });
    return () => unsubscribe();
  }, []);

  /*   useEffect(() => {
    if (state.employee?.id) {
      dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

      localStorage.setItem("EmployeeId", JSON.stringify(state.employee.id));
      getEmployeeImg(state.company.id)
        .then((res) => {
          dispatch({
            type: "GET_COMPANY_IMAGE",
            payload: res,
          });
          dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        })
        .catch((e: Error) => {
          console.error("Error when updating employee", e);
          dispatch({
            type: "SET_REQUEST_ERROR",
            payload: { message: "", err: e },
          });
          dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        });
    }
  }, [state.employee]); */

  return (
    <EmployeeStateContext.Provider value={state}>
      <EmployeeDispatchContext.Provider value={dispatch}>
        {canActivate && children}
      </EmployeeDispatchContext.Provider>
    </EmployeeStateContext.Provider>
  );
};

export const useEmployeeStore = () => {
  const state = useContext(EmployeeStateContext);
  const dispatch = useContext(EmployeeDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useEmployeeStore must be used within a EmployeeStoreProvider"
    );
  }

  const actions = companyActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
