export const Roles = [
  "Barista",
  "Bartender",
  "Cafeassistant",
  "Cashier",
  "Cleaner",
  "Conferencehostess",
  "Dishwasher",
  "Driver",
  "Entrancehost",
  "Eventstaff",
  "Homecarestaff",
  "Kitchenassistant",
  "Mover",
  "Receptionist",
  "Runner",
  "Salesrepresentative",
  "Securityguard",
  "Shopassistant",
  "Support",
  "Waiter",
  "Warehouseworker",
];
