import React from "react";
import { LoggedOutPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { SignUp } from "web-apps/company/components/signUp/signUp.component";

export const SignUpPage = () => {
  return (
    <LoggedOutPageContainer>
      <SignUp />
    </LoggedOutPageContainer>
  );
};
