import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import firebase from "services/firebase/firebase.config";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyInfoForm } from "../companyInfoForm/companyInfoForm.component";
import { Confirmation } from "../confirmation/confirmation.component";
//import { MembershipForm } from "../membershipForm/membershipForm.component";
import { ContactForm } from "../contactForm/contactForm.component";
import { Slide, PageSliderStyled } from "./registrationContainer.styled";
import { Text } from "components/Typography/text.styled";
import { CompanyRequestDto } from "model/Company";
import { CreateProfile } from "../createProfile/createProfile.component";

export const RegistrationContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const firebaseUser = firebase.auth().currentUser;
  const [authState, authActions] = useAuthStore();
  const [companyState, companyActions] = useCompanyStore();
  const [page, setPage] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [legalName, setLegalName] = useState("");
  /*   const [collectiveAgreement, setCollectiveAgreement] = useState("");
   */ const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyBio, setCompanyBio] = useState("");
  const [city, setCity] = useState("");
  const [companyAdress, setCompanyAdress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [location, setLocation] = useState("");
  const [error, setError] = useState(false);
  const [prevPage, setPrevPage] = useState(0);

  const register = async () => {
    if (
      companyName &&
      registrationNumber &&
      legalName &&
      contactPersonEmail &&
      contactPersonPhoneNumber &&
      firstName &&
      lastName &&
      city &&
      companyAdress &&
      postalCode &&
      location
    ) {
      const data: CompanyRequestDto = {
        companyName: companyName.trim(),
        legalName: legalName.trim(),
        registrationNumber: registrationNumber.trim().replace("-", ""),
        description: companyBio.trim(),
        address: companyAdress.trim(),
        postalCode: postalCode.trim(),
        error: null,
        city: city.trim(),
        countryId: 1,
        email: firebaseUser?.email?.trim() || "",
        phoneNumber: contactPersonPhoneNumber.replace("+", ""),
        contactPerson: firstName.trim() + " " + lastName.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        websiteUrl: "",
        collectiveAgreement: null,
        location: location,
      };

      companyActions
        .saveCompany(data, companyState.companies)
        .then(() => {
          setPage((prevState) => prevState + 1);
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const updateCompanyDescription = async () => {
    if (companyState.company) {
      const data: CompanyRequestDto = {
        ...companyState.company,
        description: companyBio,
      };

      companyActions
        .saveCompany(data, companyState.companies, companyState.company.id)
        .then((res) => {
          setPage((prevState) => prevState + 1);
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  const handleLogin = () => {
    if (authState.firestoreUser) {
      authActions.loginEmailAndPassword(authState.firestoreUser).then(() => {
        history.push("/company/dashboard");
      });
    }
  };

  const renderSlide = () => {
    switch (page) {
      case 0: {
        return (
          <CompanyInfoForm
            page={page}
            setPage={setPage}
            companyName={companyName}
            setCompanyName={setCompanyName}
            registrationNumber={registrationNumber}
            setRegistrationNumber={setRegistrationNumber}
            legalName={legalName}
            setLegalName={setLegalName}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            city={city}
            setCity={setCity}
            companyAdress={companyAdress}
            setCompanyAdress={setCompanyAdress}
            location={location}
            setLocation={setLocation}
            companyBio={companyBio}
            setCompanyBio={setCompanyBio}
            /*             collectiveAgreement={collectiveAgreement}
            setCollectiveAgreement={setCollectiveAgreement} */
          />
        );
      }
      case 1: {
        return (
          <ContactForm
            page={page}
            setPage={setPage}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            contactPersonEmail={contactPersonEmail}
            setContactPersonEmail={setContactPersonEmail}
            contactPersonPhoneNumber={contactPersonPhoneNumber}
            setContactPersonPhoneNumber={setContactPersonPhoneNumber}
            register={register}
          />
        );
      }
      // case 2: {
      //   return (
      //     <MembershipForm page={page} setPage={setPage} register={register} />
      //   );
      // }
      case 2: {
        return (
          <CreateProfile
            companyBio={companyBio}
            setCompanyBio={setCompanyBio}
            updateCompanyDescription={updateCompanyDescription}
            page={page}
            setPage={setPage}
          />
        );
      }
      case 3: {
        return <Confirmation handleLogin={handleLogin} />;
      }
    }
  };

  useEffect(() => {
    if (firebaseUser?.email) {
      setContactPersonEmail(firebaseUser.email);
    }
  }, [firebaseUser]);

  useEffect(() => {
    setPrevPage(page);

    const bodyEl = document.querySelector("body");
    if (bodyEl) bodyEl.scrollIntoView();
  }, [page]);

  return (
    <>
      {!error ? (
        <TransitionGroup
          component={PageSliderStyled}
          childFactory={(child) =>
            React.cloneElement(child, {
              classNames: `${
                prevPage < page ? "left" : prevPage > page ? "right" : "_"
              } slide`,
            })
          }
        >
          <CSSTransition
            key={page}
            timeout={{ enter: 600, exit: 600 }}
            mountOnEnter={false}
            unmountOnExit={true}
          >
            <Slide className="page">{renderSlide()}</Slide>
          </CSSTransition>
        </TransitionGroup>
      ) : (
        <PageSliderStyled>
          <Slide>
            <Text>{t("RegisterCompany.SomethingWentWrong")}</Text>
          </Slide>
        </PageSliderStyled>
      )}
    </>
  );
};
