import styled from "styled-components";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MessageListStyled = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid ${Color.SeaBlue200};
  border-right: 1px solid ${Color.SeaBlue200};
  padding: 0 10px;
`;

export const MessageListItemStyled = styled.div<{
  backgroundColor: Color;
  alignSelf: "flex-start" | "flex-end";
}>`
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  border-radius: 8px;
  max-width: 60%;
  min-width: 25%;
  align-self: ${(props) => props.alignSelf};
  margin: 10px 0;

  p {
    font-family: ${FontFamily.MontserratRegular};
    word-wrap: break-word;
    max-width: fit-content;
  }
`;

export const TextInput = styled.input`
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.MontserratRegular};
  background-color: ${Color.SeaBlue200};
  border-radius: 20px;
  flex: 1;
  outline: none;
`;

export const SendMessageForm = styled.form`
  display: flex;
  align-items: center;
  padding: 10px 10px 30px 10px;
  border-top: 1px solid ${Color.SeaBlue200};
  border-right: 1px solid ${Color.SeaBlue200};
  border-left: 1px solid ${Color.SeaBlue200};
`;

export const RowWrapper = styled.div`
  display: flex;
`;

export const WorkerImageWrapper = styled.div`
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  margin: 5px;
`;

export const SmallImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const SubmitButton = styled.button`
  background-color: ${Color.White};
  border: none;
  padding: 0;
  border-radius: 50px;
`;

export const ShowApplicantCell = styled.div`
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  p {
    margin-left: 10px;
  }
`;

export const ApplicationContainer = styled.div`
  background-color: ${Color.SeaBlue200};
  margin-top: -8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  margin-bottom: 20px;
`;

export const SkillRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    margin: 2px;
  }
`;
export const Row = styled.div`
  display: flex;
  max-width: 200px;
  justify-content: space-between;
`;

export const ApplicationItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  margin: 10px 0;
  width: 100%;
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ApplicationsModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${Color.White};
  width: 400px;
  padding: 10px;
  outline: none;
  border-radius: 10px;
`;
