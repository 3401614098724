import styled from "styled-components";
import { Color } from "config/colors";

export const NavBarStyled = styled.nav`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid ${Color.SeaBlue200};
  z-index: 99;
  background-color: white;
`;
export const NavBarInnerStyled = styled.nav`
  max-width: 1100px;
  height: 75px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Burger = styled.div`
  margin: 0 20px 0 15px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const LogInWrapper = styled.li`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin: 0 20px 0 15px;

  @media (min-width: 1000px) {
    display: flex;
  }
`;

export const ChatIconWrapper = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const ChatButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
`;

export const Flex1 = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

export const SelectCompanyWrapper = styled.div`
  width: 200px;
  padding: 8px;

  p {
    margin-left: 8px;
  }
`;

export const OptionsWrapper = styled.div`
  width: 230px;
  position absolute;
  right: 20px;
  background-color: ${Color.White};
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  border-radius: 8px;
`;

export const Option = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  border-bottom: 1px solid ${Color.SeaBlue200};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const CompanyOption = styled(Option)`
  background-color: ${Color.SeaBlue200};
  border-bottom: 1px solid ${Color.White};
  flex-wrap: nowrap;

  p {
    white-space: normal;
  }
`;

export const CompanyImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
  margin: 0 8px;
`;
