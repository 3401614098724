import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const EmployeeListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin: 10px 0 50px;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  width: fit-content;
  margin: auto;

  button {
    margin: 0 10px;
  }
`;

export const EmployeeRow = styled.li`
  border-bottom: 1px solid ${Color.SeaBlue200};
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 10px;

  p {
    margin: 10px;
  }

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }
`;

export const EmployeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  padding: 0 20px;

  p {
    margin: 0;
  }
`;

export const EmployeeImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const NoEmployeesContainer = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export const DividerLine = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: ${Color.SeaBlue200};
`;

export const NoApplicantsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PhoneInputWrapper = styled.div`
  margin-bottom: 16px;
  .PhoneInputInput {
    font-family: ${FontFamily.MontserratRegular};
    border: none;
    color: ${Color.MidnightBlue};
    border-bottom: 0.5px solid ${Color.SeaBlue400};
    outline: none;
  }
  ,
  .PhoneInputInput::placeholder {
    color: ${Color.SeaBlue400};
  }
  ,
  .PhoneInput + .PhoneInput--focus {
    outline: none;
  }
  ,
  .PhoneInputCountryIcon--border {
    border: none;
    border-radius: 2px;
    color: ${Color.SeaBlue600};
    border-color: white;
  }
`;
export const FavoritesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  width: 100%;
  padding: 4px 4px 4px 4px;
  border-radius: 8px;
  margin-left: 10px;

  p {
    margin: 0;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const ReferenceCodeButton = styled.div`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
export const SettingsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  div {
    margin-top: 16px;
  }
`;

export const SettingsModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
`;

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
export const SelectRateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  p {
    margin: 0;
    margin-left: 4px;
  }
`;
