import React, { useEffect, useState } from "react";
import { useChatStore } from "web-apps/company/stores/chatStore/chatStore";
import { Chat as ChatType } from "web-apps/company/stores/chatStore/chatStore.reducer";
import { Chat } from "web-apps/company/components/chat/chat.component";
import { CompanyGigUserDto } from "model/Gig";
import { ApplicationDto, UserApplicationDto } from "model/Application";
import { ChatLayout } from "components/pageLayouts/pageLayouts.styled";
import { CustomLink, Text } from "components/Typography/text.styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Color } from "config/colors";
import { useGetGigApplications } from "hooks/useGetGigApplications";
import { ApplicantDetailsModal } from "web-apps/company/components/applicantDetails/applicantDetailsModal.component";
import { RowSpaceBetween } from "web-apps/company/components/timeReport/monthlyOverview.styled";
import { FontFamily, FontSize } from "config/font";
import { Modal } from "@material-ui/core";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  ApplicationItemWrapper,
  ApplicationsModalWrapper,
} from "web-apps/company/components/chat/chat.styled";
export const ChatPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const { userId, gigId } = Object.fromEntries(urlParams);
  const [chatState] = useChatStore();
  const [user, setUser] = useState<
    CompanyGigUserDto | (UserApplicationDto & { firebaseId: string })
  >();
  const [chatData, setChatData] = useState<ChatType | null>(null);
  const history = useHistory();
  const [gigApplications] = useGetGigApplications(chatData?.gigId);
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [showSeveralApplicationsModal, setShowSeveralApplicationsModal] =
    useState(false);
  const [currentApplication, setCurrentApplication] = useState<any>();

  const [matchingApplications, setMatchingApplications] = useState<
    ApplicationDto[]
  >([]);

  useEffect(() => {
    chatState.socket.current?.emit("join_chat", {
      gigId: gigId,
      otherUser: { userId: userId, type: "User" },
    });
  }, [chatState.socket.current, userId, gigId]);

  useEffect(() => {
    const matchingChatData = chatState.state.chats.find(
      (chatData) =>
        chatData.gigId === gigId &&
        chatData.otherUser.userId === userId &&
        chatData.otherUser.type === "User"
    );

    if (matchingChatData) {
      setChatData(matchingChatData);
    }
  }, [chatState.state.chats]);

  useEffect(() => {
    if (chatData) {
      const applications = gigApplications?.filter((application) => {
        return (
          application.worker.id === parseInt(chatData.otherUser.userId) &&
          chatData.otherUser.type === "User" &&
          application.gigId === parseInt(chatData.gigId)
        );
      });

      if (applications) {
        setMatchingApplications(applications);

        if (applications[0]?.worker.firebaseId) {
          setUser({
            ...applications[0].worker,
            firebaseId: applications[0].worker.firebaseId,
          });
        }
      }
    }
  }, [gigApplications, chatData]);

  return (
    <ChatLayout>
      <RowSpaceBetween>
        <CustomLink color={Color.Destructive} onClick={() => history.goBack()}>
          {t("General.Back")}
        </CustomLink>
        {matchingApplications.length > 0 && (
          <CustomLink
            color={Color.BurntSienna}
            fontSize={FontSize.Small}
            onClick={() => {
              if (matchingApplications.length > 1) {
                setShowSeveralApplicationsModal(true);
              } else {
                setCurrentApplication(matchingApplications[0]);
                setShowApplicationDetails(true);
              }
            }}
          >
            {t("Chat.ShowApplicationDetails")}
          </CustomLink>
        )}
      </RowSpaceBetween>

      {user && chatData && (
        <Chat chatData={chatData} user={user} gigId={gigId} userId={userId} />
      )}
      {showApplicationDetails && (
        <ApplicantDetailsModal
          applicant={currentApplication}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
        />
      )}
      {showSeveralApplicationsModal && (
        <Modal
          open={showSeveralApplicationsModal}
          onClose={() => {
            setShowSeveralApplicationsModal(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ApplicationsModalWrapper>
            {matchingApplications.map((application) => (
              <ApplicationItemWrapper
                onClick={() => {
                  setCurrentApplication(application);
                  setShowApplicationDetails(true);
                  setShowSeveralApplicationsModal(false);
                }}
              >
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {`${application.worker.firstName} ${application.worker.lastName}`}
                </Text>

                <Text fontFamily={FontFamily.MontserratRegular}>
                  {t(`ApplicationsCompany.${application.status}`)}
                </Text>
                <CustomIcon
                  name="chevron-right"
                  size="38px"
                  color={Color.BurntSienna}
                  padding="10px"
                />
              </ApplicationItemWrapper>
            ))}
            <CustomLink
              onClick={() => setShowSeveralApplicationsModal(false)}
              color={Color.Destructive}
            >
              {t("General.Close")}
            </CustomLink>
          </ApplicationsModalWrapper>
        </Modal>
      )}
    </ChatLayout>
  );
};
//Kolla vrf chat application inte går att neka
