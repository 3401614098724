import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  InputContainer,
  LabelStyled,
  TextInput,
} from "components/form/inputs/inputs.styled";
import { Text } from "components/Typography/text.styled";
import { CompanyUser, UserRole, UserRoleRequest } from "model/Company";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Color } from "config/colors";
import { validateEmail } from "web-apps/company/utils/utils";
import { FontSize } from "config/font";

export type Position = {
  id: number;
  positionName: string;
};

type Props = {
  setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentUser: CompanyUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<CompanyUser | null>>;
};

export const CompanyUserForm: React.FC<Props> = ({
  setShowAddUserModal,
  currentUser,
  setCurrentUser,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();
  const [firstName, setFirstName] = useState(
    currentUser ? currentUser.firstName : ""
  );
  const [lastName, setLastName] = useState(
    currentUser ? currentUser.lastName : ""
  );
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRoleRequest>(
    // UserRoleRequest.COMPANY_BASIC

    UserRoleRequest.COMPANY_ADMIN
  );
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    getDefaultRole();
  }, [currentUser, companyState.users]);

  useEffect(() => {
    if (email && !validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);

  const validate = () => {
    if (!currentUser && firstName && lastName && email && !emailError && role) {
      return true;
    } else if (currentUser && firstName && lastName) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (currentUser && role && companyState.company) {
      companyActions
        .updateCompanyUser(
          companyState.company?.id,
          currentUser.id,
          currentUser.firebaseId,
          role,
          firstName,
          lastName
        )
        .then(() => {
          setShowAddUserModal(false);
          setCurrentUser(null);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("CompanyUsers.UserCouldNotBeUpdated"),
            },
          });
          setIsLoading(false);
        });
    } else if (validate() && companyState.company) {
      const data = {
        firstName,
        lastName,
        email,
        role,
      };
      companyActions
        .addCompanyUser(companyState.company?.id, data)
        .then(() => {
          setShowAddUserModal(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("CompanyUsers.CouldNotAddUser"),
            },
          });
          setIsLoading(false);
        });
    }
  };

  const getDefaultRole = () => {
    if (currentUser) {
      switch (currentUser.role) {
        case UserRole.COMPANY_OWNER:
          setRole(UserRoleRequest.COMPANY_OWNER);
          break;
        case UserRole.COMPANY_ADMIN:
          setRole(UserRoleRequest.COMPANY_ADMIN);
          break;
        case UserRole.COMPANY_TIMESTAMP:
          setRole(UserRoleRequest.COMPANY_TIMESTAMP);
          break;
        // case UserRole.COMPANY_BASIC:
        //   setRole(UserRoleRequest.COMPANY_BASIC);
        //   break;
        default:
          setRole(UserRoleRequest.COMPANY_ADMIN);
        //setRole(UserRoleRequest.COMPANY_BASIC);
      }
    } else {
      // setRole(UserRoleRequest.COMPANY_BASIC);
      setRole(UserRoleRequest.COMPANY_ADMIN);
    }
  };

  return (
    <>
      <InputContainer>
        <LabelStyled>{t("CompanyUsers.FirstName")}</LabelStyled>

        <TextInput
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
      </InputContainer>
      <InputContainer>
        <LabelStyled>{t("CompanyUsers.LastName")}</LabelStyled>
        <TextInput
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />
      </InputContainer>
      {!currentUser && (
        <InputContainer>
          <LabelStyled>{t("CompanyUsers.Email")}</LabelStyled>
          <TextInput
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          {emailError && (
            <Text fontSize={FontSize.Small} color={Color.Destructive}>
              {t("CompanyUsers.InvalidEmail")}
            </Text>
          )}
        </InputContainer>
      )}

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={role}
        name="radio-buttons-group"
        row
      >
        <FormControlLabel
          value={UserRoleRequest.COMPANY_OWNER.toString()}
          control={
            <Radio
              color="default"
              onClick={() => {
                setRole(UserRoleRequest.COMPANY_OWNER);
              }}
              checked={role === UserRoleRequest.COMPANY_OWNER}
            />
          }
          label={`${t("CompanyUsers.CompanyOwner")}`}
        />
        <FormControlLabel
          value={UserRoleRequest.COMPANY_ADMIN.toString()}
          control={
            <Radio
              color="default"
              onClick={() => {
                setRole(UserRoleRequest.COMPANY_ADMIN);
              }}
              checked={role === UserRoleRequest.COMPANY_ADMIN}
            />
          }
          label={`${t("CompanyUsers.CompanyAdmin")}`}
        />
        {/* <FormControlLabel
          value={UserRoleRequest.COMPANY_BASIC.toString()}
          control={
            <Radio
              color="default"
              onClick={() => {
                setRole(UserRoleRequest.COMPANY_BASIC);
              }}
              checked={role === UserRoleRequest.COMPANY_BASIC}
            />
          }
          label={`${t("CompanyUsers.CompanyBasic")}`}
        /> */}
        <FormControlLabel
          value={UserRoleRequest.COMPANY_TIMESTAMP.toString()}
          control={
            <Radio
              color="default"
              onClick={() => {
                setRole(UserRoleRequest.COMPANY_TIMESTAMP);
              }}
              checked={role === UserRoleRequest.COMPANY_TIMESTAMP}
            />
          }
          label={`${t("CompanyUsers.CompanyStampClock")}`}
        />
      </RadioGroup>
      {isLoading ? (
        <ButtonStyled disabled>
          <CircularProgress style={{ color: Color.BurntSienna }} />
        </ButtonStyled>
      ) : (
        <ButtonStyled disabled={!validate()} onClick={handleSubmit}>
          {t("CompanyUsers.Save")}
        </ButtonStyled>
      )}
    </>
  );
};
