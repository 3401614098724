import { Color } from "config/colors";
import styled, { css } from "styled-components";

type ButtonProps = {
  isDisabled?: boolean;
};

export const WeekDay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.MidnightBlue};
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  margin: 16px 0;
  justify-content: center;
  align-items: center;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  button {
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    margin: 0 8px;
    //margin-right: 8px;
    //padding: 8px;'font
  }
`;
