import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, H3, H4, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyEmployee, ScheduleShift } from "model/Employees";
import moment from "moment";
import { addLeadingZero } from "utils/utils";
import {
  ButtonContainer,
  CalendarGrid,
  CalendarGridContainer,
  CalendarHeaderGrid,
  CircularProgressWrapper,
  GigHeader,
  TitleRow,
  WeekDay,
} from "../calendar/weeklyCalendar.styled";
import {
  CircularProgress,
  FormControlLabel,
  Modal,
  Switch,
} from "@material-ui/core";
import { SaveShiftForm } from "./saveShiftForm.component";
import {
  CloseLinkWrapper,
  EmployeesModalBody,
  SettingsButtonsRow,
  ShiftFormModalBody,
  ViewContainer,
} from "./companyEmployees.styled";
import { StationModal } from "./stationForm.component";
import { CalendarStation } from "./calendarStation.component";
import { EmployeeModal } from "./employeeModal.component";

export const CompanyEmployeesSchedule: React.FC = () => {
  const [companyState] = useCompanyStore();
  const { t } = useTranslation();
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const today = moment();
  const todayWeekNumber = moment().week();
  const [currentDate, setCurrentDate] = useState(today);
  const todayWeekday = currentDate.day();
  const [weekNumber, setWeekNumber] = useState(currentDate.week());
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
  );
  const [currentDates, setCurrentDates] = useState<any[]>([]);
  const [showCreateShiftModal, setShowCreateShiftModal] = useState(false);
  const [clickedDay, setClickedDay] = useState("");
  const [clickedStation, setClickedStation] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showStationModal, setShowStationModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [stations, setStations] = useState<{ id: number; name: string }[]>([]);
  const [roles, setRoles] = useState<{ id: number; role: string }[]>([]);
  const [shiftsByStation, setShiftsByStation] = useState<any[]>([]);
  const [scheduleShifts, setScheduleShifts] = useState<ScheduleShift[]>([]);
  const [showAllScheduleStations, setShowAllScheduleStations] = useState(true);
  const [filteredShiftsByStations, setFilteredShiftsByStations] = useState<
    any[]
  >([]);
  const [showEmployeesModal, setShowEmployeesModal] = useState(false);
  const [companyEmployees, setCompanyEmployees] = useState<CompanyEmployee[]>(
    []
  );

  useEffect(() => {
    fetchScheduleStations();
    fetchScheduleRoles();
    fetchCompanyEmployees();
  }, [currentDates]);

  useEffect(() => {
    fetchScheduleShifts();
  }, [stations]);

  useEffect(() => {
    let sortedShifts: any[] = [];
    stations.forEach((station) => {
      sortedShifts.push({
        station: { ...station },
        shifts: scheduleShifts.filter(
          (shift) => shift.station.id === station.id
        ),
      });
    });
    setShiftsByStation(sortedShifts);
    setFilteredShiftsByStations(
      sortedShifts.filter((stationItem) => stationItem.shifts.length !== 0)
    );
  }, [scheduleShifts]);

  const createDateNumbers = () => {
    const firstDate = currentDates[0].date;
    const lastDate = currentDates[6].date;

    const start = `${firstDate.year()}${addLeadingZero(
      firstDate.month() + 1
    )}${addLeadingZero(firstDate.date())}`;
    const end = `${lastDate.year()}${addLeadingZero(
      lastDate.month() + 1
    )}${addLeadingZero(lastDate.date())}`;
    return { start: parseInt(start), end: parseInt(end) };
  };

  const fetchScheduleStations = () => {
    if (companyState.company?.id && currentDates.length > 0) {
      setIsLoading(true);
      Api()
        .company.companyEmployees.getCompanyScheduleStations(
          companyState.company.id
        )
        .then((res) => {
          setIsLoading(false);
          setStations(res.data);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING SCHEDULE STATIONS", err);
        });
    }
  };
  const fetchScheduleShifts = () => {
    if (companyState.company?.id && currentDates.length > 0) {
      setIsLoading(true);
      Api()
        .company.companyEmployees.getCompanyScheduleShifts(
          companyState.company.id,
          createDateNumbers().start,
          createDateNumbers().end
        )
        .then((res) => {
          setIsLoading(false);
          setScheduleShifts(res.data);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING SCHEDULE SHIFTS", err);
        });
    }
  };
  const fetchScheduleRoles = () => {
    if (companyState.company?.id && currentDates.length > 0) {
      setIsLoading(true);
      Api()
        .company.companyEmployees.getCompanyScheduleRoles(
          companyState.company.id
        )
        .then((res) => {
          setIsLoading(false);
          setRoles(res.data);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING SCHEDULE SHIFTS", err);
        });
    }
  };

  const fetchCompanyEmployees = () => {
    if (companyState.company?.id && currentDates.length > 0) {
      setIsLoading(true);
      Api()
        .company.companyEmployees.getCompanyEmployees(companyState.company.id)
        .then((res) => {
          setIsLoading(false);
          setCompanyEmployees(res.data);
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING SCHEDULE SHIFTS", err);
        });
    }
  };

  const handleClickDay = (day: any) => {
    const today = moment().set({ hour: 0, minutes: 0, seconds: 0 });
    if (day.date >= today) {
      setClickedDay(day.date.format("YYYY-MM-DD"));
      setShowCreateShiftModal(true);
    }
  };

  const handleClickCreateGig = () => {
    const today = moment().set({ hour: 0, minutes: 0, seconds: 0 });
    if (currentDates[0].date >= today) {
      setClickedDay(currentDates[0].date.format("YYYY-MM-DD"));
      setShowCreateShiftModal(true);
    } else {
      setClickedDay(moment(today).format("YYYY-MM-DD"));
      setShowCreateShiftModal(true);
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "CalendarDate",
      moment(currentDate).format("YYYY-MM-DD")
    );
    setWeekNumber(currentDate.week());
    setFirstDayOfWeek(
      moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
    );
  }, [currentDate]);

  useEffect(() => {
    let dates: any[] = [];
    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(moment(firstDayOfWeek).add(x, "days")),
        weekDay: weekdayList[x],
      });
    }
    setCurrentDates(dates);
  }, [firstDayOfWeek]);

  const resetStateVariables = () => {
    setCurrentDates([]);
  };
  const clickPrevWeek = () => {
    setCurrentDate(moment(currentDate.subtract(7, "days")));
    resetStateVariables();
  };

  const clickNextWeek = () => {
    setCurrentDate(moment(currentDate.add(7, "days")));
    resetStateVariables();
  };

  const clickToday = () => {
    setCurrentDate(moment(today));
  };

  return (
    <>
      <TitleRow>
        <ButtonContainer>
          <ButtonStyled
            color={Color.White}
            backgroundColor={Color.MidnightBlue}
            onClick={handleClickCreateGig}
          >
            {t("CalendarCompany.Create")}
          </ButtonStyled>
        </ButtonContainer>
        <CustomIcon
          name="chevron-left"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickPrevWeek();
          }}
        />
        <Text
          fontSize={FontSize.H4}
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
        >{`${t("CalendarCompany.Week")} ${weekNumber}`}</Text>

        <Text
          fontSize={FontSize.Standard}
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratRegular}
        >{`${moment(firstDayOfWeek).format("DD MMM")} - ${moment(
          moment(firstDayOfWeek).add(6, "days")
        ).format("DD MMM")}   ${moment(firstDayOfWeek).format("YYYY")} ${
          moment(moment(firstDayOfWeek).add(6, "days")).format("YYYY") !==
          moment(firstDayOfWeek).format("YYYY")
            ? "/" + moment(firstDayOfWeek).add(6, "days").format("YYYY")
            : ""
        }`}</Text>
        <CustomIcon
          name="chevron-right"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickNextWeek();
          }}
        />
        <ButtonContainer>
          <ButtonStyled
            color={Color.White}
            backgroundColor={Color.MidnightBlue}
            disabled={
              todayWeekNumber === weekNumber &&
              today.format("YYYY") === currentDate.format("YYYY")
                ? true
                : false
            }
            onClick={clickToday}
          >
            {t("CalendarCompany.Today")}
          </ButtonStyled>
        </ButtonContainer>
      </TitleRow>
      <ViewContainer>
        <>
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                color="default"
                onChange={() =>
                  setShowAllScheduleStations(!showAllScheduleStations)
                }
              />
            }
            label={
              showAllScheduleStations
                ? t("CompanyEmployees.ShowingAllStations")
                : t("CompanyEmployees.ShowingStationsWithShifts")
            }
            style={{ color: Color.MidnightBlue, fontSize: FontSize.Small }}
          />
          <SettingsButtonsRow>
            <ButtonStyled
              onClick={() => setShowEmployeesModal(true)}
              icon={true}
              fontSize={FontSize.Small}
              backgroundColor={Color.MidnightBlue}
              lessPadding
            >
              {t("CompanyEmployees.CompanyEmployees")}
              <CustomIcon name="person" size="20px" color={Color.White} />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => setShowStationModal(true)}
              icon={true}
              fontSize={FontSize.Small}
              backgroundColor={Color.MidnightBlue}
              lessPadding
            >
              {t("CompanyEmployees.Stations")}

              <CustomIcon name="settings" size="20px" color={Color.White} />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => setShowRoleModal(true)}
              icon={true}
              fontSize={FontSize.Small}
              backgroundColor={Color.MidnightBlue}
              lessPadding
            >
              {t("CompanyEmployees.Roles")}
              <CustomIcon name="settings" size="20px" color={Color.White} />
            </ButtonStyled>
          </SettingsButtonsRow>
        </>
      </ViewContainer>
      <CalendarHeaderGrid>
        <GigHeader>
          <Text
            fontSize={FontSize.Standard}
            color={Color.MidnightBlue}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            Station
          </Text>
        </GigHeader>
        {currentDates.map((date: any) => (
          <WeekDay
            key={date.weekDay}
            future={
              date.date >= moment().set({ hour: 0, minutes: 0, seconds: 0 })
                ? true
                : false
            }
            today={
              date.date.format("DD-MM-YYYY") === today.format("DD-MM-YYYY")
                ? true
                : false
            }
          >
            <Text
              fontSize={FontSize.Standard}
              color={Color.White}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {date.weekDay}
            </Text>
            <Text fontSize={FontSize.Small} color={Color.White}>
              {moment(date.date).format("D/M")}
            </Text>
            <CustomIcon
              id="plusIcon"
              name="plus_old"
              color={Color.White}
              size="20px"
            />
          </WeekDay>
        ))}
      </CalendarHeaderGrid>
      {!isLoading ? (
        <CalendarGridContainer>
          <CalendarGrid>
            {(showAllScheduleStations
              ? shiftsByStation
              : filteredShiftsByStations
            ).map((item, index) => (
              <CalendarStation
                setShowCreateShiftModal={setShowCreateShiftModal}
                setClickedStation={setClickedStation}
                setClickedDay={setClickedDay}
                shiftsByStation={item}
                stationIndex={index}
                currentDates={currentDates}
                roles={roles}
                stations={stations}
                scheduleShifts={scheduleShifts}
                setScheduleShifts={setScheduleShifts}
              />
            ))}
          </CalendarGrid>
          {scheduleShifts.length === 0 && (
            <Text
              style={{ textAlign: "center" }}
              fontSize={FontSize.Standard}
              color={Color.SeaBlue600}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CalendarCompany.NoShiftsThisWeek")}
            </Text>
          )}
        </CalendarGridContainer>
      ) : (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}
      <Modal
        open={showCreateShiftModal}
        onClose={() => {
          setShowCreateShiftModal(false);
        }}
      >
        <ShiftFormModalBody>
          <CloseLinkWrapper>
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowCreateShiftModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </CloseLinkWrapper>

          <H3>{t("CompanyEmployees.CreateShift")}</H3>
          <SaveShiftForm
            chosenDate={clickedDay}
            chosenStation={clickedStation}
            setShowCreateShiftModal={setShowCreateShiftModal}
            stations={stations}
            roles={roles}
            scheduleShifts={scheduleShifts}
            setScheduleShifts={setScheduleShifts}
            currentDates={currentDates}
          />
        </ShiftFormModalBody>
      </Modal>
      <Modal
        open={showStationModal}
        onClose={() => {
          setShowStationModal(false);
        }}
      >
        <ShiftFormModalBody>
          <CloseLinkWrapper>
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowStationModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </CloseLinkWrapper>
          <H3>{t("CompanyEmployees.Stations")}</H3>
          <StationModal type="station" list={stations} setList={setStations} />
        </ShiftFormModalBody>
      </Modal>
      <Modal
        open={showRoleModal}
        onClose={() => {
          setShowRoleModal(false);
        }}
      >
        <ShiftFormModalBody>
          <CloseLinkWrapper>
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowRoleModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </CloseLinkWrapper>
          <H3>{t("CompanyEmployees.Roles")}</H3>
          <StationModal type="role" list={roles} setList={setRoles} />
        </ShiftFormModalBody>
      </Modal>
      <Modal
        open={showEmployeesModal}
        onClose={() => {
          setShowEmployeesModal(false);
        }}
      >
        <EmployeesModalBody>
          <CloseLinkWrapper>
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowEmployeesModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </CloseLinkWrapper>
          <H3>{t("CompanyEmployees.CompanyEmployees")}</H3>
          <EmployeeModal
            list={companyEmployees}
            setList={setCompanyEmployees}
            setShowEmployeesModal={setShowEmployeesModal}
          />
        </EmployeesModalBody>
      </Modal>
    </>
  );
};
