import { AxiosResponse } from "axios";
import { WorkpoolState } from "./workpoolStore.reducer";
import { WorkpoolDispatch } from "./workpoolStore";
import { Api } from "services/api/api.service";
import { WorkpoolWorker } from "model/Workpool";

const verifyEmployee =
  (dispatch: WorkpoolDispatch) =>
  async (
    companyId: number,
    workerData: WorkpoolWorker
  ): Promise<AxiosResponse<any> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workpool.verifyWorkpoolEmployee(companyId, workerData.workerId)

      .then((res: any) => {
        dispatch({
          type: "VERIFY_EMPLOYEE",
          payload: {
            ...workerData,
            isValidated: true,
            requestType: workerData.requestType,
          },
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating employee status", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const deleteEmployee =
  (dispatch: WorkpoolDispatch) =>
  async (
    companyId: number,
    workerId: number
  ): Promise<AxiosResponse<any> | void> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workpool.deleteWorkpoolEmployee(companyId, workerId)
      .then((res: any) => {
        console.log(res);
        dispatch({
          type: "DELETE_EMPLOYEE",
          payload: workerId,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating employee status", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

const addNewEmployee =
  (dispatch: WorkpoolDispatch, state: WorkpoolState) =>
  async (companyId: number, data: any): Promise<AxiosResponse<any>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = await Api()
      .company.workpool.addEmployee(companyId, data)
      .then((res: any) => {
        const newUnverified = state.unverifiedEmployees.filter(
          (emp) => emp.workerId !== res.data.workerId
        );
        dispatch({
          type: "GET_ALL_UNVERIFIED_EMPLOYEES",
          payload: [...newUnverified, res.data],
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating employee status", e);
        dispatch({ type: "SET_IS_LOADING", payload: false });
        return Promise.reject();
      });
    return res;
  };

export const workpoolActions = (
  dispatch: WorkpoolDispatch,
  state: WorkpoolState
) => ({
  verifyEmployee: verifyEmployee(dispatch),
  deleteEmployee: deleteEmployee(dispatch),
  addNewEmployee: addNewEmployee(dispatch, state),
});
