import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";
import { FavoriteWorkerWrapper } from "../profile/favorite/favoriteList.styled";
import { EmployeeImageWrapper } from "../workpool/workpool.styled";

export const FormWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
`;

export const InputWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  margin-bottom: 10px;
  width: 300px;
`;

export const LabelStyled = styled.span<{
  color?: Color;
  fontFamily?: FontFamily;
  fontSize?: FontSize;
}>`
  color: ${(props) => (props.color ? props.color : Color.SeaBlue600)};
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : FontFamily.MontserratSemiBold},
    Helvetica;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : FontSize.Standard};
`;

export const Cell = styled(FavoriteWorkerWrapper)`
  align-items: center;
  p {
    margin: 0;
  }
`;

export const ImageWrapper = styled(EmployeeImageWrapper)`
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  margin-right: 10px;
`;

export const ShiftFormModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }

  h3 {
    margin: 20px 0 20px 0;
  }
`;

export const EmployeesModalBody = styled(ShiftFormModalBody)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 690px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  outline: none;
  border-radius: 8px;
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  max-height: 48px;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 6px;
  border-bottom: 1px solid ${Color.SeaBlue300};
  p {
    margin: 0;
  }
`;

export const CompanyEmployeeModalRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  /*   height: 50px;
  padding: 6px;
  border-bottom: 1px solid ${Color.SeaBlue300};
  p {
    margin: 0;
  } */
`;

export const EmployeeListColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 400px;
  overflow-y: auto;
`;

export const EmployeeFormDividerLine = styled.div`
  height: 400px;
  width: 2px;
  background-color: ${Color.Positive};
  margin: 0 20px 0 20px;
`;

export const SettingsButtonsRow = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-around;
  align-items: center;
`;

export const CloseLinkWrapper = styled.div`
  width: 100%;
  text-align: right;
  div {
    margin: 0;
  }
`;
