import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { companyActions } from "./companyStore.actions";
import {
  CompanyActions,
  companyInitState,
  CompanyState,
  companyStateReducer,
} from "./companyStore.reducer";
import { useAuthStore } from "stores/authStore/authStore";
import { getCompanyImg } from "services/firebase/firebase.service";
import { Api } from "services/api/api.service";
import { CompanyDto } from "model/Company";

export type CompanyDispatch = (action: CompanyActions) => void;

const CompanyStateContext = createContext<CompanyState | undefined>(undefined);
const CompanyDispatchContext = createContext<CompanyDispatch | undefined>(
  undefined
);

export const CompanyStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(companyStateReducer, companyInitState);
  const [authState, authActions, authDispatch] = useAuthStore();
  const [canActivate, setCanActivate] = useState(false);
  const history = useHistory();

  const saveImagesOnCompanies = (companies: CompanyDto[]) => {
    const newCompanies = companies.map(async (company: CompanyDto) => {
      return { ...company, companyImage: await getCompanyImg(company.id) };
    });
    return Promise.all(newCompanies).then((res) => {
      dispatch({
        type: "UPDATE_COMPANIES",
        payload: res,
      });
    });
  };

  useEffect(() => {
    if (authState.accountType !== "Employee" && !state.company?.id) {
      const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
        setCanActivate(true);
        if (user) {
          authDispatch({ type: "SET_IS_LOADING", payload: true });
          authActions
            .loginEmailAndPassword(user)
            .then((res: any) => {
              if (res.data) {
                dispatch({
                  type: "UPDATE_COMPANY_USER",
                  payload: res.data.companyUser,
                });

                const companyId = localStorage.getItem("CompanyId");
                if (companyId) {
                  dispatch({
                    type: "UPDATE_COMPANY_INFORMATION",
                    payload: {
                      ...res.data.companies.find(
                        (company: CompanyDto) =>
                          company.id === parseInt(companyId)
                      ),
                    },
                  });
                } else if (res.data.companies.length === 1) {
                  dispatch({
                    type: "UPDATE_COMPANY_INFORMATION",
                    payload: { ...res.data.companies[0] },
                  });
                }
                saveImagesOnCompanies(res.data.companies);
              } else {
                history.push("/company/registration"); //Här pushas till registration efter lagt till employee?
              }
              authDispatch({ type: "SET_IS_LOADING", payload: false });
            })
            .catch(() => {
              authDispatch({ type: "SET_IS_LOADING", payload: false });
            });
        } else {
          localStorage.removeItem("CompanyId");
          localStorage.removeItem("CalendarDate");

          dispatch({ type: "CLEAR_COMPANY_INFORMATION" });
        }
      });
      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (state.company?.id) {
      dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

      localStorage.setItem("CompanyId", JSON.stringify(state.company.id));
      getCompanyImg(state.company.id)
        .then((res) => {
          dispatch({
            type: "GET_COMPANY_IMAGE",
            payload: res,
          });
          dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        })
        .catch((e: Error) => {
          console.error("Error when updating company", e);
          dispatch({
            type: "SET_REQUEST_ERROR",
            payload: { message: "", err: e },
          });
          dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        });
    }
  }, [state.company]);

  useEffect(() => {
    if (state.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.getCompanyFavorites(state.company.id)
        .then((res: any) => {
          dispatch({
            type: "GET_COMPANY_FAVORITES",
            payload: res.data,
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
    if (state.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.getCompanyUsers(state.company.id)
        .then((res: any) => {
          dispatch({
            type: "GET_COMPANY_USERS",
            payload: res.data,
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
    if (state.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.getCompanyObSettings(state.company.id)
        .then((res: any) => {
          dispatch({
            type: "UPDATE_COMPANY_OBSETTINGS",
            payload: res.data,
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [state.company]);

  return (
    <CompanyStateContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>
        {canActivate && children}
      </CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
};

export const useCompanyStore = () => {
  const state = useContext(CompanyStateContext);
  const dispatch = useContext(CompanyDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useCompanyStore must be used within a CompanyStoreProvider"
    );
  }

  const actions = companyActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
