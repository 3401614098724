import React from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAuthStore } from "stores/authStore/authStore";
import { useGlobalStore } from "stores/globalStore/globalStore";
import { SaveGigForm } from "web-apps/company/components/saveGig/saveGigForm/saveGigForm.component";
import { Color } from "config/colors";
import { H3, Text } from "components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { BannerComponent } from "components/banners/banner.component";

export const CreateGigPage = () => {
  const { t } = useTranslation();
  const [authState] = useAuthStore();
  const [globalState] = useGlobalStore();
  const [companyState] = useCompanyStore();

  return (
    <>
      {companyState.isLoading ||
      globalState.isLoading ||
      authState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          {companyState.company?.verified ? (
            <>
              <H3>{`${t("GigsCompany.CreateGigFor")} ${
                companyState.company.companyName
              }`}</H3>
              <SaveGigForm />
            </>
          ) : (
            <BannerComponent
              icon="clock"
              backgroundColor={Color.ChampagnePink}
              fill={Color.Destructive}
            >
              <Text color={Color.Destructive}>
                {t("GigsCompany.UnverifiedAccount")}
              </Text>
              <Text color={Color.BurntSienna}>
                {t("GigsCompany.UnverifiedAccountDetails")}
              </Text>
            </BannerComponent>
          )}
        </LoggedInPageContainer>
      )}
    </>
  );
};
