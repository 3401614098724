import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";

const login = async (uid: string): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.get<any>(
    `${API_ROOT}/login/companyEmployee/${uid}`
  );
};

export const employee = {
  login,
};
