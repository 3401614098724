import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontSize } from "config/font";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { H4, Text } from "components/Typography/text.styled";
import { CircularProgress } from "@material-ui/core";
import { FormWrapper, InputWrapper } from "./companyEmployees.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import { validateEmail } from "web-apps/company/utils/utils";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { secondaryApp } from "services/firebase/firebase.config";
import { CompanyEmployee } from "model/Employees";
import { TextInput } from "../calendar/template/calendarTemplatePicker.styled";

type ModalProps = {
  employees: CompanyEmployee[];
  setEmployees: React.Dispatch<React.SetStateAction<CompanyEmployee[]>>;
};

export const NewEmployeeForm: React.FC<ModalProps> = (props) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("asdadasdasd");
  const [emailError, setEmailError] = useState("Erororororo");

  const validate = () => {
    if (
      email.trim() &&
      password.trim() &&
      firstName.trim() &&
      lastName.trim() &&
      !emailError &&
      !passwordError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = () => {
    if (password && password.length < 6) {
      setPasswordError(t("RegisterCompany.PasswordMustBe6Characters"));
    } else {
      setPasswordError("");
    }
  };

  const handleSignUp = async () => {
    let companyId = companyState.company?.id;
    if (validate() && companyId) {
      setIsLoading(true);
      secondaryApp
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(function (res) {
          if (companyId) {
            if (res.user?.uid) {
              const data = {
                firebaseId: res.user.uid || "",
                firstName: firstName,
                lastName: lastName,
                companyId: companyId,
              };
              Api()
                .company.companyEmployees.createCompanyEmployee(companyId, data)
                .then((res) => {
                  props.setEmployees([...props.employees, res.data]);
                  setFirstName("");
                  setLastName("");
                  setEmail("");
                  setPassword("");
                  setIsLoading(false);
                });
            }
          }

          secondaryApp.auth().signOut();
        });
    }
  };

  useEffect(() => {
    if (validateEmail(email)) {
      setEmail(email.trim());
      setEmailError("");
    } else {
      setEmailError(t("RegisterCompany.InvalidEmail"));
    }
  }, [email]);

  useEffect(() => {
    validatePassword();
  }, [password]);

  return (
    <>
      {isLoading ? (
        <FormWrapper style={{ width: "500px" }}>
          <CircularProgress />
        </FormWrapper>
      ) : (
        <>
          <FormWrapper>
            <H4> {t("CompanyEmployees.AddNewEmployee")}</H4>
            <InputWrapper>
              <LabelStyled style={{ alignSelf: "flex-start" }}>
                {t("CompanyEmployees.FirstName")}
              </LabelStyled>
              <TextInput
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelStyled style={{ alignSelf: "flex-start" }}>
                {t("CompanyEmployees.LastName")}
              </LabelStyled>
              <TextInput
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelStyled style={{ alignSelf: "flex-start" }}>
                {t("CompanyEmployees.Email")}
              </LabelStyled>
              <TextInput
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <Text color={Color.Destructive} fontSize={FontSize.Small}>
                  {emailError}
                </Text>
              )}
            </InputWrapper>
            <InputWrapper>
              <LabelStyled style={{ alignSelf: "flex-start" }}>
                {t("CompanyEmployees.Password")}
              </LabelStyled>
              <TextInput
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword();
                }}
              />
              {passwordError && (
                <Text color={Color.Destructive} fontSize={FontSize.Small}>
                  {passwordError}
                </Text>
              )}
            </InputWrapper>

            <ButtonStyled disabled={!validate()} onClick={() => handleSignUp()}>
              {t("CompanyEmployees.AddEmployee")}
            </ButtonStyled>
          </FormWrapper>
        </>
      )}
    </>
  );
};
