import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CompanyGigDto } from "model/Gig";
import { Gig } from "./gig.component";
import { PassedGig } from "./passedGig.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { H3, H4, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { ButtonWrapper, NoGigsWrapper, GigsWrapper } from "./gigs.styled";
import { TabsStyled, TabStyled } from "../tab/tab.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useWindowScrollProgress } from "hooks/useWindowScrollProgress";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { Modal } from "@material-ui/core";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";

export const Gigs: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyState] = useCompanyStore();

  const apiState = useRef({ loading: false, completed: false, page: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [activeGigs, setActiveGigs] = useState<CompanyGigDto[]>([]);
  const status =
    new URLSearchParams(window.location.search).get("status") ?? "Active";
  const fetchGigs = useCallback(() => {
    if (
      !companyState.company ||
      apiState.current.loading ||
      apiState.current.completed
    )
      return;

    setIsLoading(true);
    apiState.current.loading = true;
    const endPoint =
      status === "Active"
        ? Api().company.gig.getActiveGigs
        : Api().company.gig.getPasssedGigs;

    // Get an aproximation of cells to load for each API call
    const cellHeight = status === "Active" ? 94 : 84;
    const amount = Math.ceil(window.innerHeight / cellHeight);

    endPoint(companyState.company.id, apiState.current.page, amount)
      .then((res) => {
        setActiveGigs((old) => [...old, ...res.data]);

        if (res.data.length >= amount) {
          apiState.current.page++;
        } else {
          apiState.current.completed = true;
        }
      })
      .finally(() => {
        setIsLoading(false);
        apiState.current.loading = false;
      });
  }, [status, companyState.company]);

  // Initial Fetch, either from tab change or swapping company
  useEffect(() => {
    setActiveGigs([]);
    apiState.current = { loading: false, completed: false, page: 0 };
    fetchGigs();
  }, [status, companyState.company]);

  useWindowScrollProgress(fetchGigs);

  const onGigClosed = (gig: CompanyGigDto) => {
    setActiveGigs((old) => old.filter((g) => g.id !== gig.id));
  };

  return (
    <>
      <ButtonWrapper>
        <CustomIcon
          style={{ marginRight: "10px" }}
          name="archive"
          size="40px"
          padding="10px"
          backgroundColor={Color.BurntSienna}
          color={Color.White}
          onClick={() => {
            history.push("gigs/archive");
          }}
        />
        <ButtonStyled onClick={() => setShowCreateGigModal(true)}>
          {t("GigsCompany.CreateGig")}
        </ButtonStyled>
      </ButtonWrapper>

      <TabsStyled>
        <TabStyled
          active={status === "Active"}
          onClick={() => history.push(`./gigs?status=Active`)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.ActiveGigs")}
          </Text>
        </TabStyled>
        <TabStyled
          active={status === "Passed"}
          onClick={() => history.push(`./gigs?status=Passed`)}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("GigsCompany.PassedGigs")}
          </Text>
        </TabStyled>
      </TabsStyled>
      <GigsWrapper loading={isLoading}>
        {activeGigs.map((gig) => {
          return status === "Active" ? (
            <Gig key={gig.id} gigData={gig} onGigClosed={onGigClosed} />
          ) : (
            <PassedGig key={gig.id} gigData={gig} />
          );
        })}
        {!activeGigs.length && !isLoading && (
          <NoGigsWrapper>
            <CustomIcon name="document" size="130px" color={Color.SeaBlue400} />
            <H4>{t("GigsCompany.NoGigs")}</H4>
            <Text color={Color.SeaBlue400} padding>
              {t("GigsCompany.NoPublishedGigs")}
            </Text>

            <ButtonStyled onClick={() => setShowCreateGigModal(true)}>
              {t("GigsCompany.CreateGig")}
            </ButtonStyled>
          </NoGigsWrapper>
        )}
      </GigsWrapper>
      {isLoading && <LoaderProgress />}
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("CalendarCompany.CreateGig")}</H3>
          <SaveGigForm setShowCreateGigModal={setShowCreateGigModal} />
        </ModalBody>
      </Modal>
    </>
  );
};
