import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Instant, LocalDateTime } from "@js-joda/core";
import { Link, useHistory } from "react-router-dom";
import { calculateBreakTime, getTimeString, getWorkHours } from "utils/utils";

import { CompanyGigDto } from "model/Gig";
import { getWorkerImages } from "web-apps/company/utils/utils";
import { getDateString } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { ShiftDto } from "model/Shift";
import { CustomIcon } from "components/icon/customIcon.component";
import { H3, Text } from "components/Typography/text.styled";
import {
  WorkerCountWrapper,
  ImageWrapper,
  SmallImage,
  Cell,
  ShiftList,
  TitleStatus,
  Options,
  DividerLine1,
  GigInfoContainer,
  ActiveShift,
  DateHours,
  DividerLine2,
  SpaceBetween,
} from "./gigs.styled";
import { CloseGigModal } from "./closeGigModal.component";
import { Modal } from "@material-ui/core";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import moment from "moment";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

export const Gig: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<(string | null)[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [visibleShift, setVisibleShift] = useState<ShiftDto | null>();
  const history = useHistory();
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const maxImages = 5;
  const startTime = gigData.shifts?.[0]?.startTime ?? "";
  const endTime = gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "";

  useEffect(() => {
    if (Date.parse(endTime) > Date.now()) {
      const nextShift = gigData.shifts.find(
        (shift) => Date.parse(shift.endTime) > Date.now()
      );
      setVisibleShift(nextShift || null);
    }
  }, [gigData]);

  useEffect(() => {
    if (!gigData.workers) return;

    const workerIds = gigData.workers.map((worker) => worker.firebaseId);
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);

    getWorkerImages(workerIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
    });
  }, [gigData.workers]);

  return (
    <>
      <Cell>
        <GigInfoContainer>
          <TitleStatus>
            <Link to={`./gig/${gigData.id}`}>
              <Text
                fontSize={FontSize.H4}
                fontFamily={FontFamily.MontserratSemiBold}
                style={{ margin: 0 }}
              >
                {t(gigData.role || "", { ns: "roles" })}
              </Text>
            </Link>
            {Date.now() > Date.parse(startTime) &&
            Date.now() < Date.parse(endTime) ? (
              <Text
                fontSize={FontSize.Small}
                color={Color.BurntSienna}
                backgroundColor={Color.Positive}
                padding
                style={{ margin: 0, lineHeight: FontSize.Small }}
              >
                {t("GigsCompany.Ongoing")}
              </Text>
            ) : (
              Date.now() < Date.parse(startTime) && (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue600}
                  backgroundColor={Color.SeaBlue300}
                  padding
                  style={{ margin: 0, lineHeight: FontSize.Small }}
                >
                  {t("GigsCompany.ComingUp")}
                </Text>
              )
            )}
          </TitleStatus>
          <DateHours>
            <Text
              fontSize={FontSize.Large}
              color={Color.MidnightBlue}
              style={{
                display: "inline-block",
                margin: 0,
                whiteSpace: "nowrap",
              }}
            >
              {startTime && getDateString(startTime)}
              {getDateString(startTime) !== getDateString(endTime) &&
                ` - ${getDateString(endTime)}`}
            </Text>
            <Text
              fontSize={FontSize.Small}
              color={Color.White}
              backgroundColor={Color.SeaBlue600}
              padding
              style={{
                display: "inline-block",
                margin: 0,
                lineHeight: FontSize.Small,
              }}
            >
              {getWorkHours(t, ...gigData.shifts)}
            </Text>
            <Text
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
              backgroundColor={Color.SeaBlue300}
              padding
              style={{ margin: 0, lineHeight: FontSize.Small }}
            >
              {t(`GigsCompany.${gigData.type}`)}
            </Text>
          </DateHours>
        </GigInfoContainer>
        <DividerLine1 />
        <ShiftList>
          {visibleShift && (
            <ActiveShift>
              <SpaceBetween>
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.BurntSienna}
                >
                  {t(
                    `WeekDays.${LocalDateTime.ofInstant(
                      Instant.parse(visibleShift.startTime)
                    )
                      .dayOfWeek()
                      .name()}SHORT`
                  )}
                </Text>
                <Text
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratSemiBold}
                >{`${getTimeString(visibleShift.startTime)} - ${getTimeString(
                  visibleShift.endTime
                )}`}</Text>
              </SpaceBetween>
              <SpaceBetween>
                <Text
                  fontSize={FontSize.Small}
                  fontFamily={FontFamily.MontserratRegular}
                  color={Color.SeaBlue600}
                  style={{ margin: 0 }}
                >
                  {(visibleShift.breakStartTime && visibleShift.breakEndTime
                    ? calculateBreakTime(
                        visibleShift.breakStartTime,
                        visibleShift.breakEndTime,
                        moment(
                          LocalDateTime.ofInstant(
                            Instant.parse(visibleShift.startTime)
                          ).toString()
                        ),
                        moment(
                          LocalDateTime.ofInstant(
                            Instant.parse(visibleShift.endTime)
                          ).toString()
                        )
                      )
                    : 0) + t("GigsWorker.MinutesUnpaidBreak")}
                </Text>
                <Text
                  fontSize={FontSize.Small}
                  fontFamily={FontFamily.MontserratRegular}
                  color={Color.SeaBlue600}
                  style={{ margin: 0 }}
                >
                  {getWorkHours(t, visibleShift)}
                </Text>
              </SpaceBetween>
              <Text
                color={Color.BurntSienna}
                fontSize={FontSize.Small}
                style={{ margin: 0 }}
              >
                {Date.now() > Date.parse(visibleShift.startTime) &&
                Date.now() < Date.parse(visibleShift.endTime)
                  ? "Ongoing"
                  : "Next-up"}
              </Text>
            </ActiveShift>
          )}
        </ShiftList>
        <DividerLine2 />
        <WorkerCountWrapper>
          {workerImages.map((url, index) => {
            if (index < maxImages) {
              return (
                <ImageWrapper key={index}>
                  {url ? (
                    <SmallImage src={url} />
                  ) : (
                    <CustomIcon
                      name="person"
                      size="100%"
                      color={Color.SeaBlue500}
                      padding="4px"
                    />
                  )}
                </ImageWrapper>
              );
            }
          })}
          <Text color={Color.SeaBlue400} style={{ margin: 0 }}>
            {gigData.workers?.length}/{gigData.amountOfWorkers}
          </Text>
        </WorkerCountWrapper>
        <Options>
          <CustomIcon
            className="icon"
            name="clone"
            size="20px"
            color={Color.BurntSienna}
            onClick={() => {
              setShowCreateGigModal(true);
            }}
          />
          <CustomIcon
            style={{ marginLeft: "10px" }}
            className="icon"
            name="trashcan"
            size="20px"
            color={Color.Destructive}
            onClick={() => {
              if (companyState.company?.id) {
                Api()
                  .company.timeReport.getGigTimeReports(
                    companyState.company?.id,
                    gigData.id
                  )
                  .then((res) => {
                    if (
                      res.data.find(
                        (timeReport) =>
                          timeReport.status !== TimeReportStatus.NEW
                      )
                    ) {
                      alertDispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          icon: "alert",
                          title: "",
                          message: t(
                            "GigsCompany.ThisGigHasConnectedTimeReports"
                          ),
                        },
                      });
                    } else {
                      setModalOpen(true);
                    }
                  });
              }
            }}
          />
        </Options>
      </Cell>

      <CloseGigModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        gigData={gigData}
        onGigClosed={onGigClosed}
      />
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("GigsCompany.CloneGig")}</H3>
          <SaveGigForm
            setShowCreateGigModal={setShowCreateGigModal}
            isClone={true}
            gigData={gigData}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
