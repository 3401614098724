import { Color } from "config/colors";
import styled from "styled-components";

export const FormStyled = styled.form`
  width: 100%;
  max-width: 300px;
  padding: 80px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  h3 {
    align-self: flex-start;

    @media (min-width: 768px) {
      margin-top: 55px;
    }
  }

  button {
    margin-top: 10px;
  }

  a {
    margin: 0 0 20px 0;
  }

  textarea {
    margin: 0;
    height: 29px;
    max-height: 300px;
    resize: none;
  }

  @media (min-width: 768px) {
    max-width: 450px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export const TermsContainer = styled(RowWrapper)`
  align-items: center;
  margin-bottom: 20px;
`;

export const RowWrapperTerms = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  flex-wrap: wrap;
  p {
    margin: 2px;
  }

  #termsText {
    &:hover {
      cursor: pointer;
      color: ${Color.Positive};
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  h3 {
    margin-top: 0;
  }
`;

export const PageSliderStyled = styled.div`
  width: 100%;
  position: relative;
  padding-top: 75px;
  overflow: hidden;

  // old page exiting, hides it underneath (bottom)
  .slide-exit.page {
    z-index: 1;
  }
  // new page entering, show it on top
  .slide-enter.page {
    z-index: 10;
  }
  // LEFT transition transform 100% to the right
  .slide-enter.left.page {
    transform: translate3d(100%, 0, 0);
  }
  // RIGHT transition transform -100% to the left
  .slide-enter.right.page {
    transform: translate3d(-100%, 0, 0);
  }
  // old exit
  .slide-exit.page {
    position: absolute;
    top: 75px;
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  // Enter is active (Page has finished transitioning) make it settle down
  .slide-enter.slide-enter-active.page {
    transform: translate3d(0, 0, 0);
    // Apply all transitions
    transition: all 600ms ease-in-out;
  }

  .slide-exit.slide-exit-active.left.page {
    transform: translate3d(-100%, 0, 0);
    /* //Apply all transitions  */
    transition: all 600ms ease-in-out;
  }

  .slide-exit.slide-exit-active.right.page {
    transform: translate3d(100%, 0, 0);
    /* //Apply all transitions  */
    transition: all 600ms ease-in-out;
  }
`;
