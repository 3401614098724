import React, { useEffect, useState } from "react";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomLink, Text } from "components/Typography/text.styled";
import {
  SettingsButtonContainer,
  SettingsModalBody,
} from "../../workpool/workpool.styled";
import { useTranslation } from "react-i18next";
import { CircularProgress, Modal } from "@material-ui/core";
import {
  HourlyRateContainer,
  NumberInputContainer,
} from "../../saveGig/hourlyRate/hourlyRate.styled";
import { NumberInput } from "components/form/inputs/inputs.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoadingContainer } from "../../companySettings/companyUsers/companyUsers.styled";
import { useWorkpoolStore } from "web-apps/company/stores/workpoolStore/workpoolStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { CompanyFavoriteDto } from "model/Favorite";

type Props = {
  favorite: CompanyFavoriteDto;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
};

export const FavoriteSettings: React.FC<Props> = ({
  favorite,
  setShowModal,
  showModal,
}) => {
  const [companyState, , companyDispatch] = useCompanyStore();
  const [workerRate, setWorkerRate] = useState(favorite.hourlyRate);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [workpoolState, , workpoolDispatch] = useWorkpoolStore();
  const [, , alertDispatch] = useAlertStore();

  const saveSettings = () => {
    if (companyState.company?.id) {
      const data = {
        phoneNumber: favorite.phoneNumber,
        workerId: favorite.workerId,
        hourlyRate: workerRate !== 0 ? workerRate : null,
      };
      setLoading(true);
      Api()
        .company.updateCompanyFavorite(
          companyState.company.id,
          data,
          favorite.id
        )
        .then((res) => {
          setLoading(false);
          setShowModal(false);
          const favorites = companyState.favorites.map((fav) =>
            fav.id === favorite.id ? res.data : fav
          );
          companyDispatch({
            type: "GET_COMPANY_FAVORITES",
            payload: favorites,
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert",
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  useEffect(() => {
    if (showModal) {
      setWorkerRate(favorite.hourlyRate);
    } else {
      setWorkerRate(0);
    }
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <SettingsModalBody>
        {!loading && (
          <>
            <Text
              align="center"
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CompanyGroup.SettingsForWorker", {
                worker: `${favorite.firstName} ${favorite.lastName}`,
              })}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              color={Color.SeaBlue600}
              fontSize={FontSize.Small}
              style={{ marginTop: 0, textAlign: "center" }}
            >
              {`(${t("CompanyGroup.OnlyYourCompanyCanSeeThisInfo")})`}
            </Text>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HourlyRateContainer>
                <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
                  {t("GigsCompany.HourlyRate")}
                </Text>
                <NumberInputContainer>
                  <NumberInput
                    type="number"
                    name="minRate"
                    color={
                      workerRate === 0 ? Color.SeaBlue200 : Color.MidnightBlue
                    }
                    value={workerRate || ""}
                    onChange={(e) => {
                      setWorkerRate(parseInt(e.currentTarget.value) || 0);
                    }}
                  />
                  <Text
                    fontSize={FontSize.Large}
                    color={Color.SeaBlue600}
                    style={{ marginLeft: "5px" }}
                  >
                    Kr
                  </Text>
                </NumberInputContainer>
              </HourlyRateContainer>
              {workerRate !== 0 ? (
                <div style={{ marginLeft: "15px" }}>
                  <CustomLink
                    fontSize={FontSize.Small}
                    color={Color.Destructive}
                    onClick={() => setWorkerRate(0)}
                  >
                    {t("CompanyGroup.RemoveDefaultRate")}
                  </CustomLink>
                </div>
              ) : (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.SeaBlue500}
                  style={{ marginLeft: "15px" }}
                >
                  {t("CompanyGroup.NoDefaultRate")}
                </Text>
              )}
            </div>

            <SettingsButtonContainer>
              <ButtonStyled
                backgroundColor={Color.BurntSienna}
                onClick={() => saveSettings()}
              >
                {t("General.Save")}
              </ButtonStyled>
              <CustomLink
                onClick={() => setShowModal(false)}
                color={Color.Destructive}
              >
                {t("General.Cancel")}
              </CustomLink>
            </SettingsButtonContainer>
          </>
        )}
        {loading && (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        )}
      </SettingsModalBody>
    </Modal>
  );
};
