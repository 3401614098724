import styled from "styled-components";
import { Color } from "config/colors";
import { InputContainer } from "components/form/inputs/inputs.styled";

export const SettingsPageContainer = styled.div`
  margin: 30px 0;
  padding: 0 10%;
`;

export const SettingsDesktopLayout = styled.div`
  display: grid;
  grid-template: auto / 300px 1fr;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 75px;
  max-width: 1300px;
`;

export const SideBarStyled = styled.div`
  padding-left: 20px;
  border-right: 1px solid ${Color.SeaBlue200};
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: 100vh;
`;

export const SettingsMenuStyled = styled.div`
  position: sticky;
  top: 0;
  padding-top: 40px;
`;

export const SettingsListStyled = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SettingsCategoryContainer = styled(InputContainer)`
  margin: 30px 0;
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  p {
    &:hover {
      cursor: pointer;
      color: ${Color.SeaBlue400};
    }
  }
`;
