import React, { useEffect, useState } from "react";
import { getWorkerImg } from "services/firebase/firebase.service";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { SmallImage } from "../gig/gigs.styled";
import {
  EmployeeRow,
  EmployeeImageWrapper,
  EmployeeInfoWrapper,
} from "./workpool.styled";
import { WorkpoolWorker } from "model/Workpool";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WorkpoolWorkerSettings } from "./workpoolWorkerSettings";

type Props = {
  employeeData: WorkpoolWorker;
  filterEmployeeStatus: string;
  checkedEmployees?: WorkpoolWorker[];
  setCheckedEmployees?: React.Dispatch<React.SetStateAction<WorkpoolWorker[]>>;
  setCurrentEmployeeId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WorkpoolListItem: React.FC<Props> = ({
  employeeData,
  filterEmployeeStatus,
  checkedEmployees,
  setCheckedEmployees,
  setCurrentEmployeeId,
  setShowModal,
}) => {
  const [workerImg, setWorkerImg] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  useEffect(() => {
    if (employeeData.worker.firebaseId) {
      getWorkerImg(employeeData.worker.firebaseId).then((res) => {
        setWorkerImg(res);
      });
    }
  }, []);

  useEffect(() => {
    if (isChecked && setCheckedEmployees && checkedEmployees) {
      setCheckedEmployees([...checkedEmployees, employeeData]);
    } else if (setCheckedEmployees && checkedEmployees) {
      const filtered = checkedEmployees.filter(
        (employee) => employee.id !== employeeData.id
      );
      setCheckedEmployees(filtered);
    }
  }, [isChecked]);

  return (
    <EmployeeRow>
      <EmployeeImageWrapper>
        {workerImg ? (
          <SmallImage src={workerImg} />
        ) : (
          <CustomIcon
            name="person"
            size="100%"
            color={Color.SeaBlue600}
            padding="10px"
          />
        )}
      </EmployeeImageWrapper>
      <EmployeeInfoWrapper
        onClick={() => {
          history.push(`./work-pool/${employeeData.id}`);
        }}
      >
        <Text fontFamily={FontFamily.MontserratSemiBold} fontSize={FontSize.H4}>
          {`${employeeData.worker.firstName} ${employeeData.worker.lastName}`}
        </Text>
        {employeeData.defaultHourlyRate !== 0 && (
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {`${employeeData.defaultHourlyRate} ${t(
              "FindWorker.HourlyRateWithCurrency"
            )}`}
          </Text>
        )}
        {employeeData.labels &&
          employeeData.labels.map((label: any, index: number) => {
            if (index < 3) {
              return (
                <Text
                  backgroundColor={Color.SeaBlue400}
                  padding
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Small}
                  color={Color.White}
                >
                  {label.label}
                </Text>
              );
            } else if (index === 3) {
              return (
                <Text
                  backgroundColor={Color.SeaBlue400}
                  padding
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Small}
                  color={Color.White}
                >
                  {`+ ${employeeData.labels.length - 3} ${t("General.More")}`}
                </Text>
              );
            }
          })}
      </EmployeeInfoWrapper>
      {(filterEmployeeStatus === "Unverified" ||
        (filterEmployeeStatus === "Invited" &&
          (employeeData.response === "NoResponse" ||
            employeeData.response === "None"))) && (
        <Text
          fontSize={FontSize.Small}
          color={Color.White}
          backgroundColor={Color.SeaBlue500}
          padding
        >
          {employeeData.requestType === "CompanyRequest"
            ? t("CompanyGroup.AwaitingResponse")
            : t("CompanyGroup.Application")}
        </Text>
      )}
      {filterEmployeeStatus === "Verified" && (
        <>
          <Text
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            backgroundColor={Color.Positive}
            padding
          >
            {t("CompanyGroup.Member")}
          </Text>
        </>
      )}
      {filterEmployeeStatus === "Unverified" &&
        employeeData.requestType === "WorkerRequest" && (
          <CustomIcon
            name="checkmark"
            size="36px"
            color={Color.White}
            backgroundColor={isChecked ? Color.BurntSienna : Color.White}
            padding="2px"
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            style={{
              border: `2px solid ${Color.BurntSienna}`,
              flexShrink: 0,
              marginLeft: "10px",
            }}
          />
        )}

      {((filterEmployeeStatus === "Invited" &&
        employeeData.response !== "Declined") ||
        filterEmployeeStatus === "Verified") && (
        <>
          {(employeeData.response === "NoResponse" ||
            employeeData.response === "None" ||
            employeeData.response === "Validated") && (
            <CustomIcon
              name="settings"
              hover={true}
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
              onClick={() => {
                setShowSettingsModal(true);
                setCurrentEmployeeId(employeeData.workerId);
              }}
            />
          )}

          <CustomIcon
            name="trashcan"
            hover={true}
            size="48px"
            color={Color.Destructive}
            padding="10px"
            onClick={() => {
              setShowModal(true);
              setCurrentEmployeeId(employeeData.workerId);
            }}
          />
        </>
      )}
      <WorkpoolWorkerSettings
        showModal={showSettingsModal}
        setShowModal={setShowSettingsModal}
        employeeData={employeeData}
      />
    </EmployeeRow>
  );
};
