import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CompanyGigDto } from "model/Gig";
import { ArchivedGig } from "./archivedGig.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { H4 } from "components/Typography/text.styled";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { NoGigsWrapper, GigsWrapper } from "./gigs.styled";

type Props = {
  gigs: CompanyGigDto[];
  loading: boolean;
};

export const ArchivedGigs: React.FC<Props> = ({ gigs, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      {!gigs.length ? (
        <NoGigsWrapper>
          <CustomIcon name="archive" size="130px" color={Color.SeaBlue400} />
          <H4>{t("GigsCompany.NoArchivedGigs")}</H4>
        </NoGigsWrapper>
      ) : (
        <GigsWrapper loading={loading}>
          {gigs.map((gig, index) => {
            return <ArchivedGig key={index} gigData={gig} />;
          })}
        </GigsWrapper>
      )}
      {loading && <LoaderProgress />}
    </>
  );
};
