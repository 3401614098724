import {
  ApplicationDto,
  ApplicationWorkerEndorsementDto,
} from "model/Application";

export type ApplicationActions =
  | {
      type: "GET_ALL_APPLICATIONS";
      payload: ApplicationDto[];
    }
  | {
      type: "GET_PENDING_APPLICATIONS_COUNT";
      payload: number;
    }
  | {
      type: "GET_APPLICATION_WORKER_ENDORSEMENTS";
      payload: ApplicationWorkerEndorsementDto[];
    }
  | {
      type: "CREATE_APPLICATION_WORKER_ENDORSEMENT";
      payload: ApplicationWorkerEndorsementDto;
    }
  | {
      type: "DELETE_APPLICATION_WORKER_ENDORSEMENT";
      payload: any;
    }
  | {
      type: "UPDATE_APPLICATION";
      payload: ApplicationDto;
    }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: ApplicationState["error"] };

export type ApplicationState = {
  applications: ApplicationDto[];
  applicationsCount: number;
  applicationWorkerEndorsements: ApplicationWorkerEndorsementDto[];
  isLoading: boolean;
  error: { message: string; err: Error } | null;
};

export const applicationInitState: ApplicationState = {
  applications: [],
  applicationsCount: 0,
  applicationWorkerEndorsements: [],
  isLoading: false,
  error: null,
};

export const ApplicationStateReducer = (
  state: ApplicationState,
  action: ApplicationActions
): ApplicationState => {
  switch (action.type) {
    case "GET_ALL_APPLICATIONS": {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case "GET_PENDING_APPLICATIONS_COUNT": {
      return {
        ...state,
        applicationsCount: action.payload,
      };
    }
    case "GET_APPLICATION_WORKER_ENDORSEMENTS": {
      return {
        ...state,
        applicationWorkerEndorsements: action.payload,
      };
    }
    case "CREATE_APPLICATION_WORKER_ENDORSEMENT": {
      return {
        ...state,
      };
    }
    case "DELETE_APPLICATION_WORKER_ENDORSEMENT": {
      return {
        ...state,
      };
    }
    case "UPDATE_APPLICATION": {
      const newApplications = state.applications.map((application) => {
        return application.id === action.payload.id
          ? action.payload
          : application;
      });
      return {
        ...state,
        applications: newApplications,
      };
    }

    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
