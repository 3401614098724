import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { ApplicationWorkerEndorsementDto } from "model/Application";

const getWorkerEndorsements = async (
  companyId: number,
  workerId: number
): Promise<AxiosResponse<ApplicationWorkerEndorsementDto[]>> => {
  return await axiosRequest.auth
    .get<ApplicationWorkerEndorsementDto[]>(
      `${API_ROOT}/companies/${companyId}/endorsement/${workerId}`
    );
};
const createWorkerEndorsement = async (
  companyId: number,
  workerId: number,
  skill: string
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.post<ApplicationWorkerEndorsementDto>(
    `${API_ROOT}/companies/${companyId}/endorsement`,
    { companyId: companyId, workerId: workerId,skill: skill }
  );
};
const deleteWorkerEndorsement = async (
  companyId: number,
  workerId: number,
  id: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.delete<any>(
    `${API_ROOT}/companies/${companyId}/endorsement`,
    { data: {companyId: companyId, workerId: workerId, id: id }}
  );
};
export const worker = {
  getWorkerEndorsements,
  createWorkerEndorsement,
  deleteWorkerEndorsement
};
