import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  SkillsCell,
  ModalBody,
  CustomModalBody,
  SkillCell,
  Column,
} from "./applicantDetails.styled";
import {
  ApplicationUserSkillDto,
  ApplicationWorkerEndorsementDto,
} from "model/Application";
type Props = {
  workerEndorsementModalOpen: boolean;
  setWorkerEndorsementModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createWorkerEndorsementSkill: ApplicationUserSkillDto;
  deleteEndorsement: (a: string) => void;
  createEndorsement: (a: string) => void;
  skillAlreadyEndorsed: boolean;
  workerEndorsements: ApplicationWorkerEndorsementDto[];
};

export const WorkerEndorementModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={props.workerEndorsementModalOpen}
        onClose={() => props.setWorkerEndorsementModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CustomModalBody>
          <ModalBody>
            <Text
              align="center"
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("ApplicationsCompany.Endorsements")}
            </Text>

            <SkillsCell>
              <SkillCell>
                <Text
                  align="center"
                  style={{ margin: "0" }}
                  fontSize={FontSize.H4}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {props.createWorkerEndorsementSkill.skill}
                </Text>
                <Text
                  align="center"
                  fontSize={FontSize.Standard}
                  fontFamily={FontFamily.MontserratRegular}
                >
                  {props.createWorkerEndorsementSkill.experience === 0
                    ? `3-9 ${t("ProfileWorker.Months")}`
                    : props.createWorkerEndorsementSkill.experience < 2
                    ? `1-2 ${t("ProfileWorker.Years")}`
                    : props.createWorkerEndorsementSkill.experience < 5
                    ? `2-5 ${t(
                        "ProfileWorker.Years"
                      )}`
                    : `+5 ${t(
                        "ProfileWorker.Years"
                      )}`}
                </Text>
              </SkillCell>
            </SkillsCell>
            <SkillsCell>
              <Column>
                {props.workerEndorsements
                  .filter(
                    (endorsement) =>
                      endorsement.skill.toLocaleLowerCase() ===
                      props.createWorkerEndorsementSkill.skill.toLowerCase()
                  )
                  .map((endorsement) => {
                    return <Text>{endorsement.endorsedBy}</Text>;
                  })}
              </Column>
            </SkillsCell>
            <SkillsCell>
              {props.skillAlreadyEndorsed && (
                <ButtonStyled
                  backgroundColor={Color.Destructive}
                  onClick={() =>
                    props.deleteEndorsement(
                      props.createWorkerEndorsementSkill.skill
                    )
                  }
                >
                  {t("ApplicationsCompany.DeleteEndorsement")}
                </ButtonStyled>
              )}
              {!props.skillAlreadyEndorsed && (
                <ButtonStyled
                  backgroundColor={Color.BurntSienna}
                  onClick={() =>
                    props.createEndorsement(
                      props.createWorkerEndorsementSkill.skill
                    )
                  }
                >
                  {t("ApplicationsCompany.CreateEndorsement")}
                </ButtonStyled>
              )}
            </SkillsCell>
          </ModalBody>
        </CustomModalBody>
      </Modal>
    </>
  );
};
