import { useCallback } from "react";
import { CompanyGigDto, CompanyGigRequestDto } from "model/Gig";
import { useState } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useTranslation } from "react-i18next";

export const useSaveManyGigs = () => {
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [gigs, setGigs] = useState<CompanyGigDto[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const saveMany = useCallback((requestBody:CompanyGigRequestDto[]) => {
      if (!companyState.company || requestBody.length === 0) return;

      const companyid = companyState.company.id
      setIsLoading(true);
      setProgress(0)

      Promise.all(
        requestBody.map((req) =>
          Api().company.gig.saveGig(companyid, req, undefined).then((res) => {
            setProgress((p) => p++)
            return res
          })
        )
      ).then((res) => {
        setGigs(res.map((g) => ({ ...g.data })))
      }).catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("Alerts.SomethingWentWrong"),
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    },
    [companyState.company]
  );

  return [gigs, isLoading, progress, saveMany] as const;
};
