import firebase from "./firebase.config";

export const getWorkerImg = (workerId: string): Promise<string> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${workerId}/photo`)
    .getDownloadURL()
    .then((url) => url)
    .catch(() => null);
};

export const getCompanyImg = (companyId: number): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Company/${companyId}/photo`)
    .getDownloadURL()
    .then((url) => {
      return url;
    })
    .catch(() => null);
};

export const updateCompanyImg = (
  companyId: number,
  file: any
): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Company/${companyId}/photo`)
    .put(file)
    .then((url) => {
      return url;
    })
    .catch(() => null);
};

export const createCompanyUser = async (email: string, password: string) => {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const getWorkerVideo = (workerId: string): Promise<any> => {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  return storageRef
    .child(`Worker/${workerId}/video`)
    .getDownloadURL()
    .then((url) => url)
    .catch(() => null);
};
