import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { CustomLink, H3 } from "components/Typography/text.styled";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { GigTimeReportsOverView } from "web-apps/company/components/timeReport/gigTimeReportsOverview.component";
import { Color } from "config/colors";

type MatchProps = {
  id?: string;
};

export const GigTimeReportsPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const { t } = useTranslation();
  const gigId = props.match.params.id;
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  const [timeReportState] = useTimeReportStore();

  const history = useHistory();

  const [role, setRole] = useState(
    new URLSearchParams(window.location.search).get("gig")
  );

  useEffect(() => {
    if (role !== null) {
      setRole(decodeURI(role));
    }
  }, [companyState.company?.id]);

  return (
    <LoggedInPageContainer>
      <H3>{t("CompanyTimeReport.TimeReportsFor") + " " + role}</H3>

      <CustomLink
        onClick={() => history.goBack()}
        color={Color.Destructive}
        text-align="right"
      >
        {t("General.Back")}
      </CustomLink>
      {authState.isLoading ||
      companyState.isLoading ||
      timeReportState.isLoading ? (
        <LoaderProgress />
      ) : (
        <GigTimeReportsOverView gigId={gigId} />
      )}
    </LoggedInPageContainer>
  );
};
