import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "web-apps/company/utils/utils";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { H3, CustomLink, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  TextInput,
  LabelStyled,
  InputContainer,
} from "components/form/inputs/inputs.styled";
import {
  FormStyled,
  RowWrapper,
} from "../registrationContainer/registrationContainer.styled";
import { ProgressIndicator } from "components/form/progressIndicator/progressIndicator.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress } from "@material-ui/core";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  contactPersonEmail: string;
  setContactPersonEmail: React.Dispatch<React.SetStateAction<string>>;
  contactPersonPhoneNumber: string;
  setContactPersonPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  register: () => void;
};

export const ContactForm: React.FC<Props> = ({
  page,
  setPage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  contactPersonEmail,
  setContactPersonEmail,
  contactPersonPhoneNumber,
  setContactPersonPhoneNumber,
  register,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const validatePhoneNumber = () => {
    const re = /^[0-9+-\s]+$/;
    if (contactPersonPhoneNumber && !re.test(contactPersonPhoneNumber)) {
      setPhoneNumberError(t("RegisterCompany.InvalidPhoneNumber"));
    } else {
      setPhoneNumberError("");
    }
  };

  const validate = () => {
    if (
      firstName &&
      lastName &&
      contactPersonEmail &&
      contactPersonPhoneNumber &&
      !emailError &&
      !phoneNumberError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      register();
    }
  };

  useEffect(() => {
    if (validateEmail(contactPersonEmail)) {
      setEmailError("");
    } else {
      setEmailError(t("RegisterCompany.InvalidEmail"));
    }
  }, [contactPersonEmail]);

  useEffect(() => {
    validatePhoneNumber();
  }, [contactPersonPhoneNumber]);

  return (
    <FormStyled onSubmit={handleSubmit}>
      <ProgressIndicator steps={3} currentStep={2} />
      {companyState.isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          <H3>{t("RegisterCompany.ContactPerson")}</H3>
          <InputContainer>
            <LabelStyled>{t("RegisterCompany.FirstName") + "*"}</LabelStyled>
            <TextInput
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </InputContainer>
          <InputContainer>
            <LabelStyled>{t("RegisterCompany.LastName") + "*"}</LabelStyled>
            <TextInput
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.currentTarget.value);
              }}
            />
          </InputContainer>
          <InputContainer>
            <LabelStyled>{t("RegisterCompany.PhoneNumber") + "*"}</LabelStyled>
            <TextInput
              type="tel"
              value={contactPersonPhoneNumber}
              onChange={(e) => {
                setContactPersonPhoneNumber(
                  e.currentTarget.value.replace(" ", "")
                );
              }}
            />
          </InputContainer>
          {phoneNumberError && (
            <Text color={Color.Destructive} fontSize={FontSize.Small}>
              {phoneNumberError}
            </Text>
          )}
          <ButtonStyled disabled={!validate() || page !== 1}>
            {t("RegisterCompany.CreateCompany")}
          </ButtonStyled>
          <RowWrapper>
            <CustomLink
              disableEvent={page !== 1}
              color={Color.Destructive}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              {t("General.Back")}
            </CustomLink>
          </RowWrapper>
        </div>
      )}
    </FormStyled>
  );
};
