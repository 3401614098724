import { CompanyCalendarGigDto } from "model/Calendar";

type calendarAction =
  | {
      type: "UPDATE_WEEKLY_CALENDAR";
      payload: CompanyCalendarGigDto[];
    }
  | {
      type: "UPDATE_OVERVIEW_WEEK_CALENDAR";
      payload: CompanyCalendarGigDto[];
    }
  | { type: "SET_IS_LOADING"; payload: boolean };

export type CalendarActions = calendarAction;

export type CalendarState = {
  currentWeek: CompanyCalendarGigDto[];
  overviewWeek: CompanyCalendarGigDto[];
  isLoading: boolean;
};

export const calendarInitState: CalendarState = {
  currentWeek: [],
  overviewWeek: [],
  isLoading: false,
};

export const calendarStateReducer = (
  state: CalendarState,
  action: CalendarActions
): CalendarState => {
  switch (action.type) {
    case "UPDATE_WEEKLY_CALENDAR": {
      return { ...state, currentWeek: action.payload };
    }

    case "UPDATE_OVERVIEW_WEEK_CALENDAR": {
      return { ...state, overviewWeek: action.payload };
    }

    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
