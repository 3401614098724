import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginForm } from "web-apps/company/components/login/loginForm.component";
import { Color } from "config/colors";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { ResetPasswordModal } from "web-apps/company/components/login/resetPasswordModal.component";
import { CustomLink, H3 } from "components/Typography/text.styled";
import { LoggedOutPageContainer } from "components/pageLayouts/pageLayouts.styled";

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <LoggedOutPageContainer>
      <H3>{t("LoginCompany.CompanyLogin")}</H3>
      <LoginForm email={email} setEmail={setEmail} />
      <ButtonStyled
        theme={Color.Destructive}
        style={{ marginBottom: "10px" }}
        onClick={() => {
          history.push("./sign-up");
        }}
      >
        {t("LoginCompany.CreateAccount")}
      </ButtonStyled>
      <CustomLink
        onClick={() => setPasswordModalOpen(true)}
        color={Color.Destructive}
      >
        {t("LoginCompany.ForgotPassword")}
      </CustomLink>
      <ResetPasswordModal
        passwordModalOpen={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
        email={email}
        setEmail={setEmail}
      />
    </LoggedOutPageContainer>
  );
};
