import styled from "styled-components";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

type NavProps = {
  active?: boolean;
};

export const NavBarMenuListStyled = styled.ul`
  flex: 1;
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: ${Color.PitchBlack};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 1000px) {
    display: flex;
  }
`;

export const NavIconLi = styled.li<NavProps>`
  ${(props) =>
    props.active !== undefined
      ? props.active
        ? `border-bottom: 2px solid ${Color.BurntSienna};`
        : `border-bottom: 2px solid ${Color.SeaBlue200};`
      : "none "}
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: border 0.3s ease-in-out;
  margin: 0;
  width: 100%;
  padding: 0 1%;

  @media (min-width: 1000px) {
    width: unset;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    font-size: ${FontSize.Standard};
    margin: 0px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const NavLi = styled(NavIconLi)`
  a {
    height: 50%;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const CompaniesLi = styled(NavIconLi)`
  flex-direction: row;
  border-bottom: 2px solid ${Color.SeaBlue200};
`;

export const DrawerSelect = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Badge = styled.div`
  background-color: ${Color.Destructive};
  color: ${Color.White};
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: ${FontSize.Small};
  position: absolute;
  margin-left: 25px;
  margin-bottom: 30px;
  padding: 6px;
`;
