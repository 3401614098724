import React from "react";
import { Color } from "config/colors";
import { CustomLink } from "components/Typography/text.styled";
import { NavIconLi } from "./navBarMenuList.styled";
import { CustomIcon } from "components/icon/customIcon.component";

type MenuItem = {
  text: string;
  path: string;
  image: string;
};

type Props = {
  menuList: MenuItem[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuItemsEmployee: React.FC<Props> = ({
  menuList,
  open,
  setOpen,
}) => {
  return (
    <>
      {menuList.map((item, index) => {
        return (
          <NavIconLi
            active={window.location.pathname === item.path}
            key={index}
          >
            <CustomLink
              color={Color.SeaBlue500}
              to={item.path}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CustomIcon
                name={item.image}
                color={Color.SeaBlue500}
                padding="5px"
              />

              <p style={{ margin: 0, textAlign: "center" }}>{item.text}</p>
            </CustomLink>
          </NavIconLi>
        );
      })}
    </>
  );
};
