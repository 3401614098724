import React from "react";
import { ShiftRequestDto } from "model/Shift";
import { Shift } from "./shift.component";

type Props = {
  shifts: ShiftRequestDto[];
  onChange: (shifts: ShiftRequestDto[]) => void;
  isClone: boolean | undefined;
  createGig: boolean;
};

export const Shifts: React.FC<Props> = ({
  shifts,
  onChange,
  isClone,
  createGig,
}) => {
  return (
    <>
      {shifts.map((shift, index) => {
        if (shift.deleteEntry !== true) {
          return (
            <Shift
              key={index}
              shiftIndex={index}
              shift={shift}
              shifts={shifts}
              onChange={onChange}
              isClone={isClone}
              createGig={createGig}
            />
          );
        }
      })}
    </>
  );
};
