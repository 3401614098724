import { useEffect } from "react";
import { clamp } from "web-apps/company/utils/utils";

export const useWindowScrollProgress = (callback: () => void, threshold: number = 0.9) => {
  useEffect(() => {
    const onScroll = () => {
      const docEl = document.documentElement
      const current = docEl.scrollTop + document.body.scrollTop
      const max = docEl.scrollHeight - docEl.clientHeight

      const p = clamp(current / max, 0, 1)
      if (p >= threshold) {
        callback()
      }
    }

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [callback, threshold])
}
