import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  ApplicationRow,
  GigCell,
  GigText,
  ShiftCell,
  ShiftColumn,
  TextBadge,
} from "./weeklyCalendar.styled";
import moment from "moment";
import { CompanyGigUserDto } from "model/Gig";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { TimeReportResolution, TimeReportStatus } from "model/TimeReport";
import { useHistory } from "react-router-dom";
import { useAlertStore } from "stores/alertStore/alertStore";
import { GigDetailsModal } from "./gigDetailsModal.component";
import { GigApplicationsModal } from "./gigApplicationsModal.component";
import {
  CompanyCalendarApplication,
  CompanyCalendarGigDto,
  CompanyCalendarShift,
  CompanyCalendarTimereport,
} from "model/Calendar";
import { TimeReportModalContainer } from "./timeReportModalContainer.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { OfferedGigModal } from "./offeredGigModal";
import { NotFilledGigModal } from "./notFilledGigModal";
import { Text } from "components/Typography/text.styled";
import { shiftWithAbsence } from "web-apps/company/utils/utils";

type CalendarGigProps = {
  gigData: CompanyCalendarGigDto;
  gigDataIndex: number;
  view: "compact" | "full";
  dashboard?: boolean;
};

type GigsWorkersList = {
  worker: CompanyGigUserDto | null;
  applications: ApplicationDto[] | null;
  shiftWithTimeReport: ShiftWithTimeReport[];
};

type ShiftWithTimeReport = {
  shift: CompanyCalendarShift;
  timeReport: CompanyCalendarTimereport | undefined;
};

export const CalendarGig: React.FC<CalendarGigProps> = ({
  gigData,
  gigDataIndex,
  view,
  dashboard,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [matchingUnhandledApplications, setMatchingUnhandledApplications] =
    useState<CompanyCalendarApplication[]>([]);

  const [gigsWorkersList, setGigsWorkersList] = useState<GigsWorkersList[]>([]);
  const [emptyDays, setEmptyDays] = useState<number[]>([]);
  const [gigShiftsByDay, setGigShiftsByDay] = useState<any>([]);
  const history = useHistory();
  const [gigModalOpen, setGigModalOpen] = useState(false);
  const [applicationsModalOpen, setApplicationsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hired, setHired] = useState(0);
  const [currentTimeReportId, setCurrentTimeReportId] = useState(0);
  const [offeredModalOpen, setOfferedModalOpen] = useState(false);
  const [offeredWorkerName, setOffredWorkerName] = useState("");
  const [showNotFilledModal, setShowNotFilledModal] = useState(false);
  const [shiftData, setShiftData] = useState<CompanyCalendarGigDto>();
  const [companyState, companyActions] = useCompanyStore();
  const [error, setError] = useState("");
  const [clickedShiftId, setClickedShiftId] = useState<number>();
  const [offeredWorkerId, setOfferedWorkerId] = useState<number>();
  useEffect(() => {
    getMatchingUnhandledApplications();

    const result = gigData.shifts.reduce(
      (acc: any, shift: CompanyCalendarShift) => {
        const key = moment(shift.startTime).format("YYYY-MM-DD");
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(shift);
        return acc;
      },
      {}
    );

    const sortedDates = Object.values(result);
    setGigShiftsByDay(sortedDates);
  }, [gigData]);

  useEffect(() => {
    getWorkersForGig();
    getAmountHiredWorkers();
  }, [gigData.applications]);

  const getMatchingUnhandledApplications = () => {
    let filteredApplications = gigData.applications.filter(
      (application) => application.status === ApplicationStatus.PENDING
    );

    setMatchingUnhandledApplications(filteredApplications);
  };

  const getAmountHiredWorkers = () => {
    setHired(
      gigData.applications.filter(
        (application) =>
          application.status === ApplicationStatus.CLOSED &&
          application.resolution === ApplicationResolution.ACCEPTED
      ).length
    );
  };

  const getWorkersForGig = () => {
    let filledWorkersList: any = [];
    let workersList: any = [];
    gigData.applications.forEach((application) => {
      const matching = filledWorkersList.findIndex(
        (worker: GigsWorkersList) => worker.worker?.id === application.worker.id
      );
      if (matching === -1) {
        let shiftWithTimeReport: ShiftWithTimeReport[] = [];
        gigData.shifts.forEach(
          (shift) =>
            (shiftWithTimeReport = [
              ...shiftWithTimeReport,
              {
                shift: shift,
                timeReport: shift.timeReports.find(
                  (timeReport) => timeReport.workerId === application.worker.id
                ),
              },
            ])
        );
        if (
          application.status === ApplicationStatus.OFFERED ||
          application.resolution === ApplicationResolution.ACCEPTED
        ) {
          filledWorkersList.push({
            worker: application.worker,
            applications:
              application.status === ApplicationStatus.OFFERED
                ? [application]
                : [],
            shiftWithTimeReport: shiftWithTimeReport,
          });
        }
      } else {
        application.status === ApplicationStatus.OFFERED &&
          filledWorkersList[matching].applications.push(application);
      }
    });
    Array.from(
      Array(
        gigData.amountOfWorkers >= filledWorkersList.length
          ? gigData.amountOfWorkers - filledWorkersList.length
          : 0
      )
    ).forEach(() => {
      workersList.push({
        worker: null,
        application: null,
        shiftWithTimeReport: [],
      });
    });
    workersList = [...filledWorkersList, ...workersList];

    setGigsWorkersList(workersList);
  };

  const getUnusedWeekDays = () => {
    let weekdays = [1, 2, 3, 4, 5, 6, 7];
    gigData.shifts.forEach((shift: CompanyCalendarShift) => {
      let shiftDay = moment(shift.startTime).day();
      if (shiftDay === 0) {
        shiftDay = 7;
      }

      weekdays = weekdays.filter((day) => day !== shiftDay);
    });

    setEmptyDays(weekdays);
  };

  useEffect(() => {
    getUnusedWeekDays();
  }, [gigData]);

  const deleteShift = (shiftId: number, gigId: string) => {
    if (companyState.company?.id) {
      companyActions
        .handleDeleteCalenderShift(companyState.company?.id, shiftId, gigId)
        .then(() => {
          window.location.reload();
        })
        .catch((err: any) => {
          if (
            err.errorMessage ==
            "Unable to delete GigShift due to linked time reports"
          ) {
            setError(err.errorMessage);
          } else {
            setError("Unknown error");
          }
        });
    }
  };

  const renderShiftCell = (
    type: "not-filled" | "filled" | "offered",
    keyId: number,
    shift: CompanyCalendarShift,
    name: string,
    timeReport?: CompanyCalendarTimereport,
    onClick?: () => void
  ) => {
    let tooltipId = "not-filled";
    let tooltipContent = t("CalendarCompany.NotFilled");
    let bgColor = Color.SeaBlue300;
    let borderColor =
      timeReport &&
      (shiftWithAbsence(timeReport) ||
        timeReport.resolution == TimeReportResolution.WITHDRAWN)
        ? Color.Destructive
        : Color.SeaBlue500;
    let startTime = shift.startTime;
    let endTime = shift.endTime;

    if (view === "compact") {
      switch (type) {
        case "filled":
          tooltipId = "worker-name";
          tooltipContent = `${
            moment(timeReport?.startTime).format("HH:mm") ?? ""
          } - ${moment(timeReport?.endTime).format("HH:mm") ?? ""}`;
          bgColor =
            timeReport &&
            (shiftWithAbsence(timeReport) ||
              timeReport.resolution == TimeReportResolution.WITHDRAWN)
              ? Color.LightWarning
              : Color.Positive;
          startTime = timeReport?.startTime ?? "";
          endTime = timeReport?.endTime ?? "";

          if (timeReport?.status === TimeReportStatus.SUBMITTED)
            borderColor = Color.Destructive;
          else if (
            timeReport?.resolution !== TimeReportResolution.UNDEFINED &&
            timeReport &&
            (!shiftWithAbsence(timeReport) ||
              timeReport.resolution !== TimeReportResolution.WITHDRAWN)
          )
            borderColor = Color.BurntSienna;
          break;

        case "offered":
          tooltipId = "worker-name-offered";
          tooltipContent = `${moment(startTime).format("HH:mm") ?? ""} - ${
            moment(endTime).format("HH:mm") ?? ""
          }`;
          bgColor = Color.ChampagnePink;

          break;
        case "not-filled":
          tooltipContent = `${moment(startTime).format("HH:mm") ?? ""} - ${
            moment(endTime).format("HH:mm") ?? ""
          }`;

          break;
      }
    } else {
      switch (type) {
        case "filled":
          tooltipId = "worker-name";
          tooltipContent = name;
          bgColor =
            timeReport &&
            (shiftWithAbsence(timeReport) ||
              timeReport.resolution == TimeReportResolution.WITHDRAWN)
              ? Color.LightWarning
              : Color.Positive;
          startTime = timeReport?.startTime ?? "";
          endTime = timeReport?.endTime ?? "";

          if (timeReport?.status === TimeReportStatus.SUBMITTED)
            borderColor = Color.Destructive;
          else if (
            timeReport?.resolution !== TimeReportResolution.UNDEFINED &&
            timeReport &&
            !(shiftWithAbsence(timeReport) ||
              timeReport.resolution !== TimeReportResolution.WITHDRAWN)
          )
            borderColor = Color.BurntSienna;
          break;

        case "offered":
          tooltipId = "worker-name-offered";
          tooltipContent = name;
          bgColor = Color.ChampagnePink;
          break;
      }
    }

    return (
      <ShiftCell
        key={keyId}
        leftBorderColor={borderColor}
        backgroundColor={bgColor}
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
        onClick={() => {
          onClick?.();
        }}
      >
        {view === "full" ? (
          <Text color={Color.White}>
            {`${moment(startTime).format("HH:mm")} - ${moment(endTime).format(
              "HH:mm"
            )}`}
          </Text>
        ) : (
          <Text fontSize={FontSize.Small} color={Color.White}>{`${
            name.length > 6 && dashboard
              ? `${name.slice(0, 6)}...`
              : name
              ? `${name}`
              : dashboard && !name
              ? t(t("CalendarCompany.NotFilled")).slice(0, 7) + "..."
              : t(t("CalendarCompany.NotFilled"))
          }`}</Text>
        )}
        {view === "full" ? (
          <Text color={Color.White}>{tooltipContent}</Text>
        ) : (
          <Tooltip
            id={tooltipId}
            place="top"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </ShiftCell>
    );
  };

  return (
    <>
      <GigCell>
        <GigText
          data-tooltip-id={
            gigData.role.length > (dashboard ? 7 : 18) ? "gig-role" : ""
          }
          data-tooltip-content={gigData.role}
          fontSize={dashboard ? FontSize.Small : FontSize.Standard}
          color={Color.White}
          fontFamily={FontFamily.MontserratSemiBold}
          onClick={() => {
            setGigModalOpen(true);
          }}
        >
          {gigData.role.length > (dashboard ? 7 : 18)
            ? gigData.role.slice(0, dashboard ? 7 : 19) + "..."
            : gigData.role}
        </GigText>
        {gigData.role.length > 18 && (
          <Tooltip
            place="top"
            id="gig-role"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 15px 0 rgba(185, 185, 185, 0.5)",
            }}
          />
        )}
        {view === "full" && (
          <>
            <ApplicationRow
              onClick={() => {
                setApplicationsModalOpen(true);
                matchingUnhandledApplications.length > 0 &&
                  history.push("?status=Pending");
              }}
            >
              <GigText
                fontSize={FontSize.Small}
                color={Color.White}
                fontFamily={FontFamily.MontserratSemiBold}
              >
                {`${hired} / ${gigData.amountOfWorkers} ${t(
                  "CalendarCompany.Hired"
                )}`}
              </GigText>
              {matchingUnhandledApplications.length > 0 && (
                <TextBadge>{matchingUnhandledApplications.length}</TextBadge>
              )}
            </ApplicationRow>

            <Text
              style={{ margin: "4px 0 0 0" }}
              fontSize={FontSize.Small}
              color={Color.SeaBlue200}
              fontFamily={FontFamily.MontserratRegular}
            >
              {`${moment(gigData.firstShift).format("D/M")} ${
                moment(gigData.lastShift).format("D/M") !==
                moment(gigData.firstShift).format("D/M")
                  ? " - " + moment(gigData.lastShift).format("D/M")
                  : ""
              }`}
            </Text>
          </>
        )}
      </GigCell>
      {emptyDays.map((day) => {
        return (
          <ShiftColumn
            key={day}
            column={day === 0 ? 7 : day}
            row={gigDataIndex}
          />
        );
      })}
      {gigShiftsByDay.map((shiftArray: CompanyCalendarShift[]) => (
        <ShiftColumn
          key={shiftArray[0].id}
          column={
            moment(shiftArray[0].startTime).day() > 0
              ? moment(shiftArray[0].startTime).day()
              : 7
          }
          row={gigDataIndex}
        >
          {shiftArray.map((shift: CompanyCalendarShift) => {
            return gigsWorkersList.map(
              (workerData: GigsWorkersList, index: number) => {
                const workerMatchingTimeReport =
                  workerData.shiftWithTimeReport.find(
                    (shiftWithTimeReport: ShiftWithTimeReport) =>
                      shiftWithTimeReport.shift.id === shift.id &&
                      shiftWithTimeReport.timeReport
                  );

                const keyId = shift.id + index;
                const workerName = workerData.worker?.firstName || "";

                // Render filled cell
                if (workerMatchingTimeReport) {
                  return renderShiftCell(
                    "filled",
                    keyId,
                    shift,
                    workerName,
                    workerMatchingTimeReport.timeReport,
                    () => {
                      setClickedShiftId(shift.id);
                      setError("");
                      setModalOpen(true);
                      if (workerMatchingTimeReport.timeReport) {
                        setCurrentTimeReportId(
                          workerMatchingTimeReport.timeReport.id
                        );
                      }
                    }
                  );
                  // Render offered cell
                } else if (
                  workerData.worker &&
                  workerData.applications &&
                  workerData.applications?.length > 0
                ) {
                  return renderShiftCell(
                    "offered",
                    keyId,
                    shift,
                    workerName,
                    undefined,
                    () => {
                      setClickedShiftId(shift.id);
                      setShiftData(gigData);
                      setOfferedModalOpen(true);
                      setOffredWorkerName(workerName);
                      setOfferedWorkerId(workerData.worker?.id);
                    }
                  );
                } else {
                  return renderShiftCell(
                    "not-filled",
                    keyId,
                    shift,
                    "",
                    undefined,
                    () => {
                      setClickedShiftId(shift.id);
                      setShiftData(gigData);
                      setShowNotFilledModal(true);
                    }
                  );
                }

                // Render default, not-filled, cell
              }
            );
          })}
        </ShiftColumn>
      ))}

      {
        // Only Render when actually opened, or else we fetch gig details for
        // all gigs in the calender.
        gigModalOpen && (
          <GigDetailsModal
            gigId={gigData.id}
            setModalOpen={setGigModalOpen}
            modalOpen
          />
        )
      }

      <GigApplicationsModal
        gigData={gigData}
        setModalOpen={setApplicationsModalOpen}
        modalOpen={applicationsModalOpen}
      />
      <TimeReportModalContainer
        timeReportId={currentTimeReportId}
        gigId={gigData.id}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        calenderView
        errorMessage={error}
      />

      <OfferedGigModal
        offeredModalOpen={offeredModalOpen}
        setOfferedModalOpen={setOfferedModalOpen}
        offeredWorkerName={offeredWorkerName}
        shiftData={shiftData}
        deleteShift={(shiftId: number, gigsId: string) =>
          deleteShift(shiftId, gigsId)
        }
        clickedShiftId={clickedShiftId}
        offeredWorkerId={offeredWorkerId}
      />

      <NotFilledGigModal
        showNotFilledModal={showNotFilledModal}
        setShowNotFilledModal={setShowNotFilledModal}
        deleteShift={(shiftId: number, gigsId: string) =>
          deleteShift(shiftId, gigsId)
        }
        shiftData={shiftData}
        clickedShiftId={clickedShiftId}
      />
    </>
  );
};
