import firebase from "firebase";
import { AuthStoreDispatch } from "./authStore";
import { Api } from "services/api/api.service";
import { authState } from "./authStore.reducer";
import { CompanyLoginDto } from "model/Login";
import { AxiosResponse } from "axios";

const loginPhoneNumber =
  (dispatch: AuthStoreDispatch) =>
  async (firestoreUser: firebase.User): Promise<any> => {};

const loginEmailAndPassword =
  (dispatch: AuthStoreDispatch) =>
  async (
    firestoreUser: firebase.User
  ): Promise<AxiosResponse<CompanyLoginDto>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    if (firestoreUser.uid) {
      return Api()
        .company.login(firestoreUser.uid)
        .then((res) => {
          if (res.status === 204) {
            dispatch({
              type: "ACCOUNT_SIGN_IN",
              payload: {
                firestoreUser: firestoreUser,
              },
            });
          } else {
            dispatch({
              type: "ACCOUNT_SIGN_IN",
              payload: {
                accountType: "Company",
                activeAccountId: res.data.companyUser.id,
                firestoreUser: firestoreUser,
                user: res.data.companyUser
              },
            });
          }
          dispatch({ type: "SET_IS_LOADING", payload: false });
          return Promise.resolve(res);
        });
    } else {
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.reject();
    }
  };

const loginEmailAndPasswordEmployee =
  (dispatch: AuthStoreDispatch) =>
  async (
    firestoreUser: firebase.User
  ): Promise<AxiosResponse<CompanyLoginDto>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    if (firestoreUser.uid) {
      return Api()
        .employee.login(firestoreUser.uid) //Change to new employee login
        .then((res) => {
          if (res.status === 204) {
            dispatch({
              type: "ACCOUNT_SIGN_IN",
              payload: {
                firestoreUser: firestoreUser,
              },
            });
          } else {
            dispatch({
              type: "ACCOUNT_SIGN_IN",
              payload: {
                accountType: "Employee",
                activeAccountId: res.data.id, //change
                firestoreUser: firestoreUser,
              },
            });
          }
          dispatch({ type: "SET_IS_LOADING", payload: false });
          return Promise.resolve(res);
        })
        .catch(() => {
          firebase.auth().signOut();
          dispatch({ type: "SET_IS_LOADING", payload: false });
          return Promise.reject();
        });
    } else {
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.reject();
    }
  };

const signOut = (dispatch: AuthStoreDispatch) => async (): Promise<void> => {
  dispatch({ type: "SET_IS_LOADING", payload: true });

  return firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: "ACCOUNT_SIGN_OUT" });
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.resolve();
    })
    .catch(() => {
      dispatch({ type: "SET_IS_LOADING", payload: false });
      return Promise.reject();
    });
};

export const AuthActions = (dispatch: AuthStoreDispatch, state: authState) => ({
  loginPhoneNumber: loginPhoneNumber(dispatch),
  loginEmailAndPassword: loginEmailAndPassword(dispatch),
  loginEmailAndPasswordEmployee: loginEmailAndPasswordEmployee(dispatch),
  signOut: signOut(dispatch),
});
