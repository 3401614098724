import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GigDetail } from "web-apps/company/components/gigDetail/gigDetail.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { PaymentMethod } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { getGigStatus } from "utils/utils";
import { H3, Text } from "components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "components/pageLayouts/pageLayouts.styled";
import { RowWrapper } from "web-apps/company/components/gigDetail/gigDetail.styled";
import { BackCustomLink } from "web-apps/company/components/applicantDetails/applicantDetails.styled";
import { useGetGig } from "hooks/useGetGig";

type MatchProps = {
  id?: string;
};

export const GigDetailPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const { t } = useTranslation();
  const id = props.match.params.id;
  const [companyState] = useCompanyStore();
  const history = useHistory();
  const [gigData, isLoading, , fetchGig] = useGetGig(id);

  useEffect(() => {
    if (
      companyState.company?.id &&
      gigData &&
      gigData.companyId !== companyState.company?.id
    ) {
      history.goBack();
    }
  }, [companyState.company?.id, gigData]);

  useEffect(() => {
    if (!gigData) {
      fetchGig(id);
    }
  }, [companyState.company?.id, gigData]);

  return (
    <>
      {isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          {gigData && (
            <>
              <RowWrapper justify="space-between">
                <H3>
                  {t("GigsCompany.GigAt", {
                    position: t(gigData.role || "", { ns: "roles" }),
                    company: companyState.company?.companyName,
                  })}
                </H3>
                <div style={{ display: "flex" }}>
                  <Text
                    color={
                      getGigStatus(gigData) === "ComingUp" ||
                      getGigStatus(gigData) === "Ongoing"
                        ? Color.BurntSienna
                        : Color.Destructive
                    }
                    fontFamily={FontFamily.MontserratRegular}
                    fontSize={FontSize.Standard}
                    backgroundColor={
                      getGigStatus(gigData) === "ComingUp" ||
                      getGigStatus(gigData) === "Ongoing"
                        ? Color.Positive
                        : Color.ChampagnePink
                    }
                    padding
                  >
                    {t(`GigsCompany.${getGigStatus(gigData)}`)}
                  </Text>
                  {gigData.paymentType === PaymentMethod.SIMFLEXITY_PAY && (
                    <Text
                      color={
                        getGigStatus(gigData) === "ComingUp" ||
                        getGigStatus(gigData) === "Ongoing"
                          ? Color.BurntSienna
                          : Color.Destructive
                      }
                      fontFamily={FontFamily.MontserratRegular}
                      fontSize={FontSize.Standard}
                      backgroundColor={
                        getGigStatus(gigData) === "ComingUp" ||
                        getGigStatus(gigData) === "Ongoing"
                          ? Color.Positive
                          : Color.ChampagnePink
                      }
                      style={{ marginLeft: 5 }}
                      padding
                    >
                      {t("SimflexityPay.UsingSimflexityPay")}
                    </Text>
                  )}
                </div>
              </RowWrapper>
              <BackCustomLink
                onClick={() => history.goBack()}
                color={Color.Destructive}
              >
                {t("General.Back")}
              </BackCustomLink>
              <GigDetail
                gigData={gigData}
                onGigClosed={() => fetchGig(gigData.id)}
              />
            </>
          )}
        </LoggedInPageContainer>
      )}
    </>
  );
};
