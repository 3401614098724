import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CompanyGigDto, GigStatus } from "model/Gig";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { ArchivedGigs } from "web-apps/company/components/gig/archivedGigs.component";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { Api } from "services/api/api.service";
import { useWindowScrollProgress } from "hooks/useWindowScrollProgress";

const GIGS_TO_FETCH = 30

export const ArchivePage = () => {
  const { t } = useTranslation();

  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();

  const apiState = useRef({ loading: false, completed: false, page: 0 })
  const [archivedGigs, setArchivedGigs] = useState<CompanyGigDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchGigs = useCallback(() => {
    if (!companyState.company || apiState.current.loading || apiState.current.completed) return;

    setIsLoading(true);  
    apiState.current.loading = true;

    Api().company.gig.getGigs(companyState.company.id, apiState.current.page, GIGS_TO_FETCH, GigStatus.CLOSED, false)
    .then((res) => {
      setArchivedGigs([...archivedGigs, ...res.data]);
      
      if (res.data.length >= GIGS_TO_FETCH) {
        apiState.current.page++
      }
      else {
        apiState.current.completed = true;
      }
    })
    .finally(() => {
      setIsLoading(false)
      apiState.current.loading = false;
    })
  }, [archivedGigs, companyState.company])

  useEffect(() => {
    fetchGigs()
  }, []);

  useWindowScrollProgress(fetchGigs)

  return (
    <LoggedInPageContainer>
      {authState.isLoading || companyState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          <H3>{t("GigsCompany.ArchivedGigs")}</H3>
          <CustomLink to="../gigs">
            <Text
              fontSize={FontSize.Large}
              color={Color.Destructive}
              align="right"
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("General.Back")}
            </Text>
          </CustomLink>
          <ArchivedGigs gigs={archivedGigs} loading={isLoading} />
        </>
      )}
    </LoggedInPageContainer>
  );
};
