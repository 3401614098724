import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CalendarTemplateDto, GigTemplateDto } from "model/GigTemplate";

const getTemplates = async (
  companyId: number,
  page?: number,
  amount?: number
): Promise<AxiosResponse<GigTemplateDto[]>> => {
  return await axiosRequest.auth
    .get<GigTemplateDto[]>(`${API_ROOT}/company/${companyId}/templates`, {
      params: {
        page,
        amount,
      },
    })
    .then((res) => res);
};

const saveTemplate = async (
  companyId: number,
  newTemplate: Omit<GigTemplateDto, "id">,
  templateId?: number
): Promise<AxiosResponse<GigTemplateDto>> => {
  if (templateId) {
    return await axiosRequest.auth
      .put<GigTemplateDto>(
        `${API_ROOT}/company/${companyId}/templates/${templateId}`,
        newTemplate
      )
      .then((res) => res);
  } else {
    return await axiosRequest.auth.post<GigTemplateDto>(
      `${API_ROOT}/company/${companyId}/templates`,
      newTemplate
    );
  }
};

const deleteTemplate = async (
  companyId: number,
  templateId: number
): Promise<AxiosResponse<void>> => {
  return await axiosRequest.auth.delete<void>(
    `${API_ROOT}/company/${companyId}/templates/${templateId}`
  );
};

const getWeekTemplates = async (
  companyId: number
  /*   page?: number,
  amount?: number */
): Promise<AxiosResponse<CalendarTemplateDto[]>> => {
  return await axiosRequest.auth
    .get<CalendarTemplateDto[]>(
      `${API_ROOT}/company/${companyId}/templates/Week`
    )
    .then((res) => res);
};

const saveWeekTemplate = async (
  companyId: number | undefined,
  newTemplate: CalendarTemplateDto
): Promise<AxiosResponse<CalendarTemplateDto>> => {
  return await axiosRequest.auth.post<CalendarTemplateDto>(
    `${API_ROOT}/company/${companyId}/Templates/Week`,
    newTemplate
  );
};

const deleteWeeklyTemplate = async (
  companyId: number,
  templateId: number
): Promise<AxiosResponse<void>> => {
  return await axiosRequest.auth.delete<void>(
    `${API_ROOT}/company/${companyId}/templates/Week/${templateId}`
  );
};

export const template = {
  getTemplates,
  saveTemplate,
  deleteTemplate,
  getWeekTemplates,
  saveWeekTemplate,
  deleteWeeklyTemplate,
};
