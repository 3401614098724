import styled from "styled-components";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";

export const WorkerDocumentListTitle = styled.p`
  font-family: ${FontFamily.MontserratSemiBold};
  font-size: 14px;
  color: ${Color.MidnightBlue};
`;
export const WorkerDocumentList = styled.ul`
  list-style: none;
  padding: 0;
`;
export const WorkerDocumentListItem = styled.li`
  margin-bottom: 20px;
`;
export const WorkerDocumentListItemTitle = styled.p`
  font-family: ${FontFamily.MontserratRegular};
  margin-bottom: 10px !important;
  font-size: 14px;
`;

export const WorkerDocumentFileSize = styled.span`
  font-size: ${FontSize.Small};
  color: ${Color.MidnightBlue};
`;
export const WorkerDocumentNoFiles = styled.p`
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.MontserratRegular};
  color: ${Color.MidnightBlue};
`;
export const WorkerDocumentDownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const WorkerDocumentDownload = styled.a`
  display: flex;
  cursor: pointer;
  font-family: ${FontFamily.MontserratSemiBold};
  border-radius: 2px;
  color: ${Color.White};
  font-size: ${FontSize.Small};
  padding: 10px;
  background-color: ${Color.BurntSienna};
  &:hover {
    opacity: 0.6;
  }
`;
