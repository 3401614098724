import { AxiosResponse } from "axios";
import { EmployeeState } from "./employeeStore.reducer";
import { EmployeeDispatch } from "./employeeStore";
import {
  getCompanyImg,
  updateCompanyImg,
} from "services/firebase/firebase.service";
import { Api } from "services/api/api.service";
import { CompanyDto, CompanyRequestDto } from "model/Company";

const saveEmployee =
  (dispatch: EmployeeDispatch) =>
  (
    company: CompanyRequestDto,
    companies: CompanyDto[],
    companyId?: number
  ): Promise<AxiosResponse<CompanyDto>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = Api()
      .company.addOrUpdateCompany(company, companyId)
      .then((res) => {
        dispatch({
          type: "UPDATE_EMPLOYEE_INFORMATION",
          payload: res.data,
        });

        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating company", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });

    dispatch({ type: "SET_IS_LOADING", payload: false });
    return res;
  };

const getCompanyImage =
  (dispatch: EmployeeDispatch) =>
  async (companyId: number): Promise<any> => {
    dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

    getCompanyImg(companyId)
      .then((res) => {
        dispatch({
          type: "GET_EMPLOYEE_IMAGE",
          payload: res,
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
      })
      .catch((e: Error) => {
        console.error("Error when updating employee", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
      });
  };

const updateEmployeeImage =
  (dispatch: EmployeeDispatch) =>
  async (employeeId: number, file: File): Promise<any> => {
    dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

    return updateCompanyImg(employeeId, file)
      .then(() => {
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when updating employee", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        return Promise.reject();
      });
  };

export const companyActions = (
  dispatch: EmployeeDispatch,
  state: EmployeeState
) => ({});
