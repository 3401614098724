import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import OrgNummer from "personnummer.js";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "stores/authStore/authStore";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  TextInput,
  LabelStyled,
  InputContainer,
} from "components/form/inputs/inputs.styled";
import {
  FormStyled,
  RowWrapper,
} from "../registrationContainer/registrationContainer.styled";

import { LocationMap } from "web-apps/company/components/location/locationMap.component";
import { ProgressIndicator } from "components/form/progressIndicator/progressIndicator.component";
import { Api } from "services/api/api.service";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  registrationNumber: string;
  setRegistrationNumber: React.Dispatch<React.SetStateAction<string>>;
  legalName: string;
  setLegalName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  companyAdress: string;
  setCompanyAdress: React.Dispatch<React.SetStateAction<string>>;
  postalCode: string;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  companyBio: string;
  setCompanyBio: React.Dispatch<React.SetStateAction<string>>;
  /*   collectiveAgreement: string;
  setCollectiveAgreement: React.Dispatch<React.SetStateAction<string>>; */
};

export const CompanyInfoForm: React.FC<Props> = ({
  page,
  setPage,
  companyName,
  setCompanyName,
  registrationNumber,
  setRegistrationNumber,
  legalName,
  setLegalName,
  city,
  setCity,
  companyAdress,
  setCompanyAdress,
  postalCode,
  setPostalCode,
  location,
  setLocation,
  companyBio,
  setCompanyBio,
  /*   collectiveAgreement,
  setCollectiveAgreement, */
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, authActions] = useAuthStore();
  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  /*   const [hasCollectiveAgreement, setHasCollectiveAgreement] = useState(
    Boolean(collectiveAgreement)
  ); */
  const [companyState] = useCompanyStore();

  const validate = () => {
    if (
      companyName &&
      registrationNumber &&
      legalName &&
      city &&
      validateAddress() &&
      !registrationNumberError /* &&
      ((!hasCollectiveAgreement && collectiveAgreement.length === 0) ||
        (hasCollectiveAgreement && collectiveAgreement)) */
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    validateAddress();
    console.log(location, "+", companyAdress, "+", city, "+", postalCode);
  }, [location, companyAdress, city, postalCode]);

  const validateRegistrationNumber = (value: string) => {
    if (OrgNummer.validateCIN(value)) {
      setRegistrationNumberError(false);
      fetchCompanyInfo(value);
      return true;
    } else {
      setRegistrationNumberError(true);
      return false;
    }
  };

  const validateAddress = () => {
    if (city && companyAdress && postalCode && location) {
      return true;
    } else {
      return false;
    }
  };

  const fetchCompanyInfo = (number: string) => {
    Api()
      .company.getCompanyInformation(number)
      .then((res) => {
        let replacedResponse = res.data
          .replace(/'/g, '"')
          .replace(/: None/g, ": null")
          .replace(/: False/g, ": false")
          .replace(/: True/g, ": true");
        const jsonRes = JSON.parse(replacedResponse);
        jsonRes.namn && setLegalName(jsonRes.namn);
        jsonRes.adress.postadress &&
          setCompanyAdress(jsonRes.adress.postadress);
        jsonRes.adress.postnummer && setPostalCode(jsonRes.adress.postnummer);
        jsonRes.adress.postort &&
          setCity(
            jsonRes.adress.postort.charAt(0).toUpperCase() +
              jsonRes.adress.postort.toLowerCase().slice(1)
          );
        if (
          (jsonRes.adress.postadress,
          jsonRes.adress.postnummer,
          jsonRes.adress.postort)
        ) {
          geocodeByAddress(
            `${jsonRes.adress.postadress}, ${jsonRes.adress.postort}`
          ).then(async (geoData) => {
            const geoLoc = await getLatLng(geoData[0]);
            if (
              geoLoc &&
              jsonRes.adress.postadress &&
              jsonRes.adress.postnummer &&
              jsonRes.adress.postort
            ) {
              setLocation(`${geoLoc.lat}, ${geoLoc.lng}`);
              setCompanyAdress(jsonRes.adress.postadress);
              setPostalCode(jsonRes.adress.postnummer);
              setCity(
                jsonRes.adress.postort.charAt(0).toUpperCase() +
                  jsonRes.adress.postort.toLowerCase().slice(1)
              );
            }
          });
        }
      })

      .catch((err) => console.log("ERROR FETCHING COMPANY INFORAMTION", err));
  };

  const cancelRegistration = () => {
    if (companyState.company) {
      history.push("/company/dashboard");
    } else {
      authActions.signOut().then(() => {
        history.push("./login");
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      setPage(page + 1);
    }
  };

  return (
    <FormStyled onSubmit={handleSubmit}>
      <ProgressIndicator steps={3} currentStep={1} />
      <H3>{t("RegisterCompany.CompanyInformation")}</H3>
      <InputContainer>
        <LabelStyled>
          {t("RegisterCompany.RegistrationNumber") + "*"}
        </LabelStyled>
        <TextInput
          type="text"
          value={registrationNumber}
          onChange={(e) => {
            validateRegistrationNumber(e.currentTarget.value.trim());
            setRegistrationNumber(e.currentTarget.value.trim());
          }}
        />
      </InputContainer>
      {registrationNumberError && (
        <Text color={Color.Destructive} fontSize={FontSize.Small}>
          {t("RegisterCompany.InvalidRegistrationNumber")}
        </Text>
      )}

      <InputContainer>
        <LabelStyled>{t("RegisterCompany.LegalName") + "*"}</LabelStyled>
        <TextInput
          type="text"
          value={legalName}
          onChange={(e) => {
            setLegalName(e.currentTarget.value);
          }}
        />
      </InputContainer>
      <InputContainer>
        <LabelStyled>{t("RegisterCompany.CompanyAddress") + "*"}</LabelStyled>

        <LocationMap
          address={companyAdress && city ? `${companyAdress}, ${city}` : ""}
          setAddress={setCompanyAdress}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setLocation={setLocation}
          inRegistration={true}
          noMap
        />
        {!validateAddress() && companyAdress !== "" && (
          <Text color={Color.Destructive} fontSize={FontSize.Small}>
            {t("RegisterCompany.LocationHelperText")}
          </Text>
        )}
      </InputContainer>
      <InputContainer>
        <LabelStyled>{t("RegisterCompany.DisplayName") + "*"}</LabelStyled>
        <TextInput
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.currentTarget.value)}
        />
      </InputContainer>

      {/* <RowWrapper style={{ alignItems: "center" }}>
        <Text
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
        >
          {t("RegisterCompany.DoYouHaveCollectiveAgreement")}
        </Text>

        <CheckBox
          onClick={() => {
            setHasCollectiveAgreement(!hasCollectiveAgreement);
            setCollectiveAgreement("");
          }}
        >
          {hasCollectiveAgreement && (
            <CustomIcon color={Color.BurntSienna} name="checkmark" />
          )}
        </CheckBox>
      </RowWrapper>

      {hasCollectiveAgreement && (
        <InputContainer>
          <LabelStyled>{t("RegisterCompany.CollectiveAgreement")}</LabelStyled>
          <TextInput
            onChange={(e) => setCollectiveAgreement(e.target.value)}
            value={collectiveAgreement}
          />
        </InputContainer>
      )} */}

      <ButtonStyled disabled={!validate() || page !== 0}>
        {t("RegisterCompany.Continue")}
      </ButtonStyled>
      <RowWrapper>
        <CustomLink
          color={Color.Destructive}
          to={"./login"}
          onClick={cancelRegistration}
        >
          {t("General.Cancel")}
        </CustomLink>
      </RowWrapper>
    </FormStyled>
  );
};
