import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import {
  GigCell,
  GigText,
  ShiftCell,
  ShiftColumn,
} from "../calendar/weeklyCalendar.styled";
import moment from "moment";
import { CompanyCalendarShift } from "model/Calendar";
import { ScheduleShift } from "model/Employees";
import { Modal } from "@material-ui/core";
import {
  CloseLinkWrapper,
  ShiftFormModalBody,
} from "./companyEmployees.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { ShiftDetails } from "./shiftDetails.component";

type CalendarStationProps = {
  shiftsByStation: { station: { id: number; name: string }; shifts: any[] };
  stationIndex: number;
  dashboard?: boolean;
  currentDates: any[];
  setShowCreateShiftModal: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedStation: React.Dispatch<
    React.SetStateAction<{ id: number; name: string } | null>
  >;
  setClickedDay: React.Dispatch<React.SetStateAction<string>>;
  roles: { role: string; id: number }[];
  stations: { name: string; id: number }[];
  scheduleShifts: ScheduleShift[];
  setScheduleShifts: React.Dispatch<React.SetStateAction<ScheduleShift[]>>;
};

export const CalendarStation: React.FC<CalendarStationProps> = ({
  shiftsByStation,
  stationIndex,
  dashboard,
  currentDates,
  setShowCreateShiftModal,
  setClickedStation,
  setClickedDay,
  roles,
  stations,
  scheduleShifts,
  setScheduleShifts,
}) => {
  const { t } = useTranslation();

  const [emptyDays, setEmptyDays] = useState<number[]>([]);

  const [shiftsByDay, setShiftsByDay] = useState<any>([]);
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [showShiftModal, setShowShiftModal] = useState(false);
  const [currentShift, setCurrentShift] = useState<ScheduleShift | null>(null);

  const getUnusedWeekDays = () => {
    let weekdays = [1, 2, 3, 4, 5, 6, 7];
    shiftsByStation.shifts.forEach((shift: CompanyCalendarShift) => {
      let shiftDay = moment(shift.startTime).day();
      if (shiftDay === 0) {
        shiftDay = 7;
      }

      weekdays = weekdays.filter((day) => day !== shiftDay);
    });
    setEmptyDays(weekdays);
  };

  useEffect(() => {
    getUnusedWeekDays();

    const byDay = weekdays.map((day) => {
      return shiftsByStation.shifts.filter((shift) => {
        return shift.dayOfWeek === day;
      });
    });
    setShiftsByDay(byDay); //Korrect för söndagar
  }, [shiftsByStation]);

  const renderShiftCell = (
    type: "not-filled" | "filled",
    keyId: number,
    shift: ScheduleShift,
    name: string,
    onClick?: () => void
  ) => {
    let tooltipId = "not-filled";
    let tooltipContent = t("CalendarCompany.NotFilled");
    let bgColor = Color.SeaBlue300;
    let borderColor = Color.SeaBlue500;
    let startTime = shift.startTime;
    let endTime = shift.endTime;

    switch (type) {
      case "filled":
        tooltipId = "worker-name";
        tooltipContent = name;
        bgColor = Color.Positive;
        startTime = shift?.startTime ?? "";
        endTime = shift?.endTime ?? "";

        borderColor = Color.BurntSienna;
        break;
    }

    return (
      <ShiftCell
        key={keyId}
        leftBorderColor={borderColor}
        backgroundColor={bgColor}
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipContent}
        disabled={type === "not-filled"}
        onClick={() => {
          onClick?.();
        }}
      >
        <Text color={Color.White}>
          {`${moment(startTime).format("HH:mm")} - ${moment(endTime).format(
            "HH:mm"
          )}`}
        </Text>

        <Text color={Color.White}>{tooltipContent}</Text>
      </ShiftCell>
    );
  };

  return (
    <>
      <GigCell>
        <GigText
          data-tooltip-id={
            shiftsByStation.station.name.length > 18 ? "gig-role" : ""
          }
          data-tooltip-content={shiftsByStation.station.name}
          fontSize={dashboard ? FontSize.Small : FontSize.Standard}
          color={Color.White}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {shiftsByStation.station.name.length > (dashboard ? 7 : 18)
            ? shiftsByStation.station.name.slice(0, dashboard ? 7 : 19) + "..."
            : shiftsByStation.station.name}
        </GigText>
        {shiftsByStation.station.name.length > 18 && (
          <Tooltip
            place="top"
            id="gig-role"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 15px 0 rgba(185, 185, 185, 0.5)",
            }}
          />
        )}
      </GigCell>
      {emptyDays.map((day) => {
        return (
          <ShiftColumn
            key={day}
            column={day === 0 ? 7 : day}
            row={stationIndex}
            onHover={true}
            onClick={() => {
              setClickedDay(
                currentDates[(day === 0 ? 7 : day) - 1].date.format(
                  "YYYY-MM-DD"
                )
              );
              setClickedStation(shiftsByStation.station);
              setShowCreateShiftModal(true);
            }}
          >
            <CustomIcon
              id={"plusIcon"}
              name="plus_old"
              size="30px"
              color={Color.Positive}
              style={{ alignSelf: "center" }}
            />
          </ShiftColumn>
        );
      })}
      {shiftsByDay.map((shiftArray: ScheduleShift[]) => {
        if (shiftArray[0]) {
          const day =
            moment(shiftArray[0].startTime).day() > 0
              ? moment(shiftArray[0].startTime).day()
              : 7;
          return (
            <ShiftColumn
              key={shiftArray[0].id}
              column={day}
              row={stationIndex}
              onHover={true}
            >
              {shiftArray.map((shift: ScheduleShift, index) => {
                const keyId = shift.id + index;
                // Render filled cell
                if (shift.employee?.firstName) {
                  return renderShiftCell(
                    "filled",
                    keyId,
                    shift,
                    shift.employee
                      ? `${shift.employee.firstName} ${shift.employee.lastName}`
                      : "",
                    () => {
                      setCurrentShift(shift);
                      setShowShiftModal(true);
                    }
                  );
                  // Render default, not-filled, cell
                } else {
                  return renderShiftCell("not-filled", keyId, shift, "", () => {
                    setCurrentShift(shift);
                    setShowShiftModal(true);
                  });
                }
              })}
              <CustomIcon
                id={"plusIcon"}
                name="plus_old"
                size="70px"
                color={Color.Positive}
                padding="10px"
                style={{
                  alignSelf: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
                onClick={() => {
                  setClickedDay(
                    currentDates[day - 1].date.format("YYYY-MM-DD")
                  );
                  setClickedStation(shiftsByStation.station);
                  setShowCreateShiftModal(true);
                }}
              />
            </ShiftColumn>
          );
        }
      })}

      <Modal
        open={showShiftModal}
        onClose={() => {
          setShowShiftModal(false);
        }}
      >
        <ShiftFormModalBody>
          <CloseLinkWrapper>
            <CustomLink
              color={Color.Destructive}
              onClick={() => setShowShiftModal(false)}
            >
              {t("General.Close")}
            </CustomLink>
          </CloseLinkWrapper>
          <H3>{t("CompanyEmployees.Shift")}</H3>
          {currentShift && (
            <ShiftDetails
              setShowShiftDetailsModal={setShowShiftModal}
              shift={currentShift}
              stations={stations}
              roles={roles}
              scheduleShifts={scheduleShifts}
              setScheduleShifts={setScheduleShifts}
              currentDates={currentDates}
            />
          )}
        </ShiftFormModalBody>
      </Modal>
    </>
  );
};
