import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { CustomLink, Text } from "components/Typography/text.styled";
import { BigModalBody, CircularProgressWrapper } from "./weeklyCalendar.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import { GigDetail } from "../gigDetail/gigDetail.component";
import { useGetGig } from "hooks/useGetGig";

type GigDetailsModalProps = {
  gigId: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
};

export const GigDetailsModal: React.FC<GigDetailsModalProps> = (props) => {
  const [gigData, isLoading, error, fetchGig] = useGetGig(props.gigId);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      props.setModalOpen(false);
    }
  }, [error]);

  return (
    <Modal
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BigModalBody>
        {isLoading ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {gigData && (
                <Text
                  fontSize={FontSize.H3}
                  fontFamily={FontFamily.LeagueSpartanBold}
                  style={{ margin: "8px 0" }}
                >
                  {gigData.role}
                </Text>
              )}

              <CustomLink
                onClick={() => props.setModalOpen(false)}
                color={Color.Destructive}
              >
                {t("General.Close")}
              </CustomLink>
            </div>

            {gigData && (
              <GigDetail
                gigData={gigData}
                onGigClosed={() => fetchGig(gigData.id)}
              />
            )}
          </>
        )}
      </BigModalBody>
    </Modal>
  );
};
