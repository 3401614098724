import styled from "styled-components";
import { Color } from "config/colors";

export const ImageWrapper = styled.div`
  width: 250px;
  height: 190px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type ProfileGridProps = {
  view?: string;
};

export const ProfileGrid = styled.div<ProfileGridProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${(props) =>
    props.view === "desktop"
      ? `
    @media (min-width: 1350px) {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 270px 2px 1fr;
      grid-template-rows: 250px auto;
    }`
      : `
    @media (min-width: 768px) {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 270px 2px 1fr;
      grid-template-rows: 250px auto;
    }}
  `}

  button > img {
    margin-left: 10px;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/2;
  grid-row: 1/3;
  padding: 20px 0;
  align-self: center;
  text-align: center;
  width: 270px;
  margin: 0 auto;

  @media (min-width: 768px) {
    align-self: start;
  }

  p {
    margin: 5px 0;
  }
`;

export const IconWrapper = styled.div<{ button: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${Color.BurntSienna};
  padding: 10px;
  transition: opacity 0.15s;

  ${(props) =>
    props.button &&
    `&:hover {
      cursor: pointer;
      opacity: 0.6;
    }`}

  svg {
    width: 100%;
    height: 100%;
  }

  svg[fill],
  svg *[fill] {
    fill-opacity: initial;
    fill: ${Color.White};
  }

  svg[fill="none"],
  svg *[fill="none"] {
    // fill-opacity: 0;
    fill: ${Color.White};
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const AboutDiv = styled.div`
  grid-column: 3/4;
  grid-row: 1/2;
  overflow-y: auto;
  margin: 20px 0 0 0;
  max-height: 390px;
`;

export const DividerLine = styled.div`
  background-color: ${Color.BurntSienna};

  width: 30px;
  height: 5px;
  align-self: center;

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    background-color: ${Color.SeaBlue200};
  }
`;

export const CompanyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;

export const ActiveGigsContainer = styled.div`
  grid-column: 3/4;
  grid-row: 2/3;
  margin-bottom: 10px;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;

export const NoGigsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  text-align: center;

  h4 {
    margin: 0px;
  }
`;

export const SimflexityPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
`;

export const FavoritesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.SeaBlue200};
  grid-row: 2/3;
  margin-bottom: 40px;
  margin-top: 20px;
  width: 100%;
  padding: 10px 10px 10px 5px;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;
