import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "../companyStore/companyStore";
import { timeReportActions } from "./timeReportStore.actions";
import {
  TimeReportActions,
  timeReportInitState,
  TimeReportState,
  TimeReportStateReducer,
} from "./timeReportStore.reducer";

export type TimeReportDispatch = (action: TimeReportActions) => void;

const TimeReportStateContext = createContext<TimeReportState | undefined>(
  undefined
);
const TimeReportDispatchContext = createContext<TimeReportDispatch | undefined>(
  undefined
);

export const TimeReportStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    TimeReportStateReducer,
    timeReportInitState
  );
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (companyState.company?.id) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.timeReport.getUnhandledTimeReportCount(companyState.company.id)
        .then((res) => {
          dispatch({
            type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
            payload: res.data.count,
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch((e: Error) => {
          console.error("Error when fetching time report count", e);
          dispatch({
            type: "SET_REQUEST_ERROR",
            payload: { message: "", err: e },
          });

          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company]);

  return (
    <TimeReportStateContext.Provider value={state}>
      <TimeReportDispatchContext.Provider value={dispatch}>
        {children}
      </TimeReportDispatchContext.Provider>
    </TimeReportStateContext.Provider>
  );
};

export const useTimeReportStore = () => {
  const state = useContext(TimeReportStateContext);
  const dispatch = useContext(TimeReportDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useTimeReportStore must be used within a TimeReportStoreProvider"
    );
  }

  const actions = timeReportActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
