import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { SkillAndEndorsements } from "model/TimeReport";
import { Row, RowDisabled } from "./endorseModal.styled";

type EndorsementListItemProps = {
  workerSkillsAndEndorsements: SkillAndEndorsements;
  skill: string;
  endorsements: string[];
  setEndorsements: React.Dispatch<React.SetStateAction<string[]>>;
};

export const EndorsementListItem: React.FC<EndorsementListItemProps> = (
  props
) => {
  useState<SkillAndEndorsements>();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selected) {
      props.setEndorsements([...props.endorsements, props.skill]);
    } else {
      const newEndorsements = props.endorsements;

      props.setEndorsements(
        newEndorsements.filter((endorsement) => endorsement !== props.skill)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      {props.workerSkillsAndEndorsements &&
      props.workerSkillsAndEndorsements.endorsements.some(
        (endorsement) =>
          endorsement.skill.toLowerCase() === props.skill.toLowerCase()
      ) ? (
        <RowDisabled>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.SeaBlue500}
            fontSize={FontSize.Large}
          >
            {props.skill}
          </Text>
          <CustomIcon name="star-filled" color={Color.SeaBlue500} />
        </RowDisabled>
      ) : (
        <Row onClick={() => setSelected(!selected)}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.MidnightBlue}
            fontSize={FontSize.Large}
          >
            {props.skill}
          </Text>
          <CustomIcon
            name={!selected ? "star" : "star-filled"}
            color={Color.MidnightBlue}
          />
        </Row>
      )}
    </>
  );
};
