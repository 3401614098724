import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "../companyStore/companyStore";
import { workpoolActions } from "./workpoolStore.actions";
import {
  WorkpoolActions,
  workpoolInitState,
  WorkpoolState,
  workpoolStateReducer,
} from "./workpoolStore.reducer";

export type WorkpoolDispatch = (action: WorkpoolActions) => void;

const WorkpoolStateContext = createContext<WorkpoolState | undefined>(
  undefined
);
const WorkpoolDispatchContext = createContext<WorkpoolDispatch | undefined>(
  undefined
);

export const WorkpoolStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(workpoolStateReducer, workpoolInitState);
  const [companyState] = useCompanyStore();

  useEffect(() => {
    if (companyState.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.workpool.getWorkpoolUnverifiedEmployees(
          companyState.company.id
        )
        .then((res) => {
          dispatch({
            type: "GET_ALL_UNVERIFIED_EMPLOYEES",
            payload: res.data.reverse(),
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    if (companyState.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.workpool.getWorkpoolVerifiedEmployees(companyState.company.id)
        .then((res) => {
          dispatch({
            type: "GET_ALL_VERIFIED_EMPLOYEES",
            payload: res.data.reverse(),
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company]);

  useEffect(() => {
    if (companyState.company) {
      dispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.workpool.getDeclinedEmployees(companyState.company.id)
        .then((res) => {
          const filtered = res.data.filter((declined) => {
            const matchUnverified = state.unverifiedEmployees.some(
              (unverified) => unverified.workerId === declined.workerId
            );
            const matchVerified = state.verifiedEmployees.some(
              (verified) => verified.workerId === declined.workerId
            );

            return (
              (!matchUnverified || matchUnverified === undefined) &&
              (!matchVerified || matchVerified === undefined)
            );
          });

          const filterDuplicates = filtered.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.workerId === value.workerId)
          );

          dispatch({
            type: "GET_ALL_DECLINED_EMPLOYEES",
            payload: filterDuplicates.reverse(),
          });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch(() => {
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company, state.unverifiedEmployees]);

  return (
    <WorkpoolStateContext.Provider value={state}>
      <WorkpoolDispatchContext.Provider value={dispatch}>
        {children}
      </WorkpoolDispatchContext.Provider>
    </WorkpoolStateContext.Provider>
  );
};

export const useWorkpoolStore = () => {
  const state = useContext(WorkpoolStateContext);
  const dispatch = useContext(WorkpoolDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useWorkpoolStore must be used within a WorkpoolStoreProvider"
    );
  }

  const actions = workpoolActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
