import { CategoryDto } from "model/Category";
import { PositionDto } from "model/Position";

export type GlobalActions =
  | {
      type: "UPDATE_CATEGORIES";
      payload: CategoryDto[];
    }
  | {
      type: "UPDATE_POSITIONS";
      payload: PositionDto[];
    }
  | {
      type: "SET_IS_LOADING";
      payload: boolean;
    };

export type GlobalStoreState = {
  positions: PositionDto[];
  categories: CategoryDto[];
  isLoading: boolean;
};

export const initGlobalStoreState: GlobalStoreState = {
  positions: [],
  categories: [],
  isLoading: false,
};

export const globalStoreReducer = (
  state: GlobalStoreState,
  action: GlobalActions
) => {
  switch (action.type) {
    case "UPDATE_CATEGORIES": {
      return { ...state, categories: action.payload };
    }
    case "UPDATE_POSITIONS": {
      return { ...state, positions: action.payload };
    }
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
