export enum Color {
  PitchBlack = "#000000",
  White = "#FFFFFF",
  MidnightBlue = "#032A41",
  BurntSienna = "#2D8A5B",
  ChampagnePink = "#FEDC97",
  LightPear = "#B5B682",
  Positive = "#C7E2CF",
  Destructive = "#DA5552",
  SeaBlue600 = "#7990A0",
  SeaBlue500 = "#B1BFC8",
  SeaBlue400 = "#BCC7CF",
  SeaBlue300 = "#D8DFE4",
  SeaBlue200 = "#EFF2F4",
  Disabled = "#D8DFE4",
  Warning = "#ffc6c4",
  LightWarning = "#FCEBEB",
}
