import { useCallback } from "react";
import { CompanyGigDto } from "model/Gig";
import { useEffect, useState } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

export const useGetGig = (
  gigId?: string | number,
  fetchOnStartup: boolean = true
) => {
  const [companyState] = useCompanyStore();
  const [gigData, setGigData] = useState<CompanyGigDto>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (fetchOnStartup) {
      fetch(gigId);
    }
  }, [gigId]);

  const fetch = useCallback(
    (gigId) => {
      if (!companyState.company || !gigId) return;

      setIsLoading(true);
      const id = typeof gigId === "string" ? parseInt(gigId) : gigId;
      Api()
        .company.gig.getGig(companyState.company.id, id)
        .then((res) => {
          let gigData: CompanyGigDto = res.data;
          setGigData(gigData);
        })
        .catch((e: any) => {
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [companyState.company, gigId]
  );

  return [gigData, isLoading, error, fetch] as const;
};
