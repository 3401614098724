import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { CustomLink, Text } from "components/Typography/text.styled";
import { BigModalBody, CircularProgressWrapper } from "./weeklyCalendar.styled";
import { ApplicationDto } from "model/Application";
import { CircularProgress, Modal } from "@material-ui/core";
import { GigApplicantsList } from "../gigApplicants/gigApplicantsList.component";
import { CompanyCalendarGigDto } from "model/Calendar";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";

type GigApplicationsModalProps = {
  gigData: CompanyCalendarGigDto;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
};

export const GigApplicationsModal: React.FC<GigApplicationsModalProps> = (
  props
) => {
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [gigApplications, setGigApplications] = useState<ApplicationDto[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.modalOpen) {
      fetchApplications();
    }
  }, [props.modalOpen]);

  const fetchApplications = () => {
    if (companyState.company?.id) {
      setIsLoading(true);
      Api()
        .company.application.getGigApplications(
          companyState.company.id,
          props.gigData.id
        )
        .then((res) => {
          setGigApplications(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Error fetching gig applications", err);
          setIsLoading(false);
          props.setModalOpen(false);
        });
    }
  };

  return (
    <Modal
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <BigModalBody>
        {isLoading ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text
                fontSize={FontSize.H3}
                fontFamily={FontFamily.LeagueSpartanBold}
                style={{ margin: "8px 0" }}
              >
                {props.gigData.role}
              </Text>
              <CustomLink
                onClick={() => props.setModalOpen(false)}
                color={Color.Destructive}
              >
                {t("General.Close")}
              </CustomLink>
            </div>

            <GigApplicantsList
              applicants={gigApplications}
              gigId={props.gigData.id.toString()}
              setApplicants={setGigApplications}
            />
          </>
        )}
      </BigModalBody>
    </Modal>
  );
};
