import { Text } from "components/Typography/text.styled";
import styled from "styled-components";

export const GigText = styled(Text)`
  margin: 0px 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ApplicationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
