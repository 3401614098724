import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CompanyEmployee, ScheduleShift } from "model/Employees";

const getCompanyEmployees = async (
  companyId: number
): Promise<AxiosResponse<CompanyEmployee[]>> => {
  return await axiosRequest.auth
    .get<CompanyEmployee[]>(`${API_ROOT}/Company/${companyId}/CompanyEmployee`)
    .then((res) => {
      return res;
    });
};

const createCompanyEmployee = async (
  companyId: number,
  employee: CompanyEmployee
): Promise<AxiosResponse<CompanyEmployee>> => {
  return await axiosRequest.auth.post(
    `${API_ROOT}/Company/${companyId}/CompanyEmployee`,
    employee
  );
};
const removeCompanyEmployee = async (
  companyId: number,
  employeeId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth.delete(
    `${API_ROOT}/Company/${companyId}/CompanyEmployee/${employeeId}`
  );
};

const getCompanyScheduleStations = async (
  companyId: number
): Promise<AxiosResponse<{ id: number; name: string }[]>> => {
  return await axiosRequest.auth
    .get<{ id: number; name: string }[]>(
      `${API_ROOT}/Company/${companyId}/schedule/station`
    )
    .then((res) => {
      return res;
    });
};

const createCompanyScheduleStation = async (
  companyId: number,
  name: string
): Promise<AxiosResponse<CompanyEmployee[]>> => {
  return await axiosRequest.auth
    .post<CompanyEmployee[]>(
      `${API_ROOT}/Company/${companyId}/schedule/station`,
      { companyId, name }
    )
    .then((res) => {
      return res;
    });
};

const getCompanyScheduleRoles = async (
  companyId: number
): Promise<AxiosResponse<{ id: number; role: string }[]>> => {
  return await axiosRequest.auth
    .get<{ id: number; role: string }[]>(
      `${API_ROOT}/Company/${companyId}/schedule/role`
    )
    .then((res) => {
      return res;
    });
};

const createCompanyScheduleRole = async (
  companyId: number,
  role: string
): Promise<AxiosResponse<CompanyEmployee[]>> => {
  return await axiosRequest.auth
    .post<CompanyEmployee[]>(`${API_ROOT}/Company/${companyId}/schedule/role`, {
      companyId,
      role,
    })
    .then((res) => {
      return res;
    });
};

const getCompanyScheduleShifts = async (
  companyId: number,
  from: any,
  to: any
): Promise<AxiosResponse<ScheduleShift[]>> => {
  return await axiosRequest.auth
    .get<ScheduleShift[]>(
      `${API_ROOT}/Company/${companyId}/schedule/shift/${from}-${to}`
    )
    .then((res) => {
      return res;
    });
};

const createCompanyScheduleShift = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<ScheduleShift>> => {
  return await axiosRequest.auth
    .post<ScheduleShift>(
      `${API_ROOT}/Company/${companyId}/schedule/shift`,
      data
    )
    .then((res) => {
      return res;
    });
};

const assignEmployeeScheduleShift = async (
  companyId: number,
  data: any
): Promise<AxiosResponse<ScheduleShift>> => {
  return await axiosRequest.auth
    .post<ScheduleShift>(
      `${API_ROOT}/Company/${companyId}/schedule/shift/employee`,
      data
    )
    .then((res) => {
      return res;
    });
};

const deleteCompanyScheduleShift = async (
  companyId: number,
  shiftId: number
): Promise<AxiosResponse<ScheduleShift>> => {
  return await axiosRequest.auth
    .delete<ScheduleShift>(
      `${API_ROOT}/Company/${companyId}/schedule/shift/${shiftId}`
    )
    .then((res) => {
      return res;
    });
};

const deleteCompanyScheduleStation = async (
  companyId: number,
  stationId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth
    .delete<ScheduleShift>(
      `${API_ROOT}/Company/${companyId}/schedule/station/
      ${stationId}`
    )
    .then((res) => {
      return res;
    });
};

const deleteCompanyScheduleRole = async (
  companyId: number,
  roleId: number
): Promise<AxiosResponse<any>> => {
  return await axiosRequest.auth
    .delete<ScheduleShift>(
      `${API_ROOT}/Company/${companyId}/schedule/role/
      ${roleId}`
    )
    .then((res) => {
      return res;
    });
};

export const companyEmployees = {
  getCompanyEmployees,
  createCompanyEmployee,
  removeCompanyEmployee,
  getCompanyScheduleStations,
  getCompanyScheduleRoles,
  getCompanyScheduleShifts,
  createCompanyScheduleStation,
  createCompanyScheduleRole,
  createCompanyScheduleShift,
  assignEmployeeScheduleShift,
  deleteCompanyScheduleShift,
  deleteCompanyScheduleStation,
  deleteCompanyScheduleRole,
};
