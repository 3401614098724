import { useCallback } from "react";
import { CompanyGigDto } from "model/Gig";
import { useState } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useTranslation } from "react-i18next";

export const useGetManyGigs = () => {
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [gigs, setGigs] = useState<CompanyGigDto[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const getMany = useCallback((gigIds:number[]) => {
      if (!companyState.company || gigIds.length === 0) return Promise.reject('No Valid Data');

      const companyid = companyState.company.id
      setIsLoading(true);
      setProgress(0)

      return Promise.all(
        gigIds.map((id) =>
          Api().company.gig.getGig(companyid, id).then((res) => {
            setProgress((p) => p++)
            return res
          })
        )
      ).then((res) => {
        const mappedGigs = res.map<CompanyGigDto>((g) => ({ ...g.data }));
        setGigs(mappedGigs)
        setIsLoading(false);
        return Promise.resolve(mappedGigs)
      }).catch(() => {
        setIsLoading(false);
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("Alerts.SomethingWentWrong"),
          },
        });

        return Promise.reject('API error');
      })
    },
    [companyState.company]
  );

  return [gigs, isLoading, progress, getMany] as const;
};
