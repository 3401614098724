import React from "react";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { WorkerCounterWrapper } from "./workerCounter.styled";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export const WorkerCounter: React.FC<Props> = ({
  value,
  onChange,
}) => {
  return (
    <WorkerCounterWrapper>
      <CustomIcon
        name="minus"
        color={Color.White}
        backgroundColor={Color.BurntSienna}
        padding="4px"
        onClick={() => {
          value > 1 && onChange(value - 1);
        }}
      />
      <Text
        color={Color.BurntSienna}
        fontFamily={FontFamily.MontserratBold}
        style={{ margin: 0, fontSize: 28, lineHeight: "28px" }}
      >
        {value}
      </Text>
      <CustomIcon
        name="plus_old"
        color={Color.White}
        backgroundColor={Color.BurntSienna}
        padding="4px"
        onClick={() => {
          onChange(value + 1);
        }}
      />
    </WorkerCounterWrapper>
  );
};
