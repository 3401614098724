import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ChatSummary } from "web-apps/company/stores/chatStore/chatStore.reducer";
import { CompanyGigUserDto } from "model/Gig";
import { UserApplicationDto } from "model/Application";
import { getDateString } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text } from "components/Typography/text.styled";
import { Badge } from "components/buttons/buttons.styled";
import { Cell, LatestMessageWrapper } from "./chatContainerList.styled";
import { useGetGig } from "hooks/useGetGig";
import { useGetGigApplications } from "hooks/useGetGigApplications";

type Props = {
  chatSummary: ChatSummary;
  setShowChatContainer: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChatContainerListItem: React.FC<Props> = ({
  chatSummary,
  setShowChatContainer,
}) => {
  const history = useHistory();
  const [otherUser, setOtherUser] = useState<
    CompanyGigUserDto | (UserApplicationDto & { firebaseId: string | null })
  >();
  const [gigData] = useGetGig(chatSummary.gigId);
  const [gigApplications] = useGetGigApplications(chatSummary.gigId);

  const getTimeString = (str: string) => {
    const d = new Date(str);
    const localeDateString = d.toLocaleDateString(navigator.languages[0], {
      weekday: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      month: "short",
    });

    return localeDateString;
  };

  const getLatestMessageString = (str: string) => {
    if (str.length > 15) {
      return str.slice(0, 15).trim() + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    const gigUser = gigData?.workers.find(
      (worker) =>
        worker.id === parseInt(chatSummary.otherUser.userId) &&
        chatSummary.otherUser.type === "User"
    );

    if (gigUser) {
      setOtherUser(gigUser);
    } else {
      const applicationUser = gigApplications?.find((application) => {
        return (
          application.worker.id === parseInt(chatSummary.otherUser.userId) &&
          chatSummary.otherUser.type === "User" &&
          application.gigId === parseInt(chatSummary.gigId)
        );
      });

      if (applicationUser?.id) {
        setOtherUser({
          ...applicationUser.worker,
          firebaseId: applicationUser.worker.firebaseId,
        });
      }
    }
  }, [gigData, gigApplications, chatSummary]);

  return (
    <li>
      <Cell
        onClick={() => {
          setShowChatContainer(false);
          history.push(
            `/company/chat?userId=${chatSummary.otherUser.userId}&gigId=${chatSummary.gigId}`
          );
        }}
      >
        {chatSummary.unread > 0 && (
          <Badge>
            <Text fontSize={FontSize.Small} color={Color.White}>
              {chatSummary.unread}
            </Text>
          </Badge>
        )}
        <Text
          style={{ margin: "0 5px 0 0", display: "inline-block" }}
          fontFamily={FontFamily.LeagueSpartanBold}
        >
          {gigData?.role}
        </Text>
        <Text
          style={{ margin: "0 15px 0 0", display: "inline-block" }}
          fontFamily={FontFamily.LeagueSpartanBold}
        >
          {gigData && (
            <>
              {gigData.shifts[0] && getDateString(gigData.shifts[0].startTime)}
              {getDateString(gigData.shifts[0].startTime) !==
                getDateString(
                  gigData.shifts[gigData.shifts.length - 1].endTime
                ) &&
                ` - ${getDateString(
                  gigData.shifts[gigData.shifts.length - 1].endTime
                )}`}
            </>
          )}
        </Text>

        <Text fontSize={FontSize.Small}>{`${otherUser?.firstName || ""} ${
          otherUser?.lastName || ""
        }`}</Text>
        <LatestMessageWrapper>
          <Text>{getLatestMessageString(chatSummary.lastMessage.text)}</Text>
          <Text
            style={{ whiteSpace: "nowrap" }}
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
          >
            {getTimeString(chatSummary.lastMessage.createdAt)}
          </Text>
        </LatestMessageWrapper>
      </Cell>
    </li>
  );
};
