import { CircularProgress } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const DropdownWrapper = styled.div`
  display: flex;
`;

export const SaveButton = styled(ButtonStyled)`
  margin-left: 20px;
  font-size: ${FontSize.Small};
  padding: 8px;
`;

export const SaveButtonModal = styled(ButtonStyled)`
  margin-top: 10px;
`;

export const StyledProgress = styled(CircularProgress)`
  position: relative;
  display: flex;
  align-self: center;
  margin-left: 18px;
`;

export const PreviewBody = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 900px;
  max-height: 80vh;
  background-color: ${Color.White};
  outline: none;
  border-radius: 8px;
  overflow: hidden;

  h3 {
    margin: 0;
  }
`;

export const SaveContent = styled(PreviewBody)`
  max-width: 500px;
  min-width: 500px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-around;

  p {
    margin-top: 0;
  }
`;

export const PreviewContent = styled.div`
  padding: 20px;
  overflow-y: scroll;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);

  div {
    margin-left: 0;
  }
`;
export const ColumnCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextInput = styled.input<{ fontSize?: FontSize }>`
  border: none;
  border-bottom: 1px solid ${Color.SeaBlue400};
  width: 100%;
  padding: 5px;
  outline: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontSize.Large)};
  color: ${(props) => (props.disabled ? Color.SeaBlue600 : Color.MidnightBlue)};
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
  border-radius: 0px;

  ::placeholder {
    color: ${Color.SeaBlue400};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;
