import React, { createContext, useReducer, useContext, useEffect } from "react";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "../companyStore/companyStore";
import {
  StaffingWorkersActions,
  staffingWorkersInitState,
  StaffingWorkersState,
  StaffingWorkersStateReducer,
} from "./staffingWorkersStore.reducer";
import { staffingWorkersActions } from "./staffingWorkersStore.actions";

export type StaffingWorkersDispatch = (action: StaffingWorkersActions) => void;

const StaffingWorkersStateContext = createContext<
  StaffingWorkersState | undefined
>(undefined);
const StaffingWorkersDispatchContext = createContext<
  StaffingWorkersDispatch | undefined
>(undefined);

export const StaffingWorkersStoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    StaffingWorkersStateReducer,
    staffingWorkersInitState
  );

  return (
    <StaffingWorkersStateContext.Provider value={state}>
      <StaffingWorkersDispatchContext.Provider value={dispatch}>
        {children}
      </StaffingWorkersDispatchContext.Provider>
    </StaffingWorkersStateContext.Provider>
  );
};

export const useStaffingWorkersStore = () => {
  const state = useContext(StaffingWorkersStateContext);
  const dispatch = useContext(StaffingWorkersDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error(
      "useStaffingWorkersStore must be used within a StaffingWorkersStoreProvider"
    );
  }

  const actions = staffingWorkersActions(dispatch, state);
  return [state, actions, dispatch] as const;
};
