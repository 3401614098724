import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useAuthStore } from "stores/authStore/authStore";
import { getDateString } from "utils/utils";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { ApplicationDto } from "model/Application";
import { H3, Text } from "components/Typography/text.styled";
import { useTranslation } from "react-i18next";
import { FontSize } from "config/font";
import { ApplicantDetails } from "web-apps/company/components/applicantDetails/applicantDetails.component";
import { LoggedInPageContainer } from "components/pageLayouts/pageLayouts.styled";
import {
  ColumnContainer,
  HeaderContainer,
} from "web-apps/company/components/applicantDetails/applicantDetails.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { useAlertStore } from "stores/alertStore/alertStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { useGetGig } from "hooks/useGetGig";

type MatchParams = {
  id?: string;
};

export const ApplicantDetailPage: React.FC<RouteComponentProps<MatchParams>> = (
  props
) => {
  const id = props.match.params.id;
  const { t } = useTranslation();
  const [authState] = useAuthStore();
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();
  const [applicationState] = useApplicationStore();

  const [applicant, setApplicant] = useState<ApplicationDto>();
  const history = useHistory();
  const companyId = new URLSearchParams(window.location.search).get(
    "CompanyId"
  );
  const [gigData] = useGetGig(applicant?.gigId);

  useEffect(() => {
    if (
      companyId &&
      companyId !== companyState.company?.id.toString() &&
      companyState.companies.length > 0
    ) {
      const correctCompany = companyState.companies.find(
        (company) => company.id.toString() === companyId
      );
      if (correctCompany) {
        companyActions.switchCompany(correctCompany, companyState?.company?.id);
      } else {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t(
              "ApplicationsCompany.ThisAccountDoesNotHaveAccessToThisApplication"
            ),
          },
        });
      }
    }
  }, [companyId, companyState.companies]);

  useEffect(() => {
    if (id) {
      const applicantData = applicationState.applications.find(
        (application) => application.id === parseInt(id)
      );
      setApplicant(applicantData);
    }
  }, [applicationState.applications, id]);

  return (
    <LoggedInPageContainer>
      {authState.isLoading ||
      companyState.isLoading ||
      applicationState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          {gigData && (
            <>
              <HeaderContainer>
                <ColumnContainer>
                  <H3>
                    {t(gigData.role, { ns: "roles" })}{" "}
                    {getDateString(gigData.shifts[0].startTime)}
                  </H3>
                  <Text fontSize={FontSize.Large}>
                    {gigData.workers.length} / {gigData.amountOfWorkers}{" "}
                    {t("General.Giggers")}
                  </Text>
                </ColumnContainer>
                {applicant && (
                  <CustomIcon
                    name="chat"
                    size="40px"
                    color={Color.White}
                    backgroundColor={Color.BurntSienna}
                    padding="10px"
                    onClick={() => {
                      history.push(
                        `/company/chat?userId=${applicant.worker.id}&gigId=${applicant.gigId}&applicationId=${applicant.id}`
                      );
                    }}
                  />
                )}
              </HeaderContainer>
              {applicant && <ApplicantDetails applicant={applicant} />}
            </>
          )}
        </>
      )}
    </LoggedInPageContainer>
  );
};
