import styled from "styled-components";

export const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 550px) {
    margin-top: 25vh;
  }
`;

export const CompaniesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 300px;
  width: 200px;
  border-radius: 50%;
  padding: 8px;
  margin: 16px;

  p {
    text-align: center;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const CompanyImage = styled.img`
  box-shadow: 0 4px 15px 0 rgba(185, 185, 185, 0.5);
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;
