import React from "react";

import { TimeReports } from "web-apps/company/components/timeReport/timeReports.component";
import { CalendarContainer } from "components/pageLayouts/pageLayouts.styled";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";

export const TimeReportsPage = () => {
  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();
  return (
    <CalendarContainer>
      {authState.isLoading || companyState.isLoading ? (
        <LoaderProgress />
      ) : (
        <TimeReports />
      )}
    </CalendarContainer>
  );
};
