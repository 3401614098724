import { AxiosResponse } from "axios";
import { CompanyState } from "./companyStore.reducer";
import { CompanyDispatch } from "./companyStore";
import {
  getCompanyImg,
  updateCompanyImg,
} from "services/firebase/firebase.service";
import { Api } from "services/api/api.service";
import {
  CompanyDto,
  CompanyRequestDto,
  CompanyUserRequestDto,
  UserRoleRequest,
} from "model/Company";

const saveCompany =
  (dispatch: CompanyDispatch) =>
  (
    company: CompanyRequestDto,
    companies: CompanyDto[],
    companyId?: number
  ): Promise<AxiosResponse<CompanyDto>> => {
    dispatch({ type: "SET_IS_LOADING", payload: true });

    const res = Api()
      .company.addOrUpdateCompany(company, companyId)
      .then((res) => {
        dispatch({
          type: "UPDATE_COMPANY_INFORMATION",
          payload: {
            ...res.data,
            //registrationNumber is null in response
            registrationNumber: company.registrationNumber,
          },
        });
        if (companyId) {
          const newCompanies = companies.map((currentCompany: CompanyDto) =>
            currentCompany.id === companyId ? res.data : currentCompany
          );
          dispatch({
            type: "UPDATE_COMPANIES",
            payload: newCompanies,
          });
        } else {
          dispatch({
            type: "UPDATE_COMPANIES",
            payload: [...companies, res.data],
          });
        }

        return Promise.resolve(res);
      })
      .catch((e: Error) => {
        console.error("Error when updating company", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });

    dispatch({ type: "SET_IS_LOADING", payload: false });
    return res;
  };

const switchCompany =
  (dispatch: CompanyDispatch) => (company: CompanyDto, companyId?: number) => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    dispatch({
      type: "UPDATE_COMPANY_INFORMATION",
      payload: company,
    });
    dispatch({ type: "SET_IS_LOADING", payload: false });
  };

const getCompany = (dispatch: CompanyDispatch) => async () => {
  dispatch({ type: "SET_IS_LOADING", payload: true });
  // const authState = useAuthState();
  // const updatedCompanyProfile = await api.company
  //   .getCompany(authState.firestoreUser.uid)
  //   .then((res) => res.data)
  //   .catch((e: Error) => {
  //     console.error('Error when updating company', e);
  //     dispatch({
  //       type: 'SET_REQUEST_ERROR',
  //       payload: { message: '', err: e },
  //     });
  //   });

  // updatedCompanyProfile &&
  //   dispatch({
  //     type: 'UPDATE_COMPANY_INFORMATION',
  //     payload: updatedCompanyProfile,
  // });
  dispatch({ type: "SET_IS_LOADING", payload: false });
};

const getCompanyImage =
  (dispatch: CompanyDispatch) =>
  async (companyId: number): Promise<any> => {
    dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

    getCompanyImg(companyId)
      .then((res) => {
        dispatch({
          type: "GET_COMPANY_IMAGE",
          payload: res,
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
      })
      .catch((e: Error) => {
        console.error("Error when updating company", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
      });
  };

const updateCompanyImage =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, file: File): Promise<any> => {
    dispatch({ type: "SET_IMAGE_IS_LOADING", payload: true });

    return updateCompanyImg(companyId, file)
      .then(() => {
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when updating company", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        dispatch({ type: "SET_IMAGE_IS_LOADING", payload: false });
        return Promise.reject();
      });
  };

const addCompanyFavorite =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, workerId: number): Promise<any> => {
    return Api()
      .company.addCompanyFavorites(companyId, workerId)
      .then((res) => {
        dispatch({ type: "ADD_COMPANY_FAVORITE", payload: res.data });
        return Promise.resolve();
      })
      .catch((e: any) => {
        console.error("Error when adding favorite", e);
        let errorMessage = e.message;

        if (e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage;
        }

        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: errorMessage, err: e },
        });
        return Promise.reject(e);
      });
  };

const addCompanyUser =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, user: CompanyUserRequestDto): Promise<any> => {
    return Api()
      .company.addCompanyUser(companyId, user)
      .then((res) => {
        dispatch({ type: "UPDATE_COMPANY_USERS", payload: res.data });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when adding company User", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
  };

const updateCompanyUser =
  (dispatch: CompanyDispatch) =>
  async (
    companyId: number,
    userId: number,
    firebaseId: string,
    role: UserRoleRequest,
    firstName: string,
    lastName: string
  ): Promise<any> => {
    return Api()
      .company.updateCompanyUser(
        companyId,
        userId,
        firebaseId,
        role,
        firstName,
        lastName
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: "UPDATE_COMPANY_USERS", payload: res.data });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when uppdating company User", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
  };

const getTimeReportForStamping =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, workerPhoneNumber: string): Promise<any> => {
    return Api()
      .company.getTimeReportForStamping(companyId, workerPhoneNumber)
      .then((res) => {
        console.log(res);

        dispatch({ type: "GET_TIME_REPORT_FOR_STAMPING", payload: res.data });
        return res.data;
      })
      .catch((e: any) => {
        console.error("Error when getting time report", e);
        let errorMessage = e.message;

        if (e.response && e.response.data && e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage;
        }

        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: errorMessage, err: e },
        });
        return Promise.reject(e);
      });
  };

const handleCheckInCheckOut =
  (dispatch: CompanyDispatch) =>
  async (
    companyId: number,
    timeReportId: number,
    workerPhoneNumber: string,
    now: boolean
  ): Promise<any> => {
    return Api()
      .company.handleCheckInCheckOut(
        companyId,
        timeReportId,
        workerPhoneNumber,
        now
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: "HANDLE_CHECKIN_CHECKOUT_USER", payload: res.data });
        return res.data;
      })
      .catch((e: any) => {
        console.error("Error handling checking", e);
        let errorMessage = e.message;

        if (e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage;
        }

        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: errorMessage, err: e },
        });
        return Promise.reject(e);
      });
  };

const handleDeleteCalenderShift =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, shiftId: number, gigId: string): Promise<any> => {
    return Api()
      .company.deleteCompaniesGigsShift(companyId, shiftId, gigId)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((e: any) => {
        console.error("Error when trying to delete shift", e);
        let errorMessage = "Unknown error occurred";

        if (e.response && e.response.data) {
          errorMessage = e.response.data.message;
        }

        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: errorMessage, err: e },
        });

        return Promise.reject({
          ...e,
          errorMessage,
        });
      });
  };

const removeCompanyUser =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, userId: number): Promise<any> => {
    return Api()
      .company.deleteCompanyUser(companyId, userId)
      .then(() => {
        dispatch({ type: "REMOVE_COMPANY_USER", payload: userId });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when deleting company User", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
  };

const removeCompanyFavorite =
  (dispatch: CompanyDispatch) =>
  async (companyId: number, workerId: number): Promise<any> => {
    return Api()
      .company.removeCompanyFavorites(companyId, workerId)
      .then(() => {
        dispatch({ type: "REMOVE_COMPANY_FAVORITE", payload: workerId });
        return Promise.resolve();
      })
      .catch((e: Error) => {
        console.error("Error when removing favorite", e);
        dispatch({
          type: "SET_REQUEST_ERROR",
          payload: { message: "", err: e },
        });
        return Promise.reject();
      });
  };

export const companyActions = (
  dispatch: CompanyDispatch,
  state: CompanyState
) => ({
  saveCompany: saveCompany(dispatch),
  getCompany: getCompany(dispatch),
  getCompanyImage: getCompanyImage(dispatch),
  updateCompanyImage: updateCompanyImage(dispatch),
  addCompanyFavorite: addCompanyFavorite(dispatch),
  removeCompanyFavorite: removeCompanyFavorite(dispatch),
  switchCompany: switchCompany(dispatch),
  addCompanyUser: addCompanyUser(dispatch),
  updateCompanyUser: updateCompanyUser(dispatch),
  removeCompanyUser: removeCompanyUser(dispatch),
  getTimeReportForStamping: getTimeReportForStamping(dispatch),
  handleCheckInCheckOut: handleCheckInCheckOut(dispatch),
  handleDeleteCalenderShift: handleDeleteCalenderShift(dispatch),
});
