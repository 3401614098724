import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { Text, CustomLink } from "components/Typography/text.styled";
import {
  ModalBody,
  FlexRow,
  ButtonContainer,
  LinkWrapper,
} from "./timeReportModal.styled";
import { Api } from "services/api/api.service";
import { CustomIcon } from "components/icon/customIcon.component";
import { TimeReportDto } from "model/TimeReport";

type Props = {
  ratingModalOpen: boolean;
  setRatingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  chosenTimeReportWithWorker: TimeReportDto;
  setEndorseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RatingModal: React.FC<Props> = ({
  chosenTimeReportWithWorker,
  ratingModalOpen,
  setRatingModalOpen,
  setEndorseModalOpen,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [timeReportState] = useTimeReportStore();
  const [isLoading, setIsLoading] = useState(false);
  const [currentRating, setCurrentRating] = useState(0);

  const rateWorker = (rating: number) => {
    setIsLoading(true);
    const data = {
      gigId: chosenTimeReportWithWorker.gigId,
      companyId: chosenTimeReportWithWorker.companyId,
      workerId: chosenTimeReportWithWorker.workerId,
      rating: rating,
      role: chosenTimeReportWithWorker.gigRole,
    };
    Api()
      .company.timeReport.rateWorker(data)
      .then(() => {
        setIsLoading(false);
        setRatingModalOpen(false);
        setEndorseModalOpen(true);
      })
      .catch((err) => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "cross",
            title: t("Alerts.OhNo"),
            message: t("Alerts.SomethingWentWrong"),
          },
        });
      });
  };

  return (
    <Modal
      open={ratingModalOpen}
      onClose={() => {
        setRatingModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        <LinkWrapper></LinkWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CustomIcon
            name={"checkmark"}
            color={Color.BurntSienna}
            size={"120px"}
          />
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.SeaBlue600}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.TimeReportCompleted")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.SeaBlue600}
          >
            {t("CompanyTimeReport.HowWasUsersPerformance", {
              user: chosenTimeReportWithWorker.firstName,
            })}
          </Text>
          <FlexRow>
            <CustomIcon
              name={currentRating > 0 ? "star-filled" : "star"}
              color={Color.BurntSienna}
              size={"60px"}
              padding="12px"
              onClick={() => {
                setCurrentRating(1);
                rateWorker(1);
              }}
              style={{ flexShrink: 0 }}
            />
            <CustomIcon
              name={currentRating > 1 ? "star-filled" : "star"}
              color={Color.BurntSienna}
              size="60px"
              padding="12px"
              onClick={() => {
                setCurrentRating(2);
                rateWorker(2);
              }}
              style={{ flexShrink: 0 }}
            />
            <CustomIcon
              name={currentRating > 2 ? "star-filled" : "star"}
              color={Color.BurntSienna}
              size="60px"
              padding="12px"
              onClick={() => {
                setCurrentRating(3);
                rateWorker(3);
              }}
              style={{ flexShrink: 0 }}
            />
            <CustomIcon
              name={currentRating > 3 ? "star-filled" : "star"}
              color={Color.BurntSienna}
              size="60px"
              padding="12px"
              onClick={() => {
                setCurrentRating(4);
                rateWorker(4);
              }}
              style={{ flexShrink: 0 }}
            />
            <CustomIcon
              name={currentRating > 4 ? "star-filled" : "star"}
              color={Color.BurntSienna}
              size="60px"
              padding="12px"
              onClick={() => {
                setCurrentRating(5);
                rateWorker(5);
              }}
              style={{ flexShrink: 0 }}
            />
          </FlexRow>
          <ButtonContainer>
            {timeReportState.isLoading || (isLoading && <CircularProgress />)}
          </ButtonContainer>
          <CustomLink
            color={Color.BurntSienna}
            align="right"
            onClick={() => {
              setRatingModalOpen(false);
              setEndorseModalOpen(true);
            }}
          >
            {t("RegisterCompany.Skip")}
          </CustomLink>
        </div>
      </ModalBody>
    </Modal>
  );
};
