import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail } from "web-apps/company/utils/utils";
import { createCompanyUser } from "services/firebase/firebase.service";
import { Color } from "config/colors";
import { FontSize, FontFamily } from "config/font";
import { CustomLink, H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  TextInput,
  LabelStyled,
  InputContainer,
} from "components/form/inputs/inputs.styled";
import {
  FormStyled,
  RowWrapper,
  RowWrapperTerms,
  TermsContainer,
} from "../registration/registrationContainer/registrationContainer.styled";
import i18n from "services/translation/i18n";
import { CustomIcon } from "components/icon/customIcon.component";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "stores/authStore/authStore";

export const SignUp = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const [, , authDispatch] = useAuthStore();

  const lang = i18n.language;

  const validate = () => {
    if (
      email &&
      password &&
      repeatPassword &&
      !emailError &&
      !passwordError &&
      isChecked
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = () => {
    if (password && password.length < 6) {
      setPasswordError(t("RegisterCompany.PasswordMustBe6Characters"));
    } else if (password !== repeatPassword) {
      setPasswordError(t("RegisterCompany.PasswordDoesNotMatch"));
    } else {
      setPasswordError("");
    }
  };

  const handleSignUp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      createCompanyUser(email, password)
        .then((res) => {
          if (res.user) {
            authDispatch({
              type: "ACCOUNT_SIGN_IN",
              payload: {
                firestoreUser: res.user,
              },
            });
          }

          history.push("/company/registration");
        })
        .catch((error) => {
          let errorCode = error.code;

          switch (errorCode) {
            case "auth/invalid-email":
              setEmailError(t("RegisterCompany.InvalidEmail"));
              break;
            case "auth/email-already-in-use":
              setEmailError(t("RegisterCompany.EmailInUse"));
              break;
            case "auth/weak-password":
              setPasswordError(t("RegisterCompany.WeakPassword"));
              break;
            default:
              setPasswordError(t("RegisterCompany.SomethingWentWrong"));
          }
        });
    }
  };

  useEffect(() => {
    if (validateEmail(email)) {
      setEmail(email.trim());
      setEmailError("");
    } else {
      setEmailError(t("RegisterCompany.InvalidEmail"));
    }
  }, [email]);

  useEffect(() => {
    validatePassword();
  }, [password, repeatPassword]);

  return (
    <FormStyled onSubmit={handleSignUp}>
      <H3>
        {`${t("General.Email")} ${t("RegisterCompany.And")}  ${t(
          "General.Password"
        )}`}
      </H3>
      <InputContainer>
        <LabelStyled>{t("General.Email")}</LabelStyled>
        <TextInput
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value);
          }}
        />
      </InputContainer>
      {emailError && (
        <Text color={Color.Destructive} fontSize={FontSize.Small}>
          {emailError}
        </Text>
      )}
      <InputContainer>
        <LabelStyled>{t("General.Password")}</LabelStyled>
        <TextInput
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.currentTarget.value);
          }}
        />
      </InputContainer>
      <InputContainer>
        <LabelStyled>{t("General.RepeatPassword")}</LabelStyled>
        <TextInput
          type="password"
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.currentTarget.value);
          }}
        />
      </InputContainer>
      {passwordError && (
        <Text color={Color.Destructive} fontSize={FontSize.Small}>
          {passwordError}
        </Text>
      )}
      <TermsContainer>
        <CustomIcon
          name="checkmark"
          size="27px"
          color={Color.White}
          backgroundColor={isChecked ? Color.BurntSienna : Color.White}
          padding="2px"
          onClick={() => {
            setIsChecked(!isChecked);
          }}
          style={{
            border: `2px solid ${Color.BurntSienna}`,
            flexShrink: 0,
            marginLeft: "15px",
          }}
        />
        <RowWrapperTerms>
          <Text fontSize={FontSize.Small} color={Color.SeaBlue600}>
            {t("RegisterCompany.CreatingAccountAgreeTermsOfService")}{" "}
          </Text>
          <Text
            id="termsText"
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratBold}
            onClick={() => {
              window.open(
                `https://www.simflexity.com/${
                  lang === "sv" ? "sv" : "en"
                }/terms-and-condition/?selectedTerms=${t(
                  "General.TermsAndConditionsLink"
                )}`
              );
            }}
          >
            {t("RegisterCompany.TermsOfService")}
          </Text>
          <Text
            fontSize={FontSize.Small}
            color={Color.SeaBlue600}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {` ${t("RegisterCompany.And")} `}
          </Text>
          <Text
            id="termsText"
            fontSize={FontSize.Small}
            color={Color.BurntSienna}
            fontFamily={FontFamily.MontserratBold}
            onClick={() => {
              window.open(
                `https://www.simflexity.com/${
                  lang === "sv" ? "sv" : "en"
                }/terms-and-condition/?selectedTerms=${t(
                  "General.PersonalIntegrityLink"
                )}`
              );
            }}
          >
            {t("RegisterCompany.PrivacyPolicy")}
          </Text>
        </RowWrapperTerms>
      </TermsContainer>
      <ButtonStyled disabled={!validate()}>
        {t("RegisterCompany.SignUp")}
      </ButtonStyled>
      <RowWrapper>
        <CustomLink color={Color.Destructive} to={"./login"}>
          {t("General.Cancel")}
        </CustomLink>
      </RowWrapper>
    </FormStyled>
  );
};
