import { InvoiceDto } from "model/Invoice";
import {
  WorkerWithTimeReports,
  WorkerWithTimeReportsForPersonnelCarrier,
} from "model/TimeReport";

export type TimeReportActions =
  | {
      type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT";
      payload: number;
    }
  | {
      type: "UPDATE_UNHANDLED_TIME_REPORTS";
      payload: WorkerWithTimeReports[];
    }
  | {
      type: "UPDATE_GIG_TIME_REPORTS";
      payload: WorkerWithTimeReports[];
    }
  | {
      type: "UPDATE_MONTHLY_TIME_REPORTS";
      payload: WorkerWithTimeReports[];
    }
  | { type: "SET_IS_LOADING"; payload: boolean }
  | { type: "SET_REQUEST_ERROR"; payload: TimeReportState["error"] }
  | {
      type: "UPDATE_MONTHLY_CARRIER_TIME_REPORTS";
      payload: WorkerWithTimeReportsForPersonnelCarrier[];
    };

export type TimeReportState = {
  isLoading: boolean;
  unhandledTimeReportCount: number;
  unhandledTimeReports: WorkerWithTimeReports[];
  monthlyTimeReports: WorkerWithTimeReports[];
  gigTimeReports: WorkerWithTimeReports[];
  personnelCarrierTimeReports: WorkerWithTimeReportsForPersonnelCarrier[];
  error: { message: string; err: Error } | null;
};

export const timeReportInitState: TimeReportState = {
  isLoading: false,
  unhandledTimeReportCount: 0,
  unhandledTimeReports: [],
  monthlyTimeReports: [],
  gigTimeReports: [],
  personnelCarrierTimeReports: [],
  error: null,
};

export const TimeReportStateReducer = (
  state: TimeReportState,
  action: TimeReportActions
): TimeReportState => {
  switch (action.type) {
    case "UPDATE_UNHANDLED_TIME_REPORT_COUNT": {
      return { ...state, unhandledTimeReportCount: action.payload };
    }
    case "UPDATE_UNHANDLED_TIME_REPORTS": {
      return { ...state, unhandledTimeReports: action.payload };
    }
    case "UPDATE_MONTHLY_TIME_REPORTS": {
      return { ...state, monthlyTimeReports: action.payload };
    }
    case "UPDATE_GIG_TIME_REPORTS": {
      return { ...state, gigTimeReports: action.payload };
    }
    case "SET_IS_LOADING": {
      return { ...state, isLoading: action.payload };
    }
    case "SET_REQUEST_ERROR": {
      return { ...state, error: action.payload };
    }
    case "UPDATE_MONTHLY_CARRIER_TIME_REPORTS": {
      return { ...state, personnelCarrierTimeReports: action.payload};
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};
