import React from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import {
  NotFilledGigModalBody,
} from "./weeklyCalendar.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { CompanyCalendarGigDto, CompanyCalendarShift } from "model/Calendar";

type NotFilledGigModalProps = {
  showNotFilledModal: boolean;
  setShowNotFilledModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteShift: (shiftId: number, gigId: string) => void;
  shiftData?: CompanyCalendarGigDto;
  clickedShiftId?: number;
};

export const NotFilledGigModal: React.FC<NotFilledGigModalProps> = ({
  showNotFilledModal,
  setShowNotFilledModal,
  deleteShift,
  shiftData,
  clickedShiftId,
}) => {
  const { t } = useTranslation();


  return (
    <Modal
      open={showNotFilledModal}
      onClose={() => {
        setShowNotFilledModal(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <NotFilledGigModalBody>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.SeaBlue600}
          fontSize={FontSize.H3}
        >
          {t("CalendarCompany.NotFilled")}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratRegular}
          color={Color.SeaBlue600}
        >
          {t("CalendarCompany.ThisGigHasNotBeenFilled")}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <ButtonStyled onClick={() => setShowNotFilledModal(false)}>
            {t("CalendarCompany.Ok")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.Destructive}
            onClick={() => {
              if (shiftData && clickedShiftId) {
                deleteShift(clickedShiftId, shiftData.id.toString());
              }
              setShowNotFilledModal(false)
            }}
          >
            {t("CompanyTimeReport.DeleteShift")}
          </ButtonStyled>
        </div>
      </NotFilledGigModalBody>
    </Modal>
  );
};
