import React from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { useAuthStore } from "stores/authStore/authStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CalendarContainer } from "components/pageLayouts/pageLayouts.styled";
import { BannerComponent } from "components/banners/banner.component";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { WeeklyCalendarComponent } from "web-apps/company/components/calendar/weeklyCalendar.component";

export const CalendarPage = () => {
  const { t } = useTranslation();

  const [authState] = useAuthStore();
  const [companyState] = useCompanyStore();

  return (
    <CalendarContainer>
      {authState.isLoading || companyState.isLoading ? (
        <LoaderProgress />
      ) : (
        <>
          {companyState.company?.verified ? (
            <WeeklyCalendarComponent />
          ) : (
            <BannerComponent
              icon="clock"
              backgroundColor={Color.ChampagnePink}
              fill={Color.Destructive}
            >
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {t("GigsCompany.UnverifiedAccount")}
              </Text>
              <Text>{t("GigsCompany.UnverifiedAccountDetails")}</Text>
            </BannerComponent>
          )}
        </>
      )}
    </CalendarContainer>
  );
};
